/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
/* Common Platform CSS */
@-ms-viewport {
  width: device-width;
  user-zoom: fixed;
  max-zoom: 1;
  min-zoom: 1;
}
@media (orientation: landscape) {
  .km-tablet .km-on-ios.km-horizontal.km-web:not(.km-ios-chrome) {
    position: fixed;
    bottom: 0;
  }
}
.km-root {
  font-size: .92em;
}
.km-root.km-retina input,
.km-root.km-retina select,
.km-root.km-retina textarea {
  font-size: 1em;
}
.km-root a {
  color: inherit;
}
.km-tablet {
  font-size: 1em;
}
.km-root *:focus {
  outline-width: 0;
}
.km-root,
.km-pane,
.km-pane-wrapper {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
  overflow-x: hidden;
}
.km-pane-wrapper {
  position: absolute;
}
.km-pane,
.km-shim {
  font-family: sans-serif;
}
.km-pane {
  overflow-x: hidden;
  position: relative;
}
.km-vertical .km-collapsible-pane {
  position: absolute;
  z-index: 2 !important;
  -webkit-transition: -webkit-transform 350ms ease-out;
          transition: transform 350ms ease-out;
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-vertical .km-expanded-splitview .km-collapsible-pane {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.km-expanded-pane-shim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.km-expanded-splitview .km-expanded-pane-shim {
  display: block;
  z-index: 1;
}
.km-root > * {
  margin: 0;
  padding: 0;
}
.km-root * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.km-content {
  display: block;
}
.km-view,
.km-split-content {
  top: 0;
  left: 0;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
  vertical-align: top;
}
.k-ff .km-view,
.k-ff .km-pane {
  overflow: hidden;
}
.k-ff18 .km-view,
.k-ff18 .km-pane,
.k-ff19 .km-view,
.k-ff19 .km-pane,
.k-ff20 .km-view,
.k-ff20 .km-pane,
.k-ff21 .km-view,
.k-ff21 .km-pane {
  position: relative;
}
.k-ff .km-view {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.km-content {
  min-height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  flex-align: stretch;
  width: auto;
  overflow: hidden;
  position: relative;
}
.km-content p,
.km-content h1,
.km-content h2,
.km-content h3,
.km-content h4,
.km-content h5,
.km-content h6 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.km-header,
.km-footer {
  display: block;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.km-header {
  padding: 0;
}
.km-footer {
  background: #1a1a1a;
}
[data-role="layout"] {
  display: none;
}
/**
 * The angular tags will be converted to div kendo-mobile-view
 *
 */
[data-role="view"],
[data-role="drawer"],
kendo-mobile-view,
kendo-mobile-split-view,
kendo-mobile-drawer {
  visibility: hidden;
}
.km-view {
  visibility: visible;
}
.km-header,
.km-footer {
  position: relative;
  z-index: 1;
}
@media all and (-webkit-min-device-pixel-ratio: 10000), not all and (-webkit-min-device-pixel-ratio: 0) {
  .km-view {
    display: table;
  }
  .km-header,
  .km-footer,
  .km-content {
    display: table-row;
  }
  .km-header,
  .km-footer {
    height: 1px;
  }
}
.km-root .k-toolbar,
.km-navbar,
.km-button,
.km-buttongroup,
.km-tabstrip,
.km-blackberry li.km-actionsheet-cancel > a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-background-origin: border-box;
          background-origin: border-box;
  position: relative;
  display: inline-block;
  padding: .4em .7em;
  margin: .1rem;
  overflow: visible;
  text-decoration: none;
}
.km-tabstrip,
.km-root .k-toolbar,
.km-navbar {
  display: block;
  padding: .8em;
  margin: 0;
  width: 100%;
  border-width: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-native-scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
}
.km-default-content {
  padding: 1em;
}
.km-shim {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10001;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-root .km-shim {
  position: absolute;
}
.km-shim:before {
  content: "\a0";
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: middle;
}
.km-shim .k-animation-container {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  width: auto;
}
/* Loader */
.km-loader {
  top: 50%;
  left: 50%;
  width: 180px;
  height: 130px;
  z-index: 100000;
  padding: 30px 30px;
  position: absolute;
  margin-top: -70px;
  margin-left: -90px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}
.km-loader h1 {
  font-size: 1rem;
  color: white;
  text-align: center;
  vertical-align: middle;
}
.km-loader .km-loading,
.km-load-more .km-icon,
.km-scroller-refresh .km-icon {
  -webkit-animation: km-spin 1s infinite linear;
          animation: km-spin 1s infinite linear;
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  font-size: 35px;
}
.km-loader .km-loading:after,
.km-load-more .km-icon:after {
  color: #ccc;
}
.km-loading-left,
.km-loading-right {
  display: none;
}
@-webkit-keyframes km-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes km-ios-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes km-ios-spin1 {
  from {
    -webkit-transform: rotate(-135deg);
  }
  to {
    -webkit-transform: rotate(225deg);
  }
}
@keyframes km-ios-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes km-ios-spin1 {
  from {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  to {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
}
/* Stretched View */
.km-stretched-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.km-stretched-view > * {
  width: 100%;
}
.km-stretched-view > .km-pane-wrapper {
  position: static;
}
.km-stretched-view > .km-pane-wrapper > .km-pane {
  position: static;
}
.km-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100000;
}
.km-root.km-native-scrolling,
.km-root.km-native-scrolling .km-view,
.km-root.km-native-scrolling .km-splitview .km-pane {
  min-height: 100%;
  height: auto;
  -webkit-transform: none;
  overflow-x: visible;
}
.km-native-scrolling,
.km-native-scrolling .km-pane,
.km-native-scrolling .km-view {
  -ms-touch-action: auto;
}
.km-native-scrolling .km-pane,
.km-native-scrolling .km-view {
  display: block;
}
.km-native-scrolling .km-content {
  -ms-flex: auto;
}
.km-native-scrolling .km-blackberry .km-content {
  min-height: auto;
}
/* Restore position:absolute during animation */
.km-native-scrolling .km-splitview {
  position: absolute;
}
.km-native-scrolling .km-header {
  position: fixed;
  top: 0;
}
.km-native-scrolling .km-android .km-header {
  top: auto;
  bottom: 0;
}
.km-native-scrolling .km-footer {
  position: fixed;
  bottom: 0;
}
.km-native-scrolling .km-android .km-footer {
  top: 0;
  bottom: auto;
}
.km-native-scrolling .km-badge {
  z-index: auto;
}
.km-native-scrolling .km-splitview .km-header,
.km-native-scrolling .km-splitview .km-footer,
.km-native-scrolling .km-popup.km-pane .km-header,
.km-native-scrolling .km-popup.km-pane .km-footer {
  position: absolute;
}
.km-native-scrolling .km-modalview .km-header,
.km-native-scrolling .km-modalview .km-footer {
  position: relative;
}
.km-native-scrolling .km-content {
  width: 100%;
}
.km-native-scrolling .km-shim,
.km-native-scrolling .km-popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto !important;
}
.km-native-scrolling .km-drawer {
  position: fixed;
  top: 0;
  height: 100% !important;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}
.km-native-scrolling > .km-pane > .km-loader {
  position: fixed;
}
.km-native-scrolling .km-header,
.km-native-scrolling .km-footer {
  z-index: 2;
}
/* Disabled states */
.km-state-disabled {
  opacity: 0.5;
}
.km-badge,
.km-detail {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  height: 2em;
  font-size: .6rem;
  text-shadow: none;
}
.km-badge {
  top: -1em;
  right: -1em;
  line-height: 2em;
  margin-left: .5em;
  min-width: .9em;
  padding: 0 .55em;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.km-tabstrip .km-badge {
  top: -0.2em;
  right: auto;
  margin-left: -1em;
}
/* DetailButtons */
.km-detail {
  position: absolute;
  float: right;
  right: .8rem;
  top: 50%;
  margin-top: -0.7rem;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-widget .km-detaildisclose {
  font-size: .8em;
}
.k-ff .km-detail {
  width: 1rem;
  height: 1rem;
}
.km-detail .km-text {
  display: none;
}
.km-widget .km-rowinsert:after,
.km-widget .km-rowdelete:after,
.km-widget .km-contactadd:after,
.km-widget .km-detaildisclose:after,
.km-widget .km-rowinsert:before,
.km-widget .km-rowdelete:before,
.km-widget .km-contactadd:before,
.km-widget .km-detaildisclose:before,
.km-detail .km-icon {
  left: .15em;
  top: .15em;
  line-height: 1em;
  font-size: 1em;
  position: absolute;
}
.km-widget .km-detaildisclose:after {
  left: .1em;
  top: .25em;
  text-align: center;
  font-weight: bold;
}
/* Buttons */
.km-button {
  cursor: pointer;
  outline: 0;
  text-align: center;
}
button.km-button {
  display: inline-block;
  font: inherit;
}
.km-button:hover {
  text-decoration: none;
}
.km-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.km-ios .km-state-disabled .km-button,
.km-android .km-state-disabled .km-button,
.km-blackberry .km-state-disabled .km-button,
.km-meego .km-state-disabled .km-button {
  color: #aaa;
  text-shadow: none;
}
.km-root .km-pane .k-button:focus,
.km-root .km-pane .k-button:active,
.km-root .km-pane .k-button:focus:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-buttongroup {
  padding: .4rem .7rem;
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
  margin: .5em auto;
}
.km-widget.km-buttongroup {
  padding: 0;
  border-color: transparent;
  background: none;
  white-space: nowrap;
  display: table;
}
.km-buttongroup > .km-button {
  display: table-cell;
}
.km-widget.km-buttongroup .km-button {
  margin: 0;
  border-width: 1px 0 1px 1px;
  padding: .48em .9em .44em;
}
.km-tablet .km-buttongroup .km-button {
  padding: .4em .8em .34em;
}
.km-widget.km-navbar .km-buttongroup {
  font-size: .95rem;
  line-height: 1em;
  margin: 0 0 .2em;
  display: inline-block;
  height: 1.5em;
  top: -2px;
}
.k-toolbar .km-buttongroup {
  margin: 0;
  display: inline-block;
}
.km-tablet .km-navbar .km-buttongroup {
  top: -1px;
}
.km-widget.km-navbar .km-buttongroup > .km-button {
  font-size: 1em;
  min-width: 4rem;
  text-align: center;
}
.km-tablet .km-navbar .km-buttongroup > .km-button {
  min-width: 6rem;
}
.km-view .km-buttongroup .km-button:last-child {
  border-right-width: 1px;
}
.km-ios .km-buttongroup .km-button {
  font-size: 1.2em;
  font-weight: bold;
}
.km-collapsible {
  margin: 1em 0;
}
.km-collapsible.km-collapsibleinset {
  margin: 1em;
}
.km-collapsible + .km-collapsible {
  margin-top: -1em;
}
.km-collapsible-header {
  position: relative;
  border-style: solid;
  border-width: 1px 0;
  padding: .4em 20px;
}
.km-collapsibleinset > .km-collapsible-header {
  border-width: 1px;
}
.km-collapsible + .km-collapsible > .km-collapsible-header {
  border-top-width: 0;
}
.km-collapsibleinset.km-collapsed > .km-collapsible-header {
  border-radius: .5em;
}
.km-collapsibleinset.km-expanded > .km-collapsible-header {
  border-radius: .5em .5em 0 0;
}
.km-collapsible-header .km-icon {
  display: inline-block;
  font-size: .8em;
  margin-right: .3em;
}
.km-collapsible-header > h1,
.km-collapsible-header > h2,
.km-collapsible-header > h3,
.km-collapsible-header > h4,
.km-collapsible-header > h5,
.km-collapsible-header > h6 {
  margin: 0;
}
.km-collapsible-content {
  border-style: solid;
  border-width: 1px 0;
  border-top: 0;
  padding: .4em;
  overflow: hidden;
}
.km-collapsible-header .km-arrow-s,
.km-collapsible-header .km-arrow-n {
  position: absolute;
  top: .4em;
}
.km-icon-left .km-arrow-s,
.km-icon-left .km-arrow-n {
  left: .2em;
}
.km-icon-right .km-arrow-s,
.km-icon-right .km-arrow-n {
  left: auto;
  right: .2em;
}
.km-icon-top .km-arrow-s,
.km-icon-top .km-arrow-n {
  position: static;
  display: block;
  margin: 0 auto;
  width: 1em;
}
.km-collapsibleinset > .km-collapsible-content {
  border-radius: 0 0 .5em .5em;
  border-width: 1px;
}
.km-collapsed > .km-collapsible-content.km-animated {
  -webkit-transform: translateY(-0.8em);
      -ms-transform: translateY(-0.8em);
          transform: translateY(-0.8em);
  border-color: transparent;
  border-bottom: none;
  visibility: hidden;
}
.km-animated {
  -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
}
.km-hide-title {
  display: none;
}
.km-show-title:after {
  display: block;
  content: "\a0";
  height: 0;
}
.km-fill-title:after {
  height: auto;
}
.km-footer .km-show-title:after {
  display: inline-block;
}
.km-view-title,
.km-dialog-title {
  position: relative;
  visibility: visible;
  text-align: center;
  font-size: 1.4em;
  line-height: 2.3em;
  margin-left: auto;
  margin-right: auto;
}
.km-horizontal .km-view-title {
  line-height: 2em;
}
.km-root .k-toolbar,
.km-navbar {
  padding: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  overflow: hidden;
  display: block;
  border-width: 0 0 1px 0;
  background-color: #fff;
}
.k-ff.km-root .k-toolbar,
.k-ff .km-navbar {
  overflow: visible;
}
.km-navbar .km-button {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.km-navbar .km-no-title {
  padding-top: .7rem;
  padding-bottom: .7rem;
}
.km-horizontal .km-navbar .km-button {
  margin-top: .3rem;
  margin-bottom: .3rem;
}
.km-horizontal .km-navbar .km-no-title {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.km-tablet.km-root .km-no-title {
  padding-top: .55rem;
  padding-bottom: .55rem;
}
.km-tablet .km-navbar .km-button {
  margin-top: .45rem;
  margin-bottom: .45rem;
}
.km-root .km-pane .km-navbar .km-no-title {
  visibility: visible;
  line-height: 0;
}
/* Navbar */
.km-on-ios.km-black-translucent-status-bar.km-app .km-header .km-navbar {
  padding-top: 1.4em;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-on-ios.km-ios5.km-cordova .km-header .km-navbar,
.km-on-ios.km-ios6.km-cordova .km-header .km-navbar {
  padding-top: 0;
}
.km-leftitem,
.km-rightitem {
  z-index: 1;
  position: absolute;
  right: .5em;
}
.km-popup .km-rightitem {
  right: 0;
}
.km-leftitem {
  left: .5em;
  right: auto;
}
.km-popup .km-leftitem {
  left: 0;
}
/* Center left/right item contents */
.km-leftitem,
.km-rightitem {
  height: 100%;
}
.km-on-ios.km-black-translucent-status-bar.km-app .km-leftitem,
.km-on-ios.km-black-translucent-status-bar.km-app .km-rightitem {
  height: auto;
}
.km-leftitem > *,
.km-rightitem > * {
  display: inline-block;
  vertical-align: middle;
}
.km-leftitem:before,
.km-rightitem:before {
  content: "\a0";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
/* Toolbar */
.km-root .k-toolbar {
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: right;
  line-height: 2.2em;
  border-style: solid;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: .55em 3.4em .55em .5em;
}
.km-root .km-widget.k-toolbar {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-root .k-toolbar span.km-icon {
  vertical-align: middle;
}
.km-root .k-toolbar .k-button-icon {
  padding-left: .4em;
  padding-right: .4em;
}
.km-root .k-toolbar .k-button-icon .km-icon {
  margin-left: 0;
  margin-right: 0;
}
.km-root .k-toolbar-resizable {
  overflow: hidden;
  white-space: nowrap;
}
.km-root .k-toolbar > * {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  line-height: inherit;
}
.km-root .k-toolbar .km-button {
  line-height: inherit;
}
.km-root div.k-toolbar > .k-align-left {
  float: left;
  line-height: inherit;
}
.km-root div.k-toolbar > .k-align-right {
  float: none;
}
.km-root .k-toolbar > .km-button,
.km-root .k-toolbar .km-buttongroup,
.km-root .k-toolbar .k-split-button,
.km-root .k-toolbar .k-widget,
.km-root .k-toolbar .km-widget,
.km-root .k-toolbar .k-textbox,
.km-root .k-toolbar label,
.km-root .k-toolbar .k-separator {
  margin: 0 .4em;
}
.km-root .k-toolbar .k-button-icontext .km-icon {
  margin-left: -0.15em;
}
.km-root .k-toolbar .k-split-button {
  padding-left: 0;
}
.km-root .k-toolbar .k-split-button .km-button,
.km-root .k-toolbar .km-buttongroup .km-group-start {
  margin: 0;
}
.km-root .k-toolbar .k-split-button > .km-button {
  padding-left: 1em;
  padding-right: .6em;
}
.km-root .k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 0 0 -1px;
  padding-left: .2em;
  padding-right: .2em;
}
.km-root .km-pane .k-toolbar .k-overflow-anchor {
  border-width: 0;
  width: 1.5em;
  height: 100%;
  margin: 0;
  font-size: 2.2em;
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}
.km-root .k-overflow-anchor span.km-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.5em;
}
.km-root .k-overflow-anchor .km-icon:after,
.km-root .k-overflow-anchor .km-icon:before {
  margin-left: 0;
}
.km-root .k-overflow-container .k-item {
  float: none;
  border: 0;
}
.km-root .k-overflow-container .k-overflow-button,
.km-root .k-split-container .km-button {
  text-align: left;
  display: block;
  white-space: nowrap;
  margin: 0 0 1px;
}
.km-root .k-overflow-container li:last-child .k-overflow-button,
.km-root .k-split-container li:last-child .km-button {
  margin: 0;
}
.km-root .k-overflow-container .km-buttongroup {
  padding: 0;
}
.km-root .k-overflow-container .km-buttongroup > li {
  display: block;
}
.km-root .k-overflow-container .k-overflow-group {
  border-width: 1px 0;
  border-style: solid;
  border-radius: 0;
  padding: 1px 0 0;
  margin: 0 0 1px;
}
.km-root .k-overflow-container .km-state-disabled {
  opacity: 1;
}
.km-root .k-overflow-container .k-overflow-hidden {
  display: none;
}
.km-root .k-overflow-container .k-toolbar-first-visible,
.km-root .k-overflow-container .k-overflow-group + .k-overflow-group {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}
.km-root .k-overflow-container .k-toolbar-last-visible {
  border-bottom: 0;
  margin-bottom: 0;
}
.km-root .k-overflow-wrapper .km-actionsheet-wrapper .km-actionsheet {
  overflow: hidden;
  overflow-y: auto;
}
.km-tabstrip {
  padding: .4rem .7rem;
}
.km-horizontal .km-tabstrip {
  padding: .2rem .7rem;
}
.km-tabstrip {
  flex-align: start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -moz-box-pack: start;
  padding: 0;
  text-align: center;
  word-spacing: -1em;
}
.km-tabstrip .km-button {
  word-spacing: normal;
  -webkit-box-shadow: none;
          box-shadow: none;
  vertical-align: bottom;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .km-tabstrip {
    word-spacing: normal;
    width: 100%;
  }
}
.km-tabstrip .km-button {
  font-family: Arial, Helvetica, sans-serif;
  color: #a8a8a8;
  padding: .4em .8em;
  border-width: 0;
  border-color: transparent;
  background: none;
  margin: 0;
  text-align: center;
}
.km-tabstrip .km-button:first-child {
  border-left: 0;
}
.km-tabstrip .km-button:last-child {
  border-right: 0;
}
.km-switch input[type=checkbox] {
  display: none;
}
.km-switch,
.km-checkbox {
  text-align: left;
  font-size: 1rem;
  display: inline-block;
  width: 6.4rem;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  overflow: hidden;
}
.km-switch-wrapper,
.km-slider-wrapper {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.km-switch-background,
.km-slider-background {
  display: block;
  margin: 0 1px 1px -5em;
  height: 100%;
  width: 200%;
}
.km-switch-container {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-checkbox {
  width: 1.8rem;
  height: 1.8rem;
}
.km-checkbox-checked:after {
  content: "\a0";
  display: block;
  width: 100%;
  height: 100%;
}
.km-switch-handle {
  top: 0;
  left: 0;
  width: 2.72em;
  height: 100%;
  display: inline-block;
  margin: -1px 0 0 -1px;
  background-color: #000;
}
.km-switch-label-on,
.km-switch-label-off {
  display: block;
  width: 130%;
  font-size: 1em;
  line-height: 2em;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
}
.km-switch-label-off {
  left: 104%;
}
.km-switch-label-on {
  left: -134%;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}
.km-list .km-switch {
  position: absolute;
  top: 50%;
  right: .8rem;
  margin-top: -1rem;
}
.km-listview-link:after {
  width: .5rem;
  height: .5rem;
  content: "\a0";
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.2rem;
  border-style: solid;
  border-width: .24rem .24rem 0 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.km-listview-wrapper > ul:not(.km-listview) {
  margin: 0 auto;
}
.km-list,
.km-listview {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.km-listinset,
.km-listgroupinset {
  margin: 1em;
}
.k-ff .km-listinset:after,
.k-ff .km-listgroupinset:after {
  display: block;
  height: 0;
  content: "\a0";
}
.km-listinset,
.km-listgroupinset .km-list {
  overflow: hidden;
}
.km-listview .km-switch {
  margin-top: -0.95rem;
  position: absolute;
  right: .8rem;
  top: 50%;
}
.km-listview .km-list {
  text-indent: 0;
}
.km-list > li,
.km-widget .km-listview-link,
.km-widget .km-listview-label {
  margin: 0;
  display: block;
  position: relative;
  list-style-type: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: .5em .7em;
}
.km-list > li {
  line-height: 1.6em;
  overflow: hidden;
}
.km-virtual-list {
  position: relative;
  width: 100%;
  -webkit-transform: translateZ(0);
}
.km-virtual-list > li {
  width: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateZ(0);
}
.km-widget.km-list .km-load-more,
.km-widget .km-list .km-load-more {
  border-bottom: 0;
}
.km-list > li > * {
  line-height: normal;
}
.km-group-title {
  display: block;
  font-weight: bold;
  padding: .2em 0;
  text-indent: .8em;
}
.km-listgroupinset .km-group-title {
  margin-top: .65em;
  line-height: 2em;
}
.km-list:not(.km-virtual-list) > li:first-child {
  border-top-width: 0;
}
.km-list:not(.km-virtual-list) > li:last-child {
  border-bottom-width: 0;
}
.km-widget .km-listview-link,
.km-widget .km-listview-label {
  line-height: inherit;
  text-decoration: none;
  margin: -0.5em -0.7em;
}
.km-listview-link:after,
.km-listview-label:after {
  border-color: #777;
  content: "\a0";
  display: block;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -0.32rem;
}
/* Filtering */
.km-filter-form {
  width: 100%;
  padding: .5em 0;
  border: 1px solid transparent;
  border-width: 1px 0;
  -webkit-transform: translatez(0);
      -ms-transform: translatez(0);
          transform: translatez(0);
}
.km-filter-wrap {
  position: relative;
  margin: 0 .7em;
  padding: .2em .4em;
  border: 1px solid transparent;
}
.km-widget .km-filter-wrap:before {
  display: inline-block;
  vertical-align: middle;
  content: "\e0e9";
  font-size: 1.6em;
  width: 1em;
  height: 1em;
  margin-right: -1em;
  color: inherit;
}
.km-tablet .km-filter-wrap {
  max-width: 24em;
  margin: 0 auto;
}
.km-filter-wrap > input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
.km-filter-wrap input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  vertical-align: middle;
  padding: 0 1.4em;
}
.km-filter-reset {
  display: inline-block;
  margin-left: -1.6em;
  vertical-align: middle;
  text-align: center;
  z-index: 1;
  text-decoration: none;
  height: 100%;
}
.km-filter-reset .km-clear {
  font-size: 1.6em;
  width: 1em;
  height: 1em;
  display: block;
}
.km-filter-reset > .km-text {
  position: absolute;
  top: -3333px;
  left: -3333px;
}
/* Load more */
.km-load-more {
  display: block;
  padding: .3em 0 1.2em;
  height: 3.2em;
  text-align: center;
}
.km-list .km-listview-item-badge.km-badge,
.km-list .km-listview-link > .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4em;
}
/* Pull to refresh */
.km-scroller-pull {
  width: 100%;
  display: block;
  position: absolute;
  line-height: 3em;
  font-size: 1.4em;
  text-align: center;
  -webkit-transform: translate3d(0, -3em, 0);
          transform: translate3d(0, -3em, 0);
}
.km-scroller-pull .km-template {
  display: inline-block;
  min-width: 200px;
  text-align: left;
}
.km-load-more .km-icon,
.km-widget .km-scroller-pull .km-icon {
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
  width: 2rem;
  font-size: 2rem;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 300ms linear;
          transition: transform 300ms linear;
}
.km-widget .km-scroller-release .km-icon {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.km-widget .km-scroller-refresh .km-icon {
  -webkit-transition: none;
          transition: none;
}
/* Scroller */
.km-touch-scrollbar {
  position: absolute;
  visibility: hidden;
  z-index: 200000;
  height: .4em;
  width: .4em;
  background-color: #333;
  opacity: 0;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity 0.3s linear;
          transition: opacity 0.3s linear;
}
.km-vertical-scrollbar {
  height: 100%;
  right: 2px;
  top: 0;
}
.km-horizontal-scrollbar {
  width: 100%;
  left: 0;
  bottom: 2px;
}
.km-scrollview,
.km-scroll-container {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -moz-user-select: -moz-none;
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
}
.km-scroll-wrapper {
  position: relative;
}
.km-scroll-header {
  position: absolute;
  z-index: 1001;
  width: 100%;
  top: 0;
  left: 0;
}
.km-scrollview {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.km-scrollview > div > * {
  -webkit-transform: translatez(0);
}
.km-scrollview > div > [data-role=page] {
  vertical-align: top;
  display: inline-block;
  min-height: 1px;
}
.km-scrollview .km-virtual-page {
  min-height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}
.k-ff18 .km-scrollview > div,
.k-ff19 .km-scrollview > div,
.k-ff20 .km-scrollview > div,
.k-ff21 .km-scrollview > div {
  width: 0;
}
.km-pages {
  text-align: center;
  margin: 0;
  padding: .6em 0 0;
  height: 1.5em;
}
.km-pages li {
  display: inline-block;
  width: .5em;
  height: .55em;
  margin: 0 .3em;
}
/* PopUp + ActionSheet */
.km-root .km-popup .k-item,
.km-widget.km-actionsheet > li {
  list-style-type: none;
  padding: inherit 1em;
  border-bottom: 1px solid #555;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-widget.km-actionsheet > li {
  line-height: 2em;
  border-bottom: 0;
}
.km-widget.km-actionsheet > li > a {
  line-height: 1.5em;
  text-align: left;
  background: transparent;
}
.km-root .km-popup .k-list,
.km-widget.km-actionsheet {
  padding: 0;
  margin: 0;
}
.km-root .km-popup .k-item:last-child,
.km-widget.km-actionsheet > li:last-child {
  border: 0;
}
.km-widget.km-actionsheet-wrapper {
  width: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
}
.km-actionsheet-root.km-shim .k-animation-container {
  width: 100% !important;
  height: 100% !important;
}
.km-tablet .km-pane div.km-actionsheet-phone {
  background: transparent;
}
.km-tablet .km-actionsheet-phone li.km-actionsheet-title,
.km-tablet div.km-actionsheet-phone li.km-actionsheet-cancel {
  display: block;
}
/* PopOver */
.km-popover-root .km-popup-wrapper {
  position: relative !important;
}
.km-popup-wrapper,
.km-modalview-wrapper {
  z-index: 10001;
  position: relative;
  background: none;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10002;
}
.km-popup-arrow,
.km-popup-arrow:after,
.km-popup-arrow:before {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 0;
  z-index: 2;
}
.km-left .km-popup-arrow,
.km-right .km-popup-arrow {
  margin-top: -8px;
}
.km-up .km-popup-arrow,
.km-down .km-popup-arrow {
  margin-left: -8px;
}
.km-popup-arrow:after,
.km-popup-arrow:before {
  display: block;
  content: "\a0";
  width: 0;
  height: 0;
}
.km-up .km-popup-arrow {
  top: auto;
  bottom: 0;
}
.km-left .km-popup-arrow {
  left: auto;
  right: 0;
}
.km-popup.km-pane {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background: transparent;
}
.km-popover-root .km-view {
  position: relative;
}
.km-popover-root .km-content {
  -ms-flex: auto;
}
/* SplitView */
div.km-splitview > .km-content,
kendo-mobile-split-view.km-splitview > .km-content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
div.km-split-vertical > .km-content,
kendo-mobile-split-view.km-split-vertical > .km-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
div.km-split-content > .km-pane {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
      -ms-flex: 2;
          flex: 2;
  width: auto;
  height: auto;
}
div.km-split-content > .km-pane:first-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
div.km-split-horizontal > .km-content > .km-pane {
  top: 0;
  bottom: 0;
}
.km-split-vertical > .km-content > .km-pane > .km-view {
  display: -webkit-box;
}
/* ModalView */
.km-modalview-root {
  text-align: center;
}
.km-modalview-root > .k-animation-container {
  text-align: left;
  position: relative !important;
  top: auto !important;
  left: auto !important;
  display: inline-block !important;
  vertical-align: middle;
}
.km-modalview,
.km-modalview-wrapper:before {
  overflow: hidden;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  max-height: 100%;
}
.km-modalview .km-content {
  box-flex: 1;
}
.km-auto-height .km-content {
  -ms-flex: auto;
}
.km-native-scrolling .km-view.km-modalview {
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.km-modalview-root:before,
.km-modalview-wrapper:before {
  vertical-align: middle;
  height: 100%;
  margin-left: -1px;
  content: "\a0";
  width: 0px;
  display: inline-block;
}
/* Drawer */
.km-drawer,
[data-role=drawer] {
  top: 0;
  left: auto;
  width: 250px;
}
.km-drawer .km-header,
.km-drawer .km-footer {
  z-index: 0;
}
.km-left-drawer {
  left: 0;
}
.km-right-drawer {
  right: 0;
}
/* Forms and icons */
.km-item label:before,
.km-item label.km-item-checked:after {
  position: absolute;
  content: " ";
  display: block;
  top: 50%;
  left: .6em;
  width: 36px;
  height: 36px;
  margin-top: -18px;
}
/* Slider */
.km-widget .k-slider {
  line-height: .6em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.km-widget .k-slider-horizontal {
  width: 50%;
  height: .6em;
  line-height: .6em;
}
.km-list .k-slider {
  position: absolute;
  right: 0;
  margin-top: -0.5em;
  top: 50%;
}
.km-root .k-slider-track {
  left: 1em !important;
  right: 1em;
  height: 100%;
  display: block;
  position: absolute;
  border: .5em solid transparent;
  border-width: .5em 0;
}
.km-widget .k-slider-horizontal .k-slider-track {
  width: auto !important;
}
.km-widget .k-slider .k-slider-track {
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-widget .k-slider-track,
.km-widget .k-slider-selection {
  margin-top: 0;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.km-widget .k-slider-horizontal .k-slider-selection {
  top: 0;
  height: 100%;
}
.km-widget .k-slider-items {
  margin: 0;
}
.km-widget .k-slider .k-draghandle {
  text-indent: -3333px;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  display: block;
  position: absolute;
}
.km-widget .k-slider-tooltip {
  display: none;
}
/* Dialog */
.km-dialog {
  position: absolute;
  min-width: 19em;
  max-width: 25em;
  overflow: hidden;
}
.km-dialog-title {
  position: static;
  float: none;
  height: 2.6em;
  margin-top: -2.6em;
  font-size: 1.22em;
  line-height: 3em;
}
.km-dialog:before {
  content: "\a0";
  display: block;
  overflow: visible;
  width: 100%;
  height: 3em;
  opacity: .2;
}
.km-dialog-content {
  font-weight: normal;
  min-height: 2em;
  text-align: center;
}
.km-dialog .km-button {
  display: block;
  margin: .4em;
  font-size: 1.3em;
  text-align: center;
  padding: .44em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* Form elements */
.km-list input[type=text]:not(.k-input),
.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=file],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list select:not([multiple]),
.km-list .k-dropdown,
.km-list textarea {
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.2em;
  position: absolute;
  top: 50%;
  line-height: normal;
  z-index: 1;
  right: 0;
  margin-top: -1em;
}
.km-widget .k-slider .k-tick,
.km-widget .k-slider .k-label,
.km-widget .k-slider .k-button {
  display: none;
}
.km-list textarea {
  position: relative;
  width: -webkit-calc(50% + .7em);
  width: calc(50% + .7em);
  margin-right: -0.7em;
}
.km-list input,
.km-list select,
.km-list textarea,
.km-list input[type=checkbox],
.km-list input[type=radio] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.km-list input[type=checkbox],
.km-list input[type=radio] {
  position: absolute;
  top: 50%;
  right: .7em;
  margin-top: -0.5em;
  background: none;
}
.km-widget input,
.km-widget textarea {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.km-widget input[readonly],
.km-widget input[type=image],
.km-widget select:not([multiple]) {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.km-list textarea {
  top: 0;
}
.km-list .k-dropdown {
  line-height: 1.4em;
}
.km-list .k-dropdown,
.km-list .k-dropdown .k-input {
  background-color: transparent;
}
.km-list .k-dropdown-wrap {
  display: inline-block;
}
.km-list .km-listview-label:after,
.km-list input ~ .km-listview-link:after,
.km-list textarea ~ .km-listview-link:after,
.km-list select ~ .km-listview-link:after,
.km-list .k-dropdown ~ .km-listview-link:after {
  display: none;
}
.km-list .k-dropdown select,
.km-list .k-dropdown .k-select {
  display: none;
}
.km-widget .km-list textarea {
  position: relative;
  float: right;
  margin-top: 0;
  font-family: inherit;
}
/* Checkboxes and Radios */
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox] {
  border: 0;
  font-size: inherit;
  width: 1em;
  height: .9em;
}
/* Nova theme features */
.km-list label.km-required:after {
  content: "*";
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.km-list .km-label-above {
  width: 100%;
  display: block;
}
.km-list .km-icon-label:before {
  display: inline-block;
  width: 1em;
  height: 1em;
  font: 1em/1em "Kendo UI";
  margin-right: .89em;
}
.km-list .km-label-above input[type=text]:not(.k-input),
.km-list .km-label-above input[type=password],
.km-list .km-label-above input[type=search],
.km-list .km-label-above input[type=number],
.km-list .km-label-above input[type=tel],
.km-list .km-label-above input[type=url],
.km-list .km-label-above input[type=email],
.km-list .km-label-above input[type=file],
.km-list .km-label-above input[type=month],
.km-list .km-label-above input[type=color],
.km-list .km-label-above input[type=week],
.km-list .km-label-above input[type=date],
.km-list .km-label-above input[type=time],
.km-list .km-label-above input[type=datetime],
.km-list .km-label-above input[type=datetime-local],
.km-list .km-label-above select:not([multiple]),
.km-list .km-label-above .k-dropdown,
.km-list .km-label-above textarea {
  width: 100%;
  right: 0;
  padding-left: 0;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
}
.km-list textarea,
.km-list fieldset input[type=password],
.km-list fieldset input[type=search],
.km-list fieldset input[type=number],
.km-list fieldset input[type=tel],
.km-list fieldset input[type=url],
.km-list fieldset input[type=email],
.km-list fieldset input[type=month],
.km-list fieldset input[type=color],
.km-list fieldset input[type=week],
.km-list fieldset input[type=date],
.km-list fieldset input[type=time],
.km-list fieldset input[type=datetime],
.km-list fieldset input[type=datetime-local],
.km-list fieldset input[type=text]:not(.k-input),
.km-list fieldset select:not([multiple]),
.km-list fieldset .k-dropdown-wrap {
  width: 100%;
  position: relative;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  right: auto;
}
.km-list fieldset textarea {
  width: 100%;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-right: 0;
  padding-left: 0;
}
.km-list .km-legend-button {
  display: block;
  text-decoration: none;
}
.km-list .km-inline-field {
  display: inline-block;
  position: relative;
  width: -webkit-calc(48.39%);
  width: calc(48.39%);
  overflow: hidden;
  white-space: nowrap;
}
.km-list .km-inline-field input[type=text]:not(.k-input),
.km-list .km-inline-field input[type=password],
.km-list .km-inline-field input[type=search],
.km-list .km-inline-field input[type=number],
.km-list .km-inline-field input[type=tel],
.km-list .km-inline-field input[type=url],
.km-list .km-inline-field input[type=email],
.km-list .km-inline-field input[type=file],
.km-list .km-inline-field input[type=month],
.km-list .km-inline-field input[type=color],
.km-list .km-inline-field input[type=week],
.km-list .km-inline-field input[type=date],
.km-list .km-inline-field input[type=time],
.km-list .km-inline-field input[type=datetime],
.km-list .km-inline-field input[type=datetime-local],
.km-list .km-inline-field select:not([multiple]),
.km-list .km-inline-field .k-dropdown,
.km-list .km-inline-field textarea {
  position: absolute;
  width: 100%;
  left: 0;
}
.km-list .km-full-width-slider {
  position: relative;
  width: auto;
  display: block;
}
/* Standalone Checkboxes and RadioButtons */
.km-root input.km-checkbox,
.km-root input.km-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
}
.km-root .km-checkbox-label,
.km-root .km-radio-label {
  display: block;
  position: relative;
  vertical-align: middle;
}
.km-root .km-checkbox-label:before,
.km-root .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
}
.km-root input.km-checkbox:checked + .km-checkbox-label:after {
  content: "\e227";
  font-family: "Kendo UI";
}
.km-root .km-radio-label {
  position: relative;
  vertical-align: middle;
}
.km-root .km-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}
.km-root input.km-radio:checked + .km-radio-label:after {
  content: "";
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0.25rem;
  border-radius: 50%;
}
/* Validation */
.km-root .km-invalid-msg {
  font-size: 1.2rem;
  position: static;
  display: inline-block;
  border-width: 1px;
  padding-top: 12px;
  padding-bottom: 11px;
  width: 100%;
  text-align: center;
}
.km-root .km-icon-label.k-i-arrow-e:before {
  content: "\E000";
}
.km-root .km-icon-label.k-i-arrow-n:before {
  content: "\E001";
}
.km-root .km-icon-label.k-i-arrow-s:before {
  content: "\E002";
}
.km-root .km-icon-label.k-i-arrow-w:before {
  content: "\E003";
}
.km-root .km-icon-label.k-i-arrow-ne:before {
  content: "\E004";
}
.km-root .km-icon-label.k-i-arrow-nw:before {
  content: "\E005";
}
.km-root .km-icon-label.k-i-arrow-se:before {
  content: "\E006";
}
.km-root .km-icon-label.k-i-arrow-sw:before {
  content: "\E007";
}
.km-root .km-icon-label.k-i-seek-e:before {
  content: "\E008";
}
.km-root .km-icon-label.k-i-seek-n:before {
  content: "\E009";
}
.km-root .km-icon-label.k-i-seek-s:before {
  content: "\E00A";
}
.km-root .km-icon-label.k-i-seek-w:before {
  content: "\E00B";
}
.km-root .km-icon-label.k-si-arrow-e:before {
  content: "\E00C";
}
.km-root .km-icon-label.k-si-arrow-n:before {
  content: "\E00D";
}
.km-root .km-icon-label.k-si-arrow-s:before {
  content: "\E00E";
}
.km-root .km-icon-label.k-si-arrow-w:before {
  content: "\E00F";
}
.km-root .km-icon-label.k-i-arrowhead-e:before {
  content: "\E010";
}
.km-root .km-icon-label.k-i-arrowhead-n:before {
  content: "\E011";
}
.km-root .km-icon-label.k-i-arrowhead-s:before {
  content: "\E012";
}
.km-root .km-icon-label.k-i-arrowhead-w:before {
  content: "\E013";
}
.km-root .km-icon-label.k-i-arrowhead-ew:before {
  content: "\E014";
}
.km-root .km-icon-label.k-i-arrowhead-ns:before {
  content: "\E015";
}
.km-root .km-icon-label.k-i-move:before {
  content: "\E016";
}
.km-root .km-icon-label.k-i-resize:before {
  content: "\E017";
}
.km-root .km-icon-label.k-i-resize-45:before {
  content: "\E018";
}
.km-root .km-icon-label.k-i-resize-135:before {
  content: "\E019";
}
.km-root .km-icon-label.k-i-resize-h:before {
  content: "\E01A";
}
.km-root .km-icon-label.k-i-resize-v:before {
  content: "\E01B";
}
.km-root .km-icon-label.k-i-refresh:before {
  content: "\E01C";
}
.km-root .km-icon-label.k-i-refresh-rev:before {
  content: "\E01D";
}
.km-root .km-icon-label.k-si-refresh:before {
  content: "\E01E";
}
.km-root .km-icon-label.k-si-refresh-rev:before {
  content: "\E01F";
}
.km-root .km-icon-label.k-i-turn-en:before {
  content: "\E020";
}
.km-root .km-icon-label.k-i-turn-es:before {
  content: "\E021";
}
.km-root .km-icon-label.k-i-turn-ne:before {
  content: "\E022";
}
.km-root .km-icon-label.k-i-turn-nw:before {
  content: "\E023";
}
.km-root .km-icon-label.k-i-turn-se:before {
  content: "\E024";
}
.km-root .km-icon-label.k-i-turn-sw:before {
  content: "\E025";
}
.km-root .km-icon-label.k-i-turn-wn:before {
  content: "\E026";
}
.km-root .km-icon-label.k-i-turn-ws:before {
  content: "\E027";
}
.km-root .km-icon-label.k-i-uturn-e:before {
  content: "\E028";
}
.km-root .km-icon-label.k-i-uturn-e-rev:before {
  content: "\E029";
}
.km-root .km-icon-label.k-i-uturn-n:before {
  content: "\E02A";
}
.km-root .km-icon-label.k-i-uturn-n-rev:before {
  content: "\E02B";
}
.km-root .km-icon-label.k-i-uturn-s:before {
  content: "\E02C";
}
.km-root .km-icon-label.k-i-uturn-s-rev:before {
  content: "\E02D";
}
.km-root .km-icon-label.k-i-uturn-w:before {
  content: "\E02E";
}
.km-root .km-icon-label.k-i-uturn-w-rev:before {
  content: "\E02F";
}
.km-root .km-icon-label.k-i-tick-sign:before {
  content: "\E030";
}
.km-root .km-icon-label.k-i-close-sign:before {
  content: "\E031";
}
.km-root .km-icon-label.k-i-plus-sign:before {
  content: "\E032";
}
.km-root .km-icon-label.k-i-minus-sign:before {
  content: "\E033";
}
.km-root .km-icon-label.k-si-plus-sign:before {
  content: "\E034";
}
.km-root .km-icon-label.k-si-minus-sign:before {
  content: "\E035";
}
.km-root .km-icon-label.k-i-cancel:before {
  content: "\E036";
}
.km-root .km-icon-label.k-i-tick:before {
  content: "\E037";
}
.km-root .km-icon-label.k-i-close:before {
  content: "\E038";
}
.km-root .km-icon-label.k-i-plus:before {
  content: "\E039";
}
.km-root .km-icon-label.k-i-minus:before {
  content: "\E03A";
}
.km-root .km-icon-label.k-si-plus:before {
  content: "\E03B";
}
.km-root .km-icon-label.k-si-minus:before {
  content: "\E03C";
}
.km-root .km-icon-label.k-i-stop:before {
  content: "\E03D";
}
.km-root .km-icon-label.k-i-pause:before {
  content: "\E03E";
}
.km-root .km-icon-label.k-i-eject:before {
  content: "\E03F";
}
.km-root .km-icon-label.k-i-volume-off:before {
  content: "\E040";
}
.km-root .km-icon-label.k-i-volume-low:before {
  content: "\E041";
}
.km-root .km-icon-label.k-i-volume-high:before {
  content: "\E042";
}
.km-root .km-icon-label.k-i-earphones:before {
  content: "\E043";
}
.km-root .km-icon-label.k-i-heart:before {
  content: "\E044";
}
.km-root .km-icon-label.k-i-heart-empty:before {
  content: "\E045";
}
.km-root .km-icon-label.k-i-heart-half:before {
  content: "\E046";
}
.km-root .km-icon-label.k-i-star:before {
  content: "\E047";
}
.km-root .km-icon-label.k-i-star-empty:before {
  content: "\E048";
}
.km-root .km-icon-label.k-i-star-half:before {
  content: "\E049";
}
.km-root .km-icon-label.k-i-star-half-empty:before {
  content: "\E04A";
}
.km-root .km-icon-label.k-i-chart-column:before {
  content: "\E04B";
}
.km-root .km-icon-label.k-i-chart-bar:before {
  content: "\E04C";
}
.km-root .km-icon-label.k-i-chart-pie:before {
  content: "\E04D";
}
.km-root .km-icon-label.k-i-chart-donut:before {
  content: "\E04E";
}
.km-root .km-icon-label.k-i-chart-line:before {
  content: "\E04F";
}
.km-root .km-icon-label.k-i-chart-area:before {
  content: "\E050";
}
.km-root .km-icon-label.k-i-chart-donut:before {
  content: "\E051";
}
.km-root .km-icon-label.k-i-align-left:before {
  content: "\E052";
}
.km-root .km-icon-label.k-i-align-center:before {
  content: "\E053";
}
.km-root .km-icon-label.k-i-align-right:before {
  content: "\E054";
}
.km-root .km-icon-label.k-i-align-justify:before {
  content: "\E055";
}
.km-root .km-icon-label.k-i-align-clear:before {
  content: "\E056";
}
.km-root .km-icon-label.k-i-bold:before {
  content: "\E057";
}
.km-root .km-icon-label.k-i-bold-sans:before {
  content: "\E058";
}
.km-root .km-icon-label.k-i-italic:before {
  content: "\E059";
}
.km-root .km-icon-label.k-i-italic-sans:before {
  content: "\E05A";
}
.km-root .km-icon-label.k-i-underline:before {
  content: "\E05B";
}
.km-root .km-icon-label.k-i-underline-sans:before {
  content: "\E05C";
}
.km-root .km-icon-label.k-i-strikethrough:before {
  content: "\E05D";
}
.km-root .km-icon-label.k-i-strikethrough-sans:before {
  content: "\E05E";
}
.km-root .km-icon-label.k-i-font-a:before {
  content: "\E05F";
}
.km-root .km-icon-label.k-i-font-a-sans:before {
  content: "\E060";
}
.km-root .km-icon-label.k-i-font-t:before {
  content: "\E061";
}
.km-root .km-icon-label.k-i-font-t-sans:before {
  content: "\E062";
}
.km-root .km-icon-label.k-i-brightness:before {
  content: "\E063";
}
.km-root .km-icon-label.k-i-brightness-contrast:before {
  content: "\E064";
}
.km-root .km-icon-label.k-i-contrast:before {
  content: "\E065";
}
.km-root .km-icon-label.k-i-crop:before {
  content: "\E066";
}
.km-root .km-icon-label.k-i-mirror:before {
  content: "\E067";
}
.km-root .km-icon-label.k-i-flip-h:before {
  content: "\E068";
}
.km-root .km-icon-label.k-i-flip-v:before {
  content: "\E069";
}
.km-root .km-icon-label.k-i-rotate:before {
  content: "\E06A";
}
.km-root .km-icon-label.k-i-rotate-rev:before {
  content: "\E06B";
}
.km-root .km-icon-label.k-i-filter:before {
  content: "\E06C";
}
.km-root .km-icon-label.k-i-filter-clear:before {
  content: "\E06D";
}
.km-root .km-icon-label.k-i-sort-asc:before {
  content: "\E06E";
}
.km-root .km-icon-label.k-i-sort-desc:before {
  content: "\E06F";
}
.km-root .km-icon-label.k-i-sort-clear:before {
  content: "\E070";
}
.km-root .km-icon-label.k-i-indent:before {
  content: "\E071";
}
.km-root .km-icon-label.k-i-outdent:before {
  content: "\E072";
}
.km-root .km-icon-label.k-i-hyperlink:before {
  content: "\E073";
}
.km-root .km-icon-label.k-i-hyperlink-clear:before {
  content: "\E074";
}
.km-root .km-icon-label.k-i-hyperlink-ext:before {
  content: "\E075";
}
.km-root .km-icon-label.k-si-hyperlink-ext:before {
  content: "\E076";
}
.km-root .km-icon-label.k-i-ul:before {
  content: "\E077";
}
.km-root .km-icon-label.k-si-ul:before {
  content: "\E078";
}
.km-root .km-icon-label.k-i-paint:before {
  content: "\E079";
}
.km-root .km-icon-label.k-i-paste:before {
  content: "\E07A";
}
.km-root .km-icon-label.k-i-pencil:before {
  content: "\E07B";
}
.km-root .km-icon-label.k-i-image:before {
  content: "\E07C";
}
.km-root .km-icon-label.k-i-image-add:before {
  content: "\E07D";
}
.km-root .km-icon-label.k-i-print:before {
  content: "\E07E";
}
.km-root .km-icon-label.k-i-zoom:before {
  content: "\E07F";
}
.km-root .km-icon-label.k-i-zoom-in:before {
  content: "\E080";
}
.km-root .km-icon-label.k-i-zoom-out:before {
  content: "\E081";
}
.km-root .km-icon-label.k-i-asterisk:before {
  content: "\E082";
}
.km-root .km-icon-label.k-i-clip:before {
  content: "\E083";
}
.km-root .km-icon-label.k-i-clip-45:before {
  content: "\E084";
}
.km-root .km-icon-label.k-i-qrcode:before {
  content: "\E085";
}
.km-root .km-icon-label.k-i-book:before {
  content: "\E086";
}
.km-root .km-icon-label.k-i-bookmark:before {
  content: "\E087";
}
.km-root .km-icon-label.k-i-briefcase:before {
  content: "\E088";
}
.km-root .km-icon-label.k-i-calendar:before {
  content: "\E089";
}
.km-root .km-icon-label.k-i-camera-still:before {
  content: "\E08A";
}
.km-root .km-icon-label.k-i-camera-video:before {
  content: "\E08B";
}
.km-root .km-icon-label.k-i-certificate:before {
  content: "\E08C";
}
.km-root .km-icon-label.k-i-clock:before {
  content: "\E08D";
}
.km-root .km-icon-label.k-i-cloud:before {
  content: "\E08E";
}
.km-root .km-icon-label.k-i-collapse:before {
  content: "\E08F";
}
.km-root .km-icon-label.k-i-columns:before {
  content: "\E090";
}
.km-root .km-icon-label.k-i-comment:before {
  content: "\E091";
}
.km-root .km-icon-label.k-i-comment-empty:before {
  content: "\E092";
}
.km-root .km-icon-label.k-i-comments:before {
  content: "\E093";
}
.km-root .km-icon-label.k-i-comments-empty:before {
  content: "\E094";
}
.km-root .km-icon-label.k-i-credit-card:before {
  content: "\E095";
}
.km-root .km-icon-label.k-i-download:before {
  content: "\E096";
}
.km-root .km-icon-label.k-i-draghandle:before {
  content: "\E097";
}
.km-root .km-icon-label.k-si-draghandle:before {
  content: "\E098";
}
.km-root .km-icon-label.k-i-envelop:before {
  content: "\E099";
}
.km-root .km-icon-label.k-i-envelop-open:before {
  content: "\E09A";
}
.km-root .km-icon-label.k-i-eye:before {
  content: "\E09B";
}
.km-root .km-icon-label.k-i-file:before {
  content: "\E09C";
}
.km-root .km-icon-label.k-i-file-add:before {
  content: "\E09D";
}
.km-root .km-icon-label.k-i-film:before {
  content: "\E09E";
}
.km-root .km-icon-label.k-i-flag:before {
  content: "\E09F";
}
.km-root .km-icon-label.k-i-folder-add:before {
  content: "\E0A0";
}
.km-root .km-icon-label.k-i-folder:before {
  content: "\E0A1";
}
.km-root .km-icon-label.k-i-folder-open:before {
  content: "\E0A2";
}
.km-root .km-icon-label.k-i-folder-up:before {
  content: "\E0A3";
}
.km-root .km-icon-label.k-i-gear:before {
  content: "\E0A4";
}
.km-root .km-icon-label.k-si-gear:before {
  content: "\E0A5";
}
.km-root .km-icon-label.k-i-transmit:before {
  content: "\E0A6";
}
.km-root .km-icon-label.k-i-beer:before {
  content: "\E0A7";
}
.km-root .km-icon-label.k-i-cocktail:before {
  content: "\E0A8";
}
.km-root .km-icon-label.k-i-coffee:before {
  content: "\E0A9";
}
.km-root .km-icon-label.k-i-wine:before {
  content: "\E0AA";
}
.km-root .km-icon-label.k-i-grid:before {
  content: "\E0AB";
}
.km-root .km-icon-label.k-i-thumbs:before {
  content: "\E0AC";
}
.km-root .km-icon-label.k-i-split-h:before {
  content: "\E0AD";
}
.km-root .km-icon-label.k-i-split-v:before {
  content: "\E0AE";
}
.km-root .km-icon-label.k-i-home:before {
  content: "\E0AF";
}
.km-root .km-icon-label.k-i-inbox:before {
  content: "\E0B0";
}
.km-root .km-icon-label.k-i-key:before {
  content: "\E0B1";
}
.km-root .km-icon-label.k-i-login:before {
  content: "\E0B2";
}
.km-root .km-icon-label.k-i-logout:before {
  content: "\E0B3";
}
.km-root .km-icon-label.k-i-place:before {
  content: "\E0B4";
}
.km-root .km-icon-label.k-i-megaphone:before {
  content: "\E0B5";
}
.km-root .km-icon-label.k-i-note:before {
  content: "\E0B6";
}
.km-root .km-icon-label.k-i-pin:before {
  content: "\E0B7";
}
.km-root .km-icon-label.k-i-unpin:before {
  content: "\E0B8";
}
.km-root .km-icon-label.k-i-power:before {
  content: "\E0B9";
}
.km-root .km-icon-label.k-i-progress-bars:before {
  content: "\E0BA";
}
.km-root .km-icon-label.k-i-road:before {
  content: "\E0BB";
}
.km-root .km-icon-label.k-i-rss:before {
  content: "\E0BC";
}
.km-root .km-icon-label.k-i-floppy:before {
  content: "\E0BD";
}
.km-root .km-icon-label.k-i-sitemap:before {
  content: "\E0BE";
}
.km-root .km-icon-label.k-i-tag-45:before {
  content: "\E0BF";
}
.km-root .km-icon-label.k-i-tag-h:before {
  content: "\E0C0";
}
.km-root .km-icon-label.k-i-thunderbolt:before {
  content: "\E0C1";
}
.km-root .km-icon-label.k-i-tooltip:before {
  content: "\E0C2";
}
.km-root .km-icon-label.k-i-trash:before {
  content: "\E0C3";
}
.km-root .km-icon-label.k-i-trophy:before {
  content: "\E0C4";
}
.km-root .km-icon-label.k-i-ungroup:before {
  content: "\E0C5";
}
.km-root .km-icon-label.k-i-upload:before {
  content: "\E0C6";
}
.km-root .km-icon-label.k-i-window:before {
  content: "\E0C7";
}
.km-root .km-icon-label.k-i-tiles:before {
  content: "\E0C8";
}
.km-root .km-icon-label.k-i-wrench:before {
  content: "\E0C9";
}
.km-root .km-icon-label.k-i-action:before {
  content: "\E0CA";
}
.km-root .km-icon-label.k-i-add:before {
  content: "\E0CB";
}
.km-root .km-icon-label.k-i-add-inv:before {
  content: "\E0CC";
}
.km-root .km-icon-label.k-i-armchair:before {
  content: "\E0CD";
}
.km-root .km-icon-label.k-i-battery:before {
  content: "\E0CE";
}
.km-root .km-icon-label.k-i-book-open:before {
  content: "\E0CF";
}
.km-root .km-icon-label.k-i-camera:before {
  content: "\E0D0";
}
.km-root .km-icon-label.k-i-cart:before {
  content: "\E0D1";
}
.km-root .km-icon-label.k-i-time:before {
  content: "\E0D2";
}
.km-root .km-icon-label.k-i-coffee-card:before {
  content: "\E0D3";
}
.km-root .km-icon-label.k-i-download-arrow:before {
  content: "\E0D4";
}
.km-root .km-icon-label.k-i-edit:before {
  content: "\E0D5";
}
.km-root .km-icon-label.k-i-faves:before {
  content: "\E0D6";
}
.km-root .km-icon-label.k-i-star:before {
  content: "\E0D7";
}
.km-root .km-icon-label.k-i-featured:before {
  content: "\E0D8";
}
.km-root .km-icon-label.k-i-forward:before {
  content: "\E0D9";
}
.km-root .km-icon-label.k-i-cog:before {
  content: "\E0DA";
}
.km-root .km-icon-label.k-i-globe:before {
  content: "\E0DB";
}
.km-root .km-icon-label.k-i-globe-inv:before {
  content: "\E0DC";
}
.km-root .km-icon-label.k-i-house:before {
  content: "\E0DD";
}
.km-root .km-icon-label.k-i-info:before {
  content: "\E0DE";
}
.km-root .km-icon-label.k-i-share:before {
  content: "\E0DF";
}
.km-root .km-icon-label.k-i-more-h:before {
  content: "\E0E0";
}
.km-root .km-icon-label.k-i-more-v:before {
  content: "\E0E1";
}
.km-root .km-icon-label.k-i-next:before {
  content: "\E0E2";
}
.km-root .km-icon-label.k-i-pause-a:before {
  content: "\E0E3";
}
.km-root .km-icon-label.k-i-user:before {
  content: "\E0E4";
}
.km-root .km-icon-label.k-i-play-a:before {
  content: "\E0E5";
}
.km-root .km-icon-label.k-i-refresh-a:before {
  content: "\E0E6";
}
.km-root .km-icon-label.k-i-reset:before {
  content: "\E0E7";
}
.km-root .km-icon-label.k-i-rewind:before {
  content: "\E0E8";
}
.km-root .km-icon-label.k-i-search-a:before {
  content: "\E0E9";
}
.km-root .km-icon-label.k-i-stop-a:before {
  content: "\E0EA";
}
.km-root .km-icon-label.k-i-tiles-a:before {
  content: "\E0EB";
}
.km-root .km-icon-label.k-i-trash-a:before {
  content: "\E0EC";
}
.km-root .km-icon-label.k-i-undo:before {
  content: "\E0ED";
}
.km-root .km-icon-label.k-i-redo:before {
  content: "\E0EE";
}
.km-root .km-icon-label.k-i-volume-a:before {
  content: "\E0EF";
}
.km-root .km-icon-label.k-i-wifi:before {
  content: "\E0F0";
}
.km-root .km-icon-label.k-i-more-lines:before {
  content: "\E0F1";
}
.km-root .km-icon-label.k-i-pull-to-ref:before {
  content: "\E0F2";
}
.km-root .km-icon-label.k-i-loading-android:before {
  content: "\E0F3";
}
.km-root .km-icon-label.k-i-loading-blackberry:before {
  content: "\E0F4";
}
.km-root .km-icon-label.k-i-loading-meego:before {
  content: "\E0F5";
}
.km-root .km-icon-label.k-i-loading-custom:before {
  content: "\E0F6";
}
.km-root .km-icon-label.k-i-loading-ios:before {
  content: "\E0F7";
}
.km-root .km-icon-label.k-i-bug:before {
  content: "\E0F8";
}
.km-root .km-icon-label.k-i-info:before {
  content: "\E0F9";
}
.km-root .km-icon-label.k-i-warning:before {
  content: "\E0FA";
}
.km-root .km-icon-label.k-i-question:before {
  content: "\E0FB";
}
.km-root .km-icon-label.k-i-insert-n:before {
  content: "\E0FC";
}
.km-root .km-icon-label.k-i-insert-m:before {
  content: "\E0FD";
}
.km-root .km-icon-label.k-i-insert-s:before {
  content: "\E0FE";
}
.km-root .km-icon-label.k-i-lock:before {
  content: "\E0FF";
}
.km-root .km-icon-label.k-i-unlock:before {
  content: "\E100";
}
.km-root .km-icon-label.k-i-phone:before {
  content: "\E101";
}
.km-root .km-icon-label.k-i-tablet:before {
  content: "\E102";
}
.km-root .km-icon-label.k-i-ol:before {
  content: "\E103";
}
.km-root .km-icon-label.k-i-barcode:before {
  content: "\E104";
}
.km-root .km-icon-label.k-i-html5:before {
  content: "\E105";
}
.km-root .km-icon-label.k-i-css3:before {
  content: "\E106";
}
.km-root .km-icon-label.k-i-kendoui:before {
  content: "\E107";
}
.km-root .km-icon-label.k-i-telerik:before {
  content: "\E108";
}
.km-root .km-icon-label.k-i-icenium:before {
  content: "\E109";
}
.km-root .km-icon-label.k-i-sitefinity:before {
  content: "\E10A";
}
.km-root .km-icon-label.k-i-twitter:before {
  content: "\E10B";
}
.km-root .km-icon-label.k-i-linkedin:before {
  content: "\E10C";
}
.km-root .km-icon-label.k-i-facebook:before {
  content: "\E10D";
}
.km-root .km-icon-label.k-i-pinterest:before {
  content: "\E10E";
}
.km-root .km-icon-label.k-i-youtube:before {
  content: "\E10F";
}
.km-root .km-icon-label.k-i-vimeo:before {
  content: "\E110";
}
.km-root .km-icon-label.k-i-behance:before {
  content: "\E111";
}
.km-root .km-icon-label.k-i-dribbble:before {
  content: "\E112";
}
.km-root .km-icon-label.k-i-googleplus:before {
  content: "\E113";
}
.km-root .km-icon-label.k-i-minimize:before {
  content: "\E114";
}
.km-root .km-icon-label.k-i-html:before {
  content: "\E115";
}
.km-root .km-icon-label.k-i-group:before {
  content: "\E116";
}
.km-root .km-icon-label.k-i-subscript:before {
  content: "\E117";
}
.km-root .km-icon-label.k-i-superscript:before {
  content: "\E118";
}
.km-root .km-icon-label.k-i-drophere:before {
  content: "\E119";
}
/* animation classes */
.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
/* Zoom */
.k-fx-zoom.k-fx-start .k-fx-next {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
/* Fade */
.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
/* Slide */
.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right {
  /* right reverse */
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Overlay */
.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
/* Platform specific workarounds */
.km-on-wp .km-view,
.km-on-wp .km-header,
.km-on-wp .km-footer,
.km-on-wp .km-slider,
.km-on-wp .km-switch,
.km-on-wp .km-filter-reset,
.km-on-wp .km-shim .k-animation-container,
.km-on-wp .km-scroll-container {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.km-ios,
.km-blackberry,
.km-on-ios .km-view,
.km-on-ios .km-header,
.km-on-ios .km-footer,
.km-on-ios .km-slider,
.km-on-ios .km-switch,
.km-on-ios .km-group-title,
.km-on-ios .km-filter-reset,
.km-on-ios .km-shim .k-animation-container,
.km-on-ios .km-scroll-container,
.km-on-blackberry .km-view,
.km-on-blackberry .km-content,
.km-on-blackberry .km-header,
.km-on-blackberry .km-footer,
.km-on-blackberry .km-icon,
.km-on-blackberry .km-switch,
.km-on-blackberry .km-popup .k-item,
.km-on-blackberry .km-actionsheet-wrapper,
.km-on-android.km-4 .k-slider {
  -webkit-transform: translatez(0);
}
.km-on-android.km-4 .km-switch,
.km-on-android.km-4 .km-listview-wrapper,
.km-on-android.km-4 .km-content,
.km-on-android.km-4 .km-switch-handle,
.km-android.km-4.km-on-android .km-switch-wrapper,
.km-on-android.km-4 .km-scroll-container,
.km-on-meego .km-content,
.km-on-meego .km-switch,
.km-on-meego .km-icon,
.km-on-meego .km-header,
.km-on-meego .km-footer,
.km-on-meego .km-content,
.km-on-meego .km-switch-handle,
.km-on-meego .km-switch-wrapper {
  -webkit-transform: translatez(0);
  -webkit-backface-visibility: hidden;
}
.km-android4.km-ios-chrome .km-listview-wrapper {
  -webkit-transform: none;
}
.km-native-scrolling .km-header,
.km-native-scrolling .km-footer,
.km-native-scrolling .km-shim,
.km-native-scrolling .km-popup-overlay,
.km-native-scrolling .km-drawer,
.km-native-scrolling > .km-pane > .km-loader,
.km-on-android.km-4 .km-scroller-pull .km-icon {
  -webkit-backface-visibility: hidden;
}
.km-on-android.km-4 input {
  -webkit-user-modify: read-write-plaintext-only;
}
.km-wp .km-view .km-absolute,
.km-meego .km-view .km-absolute {
  position: absolute;
}
/* Icon per-widget styles */
.km-detail .km-icon,
.km-button .km-icon,
.km-list .km-icon,
.km-ios .km-button .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1em;
  margin-left: -0.3em;
  margin-right: 0.3em;
  vertical-align: baseline;
  display: inline-block;
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
}
html .km-widget .km-view .km-notext {
  margin-left: 0;
  margin-right: 0;
}
.km-buttongroup .km-button .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1em;
  margin: .05em .16em 0 0;
}
.km-tabstrip .km-button .km-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
}
.km-tabstrip .km-image,
.km-tabstrip .km-button .km-icon {
  margin: 0 auto .1em;
  display: inline-block;
}
.km-tabstrip .km-text {
  display: block;
}
.km-phone .km-tabstrip .km-icon {
  height: 2.2rem;
  width: 2.2rem;
  font-size: 2.2rem;
}
.km-phone .km-horizontal .km-tabstrip .km-icon {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}
/* Icons */
@font-face {
  font-family: "Kendo UI";
  src: url("images/kendoui.woff?v=1.1") format("woff"), url("images/kendoui.ttf?v=1.1") format("truetype"), url("images/kendoui.svg#kendoui") format("svg");
}
body:before {
  font-family: "Kendo UI";
  content: "\a0";
  font-size: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.km-root .km-pane .km-view .km-icon {
  -webkit-background-clip: text;
  -webkit-background-size: 0 0;
          background-size: 0 0;
}
.km-icon {
  position: relative;
}
.km-icon:after,
.km-icon:before,
.km-contactadd:after,
.km-contactadd:before,
.km-rowdelete:after,
.km-rowdelete:before,
.km-rowinsert:after,
.km-rowinsert:before,
.km-detaildisclose:after,
.km-detaildisclose:before,
.km-loading:after,
.km-filter-wrap:before {
  position: relative;
  content: "\a0";
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  vertical-align: middle;
  -webkit-background-size: auto auto;
          background-size: auto;
  font: 1em/1em "Kendo UI";
}
.km-icon:before,
.km-contactadd:before,
.km-rowdelete:before,
.km-rowinsert:before,
.km-detaildisclose:before {
  position: absolute;
  margin-top: 1px;
  color: rgba(0, 0, 0, 0.7);
  display: none;
}
.km-state-active .km-icon:before,
.km-state-active .km-contactadd:before,
.km-state-active .km-rowdelete:before,
.km-state-active .km-rowinsert:before,
.km-state-active .km-detaildisclose:before {
  display: block;
}
.km-ios7 .km-detaildisclose:after {
  font-family: serif;
}
.km-ios7 .km-icon:before,
.km-ios7 .km-contactadd:before,
.km-ios7 .km-rowdelete:before,
.km-ios7 .km-rowinsert:before,
.km-ios7 .km-detaildisclose:before {
  display: none;
}
.k-webkit .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-webkit .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-safari .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after,
.k-safari .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after {
  background-image: inherit;
  background-repeat: inherit;
  background-position: inherit;
  background-color: currentcolor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.km-root .km-pane.km-on-blackberry.km-blackberry6 .km-view .km-icon:after,
.km-root .km-pane.km-on-blackberry.km-blackberry7 .km-view .km-icon:after,
.km-root .km-pane.km-pane.km-on-android .km-view .km-icon:after,
.km-root .km-pane.km-pane.km-on-meego .km-view .km-icon:after {
  background: none;
  -webkit-text-fill-color: inherit;
}
.km-contactadd:after,
.km-contactadd:before,
.km-rowinsert:after,
.km-rowinsert:before {
  content: "\E039";
}
.km-rowdelete:after,
.km-rowdelete:before {
  content: "\E03a";
}
.km-detaildisclose:after,
.km-detaildisclose:before {
  content: "\E0E2";
}
.km-action:after,
.km-action:before {
  content: "\e0ca";
}
.km-add:after,
.km-add:before {
  content: "\e0cb";
}
.km-arrow-n:after,
.km-arrow-n:before {
  content: "\e001";
}
.km-arrow-s:after,
.km-arrow-s:before {
  content: "\e002";
}
.km-battery:after,
.km-battery:before {
  content: "\e0ce";
}
.km-bookmarks:after,
.km-bookmarks:before {
  content: "\e0cf";
}
.km-camera:after,
.km-camera:before {
  content: "\e0d0";
}
.km-cart:after,
.km-cart:before {
  content: "\e0d1";
}
.km-edit:after,
.km-compose:after,
.km-edit:before,
.km-compose:before {
  content: "\e0d5";
}
.km-contacts:after,
.km-contacts:before {
  content: "\e0e4";
}
.km-trash:after,
.km-delete:after,
.km-trash:before,
.km-delete:before {
  content: "\e0ec";
}
.km-details:after,
.km-details:before {
  content: "\e0e2";
}
.km-download:after,
.km-downloads:after,
.km-download:before,
.km-downloads:before {
  content: "\e0d4";
}
.km-fastforward:after,
.km-fastforward:before {
  content: "\e0d9";
}
.km-toprated:after,
.km-favorites:after,
.km-toprated:before,
.km-favorites:before {
  content: "\e0d7";
}
.km-featured:after,
.km-featured:before {
  content: "\e0d8";
}
.km-globe:after,
.km-globe:before {
  content: "\e0dc";
}
.km-history:after,
.km-history:before {
  content: "\e0e7";
}
.km-home:after,
.km-home:before {
  content: "\e0dd";
}
.km-info:after,
.km-about:after,
.km-info:before,
.km-about:before {
  content: "\e0de";
}
.km-minus:after,
.km-minus:before {
  content: "\e033";
}
.km-more:after,
.km-more:before {
  content: "\e0e0";
}
.km-mostrecent:after,
.km-mostrecent:before {
  content: "\e0cc";
}
.km-mostviewed:after,
.km-mostviewed:before {
  content: "\e0d6";
}
.km-organize:after,
.km-organize:before {
  content: "\e0eb";
}
.km-pause:after,
.km-pause:before {
  content: "\e0e3";
}
.km-play:after,
.km-play:before {
  content: "\e0e5";
}
.km-plus:after,
.km-plus:before {
  content: "\e032";
}
.km-recents:after,
.km-recents:before {
  content: "\e0d2";
}
.km-refresh:after,
.km-refresh:before {
  content: "\e0e6";
}
.km-reply:after,
.km-reply:before {
  content: "\e0ed";
}
.km-rewind:after,
.km-rewind:before {
  content: "\e0e8";
}
.km-search:after,
.km-search:before {
  content: "\e0e9";
}
.km-settings:after,
.km-settings:before {
  content: "\e0da";
}
.km-share:after,
.km-share:before {
  content: "\e0df";
}
.km-sounds:after,
.km-volume:after,
.km-sounds:before,
.km-volume:before {
  content: "\e0ef";
}
.km-stop:after,
.km-stop:before {
  content: "\e0ea";
}
.km-wifi:after,
.km-wifi:before {
  content: "\e0f0";
}
.km-drawer-icon:after,
.km-drawer-icon:before,
.km-hamburger:before,
.km-hamburger:after {
  content: "\e105";
}
.km-root .km-pane .km-icon.km-check {
  -webkit-background-clip: initial;
}
.km-root .km-pane .km-check:checked:after,
.km-widget .km-check:checked:after {
  content: "\e227";
}
.km-android .km-more:after,
.km-android .km-more:before {
  content: "\e0e1";
}
.km-meego .km-more:after,
.km-meego .km-more:before {
  content: "\e0f1";
}
.km-wp .km-loading:after,
.km-wp .km-load-more .km-icon:after,
.km-wp .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-meego .km-loading:after,
.km-meego .km-load-more .km-icon:after,
.km-meego .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-root .km-android .km-loading:after,
.km-android .km-load-more .km-icon:after,
.km-root .km-android .km-scroller-refresh .km-icon:after {
  content: "\e0f6";
}
.km-scroller-pull .km-icon:after {
  content: "\e0f2";
}
.km-icon.km-phone:after,
.km-ios7 .km-state-active .km-phone:after {
  content: "\e326";
}
.km-ios7 .km-detaildisclose:after {
  content: "i";
}
.km-ios7 .km-action:after {
  content: "\e1ff";
}
.km-ios7 .km-add:after {
  content: "\e200";
}
.km-ios7 .km-mostrecent:after {
  content: "\e201";
}
.km-ios7 .km-battery:after {
  content: "\e203";
}
.km-ios7 .km-bookmarks:after {
  content: "\e204";
}
.km-ios7 .km-camera:after {
  content: "\e205";
}
.km-ios7 .km-cart:after {
  content: "\e206";
}
.km-ios7 .km-recents:after {
  content: "\e207";
}
.km-ios7 .km-download:after,
.km-ios7 .km-downloads:after {
  content: "\e209";
}
.km-ios7 .km-edit:after {
  content: "\e20a";
}
.km-ios7 .km-mostviewed:after {
  content: "\e20b";
}
.km-ios7 .km-toprated:after,
.km-ios7 .km-favorites:after {
  content: "\e20c";
}
.km-ios7 .km-featured:after {
  content: "\e20d";
}
.km-ios7 .km-fastforward:after {
  content: "\e20e";
}
.km-ios7 .km-settings:after {
  content: "\e20f";
}
.km-ios7 .km-globe:after {
  content: "\e211";
}
.km-ios7 .km-home:after {
  content: "\e212";
}
.km-ios7 .km-info:after,
.km-ios7 .km-about:after {
  content: "\e213";
}
.km-ios7 .km-share:after {
  content: "\e214";
}
.km-ios7 .km-more:after {
  content: "\e215";
}
.km-ios7 .km-details:after {
  content: "\e217";
}
.km-ios7 .km-pause:after {
  content: "\e218";
}
.km-ios7 .km-contacts:after {
  content: "\e219";
}
.km-ios7 .km-play:after {
  content: "\e21a";
}
.km-ios7 .km-refresh:after {
  content: "\e21b";
}
.km-ios7 .km-history:after {
  content: "\e21c";
}
.km-ios7 .km-rewind:after {
  content: "\e21d";
}
.km-ios7 .km-search:after {
  content: "\e21e";
}
.km-ios7 .km-stop:after {
  content: "\e21f";
}
.km-ios7 .km-organize:after {
  content: "\e220";
}
.km-ios7 .km-trash:after,
.km-ios7 .km-delete:after {
  content: "\e221";
}
.km-ios7 .km-reply:after {
  content: "\e222";
}
.km-ios7 .km-forward:after {
  content: "\e223";
}
.km-ios7 .km-sounds:after,
.km-ios7 .km-volume:after {
  content: "\e224";
}
.km-ios7 .km-wifi:after {
  content: "\e225";
}
.km-ios7 .km-phone:after {
  content: "\e226";
}
.km-ios7 .km-state-active .km-action:after {
  content: "\e2ff";
}
.km-ios7 .km-state-active .km-add:after {
  content: "\e300";
}
.km-ios7 .km-state-active .km-mostrecent:after {
  content: "\e301";
}
.km-ios7 .km-state-active .km-battery:after {
  content: "\e303";
}
.km-ios7 .km-state-active .km-bookmarks:after {
  content: "\e304";
}
.km-ios7 .km-state-active .km-camera:after {
  content: "\e305";
}
.km-ios7 .km-state-active .km-cart:after {
  content: "\e306";
}
.km-ios7 .km-state-active .km-recents:after {
  content: "\e307";
}
.km-ios7 .km-state-active .km-download:after,
.km-ios7 .km-state-active .km-downloads:after {
  content: "\e309";
}
.km-ios7 .km-state-active .km-edit:after {
  content: "\e30a";
}
.km-ios7 .km-state-active .km-mostviewed:after {
  content: "\e30b";
}
.km-ios7 .km-state-active .km-toprated:after,
.km-ios7 .km-state-active .km-favorites:after {
  content: "\e30c";
}
.km-ios7 .km-state-active .km-featured:after {
  content: "\e30d";
}
.km-ios7 .km-state-active .km-fastforward:after {
  content: "\e30e";
}
.km-ios7 .km-state-active .km-settings:after {
  content: "\e30f";
}
.km-ios7 .km-state-active .km-globe:after {
  content: "\e311";
}
.km-ios7 .km-state-active .km-home:after {
  content: "\e312";
}
.km-ios7 .km-state-active .km-info:after,
.km-ios7 .km-state-active .km-about:after {
  content: "\e313";
}
.km-ios7 .km-state-active .km-share:after {
  content: "\e314";
}
.km-ios7 .km-state-active .km-more:after {
  content: "\e315";
}
.km-ios7 .km-state-active .km-details:after {
  content: "\e317";
}
.km-ios7 .km-state-active .km-pause:after {
  content: "\e318";
}
.km-ios7 .km-state-active .km-contacts:after {
  content: "\e319";
}
.km-ios7 .km-state-active .km-play:after {
  content: "\e31a";
}
.km-ios7 .km-state-active .km-refresh:after {
  content: "\e31b";
}
.km-ios7 .km-state-active .km-history:after {
  content: "\e31c";
}
.km-ios7 .km-state-active .km-rewind:after {
  content: "\e31d";
}
.km-ios7 .km-state-active .km-search:after {
  content: "\e31e";
}
.km-ios7 .km-state-active .km-stop:after {
  content: "\e31f";
}
.km-ios7 .km-state-active .km-organize:after {
  content: "\e320";
}
.km-ios7 .km-state-active .km-trash:after,
.km-ios7 .km-state-active .km-delete:after {
  content: "\e321";
}
.km-ios7 .km-state-active .km-reply:after {
  content: "\e322";
}
.km-ios7 .km-state-active .km-forward:after {
  content: "\e323";
}
.km-ios7 .km-state-active .km-sounds:after,
.km-ios7 .km-state-active .km-volume:after {
  content: "\e324";
}
.km-ios7 .km-state-active .km-wifi:after {
  content: "\e325";
}
.km-arrowdown:after,
.km-arrowdown:before {
  content: "\e002";
}
.km-wp .km-scroller-pull .km-icon:after {
  content: "\E0D4";
}
.km-on-wp.km-app .km-icon:after,
.km-on-wp.km-app .km-filter-wrap:before,
.km-on-wp.km-app .km-state-active .km-icon:after {
  color: transparent;
  background-image: url("images/wp8_icons.png");
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
  height: 1em;
  margin-top: 0;
  vertical-align: middle;
}
.km-wp-light.km-app .km-icon:after,
.km-wp-light.km-app .km-filter-wrap:before {
  background-image: url("images/wp8_inverseicons.png");
}
.km-on-wp.km-app .km-icon {
  line-height: 1em;
}
.km-on-wp.km-app .km-icon:before {
  display: none;
}
.km-on-wp.km-app .km-action:after {
  background-position-x: 20%;
}
.km-on-wp.km-app .km-add:after,
.km-on-wp.km-app .km-filter-reset .km-clear:after {
  background-position-x: 22%;
}
.km-on-wp.km-app .km-battery:after {
  background-position-x: 24%;
}
.km-on-wp.km-app .km-bookmarks:after {
  background-position-x: 26%;
}
.km-on-wp.km-app .km-camera:after {
  background-position-x: 28%;
}
.km-on-wp.km-app .km-cart:after {
  background-position-x: 30%;
}
.km-on-wp.km-app .km-edit:after,
.km-on-wp.km-app .km-compose:after {
  background-position-x: 32%;
}
.km-on-wp.km-app .km-contacts:after {
  background-position-x: 34%;
}
.km-on-wp.km-app .km-trash:after,
.km-on-wp.km-app .km-delete:after {
  background-position-x: 36%;
}
.km-on-wp.km-app .km-details:after {
  background-position-x: 38%;
}
.km-on-wp.km-app .km-download:after,
.km-on-wp.km-app .km-downloads:after {
  background-position-x: 40%;
}
.km-on-wp.km-app .km-fastforward:after {
  background-position-x: 42%;
}
.km-on-wp.km-app .km-toprated:after,
.km-on-wp.km-app .km-favorites:after {
  background-position-x: 44%;
}
.km-on-wp.km-app .km-featured:after {
  background-position-x: 46%;
}
.km-on-wp.km-app .km-globe:after {
  background-position-x: 48%;
}
.km-on-wp.km-app .km-history:after {
  background-position-x: 50%;
}
.km-on-wp.km-app .km-home:after {
  background-position-x: 52%;
}
.km-on-wp.km-app .km-info:after,
.km-on-wp.km-app .km-about:after {
  background-position-x: 54%;
}
.km-on-wp.km-app .km-more:after {
  background-position-x: 56%;
}
.km-on-wp.km-app .km-mostrecent:after {
  background-position-x: 58%;
}
.km-on-wp.km-app .km-mostviewed:after {
  background-position-x: 60%;
}
.km-on-wp.km-app .km-organize:after {
  background-position-x: 62%;
}
.km-on-wp.km-app .km-pause:after {
  background-position-x: 64%;
}
.km-on-wp.km-app .km-play:after {
  background-position-x: 66%;
}
.km-on-wp.km-app .km-recents:after {
  background-position-x: 68%;
}
.km-on-wp.km-app .km-refresh:after {
  background-position-x: 70%;
}
.km-on-wp.km-app .km-reply:after {
  background-position-x: 72%;
}
.km-on-wp.km-app .km-rewind:after {
  background-position-x: 74%;
}
.km-on-wp.km-app .km-search:after,
.km-on-wp.km-app .km-filter-wrap:before {
  background-position-x: 76%;
}
.km-on-wp.km-app .km-settings:after {
  background-position-x: 78%;
}
.km-on-wp.km-app .km-share:after {
  background-position-x: 80%;
}
.km-on-wp.km-app .km-sounds:after,
.km-on-wp.km-app .km-volume:after {
  background-position-x: 82%;
}
.km-on-wp.km-app .km-stop:after {
  background-position-x: 84%;
}
.km-on-wp.km-app .km-wifi:after {
  background-position-x: 86%;
}
.km-on-wp.km-app.km-android .km-more:after {
  background-position-x: 88%;
}
.km-on-wp.km-app.km-meego .km-more:after {
  background-position-x: 90%;
}
.km-on-wp.km-app.km-meego .km-loading:after,
.km-on-wp.km-app.km-meego .km-load-more .km-icon:after,
.km-on-wp.km-app.km-meego .km-scroller-refresh .km-icon:after {
  background-position-x: 94%;
}
.km-on-wp.km-app .km-scroller-pull .km-icon:after {
  background-position-x: 100%;
}
.km-on-wp.km-app .km-filter-wrap:before {
  display: inline-block;
  content: "\a0";
}
.km-on-wp.km-app .km-filter-reset .km-clear:after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.km-list .km-icon-label.k-i-arrow-e:before {
  content: "\E000";
}
.km-list .km-icon-label.k-i-arrow-n:before {
  content: "\E001";
}
.km-list .km-icon-label.k-i-arrow-s:before {
  content: "\E002";
}
.km-list .km-icon-label.k-i-arrow-w:before {
  content: "\E003";
}
.km-list .km-icon-label.k-i-arrow-ne:before {
  content: "\E004";
}
.km-list .km-icon-label.k-i-arrow-nw:before {
  content: "\E005";
}
.km-list .km-icon-label.k-i-arrow-se:before {
  content: "\E006";
}
.km-list .km-icon-label.k-i-arrow-sw:before {
  content: "\E007";
}
.km-list .km-icon-label.k-i-seek-e:before {
  content: "\E008";
}
.km-list .km-icon-label.k-i-seek-n:before {
  content: "\E009";
}
.km-list .km-icon-label.k-i-seek-s:before {
  content: "\E00A";
}
.km-list .km-icon-label.k-i-seek-w:before {
  content: "\E00B";
}
.km-list .km-icon-label.k-si-arrow-e:before {
  content: "\E00C";
}
.km-list .km-icon-label.k-si-arrow-n:before {
  content: "\E00D";
}
.km-list .km-icon-label.k-si-arrow-s:before {
  content: "\E00E";
}
.km-list .km-icon-label.k-si-arrow-w:before {
  content: "\E00F";
}
.km-list .km-icon-label.k-i-arrowhead-e:before {
  content: "\E010";
}
.km-list .km-icon-label.k-i-arrowhead-n:before {
  content: "\E011";
}
.km-list .km-icon-label.k-i-arrowhead-s:before {
  content: "\E012";
}
.km-list .km-icon-label.k-i-arrowhead-w:before {
  content: "\E013";
}
.km-list .km-icon-label.k-i-arrowhead-ew:before {
  content: "\E014";
}
.km-list .km-icon-label.k-i-arrowhead-ns:before {
  content: "\E015";
}
.km-list .km-icon-label.k-i-move:before {
  content: "\E016";
}
.km-list .km-icon-label.k-i-resize:before {
  content: "\E017";
}
.km-list .km-icon-label.k-i-resize-45:before {
  content: "\E018";
}
.km-list .km-icon-label.k-i-resize-135:before {
  content: "\E019";
}
.km-list .km-icon-label.k-i-resize-h:before {
  content: "\E01A";
}
.km-list .km-icon-label.k-i-resize-v:before {
  content: "\E01B";
}
.km-list .km-icon-label.k-i-refresh:before {
  content: "\E01C";
}
.km-list .km-icon-label.k-i-refresh-rev:before {
  content: "\E01D";
}
.km-list .km-icon-label.k-si-refresh:before {
  content: "\E01E";
}
.km-list .km-icon-label.k-si-refresh-rev:before {
  content: "\E01F";
}
.km-list .km-icon-label.k-i-turn-en:before {
  content: "\E020";
}
.km-list .km-icon-label.k-i-turn-es:before {
  content: "\E021";
}
.km-list .km-icon-label.k-i-turn-ne:before {
  content: "\E022";
}
.km-list .km-icon-label.k-i-turn-nw:before {
  content: "\E023";
}
.km-list .km-icon-label.k-i-turn-se:before {
  content: "\E024";
}
.km-list .km-icon-label.k-i-turn-sw:before {
  content: "\E025";
}
.km-list .km-icon-label.k-i-turn-wn:before {
  content: "\E026";
}
.km-list .km-icon-label.k-i-turn-ws:before {
  content: "\E027";
}
.km-list .km-icon-label.k-i-uturn-e:before {
  content: "\E028";
}
.km-list .km-icon-label.k-i-uturn-e-rev:before {
  content: "\E029";
}
.km-list .km-icon-label.k-i-uturn-n:before {
  content: "\E02A";
}
.km-list .km-icon-label.k-i-uturn-n-rev:before {
  content: "\E02B";
}
.km-list .km-icon-label.k-i-uturn-s:before {
  content: "\E02C";
}
.km-list .km-icon-label.k-i-uturn-s-rev:before {
  content: "\E02D";
}
.km-list .km-icon-label.k-i-uturn-w:before {
  content: "\E02E";
}
.km-list .km-icon-label.k-i-uturn-w-rev:before {
  content: "\E02F";
}
.km-list .km-icon-label.k-i-tick-sign:before {
  content: "\E030";
}
.km-list .km-icon-label.k-i-close-sign:before {
  content: "\E031";
}
.km-list .km-icon-label.k-i-plus-sign:before {
  content: "\E032";
}
.km-list .km-icon-label.k-i-minus-sign:before {
  content: "\E033";
}
.km-list .km-icon-label.k-si-plus-sign:before {
  content: "\E034";
}
.km-list .km-icon-label.k-si-minus-sign:before {
  content: "\E035";
}
.km-list .km-icon-label.k-i-cancel:before {
  content: "\E036";
}
.km-list .km-icon-label.k-i-tick:before {
  content: "\E037";
}
.km-list .km-icon-label.k-i-close:before {
  content: "\E038";
}
.km-list .km-icon-label.k-i-plus:before {
  content: "\E039";
}
.km-list .km-icon-label.k-i-minus:before {
  content: "\E03A";
}
.km-list .km-icon-label.k-si-plus:before {
  content: "\E03B";
}
.km-list .km-icon-label.k-si-minus:before {
  content: "\E03C";
}
.km-list .km-icon-label.k-i-stop:before {
  content: "\E03D";
}
.km-list .km-icon-label.k-i-pause:before {
  content: "\E03E";
}
.km-list .km-icon-label.k-i-eject:before {
  content: "\E03F";
}
.km-list .km-icon-label.k-i-volume-off:before {
  content: "\E040";
}
.km-list .km-icon-label.k-i-volume-low:before {
  content: "\E041";
}
.km-list .km-icon-label.k-i-volume-high:before {
  content: "\E042";
}
.km-list .km-icon-label.k-i-earphones:before {
  content: "\E043";
}
.km-list .km-icon-label.k-i-heart:before {
  content: "\E044";
}
.km-list .km-icon-label.k-i-heart-empty:before {
  content: "\E045";
}
.km-list .km-icon-label.k-i-heart-half:before {
  content: "\E046";
}
.km-list .km-icon-label.k-i-star:before {
  content: "\E047";
}
.km-list .km-icon-label.k-i-star-empty:before {
  content: "\E048";
}
.km-list .km-icon-label.k-i-star-half:before {
  content: "\E049";
}
.km-list .km-icon-label.k-i-star-half-empty:before {
  content: "\E04A";
}
.km-list .km-icon-label.k-i-chart-column:before {
  content: "\E04B";
}
.km-list .km-icon-label.k-i-chart-bar:before {
  content: "\E04C";
}
.km-list .km-icon-label.k-i-chart-pie:before {
  content: "\E04D";
}
.km-list .km-icon-label.k-i-chart-donut:before {
  content: "\E04E";
}
.km-list .km-icon-label.k-i-chart-line:before {
  content: "\E04F";
}
.km-list .km-icon-label.k-i-chart-area:before {
  content: "\E050";
}
.km-list .km-icon-label.k-i-chart-donut:before {
  content: "\E051";
}
.km-list .km-icon-label.k-i-align-left:before {
  content: "\E052";
}
.km-list .km-icon-label.k-i-align-center:before {
  content: "\E053";
}
.km-list .km-icon-label.k-i-align-right:before {
  content: "\E054";
}
.km-list .km-icon-label.k-i-align-justify:before {
  content: "\E055";
}
.km-list .km-icon-label.k-i-align-clear:before {
  content: "\E056";
}
.km-list .km-icon-label.k-i-bold:before {
  content: "\E057";
}
.km-list .km-icon-label.k-i-bold-sans:before {
  content: "\E058";
}
.km-list .km-icon-label.k-i-italic:before {
  content: "\E059";
}
.km-list .km-icon-label.k-i-italic-sans:before {
  content: "\E05A";
}
.km-list .km-icon-label.k-i-underline:before {
  content: "\E05B";
}
.km-list .km-icon-label.k-i-underline-sans:before {
  content: "\E05C";
}
.km-list .km-icon-label.k-i-strikethrough:before {
  content: "\E05D";
}
.km-list .km-icon-label.k-i-strikethrough-sans:before {
  content: "\E05E";
}
.km-list .km-icon-label.k-i-font-a:before {
  content: "\E05F";
}
.km-list .km-icon-label.k-i-font-a-sans:before {
  content: "\E060";
}
.km-list .km-icon-label.k-i-font-t:before {
  content: "\E061";
}
.km-list .km-icon-label.k-i-font-t-sans:before {
  content: "\E062";
}
.km-list .km-icon-label.k-i-brightness:before {
  content: "\E063";
}
.km-list .km-icon-label.k-i-brightness-contrast:before {
  content: "\E064";
}
.km-list .km-icon-label.k-i-contrast:before {
  content: "\E065";
}
.km-list .km-icon-label.k-i-crop:before {
  content: "\E066";
}
.km-list .km-icon-label.k-i-mirror:before {
  content: "\E067";
}
.km-list .km-icon-label.k-i-flip-h:before {
  content: "\E068";
}
.km-list .km-icon-label.k-i-flip-v:before {
  content: "\E069";
}
.km-list .km-icon-label.k-i-rotate:before {
  content: "\E06A";
}
.km-list .km-icon-label.k-i-rotate-rev:before {
  content: "\E06B";
}
.km-list .km-icon-label.k-i-filter:before {
  content: "\E06C";
}
.km-list .km-icon-label.k-i-filter-clear:before {
  content: "\E06D";
}
.km-list .km-icon-label.k-i-sort-asc:before {
  content: "\E06E";
}
.km-list .km-icon-label.k-i-sort-desc:before {
  content: "\E06F";
}
.km-list .km-icon-label.k-i-sort-clear:before {
  content: "\E070";
}
.km-list .km-icon-label.k-i-indent:before {
  content: "\E071";
}
.km-list .km-icon-label.k-i-outdent:before {
  content: "\E072";
}
.km-list .km-icon-label.k-i-hyperlink:before {
  content: "\E073";
}
.km-list .km-icon-label.k-i-hyperlink-clear:before {
  content: "\E074";
}
.km-list .km-icon-label.k-i-hyperlink-ext:before {
  content: "\E075";
}
.km-list .km-icon-label.k-si-hyperlink-ext:before {
  content: "\E076";
}
.km-list .km-icon-label.k-i-ul:before {
  content: "\E077";
}
.km-list .km-icon-label.k-si-ul:before {
  content: "\E078";
}
.km-list .km-icon-label.k-i-paint:before {
  content: "\E079";
}
.km-list .km-icon-label.k-i-paste:before {
  content: "\E07A";
}
.km-list .km-icon-label.k-i-pencil:before {
  content: "\E07B";
}
.km-list .km-icon-label.k-i-image:before {
  content: "\E07C";
}
.km-list .km-icon-label.k-i-image-add:before {
  content: "\E07D";
}
.km-list .km-icon-label.k-i-print:before {
  content: "\E07E";
}
.km-list .km-icon-label.k-i-zoom:before {
  content: "\E07F";
}
.km-list .km-icon-label.k-i-zoom-in:before {
  content: "\E080";
}
.km-list .km-icon-label.k-i-zoom-out:before {
  content: "\E081";
}
.km-list .km-icon-label.k-i-asterisk:before {
  content: "\E082";
}
.km-list .km-icon-label.k-i-clip:before {
  content: "\E083";
}
.km-list .km-icon-label.k-i-clip-45:before {
  content: "\E084";
}
.km-list .km-icon-label.k-i-qrcode:before {
  content: "\E085";
}
.km-list .km-icon-label.k-i-book:before {
  content: "\E086";
}
.km-list .km-icon-label.k-i-bookmark:before {
  content: "\E087";
}
.km-list .km-icon-label.k-i-briefcase:before {
  content: "\E088";
}
.km-list .km-icon-label.k-i-calendar:before {
  content: "\E089";
}
.km-list .km-icon-label.k-i-camera-still:before {
  content: "\E08A";
}
.km-list .km-icon-label.k-i-camera-video:before {
  content: "\E08B";
}
.km-list .km-icon-label.k-i-certificate:before {
  content: "\E08C";
}
.km-list .km-icon-label.k-i-clock:before {
  content: "\E08D";
}
.km-list .km-icon-label.k-i-cloud:before {
  content: "\E08E";
}
.km-list .km-icon-label.k-i-collapse:before {
  content: "\E08F";
}
.km-list .km-icon-label.k-i-columns:before {
  content: "\E090";
}
.km-list .km-icon-label.k-i-comment:before {
  content: "\E091";
}
.km-list .km-icon-label.k-i-comment-empty:before {
  content: "\E092";
}
.km-list .km-icon-label.k-i-comments:before {
  content: "\E093";
}
.km-list .km-icon-label.k-i-comments-empty:before {
  content: "\E094";
}
.km-list .km-icon-label.k-i-credit-card:before {
  content: "\E095";
}
.km-list .km-icon-label.k-i-download:before {
  content: "\E096";
}
.km-list .km-icon-label.k-i-draghandle:before {
  content: "\E097";
}
.km-list .km-icon-label.k-si-draghandle:before {
  content: "\E098";
}
.km-list .km-icon-label.k-i-envelop:before {
  content: "\E099";
}
.km-list .km-icon-label.k-i-envelop-open:before {
  content: "\E09A";
}
.km-list .km-icon-label.k-i-eye:before {
  content: "\E09B";
}
.km-list .km-icon-label.k-i-file:before {
  content: "\E09C";
}
.km-list .km-icon-label.k-i-file-add:before {
  content: "\E09D";
}
.km-list .km-icon-label.k-i-film:before {
  content: "\E09E";
}
.km-list .km-icon-label.k-i-flag:before {
  content: "\E09F";
}
.km-list .km-icon-label.k-i-folder-add:before {
  content: "\E0A0";
}
.km-list .km-icon-label.k-i-folder:before {
  content: "\E0A1";
}
.km-list .km-icon-label.k-i-folder-open:before {
  content: "\E0A2";
}
.km-list .km-icon-label.k-i-folder-up:before {
  content: "\E0A3";
}
.km-list .km-icon-label.k-i-gear:before {
  content: "\E0A4";
}
.km-list .km-icon-label.k-si-gear:before {
  content: "\E0A5";
}
.km-list .km-icon-label.k-i-transmit:before {
  content: "\E0A6";
}
.km-list .km-icon-label.k-i-beer:before {
  content: "\E0A7";
}
.km-list .km-icon-label.k-i-cocktail:before {
  content: "\E0A8";
}
.km-list .km-icon-label.k-i-coffee:before {
  content: "\E0A9";
}
.km-list .km-icon-label.k-i-wine:before {
  content: "\E0AA";
}
.km-list .km-icon-label.k-i-grid:before {
  content: "\E0AB";
}
.km-list .km-icon-label.k-i-thumbs:before {
  content: "\E0AC";
}
.km-list .km-icon-label.k-i-split-h:before {
  content: "\E0AD";
}
.km-list .km-icon-label.k-i-split-v:before {
  content: "\E0AE";
}
.km-list .km-icon-label.k-i-home:before {
  content: "\E0AF";
}
.km-list .km-icon-label.k-i-inbox:before {
  content: "\E0B0";
}
.km-list .km-icon-label.k-i-key:before {
  content: "\E0B1";
}
.km-list .km-icon-label.k-i-login:before {
  content: "\E0B2";
}
.km-list .km-icon-label.k-i-logout:before {
  content: "\E0B3";
}
.km-list .km-icon-label.k-i-place:before {
  content: "\E0B4";
}
.km-list .km-icon-label.k-i-megaphone:before {
  content: "\E0B5";
}
.km-list .km-icon-label.k-i-note:before {
  content: "\E0B6";
}
.km-list .km-icon-label.k-i-pin:before {
  content: "\E0B7";
}
.km-list .km-icon-label.k-i-unpin:before {
  content: "\E0B8";
}
.km-list .km-icon-label.k-i-power:before {
  content: "\E0B9";
}
.km-list .km-icon-label.k-i-progress-bars:before {
  content: "\E0BA";
}
.km-list .km-icon-label.k-i-road:before {
  content: "\E0BB";
}
.km-list .km-icon-label.k-i-rss:before {
  content: "\E0BC";
}
.km-list .km-icon-label.k-i-floppy:before {
  content: "\E0BD";
}
.km-list .km-icon-label.k-i-sitemap:before {
  content: "\E0BE";
}
.km-list .km-icon-label.k-i-tag-45:before {
  content: "\E0BF";
}
.km-list .km-icon-label.k-i-tag-h:before {
  content: "\E0C0";
}
.km-list .km-icon-label.k-i-thunderbolt:before {
  content: "\E0C1";
}
.km-list .km-icon-label.k-i-tooltip:before {
  content: "\E0C2";
}
.km-list .km-icon-label.k-i-trash:before {
  content: "\E0C3";
}
.km-list .km-icon-label.k-i-trophy:before {
  content: "\E0C4";
}
.km-list .km-icon-label.k-i-ungroup:before {
  content: "\E0C5";
}
.km-list .km-icon-label.k-i-upload:before {
  content: "\E0C6";
}
.km-list .km-icon-label.k-i-window:before {
  content: "\E0C7";
}
.km-list .km-icon-label.k-i-tiles:before {
  content: "\E0C8";
}
.km-list .km-icon-label.k-i-wrench:before {
  content: "\E0C9";
}
.km-list .km-icon-label.k-i-action:before {
  content: "\E0CA";
}
.km-list .km-icon-label.k-i-add:before {
  content: "\E0CB";
}
.km-list .km-icon-label.k-i-add-inv:before {
  content: "\E0CC";
}
.km-list .km-icon-label.k-i-armchair:before {
  content: "\E0CD";
}
.km-list .km-icon-label.k-i-battery:before {
  content: "\E0CE";
}
.km-list .km-icon-label.k-i-book-open:before {
  content: "\E0CF";
}
.km-list .km-icon-label.k-i-camera:before {
  content: "\E0D0";
}
.km-list .km-icon-label.k-i-cart:before {
  content: "\E0D1";
}
.km-list .km-icon-label.k-i-time:before {
  content: "\E0D2";
}
.km-list .km-icon-label.k-i-coffee-card:before {
  content: "\E0D3";
}
.km-list .km-icon-label.k-i-download-arrow:before {
  content: "\E0D4";
}
.km-list .km-icon-label.k-i-edit:before {
  content: "\E0D5";
}
.km-list .km-icon-label.k-i-faves:before {
  content: "\E0D6";
}
.km-list .km-icon-label.k-i-star:before {
  content: "\E0D7";
}
.km-list .km-icon-label.k-i-featured:before {
  content: "\E0D8";
}
.km-list .km-icon-label.k-i-forward:before {
  content: "\E0D9";
}
.km-list .km-icon-label.k-i-cog:before {
  content: "\E0DA";
}
.km-list .km-icon-label.k-i-globe:before {
  content: "\E0DB";
}
.km-list .km-icon-label.k-i-globe-inv:before {
  content: "\E0DC";
}
.km-list .km-icon-label.k-i-house:before {
  content: "\E0DD";
}
.km-list .km-icon-label.k-i-info:before {
  content: "\E0DE";
}
.km-list .km-icon-label.k-i-share:before {
  content: "\E0DF";
}
.km-list .km-icon-label.k-i-more-h:before {
  content: "\E0E0";
}
.km-list .km-icon-label.k-i-more-v:before {
  content: "\E0E1";
}
.km-list .km-icon-label.k-i-next:before {
  content: "\E0E2";
}
.km-list .km-icon-label.k-i-pause-a:before {
  content: "\E0E3";
}
.km-list .km-icon-label.k-i-user:before {
  content: "\E0E4";
}
.km-list .km-icon-label.k-i-play-a:before {
  content: "\E0E5";
}
.km-list .km-icon-label.k-i-refresh-a:before {
  content: "\E0E6";
}
.km-list .km-icon-label.k-i-reset:before {
  content: "\E0E7";
}
.km-list .km-icon-label.k-i-rewind:before {
  content: "\E0E8";
}
.km-list .km-icon-label.k-i-search-a:before {
  content: "\E0E9";
}
.km-list .km-icon-label.k-i-stop-a:before {
  content: "\E0EA";
}
.km-list .km-icon-label.k-i-tiles-a:before {
  content: "\E0EB";
}
.km-list .km-icon-label.k-i-trash-a:before {
  content: "\E0EC";
}
.km-list .km-icon-label.k-i-undo:before {
  content: "\E0ED";
}
.km-list .km-icon-label.k-i-redo:before {
  content: "\E0EE";
}
.km-list .km-icon-label.k-i-volume-a:before {
  content: "\E0EF";
}
.km-list .km-icon-label.k-i-wifi:before {
  content: "\E0F0";
}
.km-list .km-icon-label.k-i-more-lines:before {
  content: "\E0F1";
}
.km-list .km-icon-label.k-i-pull-to-ref:before {
  content: "\E0F2";
}
.km-list .km-icon-label.k-i-loading-android:before {
  content: "\E0F3";
}
.km-list .km-icon-label.k-i-loading-blackberry:before {
  content: "\E0F4";
}
.km-list .km-icon-label.k-i-loading-meego:before {
  content: "\E0F5";
}
.km-list .km-icon-label.k-i-loading-custom:before {
  content: "\E0F6";
}
.km-list .km-icon-label.k-i-loading-ios:before {
  content: "\E0F7";
}
.km-list .km-icon-label.k-i-bug:before {
  content: "\E0F8";
}
.km-list .km-icon-label.k-i-info:before {
  content: "\E0F9";
}
.km-list .km-icon-label.k-i-warning:before {
  content: "\E0FA";
}
.km-list .km-icon-label.k-i-question:before {
  content: "\E0FB";
}
.km-list .km-icon-label.k-i-insert-n:before {
  content: "\E0FC";
}
.km-list .km-icon-label.k-i-insert-m:before {
  content: "\E0FD";
}
.km-list .km-icon-label.k-i-insert-s:before {
  content: "\E0FE";
}
.km-list .km-icon-label.k-i-lock:before {
  content: "\E0FF";
}
.km-list .km-icon-label.k-i-unlock:before {
  content: "\E100";
}
.km-list .km-icon-label.k-i-phone:before {
  content: "\E101";
}
.km-list .km-icon-label.k-i-tablet:before {
  content: "\E102";
}
.km-list .km-icon-label.k-i-ol:before {
  content: "\E103";
}
.km-list .km-icon-label.k-i-barcode:before {
  content: "\E104";
}
.km-list .km-icon-label.k-i-html5:before {
  content: "\E105";
}
.km-list .km-icon-label.k-i-css3:before {
  content: "\E106";
}
.km-list .km-icon-label.k-i-kendoui:before {
  content: "\E107";
}
.km-list .km-icon-label.k-i-telerik:before {
  content: "\E108";
}
.km-list .km-icon-label.k-i-icenium:before {
  content: "\E109";
}
.km-list .km-icon-label.k-i-sitefinity:before {
  content: "\E10A";
}
.km-list .km-icon-label.k-i-twitter:before {
  content: "\E10B";
}
.km-list .km-icon-label.k-i-linkedin:before {
  content: "\E10C";
}
.km-list .km-icon-label.k-i-facebook:before {
  content: "\E10D";
}
.km-list .km-icon-label.k-i-pinterest:before {
  content: "\E10E";
}
.km-list .km-icon-label.k-i-youtube:before {
  content: "\E10F";
}
.km-list .km-icon-label.k-i-vimeo:before {
  content: "\E110";
}
.km-list .km-icon-label.k-i-behance:before {
  content: "\E111";
}
.km-list .km-icon-label.k-i-dribbble:before {
  content: "\E112";
}
.km-list .km-icon-label.k-i-googleplus:before {
  content: "\E113";
}
.km-list .km-icon-label.k-i-minimize:before {
  content: "\E114";
}
.km-list .km-icon-label.k-i-html:before {
  content: "\E115";
}
.km-list .km-icon-label.k-i-group:before {
  content: "\E116";
}
.km-list .km-icon-label.k-i-subscript:before {
  content: "\E117";
}
.km-list .km-icon-label.k-i-superscript:before {
  content: "\E118";
}
.km-list .km-icon-label.k-i-drophere:before {
  content: "\E119";
}
/* Support for iOS6 */
@media only screen and (height: 320px) and (orientation: landscape) {
  .km-ios6 {
    height: 100% !important;
  }
}
/* CSS */
.km-ios:after {
  content: url("images/back.png");
  display: none;
}
.km-ios {
  font: bold 0.9em HelveticaNeue, sans-serif;
}
.km-ios .km-view {
  overflow: hidden;
}
.km-ios .km-toolbar,
.km-ios .km-navbar,
.km-ios .km-button,
.km-ios .km-tabstrip,
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li > a {
  overflow: visible;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
}
/* Icons */
.k-ff .km-ios .km-detail .km-icon {
  top: 5%;
  left: 7%;
}
.km-ios .km-button .km-icon:before,
.km-ios .km-button .km-contactadd:before,
.km-ios .km-button .km-rowdelete:before,
.km-ios .km-button .km-rowinsert:before,
.km-ios .km-button .km-detaildisclose:before {
  display: block;
  margin-top: -1px;
}
.km-ios .km-button .km-icon,
.km-ios .km-button.km-state-active .km-icon {
  text-shadow: none;
}
.km-ios .km-buttongroup .km-icon {
  margin-top: .1em;
}
.k-webkit .km-ios .km-icon:after,
.k-safari .km-ios .km-icon:after {
  background-color: currentcolor;
}
.km-ios .km-tabstrip .km-icon:before {
  display: block;
  margin-top: 1px;
}
.km-ios .km-tabstrip .km-state-active .km-icon:before {
  margin-top: 2px;
}
.km-ios .km-navbar,
.km-ios .km-toolbar,
.km-ios .km-tabstrip {
  border: 0;
  border-radius: 0;
}
/* Loader */
.km-ios.km-pane > .km-loader,
.km-ios .km-pane > .km-loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios .km-loader:before {
  content: "\a0";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.km-ios .km-load-more,
.km-ios .km-scroller-pull {
  font-size: 1em;
}
.km-ios .km-scroller-pull {
  opacity: .5;
}
.km-ios .km-list > .km-load-more {
  border-top: 0;
}
.km-ios .km-loader h1,
.km-ios .km-scroller-refresh .km-template {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2em;
}
.km-ios .km-scroller-pull .km-template {
  min-width: 0;
}
.km-ios .km-loading,
.km-ios .km-loader .km-loading-left,
.km-root .km-ios .km-load-more.km-scroller-refresh .km-icon,
.km-root .km-ios .km-scroller-pull.km-scroller-refresh .km-icon,
.km-ios .km-scroller-refresh .km-loading-left {
  display: inline-block;
  top: auto;
  left: auto;
  width: 8px;
  height: 2px;
  font-size: 1em;
  margin: 0;
  border: 0;
  border-radius: 5px;
  vertical-align: middle;
  -webkit-transform-origin: 150% 50%;
      -ms-transform-origin: 150% 50%;
          transform-origin: 150% 50%;
  background: currentcolor;
  -webkit-animation: km-ios-spin 1.5s infinite steps(8);
          animation: km-ios-spin 1.5s infinite steps(8);
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.6);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.6);
}
.km-ios .km-loading,
.km-ios .km-loader .km-loading-left {
  margin: 2px 0 0;
}
.km-ios .km-loading:before,
.km-ios .km-loading:after,
.km-ios .km-load-more.km-scroller-refresh .km-icon:after,
.km-ios .km-load-more.km-scroller-refresh .km-icon:before,
.km-ios .km-scroller-pull.km-scroller-refresh .km-icon:after,
.km-ios .km-scroller-pull.km-scroller-refresh .km-icon:before {
  color: inherit;
  content: "\a0";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
  margin-top: 0;
  -webkit-transform-origin: inherit;
      -ms-transform-origin: inherit;
          transform-origin: inherit;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  -webkit-background-clip: border-box;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.5);
}
.km-ios .km-loading:after,
.km-ios .km-load-more.km-scroller-refresh .km-icon:after,
.km-ios .km-scroller-pull.km-scroller-refresh .km-icon:after {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.4);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.4);
}
.km-ios .km-loader .km-loading-left,
.km-ios .km-scroller-refresh .km-loading-left {
  margin-left: -8px;
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.3);
  -webkit-animation: km-ios-spin1 1.5s infinite steps(8);
          animation: km-ios-spin1 1.5s infinite steps(8);
}
.km-ios .km-loading-left:after,
.km-ios .km-loading-left:before,
.km-ios .km-loading-right {
  display: none;
}
.km-ios .km-loading,
.km-ios .km-loading:after,
.km-ios .km-loading:before {
  color: black;
}
.km-ios .km-loading:before {
  color: rgba(0, 0, 0, 0.9);
}
.km-ios .km-loading:after {
  color: rgba(0, 0, 0, 0.8);
}
.km-ios .km-loading-left {
  color: rgba(0, 0, 0, 0.7);
}
/* PopUp */
.km-ios .km-popup .k-popup {
  font-size: .8em;
}
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li > a {
  overflow: hidden;
  border-width: 3px;
  border-radius: .6em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  font-size: 1.5em;
  padding: .35em 0 .35em .6em;
  text-shadow: none;
}
.km-root .km-ios .km-popup .k-item {
  border-width: 3px;
  border-style: solid;
}
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li {
  margin: .5em 0;
}
.km-ios .km-popup .k-item:first-child,
.km-ios .km-actionsheet > li:first-child {
  margin: 1em 0 .5em;
}
.km-ios .km-popup .k-item:last-child {
  margin: .5em 0 1em;
}
.km-ios .km-popup .k-state-hover,
.km-ios .km-popup .k-state-focused,
.km-ios .km-popup .k-state-selected,
.km-ios .km-actionsheet > li > a:active,
.km-ios .km-actionsheet > li > a:hover,
.km-ios li.km-actionsheet-cancel > a {
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-ios .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios .km-popup .k-list-container {
  width: 100% !important;
}
.km-ios .km-actionsheet,
.km-phone .km-ios .km-popup .k-list-container {
  max-height: 80%;
}
.km-ios .km-actionsheet-wrapper,
.km-ios .km-popup .k-list-container {
  bottom: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1em 1.5em;
}
.km-phone .km-ios .km-actionsheet-wrapper,
.km-phone .km-ios .km-popup .k-list-container {
  border-top: 0;
}
.km-ios .km-popup-wrapper,
.km-ios .km-modalview-wrapper {
  padding: 15px;
}
.km-ios .km-popup.km-pane {
  border: 5px solid transparent;
}
.km-ios .km-popup.km-pane,
.km-tablet .km-ios .km-actionsheet-wrapper {
  border-radius: 5px;
}
.km-ios .km-modalview,
.km-ios .km-popup.km-pane .km-view {
  overflow: visible;
  border-radius: 0;
}
html .km-ios .km-modalview .km-navbar,
html .km-ios .km-modalview .km-toolbar,
html .km-ios .km-popup.km-pane .km-navbar,
html .km-ios .km-popup.km-pane .km-toolbar,
html .km-ios .km-popup.km-pane .km-tabstrip {
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-ios .km-modalview .km-header,
.km-ios .km-popup.km-pane .km-header {
  padding: 0 5px;
  margin: -5px -5px 2px;
  border-radius: 5px 5px 0 0;
  -webkit-margin-collapse: separate;
}
/* Checkboxes and Radios */
.km-ios .km-listview-label input[type=radio]:checked,
.km-ios .km-listview-label input[type=checkbox]:checked {
  font-size: 1.2em;
}
/* Tablet Styles */
.km-tablet .km-ios .km-tabstrip {
  padding: .2rem 0 .2rem;
}
.km-tablet .km-ios.km-horizontal .km-tabstrip {
  padding: .2rem 0 0;
}
.km-tablet .km-pane.km-ios .km-tabstrip {
  padding: 0;
}
.km-tablet .km-ios .km-tabstrip .km-icon {
  margin: .3rem auto;
}
.km-tablet .km-ios.km-horizontal .km-tabstrip .km-icon {
  margin: .1rem auto;
}
.km-tablet .km-ios .km-tabstrip .km-button {
  padding: .1rem .3rem;
  margin: 0;
  min-width: 8em;
}
.km-tablet .km-ios.km-horizontal .km-tabstrip .km-button {
  padding: 0 .2rem;
}
.km-tablet .km-ios .km-view-title,
.km-tablet .km-ios .km-modalview .km-view-title {
  line-height: 2.2em;
}
.km-tablet .km-ios .km-actionsheet-title,
.km-tablet .km-ios li.km-actionsheet-cancel {
  display: none;
}
.km-tablet .km-ios .km-actionsheet-wrapper,
.km-tablet .km-ios .km-popup .k-list-container {
  padding: .6em 1em;
}
/* Color Template */
/* Variables */
.km-ios,
.km-ios .km-listview-wrapper .km-filter-wrap {
  background: #fff;
}
.km-ios .k-toolbar,
.km-ios .km-navbar,
.km-ios .km-button,
.km-ios .km-tabstrip,
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li > a {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), color-stop(6%, rgba(255, 255, 255, 0.45)), color-stop(50%, rgba(255, 255, 255, 0.2)), color-stop(50%, rgba(255, 255, 255, 0.15)), to(rgba(100, 100, 100, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.45) 6%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(100, 100, 100, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.45) 6%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(100, 100, 100, 0));
  border-color: #2f3740 #394d68 #375073;
  background-color: #7185a2;
}
.km-ios .km-view-title,
.km-ios .km-dialog-title {
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
}
.km-ios .k-toolbar,
.km-ios .km-navbar,
.km-ios .km-button,
.km-ios .km-tabstrip,
.km-ios .km-view-title,
.km-ios .km-dialog-title,
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li > a,
.km-ios .k-split-container > li > .km-button,
.km-ios .k-overflow-container > li > .km-button {
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
}
.km-ios .km-button {
  border-bottom-color: rgba(255, 255, 255, 0.5);
  background: transparent, #496b9a;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.35)), color-stop(50%, rgba(255, 255, 255, 0.1)), color-stop(50%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
}
.km-ios .km-button,
.km-ios .k-toolbar .km-buttongroup {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0,0,0,.3), inset 0 1px 3px rgba(0,0,0,.5);
          box-shadow: inset 0 0 0 1px rgba(0,0,0,.3), inset 0 1px 3px rgba(0,0,0,.5);
}
.km-ios .km-button:active,
.km-ios .km-button.km-state-active {
  background-color: #264e8c;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0,0,0,.3), inset 0 1px 3px rgba(0,0,0,.5), inset 0 0 0 1000px rgba(0,0,0,.2);
          box-shadow: inset 0 0 0 1px rgba(0,0,0,.3), inset 0 1px 3px rgba(0,0,0,.5), inset 0 0 0 1000px rgba(0,0,0,.2);
}
.km-ios .km-icon,
.km-ios .km-detail .km-icon,
.km-ios li.km-state-active .km-icon,
.km-ios li.km-state-active,
.km-ios .km-switch-label-on {
  color: #fff;
}
.km-ios .km-checkbox,
.km-ios .km-switch-wrapper {
  background-color: #f0f0f0;
}
.km-ios .km-switch-background,
.km-ios .k-slider-selection {
  background-color: #007EE9;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), color-stop(50%, #eeeeee));
  background-image: -webkit-linear-gradient(top, #dbdbdb, #eeeeee 50%);
  background-image: linear-gradient(to bottom, #dbdbdb, #eeeeee 50%);
}
.km-ios .km-switch-background:after {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.14)), to(rgba(255, 255, 255, 0.4)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.14) 0, rgba(255, 255, 255, 0.4));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.14) 0, rgba(255, 255, 255, 0.4));
}
.km-ios .km-switch-container {
  -webkit-box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.3), inset 0 -1px 1px 0 rgba(0,0,0,.2);
          box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.3), inset 0 -1px 1px 0 rgba(0,0,0,.2);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(95%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2));
}
.km-ios .km-checkbox {
  border-color: #e5e5e5;
}
.km-ios .km-checkbox-checked {
  border-color: #fff;
  background-color: #BB0000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f1969b), color-stop(50%, rgba(230, 70, 78, 0.5)), color-stop(50%, rgba(222, 11, 21, 0.5)), to(rgba(183, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, #f1969b, rgba(230, 70, 78, 0.5) 50%, rgba(222, 11, 21, 0.5) 50%, rgba(183, 0, 0, 0));
  background-image: linear-gradient(to bottom, #f1969b, rgba(230, 70, 78, 0.5) 50%, rgba(222, 11, 21, 0.5) 50%, rgba(183, 0, 0, 0));
  -webkit-box-shadow: 0 2px 3px #333;
          box-shadow: 0 2px 3px #333;
}
.km-ios .km-switch-handle {
  border-color: rgba(102, 102, 102, 0.8);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
          box-shadow: inset 0 0 0 1px #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(5%, #d7d7d7), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #d7d7d7 5%, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #d7d7d7 5%, #ffffff);
}
.km-ios .km-switch-label-off {
  color: #7f7f7f;
}
.km-ios .km-switch-container,
.km-ios .km-switch-wrapper {
  border-color: #f0f0f0;
}
.km-ios .km-badge,
.km-ios .km-detail {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), color-stop(50%, rgba(255, 255, 255, 0.2)), color-stop(50%, rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
  color: #FFF;
  background-color: #bc0404;
}
.km-ios .km-detail {
  background-color: #1F6DD8;
}
.km-ios .km-detail:active,
.km-ios .km-detail.km-state-active {
  -webkit-box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5), inset 0 0 0 1000px rgba(0,0,0,.2);
  box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5), inset 0 0 0 1000px rgba(0,0,0,.2);
}
/* Filter box */
.km-ios .km-filter-form {
  color: #b1b3b8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
}
.km-ios .km-filter-wrap:before,
.km-ios .km-filter-reset .km-clear {
  color: #adadad;
}
.km-ios .km-list li > .km-icon,
.km-ios .km-listview-link > .km-icon {
  color: #000;
}
.km-ios .km-button .km-icon:before,
.km-ios .km-button .km-contactadd:before,
.km-ios .km-button .km-rowdelete:before,
.km-ios .km-button .km-rowinsert:before,
.km-ios .km-button .km-detaildisclose:before {
  color: rgba(0, 0, 0, 0.3);
}
.km-ios .km-rowinsert {
  background-color: #24870D;
}
.km-ios .km-rowdelete {
  background-color: #B01B1A;
}
/* ButtonGroup */
.km-ios .km-buttongroup .km-button,
.km-tablet .km-ios .km-buttongroup .km-button {
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4);
          box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4);
}
.km-ios .km-header > .km-buttongroup .km-button:not(.km-state-active),
.km-ios .km-footer > .km-buttongroup .km-button:not(.km-state-active),
.km-ios .km-content .km-buttongroup .km-button:not(.km-state-active) {
  color: #929292;
  text-shadow: 0 1px rgba(255, 255, 255, 0.8);
}
.km-ios .km-header > .km-buttongroup .km-button,
.km-ios .km-footer > .km-buttongroup .km-button,
.km-ios .km-content .km-buttongroup .km-button {
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0.12)), to(rgba(0, 0, 0, 0.25)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12) 50%, rgba(0, 0, 0, 0.25));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12) 50%, rgba(0, 0, 0, 0.25));
}
.km-root .km-ios .km-buttongroup .km-state-active {
  -webkit-box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 0 0 1000px rgba(0,0,0,.2);
  box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 0 0 1000px rgba(0,0,0,.2);
}
.km-ios .km-header > .km-buttongroup .km-state-active,
.km-ios .km-footer > .km-buttongroup .km-state-active,
.km-ios .km-content .km-buttongroup .km-state-active {
  color: #fff;
  border-color: rgba(0, 0, 0, 0.4);
  background-color: #3D7EEB;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(50%, rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0.25)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.25));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.25));
  -webkit-box-shadow: inset -1px 0 2px rgba(0, 0, 0, 0.3);
          box-shadow: inset -1px 0 2px rgba(0, 0, 0, 0.3);
}
.km-ios .km-navbar,
.km-ios .k-toolbar {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.65)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0));
  background-color: #506D93;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
}
.km-ios .km-view-title {
  -webkit-box-shadow: rgba(0,0,0,.3) 0px -1px 0px inset, rgba(0,0,0,.3) 0px 1px 3px;
          box-shadow: rgba(0,0,0,.3) 0px -1px 0px inset, rgba(0,0,0,.3) 0px 1px 3px;
}
/* TabStrip */
.km-ios .km-tabstrip {
  border-top-color: rgba(255, 255, 255, 0.2);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(46, 46, 46, 0.8)), color-stop(50%, rgba(21, 21, 21, 0.5)), color-stop(50%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
  background-image: -webkit-linear-gradient(top, rgba(46, 46, 46, 0.8), rgba(21, 21, 21, 0.5) 50%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.8));
  background-image: linear-gradient(to bottom, rgba(46, 46, 46, 0.8), rgba(21, 21, 21, 0.5) 50%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.8));
  -webkit-box-shadow: 0 -1px 0 0 #000;
          box-shadow: 0 -1px 0 0 #000;
  background-color: #1A1A1A;
  text-shadow: 0 1px 1px #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.18)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0));
}
html .km-ios .km-tabstrip .km-button {
  color: #d1d1d1;
  -webkit-box-shadow: 1px 0 0 rgba(255,255,255,.2), 2px 0 0 rgba(0,0,0,.5);
          box-shadow: 1px 0 0 rgba(255,255,255,.2), 2px 0 0 rgba(0,0,0,.5);
}
html .km-ios .km-tabstrip .km-button:active,
html .km-ios .km-tabstrip .km-state-active,
html .km-ios .km-tabstrip .km-state-active:last-child,
html .km-ios .km-tabstrip .km-button:active:last-child {
  color: #FFF;
  background-color: #1A1A1A;
  -webkit-box-shadow: 0 0 2px rgba(255,255,255,.4);
          box-shadow: 0 0 2px rgba(255,255,255,.4);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, 0.18)), color-stop(right, to), color-stop(rgba(0, 0, 0, 0.6)), color-stop(3%, rgba(0, 0, 0, 0)), color-stop(97%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.18), to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0) 97%, rgba(0, 0, 0, 0.6));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.18), to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0) 97%, rgba(0, 0, 0, 0.6));
}
/* ListView */
.km-ios .km-listinset > li:first-child,
.km-ios .km-listgroupinset .km-list > li:first-child {
  border-color: #b1b3b8;
  -webkit-box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 0 rgba(200,200,200,.4), inset 0 -1px 0 rgba(0,0,0,.2);
          box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 0 rgba(200,200,200,.4), inset 0 -1px 0 rgba(0,0,0,.2);
}
.km-ios .km-list > li {
  border-top-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.km-ios .km-listinset > li,
.km-ios .km-listgroupinset .km-list > li {
  border-color: #b1b3b8;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.2), inset 0 1px 0 rgba(255,255,255,.4);
          box-shadow: inset 0 -1px 0 rgba(0,0,0,.2), inset 0 1px 0 rgba(255,255,255,.4);
}
.km-ios .km-listinset > li:last-child,
.km-ios .km-listgroupinset .km-list > li:last-child {
  border-color: #b1b3b8;
  -webkit-box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 rgba(255,255,255,.4);
          box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 rgba(255,255,255,.4);
}
.km-ios li.km-state-active .km-listview-link,
.km-ios li.km-state-active .km-listview-label {
  color: #fff;
  background: #0260E8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.28)), color-stop(50%, rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
}
.km-ios .km-listview-link:after {
  color: #7b7b7b;
}
.km-ios .km-state-active .km-listview-link:after {
  color: #fff;
}
.km-ios .km-group-title {
  color: #FFF;
  text-shadow: 0 1px 1px rgba(100, 100, 100, 0.8);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #94A3AE;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
  -webkit-box-shadow: 0 -1px 0 #86929A;
          box-shadow: 0 -1px 0 #86929A;
}
.km-ios .km-listgroupinset .km-group-title {
  color: #4C566C;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
}
.km-ios .km-list > li {
  background: #f0f0f0;
}
.km-ios,
.km-ios .km-content {
  background: #f0f0f0;
  color: #000;
}
.km-ios .km-insetcontent {
  background: #c4ccd5 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAABCAYAAAASC7TOAAAAGklEQVR4Xm3DMREAAACCQKPYvySeO8MHML0BOrEVLMJmdzAAAAAASUVORK5CYII=");
}
/* Icons */
.km-ios .km-button .km-icon,
.km-ios .k-toolbar .km-button .km-icon,
.km-ios .km-button.km-state-active .km-icon {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.7)), to(rgba(255, 255, 255, 0.9)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
}
.km-ios .km-buttongroup .km-icon {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
}
.km-ios .km-tabstrip .km-icon {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  color: #666666;
}
.km-ios .km-tabstrip .km-state-active .km-icon {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.6)), color-stop(50%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  color: #00B0FF;
}
/* Pull to refresh */
.km-root .km-ios .km-load-more,
.km-ios .km-scroller-pull {
  color: #656565;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}
.km-ios .km-load-more .km-icon,
.km-ios .km-scroller-pull .km-icon {
  color: #656565;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}
.k-webkit .km-ios .km-load-more .km-icon:after,
.k-safari .km-ios .km-load-more .km-icon:after,
.k-webkit .km-ios .km-scroller-pull .km-icon:after,
.k-safari .km-ios .km-scroller-pull .km-icon:after {
  background: rgba(255, 255, 255, 0.5);
  text-shadow: 0 1px 1px #656565;
}
/* ScrollView */
.km-ios .km-pages li {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 1em;
  -webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
          box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}
.km-ios .km-pages .km-current-page {
  background: rgba(0, 0, 0, 0.3);
}
/* Slider */
.km-ios .k-slider .k-draghandle,
.km-ios .k-slider .k-draghandle:hover {
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#fff));
  background-image: -webkit-linear-gradient(top, #ccc, #fff);
  background-image: linear-gradient(to bottom, #ccc, #fff);
  -webkit-box-shadow: inset 0 0 0 1px #f9f9f9, 1px 1px 3px rgba(0,0,0,.3);
          box-shadow: inset 0 0 0 1px #f9f9f9, 1px 1px 3px rgba(0,0,0,.3);
}
.km-ios .k-slider-selection {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), color-stop(15%, rgba(0, 0, 0, 0)), color-stop(90%, rgba(0, 0, 0, 0)), color-stop(rgba(0, 0, 0, 0.4)), color-stop(bottom, to), color-stop(#2a5db2), to(#6da9f7));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.4), to bottom, #2a5db2, #6da9f7);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.4), to bottom, #2a5db2, #6da9f7);
}
.km-ios .k-slider-track {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), color-stop(30%, rgba(0, 0, 0, 0)), color-stop(90%, rgba(0, 0, 0, 0)), color-stop(rgba(0, 0, 0, 0.4)), color-stop(bottom, to), color-stop(#dbdbdb), to(#ffffff));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.4), to bottom, #dbdbdb, #ffffff);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.4), to bottom, #dbdbdb, #ffffff);
}
/* Dialog */
.km-ios .km-dialog {
  background: rgba(10, 25, 65, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 3px 3px 5px #111;
          box-shadow: 3px 3px 5px #111;
}
.km-ios .km-dialog-title {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), color-stop(60%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 60%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 60%);
  text-shadow: 0 -1px #333;
}
.km-ios .km-dialog:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22><defs><clipPath id=%22clip%22><rect x=%220%22 y=%220%22 width=%22100%%22 height=%22100%%22 rx=%2210%22 ry=%2210%22/></clipPath></defs><ellipse fill=%22white%22 cx=%2250%%22 cy=%220%22 rx=%2280%%22 ry=%2260%%22 clip-path=%22url(%23clip)%22/></svg>');
}
.km-ios .km-dialog-content {
  color: #fff;
  text-shadow: 0 -1px #333;
}
/* Loader */
.km-ios .km-loader {
  background-color: rgba(0, 0, 0, 0.1);
}
.km-ios .km-loader h1,
.km-ios .km-scroller-refresh .km-template {
  color: #222;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
.km-ios .km-scroller-pull .km-template {
  color: #656565;
}
/* Forms */
.km-ios .km-list input[type=password],
.km-ios .km-list input[type=search],
.km-ios .km-list input[type=number],
.km-ios .km-list input[type=tel],
.km-ios .km-list input[type=url],
.km-ios .km-list input[type=email],
.km-ios .km-list input[type=month],
.km-ios .km-list input[type=color],
.km-ios .km-list input[type=week],
.km-ios .km-list input[type=date],
.km-ios .km-list input[type=time],
.km-ios .km-list input[type=datetime],
.km-ios .km-list input[type=datetime-local],
.km-ios .km-list input[type=text]:not(.k-input),
.km-ios .km-list select:not([multiple]),
.km-ios .km-list .k-dropdown-wrap,
.km-ios .km-list textarea {
  color: #385487;
}
.km-ios .km-list .k-dropdown-wrap .k-input {
  color: #385487;
}
.km-ios .km-list select:not([multiple]) option {
  color: #333;
}
.km-ios .km-content .km-list .km-state-active input[type=password],
.km-ios .km-content .km-list .km-state-active input[type=search],
.km-ios .km-content .km-list .km-state-active input[type=number],
.km-ios .km-content .km-list .km-state-active input[type=tel],
.km-ios .km-content .km-list .km-state-active input[type=url],
.km-ios .km-content .km-list .km-state-active input[type=email],
.km-ios .km-content .km-list .km-state-active input[type=month],
.km-ios .km-content .km-list .km-state-active input[type=color],
.km-ios .km-content .km-list .km-state-active input[type=week],
.km-ios .km-content .km-list .km-state-active input[type=date],
.km-ios .km-content .km-list .km-state-active input[type=time],
.km-ios .km-content .km-list .km-state-active input[type=datetime],
.km-ios .km-content .km-list .km-state-active input[type=datetime-local],
.km-ios .km-content .km-list .km-state-active input[type=text]:not(.k-input),
.km-ios .km-content .km-list .km-state-active select:not([multiple]),
.km-ios .km-content .km-list .km-state-active textarea {
  color: #fff;
}
/* PopUp + ActionSheet */
.km-ios .km-actionsheet > li > a {
  color: #000;
}
.km-ios .km-popup .k-item,
.km-ios .km-actionsheet > li > a,
.km-ios .k-split-container.km-actionsheet > li > .km-button,
.km-ios .k-overflow-container.km-actionsheet > li > .km-button {
  color: #000;
  -webkit-box-shadow: inset 0 1px 1px rgba(255,255,255,.5), 0 1px 1px rgba(255,255,255,.3);
          box-shadow: inset 0 1px 1px rgba(255,255,255,.5), 0 1px 1px rgba(255,255,255,.3);
  border-color: rgba(40, 40, 40, 0.9);
  background: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0.2)), color-stop(85%, rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.15)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.25) 85%, rgba(0, 0, 0, 0.15));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.25) 85%, rgba(0, 0, 0, 0.15));
}
.km-root .km-ios .km-popup .k-item {
  border-color: rgba(0, 0, 0, 0.8);
}
.km-ios .km-actionsheet > li > a:active,
.km-ios .km-actionsheet-wrapper .km-actionsheet > li > .km-state-active,
.km-ios .km-actionsheet > li > a:hover,
.km-ios li.km-actionsheet-cancel > a {
  color: #fff;
}
.km-ios .km-popup .k-state-hover,
.km-ios .km-popup .k-state-focused,
.km-ios .km-popup .k-state-selected,
.km-ios .km-actionsheet > li > a:active,
.km-ios .km-actionsheet-wrapper .km-actionsheet > li > .km-state-active,
.km-ios .km-actionsheet > li > a:hover,
.km-ios li.km-actionsheet-cancel > a {
  color: #fff;
  background: #1537c6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.45)), color-stop(60%, rgba(255, 255, 255, 0.1)), color-stop(80%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.15)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.15));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.15));
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
}
.km-ios li.km-actionsheet-cancel > a {
  background-color: #2b3038;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), color-stop(60%, rgba(255, 255, 255, 0)), color-stop(85%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.08)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0.08));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0.08));
}
.km-ios .km-popup {
  background: rgba(0, 0, 0, 0.6);
}
.km-ios .km-actionsheet-wrapper,
.km-ios .k-split-wrapper .km-actionsheet-wrapper,
.km-ios .k-overflow-wrapper .km-actionsheet-wrapper,
.km-ios .km-popup .k-list-container {
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(23, 37, 64, 0.6);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), color-stop(10%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0));
}
.km-phone .km-ios .km-actionsheet-wrapper,
.km-phone .km-ios .km-popup .k-list-container {
  -webkit-box-shadow: 0 -1px 2px rgba(0,0,0,.3), inset 0 1px 0 rgba(255,255,255,.5);
          box-shadow: 0 -1px 2px rgba(0,0,0,.3), inset 0 1px 0 rgba(255,255,255,.5);
}
.km-root .km-ios .k-split-container.km-actionsheet,
.km-root .km-ios .k-overflow-container.km-actionsheet {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-ios .km-shim {
  background: rgba(23, 23, 46, 0.5);
}
/* Checkboxes and Radios */
.km-ios .km-listview-label input[type=radio]:checked,
.km-ios .km-listview-label input[type=checkbox]:checked {
  font-size: 1.2em;
  color: #3E4E65;
}
.km-ios .km-state-active input[type=radio]:checked,
.km-ios .km-state-active input[type=checkbox]:checked {
  color: #fff;
}
/* Tablet Styles */
.km-tablet .km-ios .km-view-title,
.km-tablet .km-ios .km-modalview .km-view-title {
  color: #848B92;
  text-shadow: 0 1px rgba(255, 255, 255, 0.8);
}
.km-tablet .km-ios .k-toolbar .km-buttongroup .km-button,
.km-tablet .km-ios *:not(.km-tabstrip):not(.km-buttongroup) > .km-button {
  background-color: #6A727D;
}
.km-ios .k-toolbar .km-buttongroup .km-button,
.km-ios *:not(.km-tabstrip):not(.km-buttongroup) > .km-button,
.km-tablet .km-ios *:not(.km-tabstrip):not(.km-buttongroup) > .km-button {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
.km-ios .k-toolbar .km-buttongroup .km-button,
.km-ios *:not(.km-tabstrip):not(.km-buttongroup) > .km-button {
  background-color: #44648f;
}
.km-tablet .km-ios .km-navbar,
.km-tablet .km-ios .k-toolbar,
.km-tablet .km-ios .km-modalview .km-header {
  background: #A8ACB9;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.9)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
}
.km-tablet .km-ios .km-content {
  background: #D7D9DF none;
}
/* SplitView */
.km-ios .km-splitview {
  background: #000;
}
.km-ios .km-splitview > .km-split-content > .km-pane {
  border-right-color: #000;
  background: #fff;
}
.km-ios .km-splitview > .km-collapsible-pane {
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
.km-ios .km-split-vertical > .km-split-content > .km-pane {
  border-bottom-color: #000;
}
/* PopOver */
.km-ios .km-popup.km-pane,
.km-ios .k-split-wrapper .km-actionsheet-wrapper,
.km-ios .k-overflow-wrapper .km-actionsheet-wrapper,
.km-tablet .km-ios .km-actionsheet-wrapper {
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), 0 0 2px rgba(0,0,0,.5);
          box-shadow: 0 0 15px rgba(0,0,0,.5), 0 0 2px rgba(0,0,0,.5);
}
.km-ios .km-modalview .km-header,
.km-ios .km-popup.km-pane .km-header {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.35)), color-stop(50%, rgba(255, 255, 255, 0.1)), color-stop(50%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
}
.km-ios .km-popup.km-pane,
.km-ios .k-split-wrapper .km-actionsheet-wrapper,
.km-ios .k-overflow-wrapper .km-actionsheet-wrapper,
.km-tablet .km-ios .km-actionsheet-wrapper,
.km-ios .km-popup.km-pane .km-navbar .km-button {
  background-color: rgba(10, 18, 38, 0.9);
}
.km-ios .km-popup-arrow:after,
.km-ios .km-popup-arrow:before {
  border-color: rgba(0, 0, 0, 0.5) transparent;
}
.km-ios .km-popup-arrow:after {
  border-color: rgba(24, 31, 49, 0.9) transparent;
}
.km-ios .km-left .km-popup-arrow:after,
.km-ios .km-right .km-popup-arrow:after {
  border-color: transparent rgba(24, 31, 49, 0.9);
}
.km-ios .km-down .km-popup-arrow:after {
  border-color: rgba(106, 111, 124, 0.9) transparent;
}
/* ModalView */
.km-ios .km-modalview {
  background-color: #7185a2;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), 0 0 2px rgba(0,0,0,.5);
          box-shadow: 0 0 15px rgba(0,0,0,.5), 0 0 2px rgba(0,0,0,.5);
}
/* Collapsible */
.km-ios .km-collapsible-header,
.km-ios .km-collapsible-content {
  border-color: transparent;
  -webkit-box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 0 rgba(200,200,200,.4), inset 0 -1px 0 rgba(0,0,0,.2);
          box-shadow: 0 1px 0 0 rgba(255,255,255,.4), inset 0 1px 0 0 rgba(200,200,200,.4), inset 0 -1px 0 rgba(0,0,0,.2);
  background: #f7f7f7;
}
.km-ios .km-collapsible-header .km-icon {
  color: #7f7f7f;
}
/* Nova theme features */
.km-ios label.km-required:after {
  color: #f44336;
}
.km-ios .km-label-above {
  color: #7b7b7b;
}
.km-ios .km-list fieldset .km-inline-field {
  border-color: #b1b3b8;
}
.km-ios .km-list fieldset legend {
  color: #FFF;
  border-top-color: #86929A;
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #94A3AE;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}
.km-ios .km-legend-button {
  text-shadow: 0 1px 1px rgba(100, 100, 100, 0.8);
}
.km-ios .km-list fieldset input[type=password],
.km-ios .km-list .km-icon-label input[type=password],
.km-ios .km-list .km-label-above input[type=password],
.km-ios .km-list fieldset input[type=search],
.km-ios .km-list .km-icon-label input[type=search],
.km-ios .km-list .km-label-above input[type=search],
.km-ios .km-list fieldset input[type=number],
.km-ios .km-list .km-icon-label input[type=number],
.km-ios .km-list .km-label-above input[type=number],
.km-ios .km-list fieldset input[type=tel],
.km-ios .km-list .km-icon-label input[type=tel],
.km-ios .km-list .km-label-above input[type=tel],
.km-ios .km-list fieldset input[type=url],
.km-ios .km-list .km-icon-label input[type=url],
.km-ios .km-list .km-label-above input[type=url],
.km-ios .km-list fieldset input[type=email],
.km-ios .km-list .km-icon-label input[type=email],
.km-ios .km-list .km-label-above input[type=email],
.km-ios .km-list fieldset input[type=month],
.km-ios .km-list .km-icon-label input[type=month],
.km-ios .km-list .km-label-above input[type=month],
.km-ios .km-list fieldset input[type=color],
.km-ios .km-list .km-icon-label input[type=color],
.km-ios .km-list .km-label-above input[type=color],
.km-ios .km-list fieldset input[type=week],
.km-ios .km-list .km-icon-label input[type=week],
.km-ios .km-list .km-label-above input[type=week],
.km-ios .km-list fieldset input[type=date],
.km-ios .km-list .km-icon-label input[type=date],
.km-ios .km-list .km-label-above input[type=date],
.km-ios .km-list fieldset input[type=time],
.km-ios .km-list .km-icon-label input[type=time],
.km-ios .km-list .km-label-above input[type=time],
.km-ios .km-list fieldset input[type=datetime],
.km-ios .km-list .km-icon-label input[type=datetime],
.km-ios .km-list .km-label-above input[type=datetime],
.km-ios .km-list fieldset input[type=datetime-local],
.km-ios .km-list .km-icon-label input[type=datetime-local],
.km-ios .km-list .km-label-above input[type=datetime-local],
.km-ios .km-list fieldset input[type=text]:not(.k-input),
.km-ios .km-list .km-icon-label input[type=text]:not(.k-input),
.km-ios .km-list .km-label-above input[type=text]:not(.k-input),
.km-ios .km-list fieldset select:not([multiple]),
.km-ios .km-list .km-icon-label select:not([multiple]),
.km-ios .km-list .km-label-above select:not([multiple]),
.km-ios .km-list fieldset .k-dropdown-wrap,
.km-ios .km-list .km-icon-label .k-dropdown-wrap,
.km-ios .km-list .km-label-above .k-dropdown-wrap,
.km-ios .km-list fieldset textarea,
.km-ios .km-list .km-icon-label textarea,
.km-ios .km-list .km-label-above textarea {
  color: #030303;
}
.km-ios .km-full-width-slider .k-slider-track {
  border-color: #b1b3b8;
  border-top-color: #9699a0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(#ffffff));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, #ffffff 100%);
}
.km-ios .k-slider-horizontal .k-slider-selection {
  background: -webkit-gradient(linear, left top, left bottom, from(#283b55), to(#849fc5));
  background: -webkit-linear-gradient(top, #283b55 0%, #849fc5 100%);
  background: linear-gradient(to bottom, #283b55 0%, #849fc5 100%);
}
.km-ios .k-slider.km-full-width-slider .k-draghandle,
.km-ios .k-slider.km-full-width-slider .k-draghandle:hover {
  border-color: #b1b3b8;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#fff));
  background-image: -webkit-linear-gradient(top, #ccc, #fff);
  background-image: linear-gradient(to bottom, #ccc, #fff);
}
.km-ios .km-checkbox-label:before,
.km-ios .km-checkbox-label:after {
  color: #3E4E65;
}
.km-ios .km-radio-label:before {
  border-color: #b1b3b8;
  background-color: #fff;
}
.km-ios .km-radio-label:after {
  background-color: #44648f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.9)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  border-color: #264e8c;
}
/* Validation */
.km-ios .km-list > li label.km-invalid,
.km-ios .km-list fieldset .km-legend-button.km-invalid,
.km-ios .km-list > li label.km-invalid.km-required:after {
  color: #f52312;
}
.km-ios .km-invalid-msg {
  color: #fff;
  background: #f52312;
}
.km-ios .km-button {
  text-align: center;
  padding: .4em .8em;
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-webkit .km-ios .km-back,
.k-safari .km-ios .km-back {
  overflow: hidden;
  margin-left: -1em;
  padding-left: 2.1em;
  -webkit-mask-image: url("images/back.png");
  -webkit-mask-size: auto 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position-x: 1em;
}
.km-ios .km-back:after {
  content: "\a0";
  display: none;
  position: absolute;
  left: 1em;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABCCAYAAABAfGYuAAAC4ElEQVR4Xr3XT4jSQRQH8K2NvcQWHZZYomBhKzaCCmVbEwJRQQtZ8c9vV9xlRTZEiujQHjKIhaAI82BbxwiCTiooKoSglBklWBRGBRnizYsXvfuat8zAw87zhO/545uZ934zUwAwZTKZODIrEhB5IvKGA54RiYgUaXTDVybALZFzIkd0wl4CxkTmqaELXiPohsJ0wwZB/YhwwHR5VxHggO0EDSDAAS8RNIwAB3yYoFEEuOCYROMIcMGbpNo5LthB0MsIcMAnJ3uVC45L9AYCXPCGRO+LTHPBK2SJLyHAAR8i6DoCXPA27Vcu2E2qPcEFzxP0GgIcMG2dbQS4YHqTmOGCzxB0BQEu+I5ENxHggOkSP0CAC6ZLbGaDJ5eYCzYkuosAF7xIlniZE75N3jdTXHCAVHuAA0ZgYXJQcME3JRpBgBPelfA6K9xut8EwjN8Sv8gGAwCUy2VwuVyfBHCPE36IeDqd/s651whjXvV6PQiFQh2J27jgZRGo1Wrg8Xi+SvwoxwBB/C7iuVwOrFZrRQAxDljhe8PhEMLhcFe9i7jg4yLQarUgGAz+lPh5nTDFw4iXSiVwOp0fBbDDASv8kWyxL7LqKAes8Jeyxf5K/DoXbBaBer0OPp+vLfFFnTDFbyFeKBTAbrfX8bLAASv8KeLJZPKz2m8OWOGvu90u3e9VLtgiAo1GA/x+/w+JX9AJUzyOeLFYVJ9QxI/phCn+GPFMJqPmeZwDVviL0WhE53mUC54TgU6nQw+boROm+FXEm80mfkx+SdyuE6Z4BPFKpQJer/ebxE06YYon1GRzu91qwJzVDtPJhjcXh8PxQeKndMIU31NtZrPZ3kl8QStM22w8Hiv8vZpuHPBBxEUgm83S6ebUCdPKnyOez+fpad8SmdYFUzyFeLVaxSHzx2w2l2T1ayKnRWYVrAPfQbzf7+OJ338Y4qm3WCxv8Y8oWBe+JPJMBAaDAT4OIZVKQSKRgH+T7a9S/jnyXAAAAABJRU5ErkJggg==");
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.k-webkit .km-ios .km-back:after,
.k-safari .km-ios .km-back:after {
  display: block;
}
.k-webkit .km-ios.km-on-android .km-back,
.k-safari .km-ios.km-on-android .km-back {
  -webkit-mask-image: none;
  padding-left: .8em;
  margin-left: 0;
}
.k-webkit .km-ios.km-on-android .km-back:after,
.k-safari .km-ios.km-on-android .km-back:after {
  display: none;
}
.k-ff .km-ios .km-back {
  overflow: hidden;
  margin-left: 0;
  padding-left: 1.4em;
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
  -webkit-clip-path: url('data:image/svg+xml;utf-8,<svg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 1 1%22><clipPath id=%22backbutton%22 clipPathUnits=%22objectBoundingBox%22><path fill=%22black%22 d=%22M8.7,1c0,0-8,0-8,0C0,1,0.2,1,0,0.5 C0.2-0,0,0,0.5,0H9V1z%22%2F><%2FclipPath><%2Fsvg>#backbutton');
          clip-path: url('data:image/svg+xml;utf-8,<svg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 1 1%22><clipPath id=%22backbutton%22 clipPathUnits=%22objectBoundingBox%22><path fill=%22black%22 d=%22M8.7,1c0,0-8,0-8,0C0,1,0.2,1,0,0.5 C0.2-0,0,0,0.5,0H9V1z%22%2F><%2FclipPath><%2Fsvg>#backbutton');
}
.k-ff .km-ios .km-back:after {
  display: block;
  left: 0;
}
.km-ios .km-badge {
  border-radius: 3em;
  -webkit-box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5);
          box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5);
}
.km-ios .km-detail {
  border-radius: 3em;
  -webkit-box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5);
          box-shadow: inset 0 0 0 .16rem #fff, 0 1px 3px rgba(0,0,0,.5);
  border: 0;
}
/* ButtonGroup */
.km-ios .km-buttongroup {
  border: none;
  background: none;
  padding: 0 1px 1px 0;
}
.km-ios .km-buttongroup .km-button,
.km-tablet .km-ios .km-buttongroup .km-button {
  border-radius: 0;
  border-width: 1px;
  border-right-width: 0;
}
.km-ios .km-navbar .km-buttongroup .km-button:not(.km-state-active) {
  background-color: transparent;
}
.km-ios .km-view .km-buttongroup .km-button:first-child {
  border-radius: 5pt 0 0 5pt;
}
.km-ios .km-view .km-buttongroup .km-button:last-child {
  border-radius: 0 5pt 5pt 0;
}
.km-ios .k-toolbar .km-buttongroup {
  border-radius: 6pt;
}
.km-ios .km-buttongroup .km-button:last-child {
  border-right-width: 1px;
}
.km-ios .km-buttongroup .km-state-active {
  text-shadow: none;
  border-width: 1px 0 1px 1px;
}
.km-ios .km-buttongroup .km-state-active:last-child {
  margin-left: -1px;
}
.km-ios .km-view-title,
.km-ios .km-dialog-title {
  position: relative;
  visibility: visible;
  text-align: center;
  font-size: 1.4em;
  line-height: 2.3em;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
.km-ios.km-horizontal .km-view-title {
  line-height: 2em;
}
.km-ios .km-navbar,
.km-ios .km-toolbar {
  overflow: visible;
}
/* ToolBar */
.km-ios .k-toolbar > .km-button,
.km-ios .k-toolbar .k-split-button > .km-button,
.km-ios .k-toolbar .km-buttongroup,
.km-ios .k-toolbar .k-split-button {
  padding-top: 0;
  padding-bottom: 1px;
  font-size: 1em;
}
.km-ios .k-toolbar .km-buttongroup .km-button {
  padding: 0 .8em;
}
.km-ios .k-toolbar .k-button-icon .km-icon,
.km-ios .k-toolbar .k-button-icontext .km-icon,
.km-ios .k-split-button .km-arrowdown {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
  margin-top: -8px;
  margin-bottom: -5px;
  vertical-align: middle;
}
.km-ios .k-split-button .km-arrowdown {
  margin-left: 2px;
  margin-right: 1px;
}
.km-ios .k-split-button .km-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.km-ios .k-split-button-arrow {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.km-ios .km-actionsheet.k-split-container > li > .km-button,
.km-ios .km-actionsheet.k-overflow-container > li > .km-button {
  min-width: 10em;
  text-align: center;
  font-size: 1.1em;
  border-radius: 5px;
  margin: .4em 0;
  padding: .35em .6em;
  text-shadow: none;
}
.km-ios .km-actionsheet.k-split-container > li > .km-button {
  min-width: 5em;
}
.km-ios .k-split-wrapper .km-popup-wrapper,
.km-ios .k-overflow-wrapper .km-popup-wrapper {
  padding: 7px;
}
.km-ios .k-split-wrapper .km-actionsheet-wrapper,
.km-ios .k-overflow-wrapper .km-actionsheet-wrapper {
  border-radius: 7px;
  padding: .4em 1em .7em;
}
html .km-ios .k-split-container.km-actionsheet > li,
html .km-ios .k-overflow-container.km-actionsheet > li {
  margin-top: 0;
  margin-bottom: 0;
}
.km-ios .k-split-container.km-actionsheet,
.km-ios .k-overflow-container.km-actionsheet {
  border-top: 0;
  background: none;
  padding: 0;
}
.km-ios .km-tabstrip {
  padding: .1em 0;
  border-top-width: 1px;
  border-top-style: solid;
}
.km-phone .km-ios .km-tabstrip {
  display: table;
  border-collapse: separate;
  border-spacing: 3px 0;
}
.km-phone .km-ios .km-tabstrip .km-button {
  display: table-cell;
}
.km-root .km-ios .km-tabstrip {
  border-spacing: 0;
  padding: 0;
}
.km-ios .km-tabstrip .km-button {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border: 0;
  font-size: .7em;
  min-width: 6em;
  text-shadow: inherit;
  margin: 0;
  padding: .1em .3em .4em;
  border-radius: 0;
}
.km-ios .km-tabstrip .km-button:last-child {
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* Switch */
.km-ios .km-switch {
  width: 5rem;
  height: 1.8rem;
  line-height: 1.65rem;
  overflow: hidden;
  font-family: HelveticaNeue, Arial, sans-serif;
}
.km-ios .km-switch-wrapper {
  overflow: hidden;
}
.km-ios .km-switch-background,
.km-ios .k-slider-selection {
  background-position: 4.25em 0;
  background-repeat: no-repeat;
}
.km-ios .km-switch-background,
.km-ios .k-slider-horizontal .k-slider-selection {
  margin-left: -3.4rem;
}
.km-ios .km-switch-background:after {
  content: "\a0";
  display: inline-block;
  margin: 0 0 0 .3rem;
  width: 78%;
  height: 50%;
  line-height: 100%;
  vertical-align: bottom;
}
.km-ios .km-switch-container {
  padding: 1px 0 1px 1px;
  border-width: 0;
}
.km-ios .km-checkbox {
  border-width: 3px;
  border-style: solid;
  border-radius: 15px;
  -webkit-transition: all 100ms ease-out;
          transition: all 100ms ease-out;
}
.km-ios .km-switch-handle {
  width: 1.6em;
  margin: -1px 3px 0 -1px;
  border-width: 1px;
  border-style: solid;
}
.km-ios .km-switch-label-off {
  left: 1.5em;
}
.km-ios .km-switch-label-on {
  left: -3em;
}
.km-ios .km-switch-label-on,
.km-ios .km-switch-label-off {
  width: 195%;
  font-size: 1em;
  line-height: 1.5em;
  vertical-align: middle;
}
.km-ios .km-switch-handle,
.km-ios .km-switch-wrapper,
.km-ios .km-switch-container,
.km-ios .km-switch-background {
  border-radius: 1.3em;
}
.km-ios .km-switch-container,
.km-ios .km-switch-wrapper {
  border-radius: 1.5em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
.km-ios .km-switch-background:after {
  border-radius: 1em 1em .5em .5em;
}
.km-ios li.km-state-active {
  border-width: 0;
}
.km-ios .km-listview .km-switch {
  margin-top: -0.85rem;
}
/* Filter box */
.km-ios .km-filter-form {
  border-color: currentcolor;
  background-color: currentcolor;
}
.km-ios .km-filter-wrap {
  color: inherit;
  border-radius: 2em;
  -webkit-box-shadow: inset 0 1px 3px #b1b3b8;
          box-shadow: inset 0 1px 3px #b1b3b8;
  -webkit-box-shadow: inset 0 1px 3px currentcolor;
          box-shadow: inset 0 1px 3px currentcolor;
  border-color: currentcolor;
  background: transparent;
}
.km-ios .km-filter-wrap .km-clear:after {
  content: "\e031";
}
.km-ios .km-list {
  overflow: visible;
}
.km-ios .km-listinset > li:first-child,
.km-ios .km-listgroupinset .km-list > li:first-child {
  border-width: 1px 1px 0;
  border-style: solid;
  border-radius: 7px 7px 0 0;
}
.km-ios .km-list > li {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.km-ios .km-listinset > li,
.km-ios .km-listgroupinset .km-list > li {
  border-style: solid;
  border-width: 0 1px;
}
.km-ios .km-listinset > li:last-child,
.km-ios .km-listgroupinset .km-list > li:last-child {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-radius: 0 0 7px 7px;
}
.km-ios .km-listinset > li:first-child:last-child,
.km-ios .km-listgroupinset .km-list > li:first-child:last-child {
  border-width: 1px;
  border-radius: 7px;
}
.km-ios .km-state-active .km-listview-link:after {
  border-color: inherit;
}
.km-on-ios.km-ios .km-listview-link:after {
  width: .66rem;
  height: .64rem;
  border-width: 0;
  -webkit-box-shadow: inset -0.2rem 0.2rem 0;
          box-shadow: inset -0.2rem 0.2rem 0;
}
.km-ios .km-listinset > li:first-child,
.km-ios .km-listgroupinset .km-list > li:first-child,
.km-ios .km-listinset li:first-child > .km-listview-link,
.km-ios .km-listgroupinset li:first-child > .km-listview-link,
.km-ios .km-listinset li:first-child > .km-listview-label,
.km-ios .km-listgroupinset li:first-child > .km-listview-label {
  border-radius: 6px 6px 0 0;
}
.km-ios .km-listinset li:last-child > .km-listview-link,
.km-ios .km-listgroupinset li:last-child > .km-listview-link,
.km-ios .km-listinset li:last-child > .km-listview-label,
.km-ios .km-listgroupinset li:last-child > .km-listview-label {
  border-radius: 0 0 6px 6px;
}
.km-ios .km-listinset li:first-child:last-child > .km-listview-link,
.km-ios .km-listgroupinset li:first-child:last-child > .km-listview-link,
.km-ios .km-listinset li:first-child:last-child > .km-listview-label,
.km-ios .km-listgroupinset li:first-child:last-child > .km-listview-label {
  border-radius: 6px;
}
.km-ios .km-group-title {
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  position: relative;
}
.km-ios .km-listgroupinset .km-group-title {
  font-size: 1.2em;
  padding: 0;
  background: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-ios .km-list > li {
  display: block;
  vertical-align: middle;
  line-height: 1.6em;
  font-size: 1.2em;
  font-weight: bold;
  overflow: hidden;
}
.km-ios .km-insetcontent .km-content {
  background: none;
}
/* Pull To Refresh */
.km-root .km-ios .km-load-more,
.km-ios .km-scroller-pull {
  font-size: 1em;
  background: none;
}
.km-ios .km-load-more .km-icon,
.km-ios .km-scroller-pull .km-icon {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.km-ios .km-pages li {
  border-width: 1px;
  border-style: solid;
  border-radius: 1em;
}
/* Slider */
.km-ios .k-slider .k-draghandle,
.km-ios .k-slider .k-draghandle:hover {
  border: 0;
  border-radius: 5em;
}
.km-ios .km-list .km-full-width-slider {
  margin-top: 0.5em;
  padding: 1.1em 0;
}
.km-ios .k-slider-vertical .k-draghandle {
  margin-left: -0.3em;
}
.km-ios .k-slider-selection {
  background: 0 0 no-repeat;
}
.km-ios .k-slider-horizontal .k-draghandle {
  top: -0.4em;
}
.km-ios .k-slider-track {
  margin: -0.5em 0.5em 0 0;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-ios .k-slider-horizontal .k-slider-selection {
  margin-left: 0;
}
.km-ios .km-full-width-slider .k-slider-track {
  height: .44rem;
  border-width: 1px;
  border-radius: 5px;
}
.km-ios .km-full-width-slider .k-draghandle,
.km-ios .km-full-width-slider .k-draghandle:hover {
  border-width: 1px;
  border-style: solid;
}
/* Dialog */
.km-ios .km-dialog {
  border-width: 2px;
  border-style: solid;
}
.km-ios .km-dialog .km-button {
  display: block;
  margin: .4em;
  font-size: 1.3em;
  text-align: center;
  padding: .44em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
}
/* Forms */
.km-ios .km-list input[type=password],
.km-ios .km-list input[type=search],
.km-ios .km-list input[type=number],
.km-ios .km-list input[type=tel],
.km-ios .km-list input[type=url],
.km-ios .km-list input[type=email],
.km-ios .km-list input[type=month],
.km-ios .km-list input[type=color],
.km-ios .km-list input[type=week],
.km-ios .km-list input[type=date],
.km-ios .km-list input[type=time],
.km-ios .km-list input[type=datetime],
.km-ios .km-list input[type=datetime-local],
.km-ios .km-list input[type=text]:not(.k-input),
.km-ios .km-list select:not([multiple]),
.km-ios .km-list .k-dropdown-wrap,
.km-ios .km-list textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.1rem;
  min-width: 6em;
  border: 0;
  padding: .4em;
  outline: none;
  background: transparent;
}
.km-ios .km-list .k-dropdown-wrap {
  padding: .2em .8em;
}
.km-ios .km-list .k-dropdown {
  margin-top: -1.05em;
  font-weight: normal;
}
.km-ios .km-list input[type=color],
.km-ios .km-list input[type=week],
.km-ios .km-list input[type=date],
.km-ios .km-list input[type=time],
.km-ios .km-list input[type=month],
.km-ios .km-list input[type=datetime],
.km-ios .km-list input[type=datetime-local],
.km-ios .km-list .k-dropdown {
  text-align: right;
}
.km-ios .km-list .k-dropdown .k-dropdown-wrap {
  display: block;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-ios label.km-required:after {
  content: "*";
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.km-ios .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-ios .km-icon-label:before {
  display: inline-block;
  width: 1em;
  height: 1em;
  font: 1em/1em "Kendo UI";
  margin-right: .89em;
}
.km-ios .km-list .km-icon-label input[type=password],
.km-ios .km-list .km-icon-label input[type=search],
.km-ios .km-list .km-icon-label input[type=number],
.km-ios .km-list .km-icon-label input[type=tel],
.km-ios .km-list .km-icon-label input[type=url],
.km-ios .km-list .km-icon-label input[type=email],
.km-ios .km-list .km-icon-label input[type=month],
.km-ios .km-list .km-icon-label input[type=color],
.km-ios .km-list .km-icon-label input[type=week],
.km-ios .km-list .km-icon-label input[type=date],
.km-ios .km-list .km-icon-label input[type=time],
.km-ios .km-list .km-icon-label input[type=datetime],
.km-ios .km-list .km-icon-label input[type=datetime-local],
.km-ios .km-list .km-icon-label input[type=text]:not(.k-input),
.km-ios .km-list .km-icon-label select:not([multiple]),
.km-ios .km-list .km-icon-label .k-dropdown-wrap,
.km-ios .km-list .km-icon-label textarea {
  right: 0;
}
.km-ios .km-label-above {
  width: 100%;
  font-size: 0.87em;
  display: block;
  margin-top: -2px;
  margin-left: .8em;
  padding-top: .92em;
  padding-bottom: 2.5em;
}
.km-ios fieldset .km-label-above {
  margin-top: 0;
  padding-top: 1.455em;
  padding-bottom: 3.091em;
}
.km-ios .km-label-above textarea {
  top: 50%;
}
.km-ios .km-label-above,
.km-ios label.km-inline-field {
  font-size: .7em;
  line-height: 1.2em;
}
.km-ios .km-list fieldset input[type=password],
.km-ios .km-list .km-label-above input[type=password],
.km-ios .km-list fieldset input[type=search],
.km-ios .km-list .km-label-above input[type=search],
.km-ios .km-list fieldset input[type=number],
.km-ios .km-list .km-label-above input[type=number],
.km-ios .km-list fieldset input[type=tel],
.km-ios .km-list .km-label-above input[type=tel],
.km-ios .km-list fieldset input[type=url],
.km-ios .km-list .km-label-above input[type=url],
.km-ios .km-list fieldset input[type=email],
.km-ios .km-list .km-label-above input[type=email],
.km-ios .km-list fieldset input[type=month],
.km-ios .km-list .km-label-above input[type=month],
.km-ios .km-list fieldset input[type=color],
.km-ios .km-list .km-label-above input[type=color],
.km-ios .km-list fieldset input[type=week],
.km-ios .km-list .km-label-above input[type=week],
.km-ios .km-list fieldset input[type=date],
.km-ios .km-list .km-label-above input[type=date],
.km-ios .km-list fieldset input[type=time],
.km-ios .km-list .km-label-above input[type=time],
.km-ios .km-list fieldset input[type=datetime],
.km-ios .km-list .km-label-above input[type=datetime],
.km-ios .km-list fieldset input[type=datetime-local],
.km-ios .km-list .km-label-above input[type=datetime-local],
.km-ios .km-list fieldset input[type=text]:not(.k-input),
.km-ios .km-list .km-label-above input[type=text]:not(.k-input),
.km-ios .km-list fieldset select:not([multiple]),
.km-ios .km-list .km-label-above select:not([multiple]),
.km-ios .km-list fieldset .k-dropdown-wrap,
.km-ios .km-list .km-label-above .k-dropdown-wrap,
.km-ios .km-list fieldset textarea,
.km-ios .km-list .km-label-above textarea {
  width: 100%;
  right: 0;
  padding-left: 0;
  left: 1.2em;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-top: -0.375em;
}
.km-ios .km-list fieldset {
  border: none;
  padding: 0 1.61em;
  margin: -0.5em -1.61em;
}
.km-ios .km-list fieldset textarea {
  width: 100%;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-right: 0;
  padding-left: 0;
}
.km-ios .km-list fieldset input[type=password],
.km-ios .km-list fieldset input[type=search],
.km-ios .km-list fieldset input[type=number],
.km-ios .km-list fieldset input[type=tel],
.km-ios .km-list fieldset input[type=url],
.km-ios .km-list fieldset input[type=email],
.km-ios .km-list fieldset input[type=month],
.km-ios .km-list fieldset input[type=color],
.km-ios .km-list fieldset input[type=week],
.km-ios .km-list fieldset input[type=date],
.km-ios .km-list fieldset input[type=time],
.km-ios .km-list fieldset input[type=datetime],
.km-ios .km-list fieldset input[type=datetime-local],
.km-ios .km-list fieldset input[type=text]:not(.k-input),
.km-ios .km-list fieldset select:not([multiple]),
.km-ios .km-list fieldset .k-dropdown-wrap,
.km-ios .km-list fieldset textarea {
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  right: auto;
  width: 100%;
  position: relative;
}
.km-ios .km-list fieldset > input[type=password],
.km-ios .km-list fieldset > input[type=search],
.km-ios .km-list fieldset > input[type=number],
.km-ios .km-list fieldset > input[type=tel],
.km-ios .km-list fieldset > input[type=url],
.km-ios .km-list fieldset > input[type=email],
.km-ios .km-list fieldset > input[type=month],
.km-ios .km-list fieldset > input[type=color],
.km-ios .km-list fieldset > input[type=week],
.km-ios .km-list fieldset > input[type=date],
.km-ios .km-list fieldset > input[type=time],
.km-ios .km-list fieldset > input[type=datetime],
.km-ios .km-list fieldset > input[type=datetime-local],
.km-ios .km-list fieldset > input[type=text]:not(.k-input),
.km-ios .km-list fieldset > select:not([multiple]),
.km-ios .km-list fieldset > .k-dropdown-wrap {
  padding: .73em 0;
  margin-top: 0;
}
.km-ios .km-list fieldset legend {
  text-indent: 0;
  font-size: 0.81em;
  margin: 0 -1.61em;
  font-weight: normal;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  padding: 2em 1.61em .5em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-ios .km-legend-button {
  display: block;
  text-transform: uppercase;
}
.km-ios .km-inline-field {
  display: inline-block;
  position: relative;
  width: -webkit-calc(48.39%);
  width: calc(48.39%);
  overflow: hidden;
  white-space: nowrap;
}
.km-ios .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1.61em;
  border-right-width: 1px;
  border-right-style: solid;
}
.km-ios .km-list fieldset .km-inline-field:first-of-type {
  padding-left: 0;
}
.km-ios .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-ios .km-list .km-inline-field input[type=password],
.km-ios .km-list .km-inline-field input[type=search],
.km-ios .km-list .km-inline-field input[type=number],
.km-ios .km-list .km-inline-field input[type=tel],
.km-ios .km-list .km-inline-field input[type=url],
.km-ios .km-list .km-inline-field input[type=email],
.km-ios .km-list .km-inline-field input[type=month],
.km-ios .km-list .km-inline-field input[type=color],
.km-ios .km-list .km-inline-field input[type=week],
.km-ios .km-list .km-inline-field input[type=date],
.km-ios .km-list .km-inline-field input[type=time],
.km-ios .km-list .km-inline-field input[type=datetime],
.km-ios .km-list .km-inline-field input[type=datetime-local],
.km-ios .km-list .km-inline-field input[type=text]:not(.k-input),
.km-ios .km-list .km-inline-field select:not([multiple]),
.km-ios .km-list .km-inline-field .k-dropdown-wrap,
.km-ios .km-list .km-inline-field textarea {
  position: absolute;
  width: 100%;
  left: 0;
  padding-left: 0;
  padding-left: 1em;
}
.km-ios .km-list .km-inline-field:first-of-type input[type=password],
.km-ios .km-list .km-inline-field:first-of-type input[type=search],
.km-ios .km-list .km-inline-field:first-of-type input[type=number],
.km-ios .km-list .km-inline-field:first-of-type input[type=tel],
.km-ios .km-list .km-inline-field:first-of-type input[type=url],
.km-ios .km-list .km-inline-field:first-of-type input[type=email],
.km-ios .km-list .km-inline-field:first-of-type input[type=month],
.km-ios .km-list .km-inline-field:first-of-type input[type=color],
.km-ios .km-list .km-inline-field:first-of-type input[type=week],
.km-ios .km-list .km-inline-field:first-of-type input[type=date],
.km-ios .km-list .km-inline-field:first-of-type input[type=time],
.km-ios .km-list .km-inline-field:first-of-type input[type=datetime],
.km-ios .km-list .km-inline-field:first-of-type input[type=datetime-local],
.km-ios .km-list .km-inline-field:first-of-type input[type=text]:not(.k-input),
.km-ios .km-list .km-inline-field:first-of-type select:not([multiple]),
.km-ios .km-list .km-inline-field:first-of-type .k-dropdown-wrap,
.km-ios .km-list .km-inline-field:first-of-type textarea {
  margin-left: 0;
  padding-left: 0;
}
/* Standalone Checkboxes and RadioButtons */
.km-ios input.km-checkbox,
.km-ios input.km-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
}
.km-ios .km-checkbox-label,
.km-ios .km-radio-label {
  display: block;
  position: relative;
  vertical-align: middle;
  font-size: 1.1em;
  line-height: 1.5em;
  margin-left: .89em;
}
.km-ios .km-checkbox-label:before,
.km-ios .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
  right: .7em;
  width: 1em;
  height: .9rem;
  border: 0;
}
.km-ios input.km-checkbox:checked + .km-checkbox-label:after {
  content: "\e227";
  font-size: 1.2em;
  line-height: 1em;
  font-family: "Kendo UI";
}
.km-ios .km-radio-label {
  position: relative;
  padding-left: 2.5em;
  vertical-align: middle;
  line-height: 1.5em;
}
.km-ios .km-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}
.km-ios .km-radio-label:after {
  border-width: 1px;
  border-style: solid;
}
.km-ios input.km-radio:checked + .km-radio-label:after {
  content: "";
  width: 1.085rem;
  height: 1.085rem;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-35%);
      transform: translateY(-35%);
  -webkit-transform: translateY(-35%);
  left: 0.365rem;
  border-radius: 50%;
}
.km-ios .km-listview-item-badge.km-badge,
.km-ios .km-listview-link > .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 2em;
}
/* PopUp + ActionSheet */
.km-ios .km-actionsheet > li > a {
  text-align: center;
}
.km-ios .km-actionsheet > .km-actionsheet-cancel {
  margin: 1.5em 0 1em;
}
.km-ios .km-actionsheet-wrapper,
.km-ios .km-actionsheet-root > .k-animation-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios .km-actionsheet > li > a {
  display: block;
}
.km-ios .km-shim.km-actionsheet-root > .k-animation-container {
  left: 0 !important;
  width: 100%;
  top: auto !important;
  bottom: 0 !important;
}
.km-ios .km-shim li.km-actionsheet-title {
  visibility: hidden;
  height: 0;
  margin: .5em 0 0;
}
/* PopOver */
.km-ios .km-popup-arrow:after {
  border-style: solid;
  border-width: 0 15px 15px;
}
.km-ios .km-down .km-popup-arrow:before {
  margin-top: -1px;
}
.km-ios .km-up .km-popup-arrow:after {
  border-width: 15px 15px 0 15px;
}
.km-ios .km-left .km-popup-arrow:after {
  border-width: 15px 0 15px 15px;
}
.km-ios .km-right .km-popup-arrow:after {
  border-width: 15px 15px 15px 0;
}
/* ModalView */
.km-ios .km-modalview {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-root .km-ios .km-modalview {
  border-radius: 5px;
}
.km-ios .km-modalview .km-header {
  padding: 0;
  margin: -1px 0 0;
}
.km-ios .km-modalview > :first-child {
  border: 0;
}
/* SplitView */
.km-ios .km-splitview > .km-split-content > .km-pane {
  border-radius: 5px;
}
.km-ios .km-split-vertical > .km-split-content > .km-pane {
  border-right: 0;
}
.km-ios .km-splitview > .km-split-content > .km-pane {
  border-right-width: 1px;
  border-right-style: solid;
}
.km-ios .km-split-vertical > .km-split-content > .km-pane {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.km-ios .km-splitview .km-navbar,
.km-ios .km-splitview .km-toolbar,
.km-ios .km-modalview > :first-child,
.km-ios .km-splitview .km-view > :first-child {
  border-radius: 5px 5px 0 0;
}
.km-ios .km-splitview .km-tabstrip,
.km-ios .km-modalview > :last-child,
.km-ios .km-splitview .km-view > :last-child {
  border-radius: 0 0 5px 5px;
}
.km-ios .km-modalview > :first-child:last-child,
.km-ios .km-pane > .km-view > :first-child:last-child {
  border-radius: 5px;
}
.km-ios .km-splitview .km-pane:last-child {
  border: 0;
}
.km-ios7 {
  font: normal 1em "HelveticaNeue Ultra Light", "Roboto Light", "Slate Light", "Segoe WP", NokiaPureTextLight, sans-serif;
}
/* override transform options for performance */
.km-root .km-ios7.km-pane,
.km-root .km-ios7 .km-view,
.km-root .km-ios7 .km-slider,
.km-root .km-ios7 .km-switch,
.km-root .km-ios7 .km-group-title,
.km-root .km-ios7 .km-filter-reset,
.km-root .km-ios7 .km-shim .k-animation-container {
  -webkit-transform: none;
}
.km-ios7 .km-navbar,
.km-ios7 .k-toolbar,
.km-ios7 .km-tabstrip {
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-ios7 .km-view:not([data-use-native-scrolling]),
.km-ios7 .km-splitview {
  overflow: hidden;
}
.km-ios7 .km-view:not([data-use-native-scrolling]) .km-content {
  overflow: visible !important;
}
.km-ios7,
.km-ios7 * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
/* Revert box/clip for Web widgets */
.km-ios7 [class^=k-],
.km-ios7 [class^=k-] * {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-ios7.km-on-android * {
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-ios7 .km-navbar,
.km-ios7 .k-toolbar,
.km-ios7 .km-tabstrip {
  border-style: solid;
  border-width: 0 0 1px;
}
.km-ios7 .km-footer .km-navbar,
.km-ios7 .km-footer .k-toolbar,
.km-ios7 .km-tabstrip {
  border-width: 1px 0 0;
}
.km-ios7 .km-navbar,
.km-ios7 .k-toolbar,
.km-ios7 .km-tabstrip,
.km-ios7 .km-tabstrip .km-button {
  border-radius: 0;
}
/* PopUp + ActionSheet */
.km-ios7 .km-actionsheet,
.km-ios7 .km-popup .k-popup {
  background: transparent;
  padding: 0 .5em;
}
.km-ios7 .km-popup .k-popup {
  font-size: 1em;
}
.km-ios7 .km-popup .k-item,
.km-ios7 .km-actionsheet > li > a {
  font-size: 1.3em;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  padding: .3em 0;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios7 .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios7 .km-popup .k-list-container {
  width: 100% !important;
  height: auto !important;
}
.km-ios7 .km-actionsheet,
.km-ios7 .km-popup .k-list-container {
  max-height: 80%;
}
.km-ios7 .km-actionsheet-wrapper,
.km-ios7 .km-popup .k-list-container {
  background: transparent;
  bottom: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios7 .km-popup .k-item:last-child,
.km-ios7 .km-actionsheet > li:last-child > a {
  border: 0;
}
.km-ios7 .km-shim .k-animation-container {
  left: 0;
  top: auto;
  bottom: 0;
}
.km-ios7 .km-popup .k-item:first-child,
.km-ios7 .km-actionsheet > li:nth-child(2) > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.km-ios7 .km-popup .k-item:last-child,
.km-ios7 .km-actionsheet > li:nth-last-child(2) > a {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.km-ios7 .km-popup .k-item:last-child {
  margin: 0 0 .5em;
}
.km-ios7 .km-popup-wrapper {
  padding: 15px;
}
.km-ios7 .km-actionsheet-wrapper.km-popup {
  padding: 2px 0;
}
/* Loader */
.km-ios7.km-pane > .km-loader,
.km-ios7 .km-pane > .km-loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-ios7 .km-loader:before {
  content: "\a0";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.km-ios7 .km-load-more,
.km-ios7 .km-scroller-pull {
  font-size: 1em;
}
.km-ios7 .km-scroller-pull {
  opacity: .5;
}
.km-ios7 .km-list > .km-load-more {
  border-top: 0;
}
.km-ios7 .km-loader h1,
.km-ios7 .km-scroller-refresh .km-template {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2em;
}
.km-ios7 .km-scroller-pull .km-template {
  min-width: 0;
}
.km-ios7 .km-loading,
.km-ios7 .km-loader .km-loading-left,
.km-root .km-ios7 .km-load-more.km-scroller-refresh .km-icon,
.km-root .km-ios7 .km-scroller-pull.km-scroller-refresh .km-icon,
.km-ios7 .km-scroller-refresh .km-loading-left {
  display: inline-block;
  top: auto;
  left: auto;
  width: 8px;
  height: 2px;
  font-size: 1em;
  margin: 0;
  border: 0;
  border-radius: 5px;
  vertical-align: middle;
  -webkit-transform-origin: 150% 50%;
      -ms-transform-origin: 150% 50%;
          transform-origin: 150% 50%;
  background: currentcolor;
  -webkit-animation: km-ios-spin 1.5s infinite steps(8);
          animation: km-ios-spin 1.5s infinite steps(8);
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.6);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.6);
}
.km-ios7 .km-loading,
.km-ios7 .km-loader .km-loading-left {
  margin: 2px 0 0;
}
.km-ios7 .km-loading:before,
.km-ios7 .km-loading:after,
.km-ios7 .km-load-more.km-scroller-refresh .km-icon:after,
.km-ios7 .km-load-more.km-scroller-refresh .km-icon:before,
.km-ios7 .km-scroller-pull.km-scroller-refresh .km-icon:after,
.km-ios7 .km-scroller-pull.km-scroller-refresh .km-icon:before {
  color: inherit;
  content: "\a0";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
  margin-top: 0;
  -webkit-transform-origin: inherit;
      -ms-transform-origin: inherit;
          transform-origin: inherit;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  -webkit-background-clip: border-box;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.5);
}
.km-ios7 .km-loading:after,
.km-ios7 .km-load-more.km-scroller-refresh .km-icon:after,
.km-ios7 .km-scroller-pull.km-scroller-refresh .km-icon:after {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.4);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.4);
}
.km-ios7 .km-loader .km-loading-left,
.km-ios7 .km-scroller-refresh .km-loading-left {
  margin-left: -8px;
  -webkit-box-shadow: 16px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 16px 0 rgba(0, 0, 0, 0.3);
  -webkit-animation: km-ios-spin1 1.5s infinite steps(8);
          animation: km-ios-spin1 1.5s infinite steps(8);
}
.km-ios7 .km-loading-left:after,
.km-ios7 .km-loading-left:before,
.km-ios7 .km-loading-right {
  display: none;
}
.km-ios7 .km-loading,
.km-ios7 .km-loading:after,
.km-ios7 .km-loading:before {
  color: black;
}
.km-ios7 .km-loading:before {
  color: rgba(0, 0, 0, 0.9);
}
.km-ios7 .km-loading:after {
  color: rgba(0, 0, 0, 0.8);
}
.km-ios7 .km-loading-left {
  color: rgba(0, 0, 0, 0.7);
}
/* Color Template */
.km-ios7 .km-navbar,
.km-ios7 .k-toolbar,
.km-ios7 .km-tabstrip {
  background-color: rgba(244, 244, 244, 0.95);
}
.km-ios7,
.km-ios7.km-pane .km-content {
  color: #000;
  background-color: #fff;
}
.km-ios7 .km-splitview > .km-split-content > .km-pane:first-child {
  border-right-color: rgba(180, 180, 180, 0.5);
}
/* NavBar */
.km-ios7 .km-navbar,
.km-ios7 .k-toolbar,
.km-ios7 .km-tabstrip {
  border-color: rgba(180, 180, 180, 0.5);
}
.km-ios7 .km-view-title {
  color: #000;
}
/* Button */
.km-ios7 .km-button {
  background: rgba(0, 0, 0, 0);
}
.km-ios7 .km-state-active[style*=background] {
  -webkit-box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.km-ios7 .km-back:before {
  -webkit-box-shadow: inset 0.25rem -0.25rem 0 #007aff;
  box-shadow: inset 0.25rem -0.25rem 0 #007aff;
}
.km-ios7 .km-detail {
  color: #007aff;
  background-color: #fff;
}
.km-ios7 .km-detail:active,
.km-ios7 .km-state-active .km-detail {
  -webkit-box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
}
.km-ios7 .km-detail.km-rowinsert {
  color: green;
}
.km-ios7 .km-detail.km-rowdelete {
  color: red;
}
/* Switch */
.km-ios7 .km-switch-wrapper {
  background-color: #bababa;
}
.km-ios7 .km-switch-background,
.km-ios7 .k-slider-selection {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: -webkit-linear-gradient(top, #fff, #fff);
  background-image: linear-gradient(to bottom, #fff, #fff);
}
.km-ios7 .km-switch-handle {
  background: #fff;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4), 0 0 0 1px currentcolor;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4), 0 0 0 1px currentcolor;
}
.km-ios7 .km-switch-off .km-switch-handle {
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4), 0 0 0 1px #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4), 0 0 0 1px #fff;
}
.km-ios7 .km-switch-container,
.km-ios7 .km-switch-wrapper {
  border-color: #efeff4;
}
/* Slider */
.km-ios7 .k-slider .k-draghandle,
.km-ios7 .k-slider .k-draghandle:hover {
  -webkit-box-shadow: 0 5px 5px #d9d9d9, inset 0 0 0 1px rgba(180, 180, 180, 0.5);
          box-shadow: 0 5px 5px #d9d9d9, inset 0 0 0 1px rgba(180, 180, 180, 0.5);
}
.km-ios7 .k-slider-track {
  background-color: #bababa;
}
/* ListView */
.km-ios7 .km-list > li {
  color: #000;
  border-top-color: rgba(180, 180, 180, 0.5);
  -webkit-border-image: -webkit-gradient(linear, left top, right top, color-stop(1em, transparent), color-stop(1em, rgba(180, 180, 180, 0.5)), to(rgba(180, 180, 180, 0.5))) 1 stretch;
  -webkit-border-image: -webkit-linear-gradient(left, transparent 1em, rgba(180, 180, 180, 0.5) 1em, rgba(180, 180, 180, 0.5) 100%) 1 stretch;
          border-image: -webkit-gradient(linear, left top, right top, color-stop(1em, transparent), color-stop(1em, rgba(180, 180, 180, 0.5)), to(rgba(180, 180, 180, 0.5))) 1 stretch;
          border-image: linear-gradient(to right, transparent 1em, rgba(180, 180, 180, 0.5) 1em, rgba(180, 180, 180, 0.5) 100%) 1 stretch;
}
.km-ios7 .km-list > li:last-child {
  -webkit-box-shadow: inset 0 -1px 0 rgba(180, 180, 180, 0.5);
          box-shadow: inset 0 -1px 0 rgba(180, 180, 180, 0.5);
}
.km-ios7 .km-list > li.km-state-active,
.km-ios7 .km-list > li.km-state-active + li {
  -webkit-border-image: none;
          border-image: none;
}
.km-ios7 .km-listview-link:after {
  color: #999ea9;
  -webkit-box-shadow: inset -0.2rem 0.2rem 0 #999ea9;
  box-shadow: inset -0.2rem 0.2rem 0 #999ea9;
}
.km-ios7 .km-group-title {
  color: rgba(104, 104, 104, 0.5);
  border-bottom-color: rgba(180, 180, 180, 0.5);
  background: #efeff4;
}
.km-ios7 .km-listgroup .km-group-title,
.km-ios7 .km-listgroupinset .km-group-title {
  border-top-color: #efeff4;
}
.km-ios7 .km-filter-form {
  color: #efeff4;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
}
.km-ios7 .km-filter-wrap > input {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
}
.km-ios7 .km-filter-wrap:before,
.km-ios7 .km-filter-reset .km-clear {
  color: rgba(0, 0, 0, 0.4);
}
.km-ios7 label.km-required:after {
  color: #fd472b;
}
/* Scroller */
.km-ios7 .km-touch-scrollbar {
  background-color: #919191;
}
/* Shim */
.km-ios7 .km-shim,
.km-ios7 .km-popup-overlay {
  background: rgba(0, 0, 0, 0.4);
}
/* PopUp */
.km-ios7 .km-popup .k-item,
.km-ios7 .km-actionsheet > li > a,
.km-ios7 .k-overflow-container a.km-state-disabled,
.km-ios7 .k-overflow-container .km-state-disabled:active {
  background: #F7F7F8;
  border-bottom-color: rgba(180, 180, 180, 0.5);
}
.km-ios7 .km-popup {
  background: rgba(0, 0, 0, 0.4);
}
.km-ios7 .km-popup.km-pane,
.km-tablet .km-ios7 .km-actionsheet-wrapper {
  background-color: #F7F7F8;
}
/* PopOver */
.km-ios7 .km-popup.km-pane {
  -webkit-box-shadow: 0 0 0 1px rgba(180, 180, 180, 0.5);
          box-shadow: 0 0 0 1px rgba(180, 180, 180, 0.5);
}
.km-ios7 .k-split-wrapper .km-popup,
.km-ios7 .k-overflow-wrapper .km-popup {
  -webkit-box-shadow: 0 0 5px rgba(180, 180, 180, 0.5);
          box-shadow: 0 0 5px rgba(180, 180, 180, 0.5);
}
.km-ios7 .km-popup-arrow:after {
  border-color: #F7F7F8 transparent;
}
.km-ios7 .km-left .km-popup-arrow:after,
.km-ios7 .km-right .km-popup-arrow:after {
  border-color: transparent #F7F7F8;
}
/* Loader */
.km-ios7.km-pane > .km-loader,
.km-ios7 .km-pane > .km-loader {
  background: rgba(0, 0, 0, 0.1);
}
.km-ios7 .k-split-wrapper .km-actionsheet-wrapper,
.km-ios7 .k-overflow-wrapper .km-actionsheet-wrapper {
  background: rgba(0, 0, 0, 0.2);
}
.km-ios7 .km-loader h1,
.km-ios7 .km-scroller-refresh .km-template {
  color: #222;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.km-ios7 .km-scroller-pull .km-template {
  color: #363940;
}
/* Forms */
.km-ios7 .km-list select:not([multiple]) option {
  color: #333;
}
/* Collapsible */
.km-ios7 .km-collapsible-header,
.km-ios7 .km-collapsible-content {
  border-color: rgba(180, 180, 180, 0.5);
  color: #000;
}
.km-ios7 .km-collapsible-header .km-icon {
  color: #bababa;
}
.km-ios7 .km-legend-button,
.km-ios7 .km-drawer a {
  text-decoration: none;
}
.km-ios7 .km-list .km-label-above,
.km-ios7 .km-checkbox-label,
.km-ios7 .km-radio-label {
  color: #8f8f8f;
}
.km-ios7 .km-list fieldset .km-inline-field {
  border-color: rgba(180, 180, 180, 0.5);
}
.km-ios7 .km-group-title,
.km-ios7 .km-list fieldset legend,
.km-ios7 .km-shim li.km-actionsheet-title {
  background: #efeff4;
  border-color: rgba(180, 180, 180, 0.5);
  color: #007aff;
}
/* Active States */
.km-ios7 .km-badge,
.km-ios7 .k-slider .k-draghandle,
.km-ios7 .k-slider .k-draghandle:hover,
.km-ios7 .km-buttongroup .km-state-active .km-icon,
.km-ios7 .km-state-active .km-text,
.km-ios7 .km-state-active.km-detail:after,
.km-ios7 .km-state-active.km-detail .km-icon:after,
.km-ios7 .km-switch-handle.km-state-active {
  color: #fff;
}
.km-ios7 .km-tabstrip .km-state-active .km-text,
.km-ios7 .k-toolbar .km-state-active .km-text,
.km-ios7 .k-overflow-container .km-state-active .km-text {
  color: inherit;
}
.km-ios7 .k-slider .k-draghandle,
.km-ios7 .k-slider .k-draghandle:hover {
  background: #fff;
}
.km-ios7 span.km-switch {
  color: #4CDA64;
}
.km-ios7 .km-badge {
  background: #fd472b;
}
.km-ios7 .km-popup .k-state-hover,
.km-ios7 .km-popup .k-state-focused,
.km-ios7 .km-popup .k-state-selected,
.km-ios7 .km-actionsheet > li > a:active,
.km-ios7 li.km-state-active .km-listview-link,
.km-ios7 li.km-state-active .km-listview-label {
  background: #d9d9d9;
}
.km-ios7 li.km-state-active .km-listview-link,
.km-ios7 li.km-state-active .km-listview-label {
  color: #000;
}
.km-ios7 .km-tabstrip .km-button:not(.km-state-active) {
  color: #929292;
}
.km-ios7 .k-list,
.km-ios7 .k-slider,
.km-ios7 .km-widget,
.km-ios7 .km-checkbox-label:after {
  color: #007aff;
}
.km-ios7 .km-checkbox-label,
.km-ios7 .km-radio-label {
  color: #000;
}
.km-ios7 .km-radio-label:before {
  border-color: #8f8f8f;
}
.km-ios7 .km-radio-label:after {
  background-color: #007aff;
}
/* Validation */
.km-ios7 .km-list > li label.km-invalid,
.km-ios7 .km-list fieldset .km-legend-button.km-invalid,
.km-ios7 .km-list > li label.km-invalid.km-required:after,
.km-ios7 .km-invalid-msg {
  color: #f52312;
}
/* Active States */
.km-ios7 .km-tabstrip .km-button,
.km-ios7 .km-popup .k-item,
.km-ios7 .k-slider-selection,
.km-ios7 .km-actionsheet > li > a {
  color: inherit;
}
.km-ios7 .km-state-active .km-badge {
  -webkit-text-fill-color: initial;
}
.km-ios7 .km-state-active,
.km-ios7 .k-slider-selection,
.km-ios7 .km-pages .km-current-page,
.km-ios7 .k-split-container .km-state-active,
.km-ios7 .k-overflow-container .km-state-active,
.km-ios7 .k-split-container li > a:active,
.km-ios7 .k-overflow-container li > a:active {
  background: currentcolor;
}
.km-ios7 .k-overflow-container .km-state-disabled .km-text {
  opacity: .5;
}
.km-ios7 .km-leftitem .km-button.km-state-active,
.km-ios7 .km-rightitem .km-button.km-state-active {
  background: none;
  opacity: .4;
}
.km-ios7 .k-toolbar > .km-state-active,
.km-ios7 .k-toolbar .k-split-button .km-state-active {
  background: none;
  text-shadow: .5px 0 1px currentcolor;
  -webkit-text-fill-color: initial;
}
.km-root .km-ios7 .k-toolbar .km-state-disabled {
  text-shadow: none;
}
/* transitions */
.km-root .km-ios7.k-fx-slide {
  /* left reverse */
  /* right */
}
.km-root .km-ios7.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-filter: brightness(70%);
}
.km-root .km-ios7.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-root .km-ios7.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
  -webkit-filter: none;
}
.km-root .km-ios7.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-filter: brightness(70%);
}
.km-root .km-ios7.k-fx-slide.k-fx-right {
  /* right reverse */
}
.km-root .km-ios7.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-root .km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
  -webkit-filter: none;
}
.km-root .km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
/* Button */
.km-ios7 .km-button {
  font-size: .9em;
  padding: .5em .8em;
  border: 1px solid currentcolor;
  border-radius: 3px;
}
.km-ios7 .km-leftitem .km-button,
.km-ios7 .km-rightitem .km-button {
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: .2em;
  padding-right: .2em;
}
.km-ios7 .k-toolbar > .km-button,
.km-ios7 .k-split-button > .km-button {
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.km-ios7 .km-footer,
.km-ios7 .km-tabstrip .km-button {
  background: none;
}
.km-ios7 .km-back:before {
  content: "\a0";
  display: inline-block;
  color: currentcolor;
  width: .8em;
  height: .8em;
  border-width: 0;
  -webkit-box-shadow: inset 0.25rem -0.25rem 0 currentcolor;
          box-shadow: inset 0.25rem -0.25rem 0 currentcolor;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* Badges and Details */
.km-ios7 .km-badge,
.km-ios7 .km-detail {
  font-size: .9em;
  border: 0;
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  text-align: center;
  border-radius: 5em;
}
.km-ios7 .km-detail {
  font-size: 1.1em;
  min-width: 1.4em;
  height: 1.4em;
  line-height: 1.4em;
  border: 1px solid currentcolor;
}
.km-ios7 .km-detail:after {
  left: auto;
  top: auto;
  line-height: 1.3em;
  text-align: center;
}
.km-ios7 .km-detaildisclose:after {
  font-size: 1.2em;
  line-height: 1em;
}
.km-ios7 .km-detail:after,
.km-ios7 .km-detail:after,
.km-ios7 .km-detail .km-icon:after,
.km-ios7 .km-detail .km-icon:after {
  color: inherit;
}
/* ButtonGroup */
.km-ios7 .km-buttongroup {
  font-size: 1em;
}
.km-ios7 .km-buttongroup > *:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.km-ios7 .km-buttongroup > *:first-child {
  border-radius: 3px 0 0 3px;
}
.km-ios7 .km-buttongroup > *:last-child {
  border-radius: 0 3px 3px 0;
}
.km-ios7 .km-buttongroup > *.km-state-active {
  border-right-width: 1px;
}
.km-ios7 .km-buttongroup > *.km-state-active + * {
  border-left-width: 0;
}
.km-ios7 .km-view-title .km-buttongroup {
  font-weight: normal;
}
/* NavBar */
.km-ios7 .km-view-title,
.km-ios7 .km-navbar .km-button {
  font-size: 1.2em;
}
.km-ios7 .km-view-title {
  font-weight: bold;
  line-height: 2.5em;
}
.km-ios7.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.km-ios7.k-fx-slide.k-fx-end .k-fx-next .km-view-title,
.km-ios7.k-fx-slide.k-fx-end .k-fx-next .km-leftitem,
.km-ios7.k-fx-slide.k-fx-end .k-fx-current .km-view-title,
.km-ios7.k-fx-slide.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.km-ios7.k-fx-slide.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-ios7.k-fx-slide.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
  opacity: 0;
}
.km-ios7.k-fx-slide.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-ios7.k-fx-slide.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
  opacity: 0;
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-ios7.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-right {
  /* left */
  /* right reverse */
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  opacity: 0;
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-ios7.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
/* ToolBar */
.km-ios7 .k-toolbar .km-buttongroup .km-button {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2em;
}
.km-ios7 .k-toolbar .k-button-icon .km-icon,
.km-ios7 .k-toolbar .k-button-icontext .km-icon,
.km-ios7 .k-split-button .km-arrowdown {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
  margin-top: -3px;
  vertical-align: middle;
}
.km-ios7 .km-actionsheet.k-split-container > li > .km-button,
.km-ios7 .km-actionsheet.k-overflow-container > li > .km-button {
  min-width: 10em;
  text-align: center;
  font-size: 1.1em;
  border: 0;
  border-radius: 0;
}
.km-ios7 .km-actionsheet.k-split-container > li > .km-button {
  min-width: 5em;
}
.km-ios7 .k-split-wrapper .km-actionsheet-wrapper,
.km-ios7 .k-overflow-wrapper .km-actionsheet-wrapper {
  padding: 1px;
}
html .km-ios7 .k-split-container.km-actionsheet > li,
html .km-ios7 .k-overflow-container.km-actionsheet > li {
  margin-top: 0;
  margin-bottom: 0;
}
/* TabStrip */
.km-ios7 .km-tabstrip {
  padding: 0;
  display: table;
  table-layout: fixed;
}
.km-ios7 .km-tabstrip .km-button {
  font-size: .7em;
  display: table-cell;
  border: 0;
  padding: .6em .8em .195em;
  line-height: 1em;
}
.km-ios7 .km-tabstrip .km-icon {
  font-size: 1.6rem;
  height: 1.6rem;
}
.km-ios7 .km-tabstrip .km-icon:before {
  display: none;
}
.km-ios7 .km-tabstrip .km-icon:after {
  text-align: center;
}
.km-ios7 .km-tabstrip .km-image,
.km-ios7 .km-tabstrip .km-button .km-icon {
  margin: 0 auto .326em;
  display: inline-block;
}
.km-tablet .km-ios7 .km-tabstrip .km-text {
  font-size: 1.125em;
}
.km-tablet .km-ios7 .km-tabstrip .km-button {
  padding-top: 0.364em;
}
.km-tablet .km-ios7 .km-tabstrip .km-image,
.km-tablet .km-ios7 .km-tabstrip .km-button .km-icon {
  margin-bottom: 0.231em;
}
/* Switch */
.km-ios7 .km-switch {
  width: 3.6rem;
  height: 2.2rem;
  overflow: visible;
}
.km-ios7 .km-switch-wrapper {
  overflow: hidden;
}
.km-ios7 .km-switch-background,
.km-ios7 .k-slider-selection {
  background-position: 4.25em 0;
  background-repeat: no-repeat;
  background-color: currentcolor;
  margin-left: -3.4rem;
}
.km-ios7 .km-switch-container {
  overflow: visible;
  border-width: 0;
  padding: 0 0 0 1px;
}
.km-ios7 .km-switch-handle {
  width: 2.2em;
  -webkit-transition: width 0.2s linear;
          transition: width 0.2s linear;
  margin: 0 3px 0 0;
}
.km-ios7 .km-switch-handle.km-state-active {
  width: 2.6em;
}
.km-ios7 .km-switch-label-on,
.km-ios7 .km-switch-label-off {
  display: none;
}
.km-ios7 .km-switch-handle,
.km-ios7 .km-switch-wrapper,
.km-ios7 .km-switch-container,
.km-ios7 .km-switch-background {
  border-radius: 3em;
}
.km-ios7 .km-switch-container,
.km-ios7 .km-switch-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
.km-ios7 .km-switch-on .km-switch-container {
  border-color: currentcolor;
}
/* ListView */
.km-ios7 .km-listinset,
.km-ios7 .km-listgroupinset {
  margin: 0;
}
.km-ios7 .km-list > li {
  font-size: 1.1em;
  padding-left: 1em;
  background: transparent;
  border-radius: 0;
  border-width: 1px 0 0;
  border-style: solid;
}
.km-ios7 .km-list > .km-load-more + li {
  border-top: 0;
}
.km-ios7 .km-listview-link:after {
  width: .66rem;
  height: .64rem;
  border-width: 0;
  -webkit-box-shadow: inset -0.2rem 0.2rem 0 currentcolor;
          box-shadow: inset -0.2rem 0.2rem 0 currentcolor;
}
.km-ios7 .km-list li > .km-listview-link,
.km-ios7 .km-list li > .km-listview-label {
  margin-left: -1em;
  padding-left: 1em;
  border-radius: 0;
}
.km-ios7 .km-group-title {
  font-size: .95em;
  position: -webkit-sticky;
  text-indent: 1em;
  margin-top: 0;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.km-ios7 .km-listgroup .km-group-title,
.km-ios7 .km-listgroupinset .km-group-title {
  line-height: 2em;
  border-top-width: 2em;
  border-top-style: solid;
}
.km-ios7 .km-listview .km-switch {
  margin-top: -1.1rem;
}
/* Filter box */
.km-ios7 .km-filter-wrap {
  color: inherit;
  border-radius: 5px;
  background: currentcolor;
}
.km-ios7 .km-filter-wrap > input {
  padding: 0 1.8em;
  color: transparent;
  background: transparent;
}
.km-ios7 .km-filter-wrap > input::-webkit-input-placeholder {
  text-align: center;
}
.km-ios7 .km-filter-wrap > input::-moz-placeholder {
  text-align: center;
}
.km-ios7 .km-filter-wrap > input::-ms-input-placeholder {
  text-align: center;
}
.km-ios7 .km-filter-wrap > input:focus::-webkit-input-placeholder {
  text-align: left;
}
.km-ios7 .km-filter-wrap > input:focus::-moz-placeholder {
  text-align: left;
}
.km-ios7 .km-filter-wrap > input:focus::-ms-input-placeholder {
  text-align: left;
}
.km-ios7 .km-filter-wrap:before,
.km-ios7 .km-filter-reset .km-clear {
  position: absolute;
  top: 50%;
  margin-top: -0.55em;
}
.km-ios7 .km-filter-wrap:before {
  font-size: 1em;
  content: "\e21e";
}
.km-ios7 .km-filter-wrap .km-clear:after {
  content: "\e031";
}
/* ScrollView */
.km-ios7 .km-pages {
  padding-top: .4em;
}
.km-ios7 .km-pages li {
  background: transparent;
  border: 1px solid currentcolor;
  height: .5em;
  border-radius: 3em;
}
/* Slider */
.km-ios7 .k-slider {
  margin-top: -0.15em;
  height: .2em;
}
.km-ios7 .km-list .km-full-width-slider {
  position: relative;
  margin-top: 0;
  padding: 1.55em 0;
  width: auto;
  display: block;
}
.km-ios7 .k-slider .k-draghandle,
.km-ios7 .k-slider .k-draghandle:hover {
  top: -0.8em;
  border: 0;
  width: 1.6em;
  height: 1.6em;
  border-radius: 5em;
}
.km-ios7 .k-slider-horizontal .k-slider-track {
  height: .29rem;
}
.km-ios7 .k-slider-vertical .k-slider-track {
  width: .29rem;
}
.km-ios7 .k-slider-track {
  margin: -0.5em 0.5em 0 -0.5em;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-ios7 .k-slider-selection {
  margin-left: 0;
}
/* Checkboxes and Radios */
.km-ios7 .km-listview-label input[type=radio]:checked,
.km-ios7 .km-listview-label input[type=checkbox]:checked {
  font-size: 1.2em;
}
/* Forms */
.km-ios7 .km-list input[type=password],
.km-ios7 .km-list input[type=search],
.km-ios7 .km-list input[type=number],
.km-ios7 .km-list input[type=tel],
.km-ios7 .km-list input[type=url],
.km-ios7 .km-list input[type=email],
.km-ios7 .km-list input[type=month],
.km-ios7 .km-list input[type=color],
.km-ios7 .km-list input[type=week],
.km-ios7 .km-list input[type=date],
.km-ios7 .km-list input[type=time],
.km-ios7 .km-list input[type=datetime],
.km-ios7 .km-list input[type=datetime-local],
.km-ios7 .km-list input[type=text]:not(.k-input),
.km-ios7 .km-list select:not([multiple]),
.km-ios7 .km-list .k-dropdown-wrap,
.km-ios7 .km-list textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.1rem;
  min-width: 6em;
  border: 0;
  padding: .4em;
  outline: none;
  right: .8em;
  background: transparent;
}
.km-ios7 .km-list .k-dropdown-wrap {
  padding: .2em;
}
.km-ios7 .km-list .k-dropdown {
  margin-top: -1.05em;
  font-weight: normal;
}
.km-ios7 .km-list input[type=color],
.km-ios7 .km-list input[type=week],
.km-ios7 .km-list input[type=date],
.km-ios7 .km-list input[type=time],
.km-ios7 .km-list input[type=month],
.km-ios7 .km-list input[type=datetime],
.km-ios7 .km-list input[type=datetime-local],
.km-ios7 .km-list .k-dropdown {
  text-align: right;
}
.km-ios7 .km-list .k-dropdown .k-dropdown-wrap {
  display: block;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-ios7 label.km-required:after {
  content: "*";
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.km-ios7 .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-ios7 .km-icon-label:before {
  display: inline-block;
  width: 1em;
  height: 1em;
  font: 1em/1em "Kendo UI";
  margin-right: .89em;
}
.km-ios7 .km-list .km-icon-label input[type=password],
.km-ios7 .km-list .km-icon-label input[type=search],
.km-ios7 .km-list .km-icon-label input[type=number],
.km-ios7 .km-list .km-icon-label input[type=tel],
.km-ios7 .km-list .km-icon-label input[type=url],
.km-ios7 .km-list .km-icon-label input[type=email],
.km-ios7 .km-list .km-icon-label input[type=month],
.km-ios7 .km-list .km-icon-label input[type=color],
.km-ios7 .km-list .km-icon-label input[type=week],
.km-ios7 .km-list .km-icon-label input[type=date],
.km-ios7 .km-list .km-icon-label input[type=time],
.km-ios7 .km-list .km-icon-label input[type=datetime],
.km-ios7 .km-list .km-icon-label input[type=datetime-local],
.km-ios7 .km-list .km-icon-label input[type=text]:not(.k-input),
.km-ios7 .km-list .km-icon-label select:not([multiple]),
.km-ios7 .km-list .km-icon-label .k-dropdown-wrap,
.km-ios7 .km-list .km-icon-label textarea {
  right: 0;
}
.km-ios7 .km-label-above {
  width: 100%;
  font-size: 0.87em;
  display: block;
  margin-top: -2px;
  padding-top: .92em;
  padding-bottom: 2.5em;
}
.km-ios7 fieldset .km-label-above {
  margin-top: 0;
  padding-top: 1.455em;
  padding-bottom: 3.091em;
}
.km-ios7 .km-label-above textarea {
  top: 50%;
}
.km-ios7 .km-label-above,
.km-ios7 label.km-inline-field {
  font-size: .7em;
  line-height: 1.2em;
}
.km-ios7 .km-list .km-label-above input[type=password],
.km-ios7 .km-list .km-label-above input[type=search],
.km-ios7 .km-list .km-label-above input[type=number],
.km-ios7 .km-list .km-label-above input[type=tel],
.km-ios7 .km-list .km-label-above input[type=url],
.km-ios7 .km-list .km-label-above input[type=email],
.km-ios7 .km-list .km-label-above input[type=month],
.km-ios7 .km-list .km-label-above input[type=color],
.km-ios7 .km-list .km-label-above input[type=week],
.km-ios7 .km-list .km-label-above input[type=date],
.km-ios7 .km-list .km-label-above input[type=time],
.km-ios7 .km-list .km-label-above input[type=datetime],
.km-ios7 .km-list .km-label-above input[type=datetime-local],
.km-ios7 .km-list .km-label-above input[type=text]:not(.k-input),
.km-ios7 .km-list .km-label-above select:not([multiple]),
.km-ios7 .km-list .km-label-above .k-dropdown-wrap,
.km-ios7 .km-list .km-label-above textarea {
  width: 100%;
  right: 0;
  padding-left: 0;
  left: .89em;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-top: -0.375em;
}
.km-ios7 .km-list fieldset .km-label-above input[type=password],
.km-ios7 .km-list fieldset .km-label-above input[type=search],
.km-ios7 .km-list fieldset .km-label-above input[type=number],
.km-ios7 .km-list fieldset .km-label-above input[type=tel],
.km-ios7 .km-list fieldset .km-label-above input[type=url],
.km-ios7 .km-list fieldset .km-label-above input[type=email],
.km-ios7 .km-list fieldset .km-label-above input[type=month],
.km-ios7 .km-list fieldset .km-label-above input[type=color],
.km-ios7 .km-list fieldset .km-label-above input[type=week],
.km-ios7 .km-list fieldset .km-label-above input[type=date],
.km-ios7 .km-list fieldset .km-label-above input[type=time],
.km-ios7 .km-list fieldset .km-label-above input[type=datetime],
.km-ios7 .km-list fieldset .km-label-above input[type=datetime-local],
.km-ios7 .km-list fieldset .km-label-above input[type=text]:not(.k-input),
.km-ios7 .km-list fieldset .km-label-above select:not([multiple]),
.km-ios7 .km-list fieldset .km-label-above .k-dropdown-wrap,
.km-ios7 .km-list fieldset .km-label-above textarea {
  margin-top: -0.25em;
}
.km-ios7 .km-list fieldset {
  border: none;
  padding: 0 1.61em;
  margin: -0.5em -1.61em;
}
.km-ios7 .km-list fieldset > input[type=password],
.km-ios7 .km-list fieldset > input[type=search],
.km-ios7 .km-list fieldset > input[type=number],
.km-ios7 .km-list fieldset > input[type=tel],
.km-ios7 .km-list fieldset > input[type=url],
.km-ios7 .km-list fieldset > input[type=email],
.km-ios7 .km-list fieldset > input[type=month],
.km-ios7 .km-list fieldset > input[type=color],
.km-ios7 .km-list fieldset > input[type=week],
.km-ios7 .km-list fieldset > input[type=date],
.km-ios7 .km-list fieldset > input[type=time],
.km-ios7 .km-list fieldset > input[type=datetime],
.km-ios7 .km-list fieldset > input[type=datetime-local],
.km-ios7 .km-list fieldset > input[type=text]:not(.k-input),
.km-ios7 .km-list fieldset > select:not([multiple]),
.km-ios7 .km-list fieldset > .k-dropdown-wrap {
  padding: 1.23em 0;
  margin-top: 0;
}
.km-ios7 .km-list fieldset textarea {
  width: 100%;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-right: 0;
  padding-left: 0;
}
.km-ios7 .km-list fieldset input[type=password],
.km-ios7 .km-list fieldset input[type=search],
.km-ios7 .km-list fieldset input[type=number],
.km-ios7 .km-list fieldset input[type=tel],
.km-ios7 .km-list fieldset input[type=url],
.km-ios7 .km-list fieldset input[type=email],
.km-ios7 .km-list fieldset input[type=month],
.km-ios7 .km-list fieldset input[type=color],
.km-ios7 .km-list fieldset input[type=week],
.km-ios7 .km-list fieldset input[type=date],
.km-ios7 .km-list fieldset input[type=time],
.km-ios7 .km-list fieldset input[type=datetime],
.km-ios7 .km-list fieldset input[type=datetime-local],
.km-ios7 .km-list fieldset input[type=text]:not(.k-input),
.km-ios7 .km-list fieldset select:not([multiple]),
.km-ios7 .km-list fieldset .k-dropdown-wrap,
.km-ios7 .km-list fieldset textarea {
  width: 100%;
  position: relative;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  right: auto;
}
.km-ios7 .km-list fieldset legend {
  text-indent: 0;
  margin: 0 -1.61em;
  font-weight: normal;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 1.8em 1.61em .72em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-ios7 .km-legend-button {
  display: block;
  text-transform: uppercase;
}
.km-ios7 .km-inline-field {
  display: inline-block;
  position: relative;
  width: -webkit-calc(48.39%);
  width: calc(48.39%);
  overflow: hidden;
  white-space: nowrap;
}
.km-ios7 .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1.61em;
  border-right-width: 1px;
  border-right-style: solid;
}
.km-ios7 .km-list fieldset .km-inline-field:first-of-type {
  padding-left: 0;
}
.km-ios7 .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-ios7 .km-list .km-inline-field input[type=password],
.km-ios7 .km-list .km-inline-field input[type=search],
.km-ios7 .km-list .km-inline-field input[type=number],
.km-ios7 .km-list .km-inline-field input[type=tel],
.km-ios7 .km-list .km-inline-field input[type=url],
.km-ios7 .km-list .km-inline-field input[type=email],
.km-ios7 .km-list .km-inline-field input[type=month],
.km-ios7 .km-list .km-inline-field input[type=color],
.km-ios7 .km-list .km-inline-field input[type=week],
.km-ios7 .km-list .km-inline-field input[type=date],
.km-ios7 .km-list .km-inline-field input[type=time],
.km-ios7 .km-list .km-inline-field input[type=datetime],
.km-ios7 .km-list .km-inline-field input[type=datetime-local],
.km-ios7 .km-list .km-inline-field input[type=text]:not(.k-input),
.km-ios7 .km-list .km-inline-field select:not([multiple]),
.km-ios7 .km-list .km-inline-field .k-dropdown-wrap,
.km-ios7 .km-list .km-inline-field textarea {
  position: absolute;
  width: 100%;
  left: 0;
  padding-left: 0;
  padding-left: 1em;
}
.km-ios7 .km-list .km-inline-field:first-of-type input[type=password],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=search],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=number],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=tel],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=url],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=email],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=month],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=color],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=week],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=date],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=time],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=datetime],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=datetime-local],
.km-ios7 .km-list .km-inline-field:first-of-type input[type=text]:not(.k-input),
.km-ios7 .km-list .km-inline-field:first-of-type select:not([multiple]),
.km-ios7 .km-list .km-inline-field:first-of-type .k-dropdown-wrap,
.km-ios7 .km-list .km-inline-field:first-of-type textarea {
  margin-left: 0;
  padding-left: 0;
}
/* Standalone Checkboxes and RadioButtons */
.km-ios7 input.km-checkbox,
.km-ios7 input.km-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
}
.km-ios7 .km-checkbox-label,
.km-ios7 .km-radio-label {
  display: block;
  position: relative;
  vertical-align: middle;
  font-size: 1.1em;
  line-height: 1.5em;
  margin-left: .89em;
}
.km-ios7 .km-checkbox-label:before,
.km-ios7 .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
  right: .7em;
  width: 1em;
  height: .9rem;
  border: 0;
}
.km-ios7 input.km-checkbox:checked + .km-checkbox-label:after {
  content: "\e227";
  font-size: 1.2em;
  line-height: 1em;
  font-family: "Kendo UI";
}
.km-ios7 .km-radio-label {
  position: relative;
  padding-left: 2.5em;
  vertical-align: middle;
  line-height: 1.5em;
}
.km-ios7 .km-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}
.km-ios7 input.km-radio:checked + .km-radio-label:after {
  content: "";
  width: 1.085rem;
  height: 1.085rem;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-42%);
      transform: translateY(-42%);
  -webkit-transform: translateY(-42%);
  left: 0.365rem;
  border-radius: 50%;
}
.km-ios7 .km-listview-item-badge.km-badge,
.km-ios7 .km-listview-link > .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 2em;
}
/* PopUp + ActionSheet */
.km-ios7 .km-popup .km-actionsheet > li > a {
  padding: .5em 0;
}
.km-ios7 .km-popup .km-actionsheet > li:nth-last-child(2) > a {
  border: 0;
}
.km-ios7 .km-actionsheet > li > a {
  display: block;
}
.km-ios7 .km-actionsheet > .km-actionsheet-cancel > a {
  font-weight: bold;
}
.km-ios7 .km-shim li.km-actionsheet-title,
.km-ios7 .km-popup li.km-actionsheet-title,
.km-ios7 .km-popup .km-actionsheet-cancel {
  display: none;
}
.km-ios7 .km-actionsheet > .km-actionsheet-cancel > a {
  border-radius: 3px;
}
.km-ios7 .km-actionsheet > li:first-child,
.km-ios7 .km-actionsheet > .km-actionsheet-cancel {
  margin: .5em 0;
}
.km-ios7 .km-popup .km-actionsheet {
  padding: 0;
}
/* PopOver */
.km-ios7 .km-popup.km-pane {
  overflow: visible;
}
.km-ios7 .km-popup.km-pane .km-leftitem {
  left: .5em;
}
.km-ios7 .km-popup.km-pane .km-rightitem {
  right: .5em;
}
.km-ios7 .km-popup.km-pane {
  overflow: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid transparent;
  border-radius: 5px;
}
.km-ios7 .km-popup.km-pane > .km-view {
  height: 100%;
  border-radius: 3px;
}
.km-ios7 .km-popup.km-pane > .km-view > :first-child,
.km-ios7 .km-popup.km-pane > .km-view > :first-child > * {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-margin-collapse: separate;
}
.km-ios7 .km-popup.km-pane > .km-view > :last-child,
.km-ios7 .km-popup.km-pane > .km-view > :last-child > * {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-margin-collapse: separate;
}
.km-ios7 .km-popup-arrow:after {
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 0 15px 15px;
}
.km-ios7 .km-down .km-popup-arrow:before {
  margin-top: -1px;
}
.km-ios7 .km-up .km-popup-arrow:before {
  margin-top: 1px;
}
.km-ios7 .km-up .km-popup-arrow:after,
.km-ios7 .km-up .km-popup-arrow:before {
  border-width: 15px 15px 0 15px;
}
.km-ios7 .km-left .km-popup-arrow:after,
.km-ios7 .km-left .km-popup-arrow:before {
  border-width: 15px 0 15px 15px;
}
.km-ios7 .km-right .km-popup-arrow:after,
.km-ios7 .km-right .km-popup-arrow:before {
  border-width: 15px 15px 15px 0;
}
/* Scroller */
.km-ios7 .km-touch-scrollbar {
  border: 0;
  width: 3px;
  border-radius: 1em;
}
.km-ios7 .km-horizontal-scrollbar {
  width: 100%;
  height: 3px;
}
/* SplitView */
.km-ios7 .km-splitview > .km-split-content > .km-pane:first-child {
  border-right-width: 1px;
  border-right-style: solid;
}
.km-android {
  font-size: .9em;
  font-weight: bold;
  font-family: Roboto, HelveticaNeue, sans-serif;
}
.km-android .km-view {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.km-root .km-android .km-tabstrip .km-button {
  background: transparent;
}
.km-android .km-view > *,
.km-android .km-modalview,
.km-android .km-popover-root .km-view,
.km-android .km-splitview .km-view,
.km-android .km-pane-wrapper .km-view {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
html .km-android .km-stretched-view {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.km-android .km-view {
  overflow: hidden;
}
.km-android .km-tabstrip .km-button {
  height: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-android .km-navbar,
.km-android .k-toolbar,
.km-android .km-button,
.km-android .km-switch,
.km-android .km-buttongroup,
.km-android .km-tabstrip {
  text-shadow: none;
}
.km-android .km-navbar [class*=item] .km-text,
.km-android .km-tabstrip .km-text {
  position: relative;
  float: left;
  left: 0;
  top: 0;
  width: 100%;
  height: 2.2em;
  margin-top: -2.5em;
  border: 1em solid transparent;
  border-right-width: 1px;
  border-left: 0;
  text-indent: -9999px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .km-navbar .km-button:last-child .km-text,
.km-android .k-toolbar .km-button:last-child .km-text,
.km-android .km-tabstrip .km-button:last-child .km-text {
  border-right: 0;
}
/* Icons */
.km-android .km-icon,
.km-android .k-button-icontext .km-icon {
  text-indent: 0;
  z-index: 1;
}
.km-android .km-notext,
.km-android .k-buttonicon .km-icon {
  margin-left: 0;
  margin-right: 0;
}
.km-android .km-navbar .km-icon {
  margin-left: .5rem;
  margin-right: -0.3rem;
  font-size: 1.4em;
}
html .km-android .km-navbar .km-notext {
  margin-left: 1em;
  margin-right: 1em;
}
.km-android .km-tabstrip .km-icon {
  position: relative;
  left: 0;
  margin-bottom: .3rem;
  z-index: 2;
  color: #555;
}
.km-android.km-horizontal .km-tabstrip .km-icon {
  margin-bottom: 0;
}
/* Loading */
.km-android .km-loader {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: auto;
  bottom: 0;
  left: 0;
  opacity: 1;
  border-radius: 0;
}
.km-android .km-loader h1 {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 1em;
  left: 50%;
  height: 1.2em;
  bottom: 0;
  font-size: 1rem;
  margin-left: -0.5em;
}
.km-android .km-loading {
  width: 1em;
  height: 1em;
  font-size: 1.8rem;
  line-height: 1em;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -0.9rem;
  bottom: .4rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .km-loading:before,
.km-android .km-loading:after {
  display: none;
}
/* PopUp */
.km-android .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.km-android .km-popup .k-list-container {
  position: static !important;
  margin-left: 10% !important;
  width: 80% !important;
  display: inline-block !important;
}
.km-android .km-actionsheet,
.km-android .km-popup .k-list-container {
  position: static;
  margin-left: 5%;
  width: 90%;
  display: inline-block;
  border: 0;
  padding: 0;
  vertical-align: middle;
  border-radius: 0;
}
.km-phone .km-android .km-popup .k-list-container {
  max-height: 80%;
}
.km-android .km-popup .k-list-container .km-scroll-container {
  padding: 0;
}
.km-android .km-popup:not(.km-pane):before,
.km-android .km-actionsheet-wrapper:before {
  content: "\a0";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.km-android li.km-actionsheet-title,
.km-android .km-popup .k-item,
.km-android .km-actionsheet > li > a {
  font-size: 1.1em;
  font-weight: normal;
  display: block;
  line-height: 3rem;
  border-width: 0 0 1px;
  padding: 0 1em;
  border-radius: 0;
  text-decoration: none;
  border-style: solid;
}
.km-android .km-popup-wrapper {
  padding: 7px 7px 13px;
}
.km-android .km-actionsheet-root .km-popup-wrapper {
  padding: 15px;
}
/* Color Template */
/* Colors */
.km-android,
.km-android .km-header,
.km-android .km-footer,
.km-android .km-content,
.km-android .km-pane,
.km-android .km-scroll-header .km-group-title,
.km-android input[type=password],
.km-android input[type=search],
.km-android input[type=number],
.km-android input[type=tel],
.km-android input[type=url],
.km-android input[type=email],
.km-android input[type=text]:not(.k-input),
.km-root .km-android textarea {
  background: #000;
  outline-color: #000;
}
.km-android-light,
.km-android-light .km-header,
.km-android-light .km-footer,
.km-android-light .km-content,
.km-android-light .km-pane,
.km-android-light .km-scroll-header .km-group-title,
.km-android-light input[type=password],
.km-android-light input[type=search],
.km-android-light input[type=number],
.km-android-light input[type=tel],
.km-android-light input[type=url],
.km-android-light input[type=email],
.km-android-light input[type=text]:not(.k-input),
.km-root .km-android-light textarea {
  background: #fcfcfc;
  outline-color: #fcfcfc;
}
.km-android .km-navbar,
.km-android .k-toolbar,
.km-android .km-button,
.km-android .km-switch,
.km-android .km-buttongroup,
.km-android .km-popup .k-item,
.km-android .km-actionsheet > li > a,
.km-android select:not([multiple]):focus option {
  color: #fff;
  border-color: rgba(153, 153, 153, 0.7);
  background-color: #000;
}
.km-android-light .km-navbar,
.km-android-light .k-toolbar,
.km-android-light .km-button,
.km-android-light .km-switch,
.km-android-light .km-buttongroup,
.km-android-light .km-tabstrip,
.km-android-light .km-popup .k-item,
.km-android-light .km-actionsheet > li > a,
.km-android-light select:not([multiple]):focus option {
  color: #222;
  background-color: #fcfcfc;
}
.km-android-light .km-navbar,
.km-android-light .k-toolbar,
.km-android-light .km-switch,
.km-android-light .km-tabstrip,
.km-android-light .km-popup .k-item,
.km-android-light .km-actionsheet > li > a {
  background-color: #e9e9e9;
}
.km-android input,
.km-android select,
.km-android textarea,
.km-android .km-widget {
  color: #fff;
  border-color: rgba(153, 153, 153, 0.7);
}
.km-android-light input,
.km-android-light select,
.km-android-light textarea,
.km-android-light .km-widget {
  color: #222;
}
.km-android .km-navbar,
.km-android .k-toolbar,
.km-android .km-tabstrip {
  -webkit-box-shadow: 0 0 1px #999, 0 0 4px #999;
          box-shadow: 0 0 1px #999, 0 0 4px #999;
}
/* Button */
.km-android .km-button,
.km-android .km-switch-handle,
.km-android .km-buttongroup .km-button,
.km-android .km-navbar .km-buttongroup .km-button {
  border-color: rgba(204, 204, 204, 0.5) rgba(102, 102, 102, 0.5) rgba(102, 102, 102, 0.5) rgba(204, 204, 204, 0.5);
  background: rgba(153, 153, 153, 0.5);
}
/* Switch */
.km-android .km-switch-container {
  background: #222222;
}
.km-android-light .km-switch-container {
  background: #dadada;
}
/* Badges & Details */
.km-android .km-badge {
  -webkit-box-shadow: 0 2px 2px rgba(102, 102, 102, 0.5);
          box-shadow: 0 2px 2px rgba(102, 102, 102, 0.5);
}
.km-android .km-badge,
.km-android .km-detail,
.km-android .km-rowdelete {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), color-stop(50%, rgba(204, 204, 204, 0.5)), color-stop(50%, rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), rgba(204, 204, 204, 0.5) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(204, 204, 204, 0.5) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0));
  border-color: #fff;
  background-color: #B01B1A;
}
.km-android-light .km-badge,
.km-android-light .km-detail,
.km-android-light .km-rowdelete {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(34, 34, 34, 0.5)), color-stop(50%, rgba(204, 204, 204, 0.5)), color-stop(50%, rgba(34, 34, 34, 0.1)), to(rgba(34, 34, 34, 0)));
  background-image: -webkit-linear-gradient(top, rgba(34, 34, 34, 0.5), rgba(204, 204, 204, 0.5) 50%, rgba(34, 34, 34, 0.1) 50%, rgba(34, 34, 34, 0));
  background-image: linear-gradient(to bottom, rgba(34, 34, 34, 0.5), rgba(204, 204, 204, 0.5) 50%, rgba(34, 34, 34, 0.1) 50%, rgba(34, 34, 34, 0));
  border-color: #222;
}
.km-android .km-detail .km-text {
  border-left-color: #dddddd;
}
.km-android .km-rowinsert {
  background-color: #24870D;
}
/* TabStrip */
.km-android .k-toolbar,
.km-android .km-tabstrip {
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
.km-android-light .k-toolbar,
.km-android-light .km-tabstrip {
  border-bottom-color: rgba(34, 34, 34, 0.4);
}
/* NavBar */
.km-android .km-header {
  -webkit-box-shadow: 0 2px 0 #222222 0 -2px 0 #222222;
          box-shadow: 0 2px 0 #222222 0 -2px 0 #222222;
}
.km-android .km-navbar [class*=item] .km-text,
.km-android .km-tabstrip .km-text {
  border-right-color: rgba(0, 0, 0, 0.265);
}
.km-android .km-navbar [class*=item] .km-text {
  border-right-color: rgba(255, 255, 255, 0.4);
}
.km-android-light .km-navbar [class*=item] .km-text,
.km-android-light .km-tabstrip .km-text {
  border-right-color: rgba(252, 252, 252, 0.265);
}
.km-android-light .km-navbar [class*=item] .km-text {
  border-right-color: rgba(34, 34, 34, 0.4);
}
/* ListView */
.km-android .km-list > li {
  -webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.135);
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.135);
}
.km-android-light .km-list > li {
  -webkit-box-shadow: 0 1px 1px rgba(34, 34, 34, 0.135);
  box-shadow: 0 -1px 0 rgba(34, 34, 34, 0.135);
}
.km-android .km-group-title {
  color: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.3);
}
.km-android-light .km-group-title {
  color: rgba(34, 34, 34, 0.4);
  -webkit-box-shadow: inset 0 -1px 1px rgba(34, 34, 34, 0.3);
  box-shadow: inset 0 -1px 0 rgba(34, 34, 34, 0.3);
}
.km-android .km-scroll-header .km-group-title {
  border-bottom-color: #333333;
}
.km-android-light .km-scroll-header .km-group-title {
  border-bottom-color: #ffffff;
}
.km-android .km-filter-wrap:before,
.km-android .km-filter-reset .km-clear {
  color: rgba(153, 153, 153, 0.7);
}
.km-android .km-item label:before {
  border-color: #000;
  background-color: #363636;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#363636), to(#2b2b2b));
  background-image: -webkit-linear-gradient(top, #363636, #2b2b2b);
  background-image: linear-gradient(to bottom, #363636, #2b2b2b);
  -webkit-box-shadow: 0 0 1px 2px #808080;
          box-shadow: 0 0 1px 2px #808080;
}
.km-android-light .km-item label:before {
  border-color: #fcfcfc;
  background-color: #c6c6c6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#c6c6c6), to(#d1d1d1));
  background-image: -webkit-linear-gradient(top, #c6c6c6, #d1d1d1);
  background-image: linear-gradient(to bottom, #c6c6c6, #d1d1d1);
  -webkit-box-shadow: 0 0 1px 2px #999;
          box-shadow: 0 0 1px 2px #999;
}
/* Icons */
.km-android .km-button .km-icon,
.km-android .km-navbar .km-icon {
  color: rgba(255, 255, 255, 0.8);
}
.km-android-light .km-button .km-icon,
.km-android-light .km-navbar .km-icon {
  color: rgba(34, 34, 34, 0.8);
}
.km-android .km-tabstrip .km-icon {
  color: rgba(255, 255, 255, 0.4);
}
.km-android-light .km-tabstrip .km-icon {
  color: rgba(34, 34, 34, 0.4);
}
/* Loading */
.km-android .km-loader {
  background: rgba(0, 0, 0, 0.1);
}
.km-android .km-loading {
  border-color: #fff rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.km-android-light .km-loading {
  border-color: #222 rgba(34, 34, 34, 0.2);
  -webkit-box-shadow: 0 1px 0 rgba(252, 252, 252, 0.1), 0 -1px 0 rgba(252, 252, 252, 0.1), inset 0 1px 0 rgba(252, 252, 252, 0.1), inset 0 -1px 0 rgba(252, 252, 252, 0.1);
          box-shadow: 0 1px 0 rgba(252, 252, 252, 0.1), 0 -1px 0 rgba(252, 252, 252, 0.1), inset 0 1px 0 rgba(252, 252, 252, 0.1), inset 0 -1px 0 rgba(252, 252, 252, 0.1);
}
/* Forms */
.km-android .k-slider-track {
  background: rgba(153, 153, 153, 0.5);
}
.km-android input[type=password],
.km-android input[type=search],
.km-android input[type=number],
.km-android input[type=tel],
.km-android input[type=url],
.km-android input[type=email],
.km-android input[type=text]:not(.k-input),
.km-root .km-android textarea {
  border-color: rgba(153, 153, 153, 0.5);
}
.km-android input[type=radio],
.km-android input[type=checkbox] {
  color: rgba(153, 153, 153, 0.5);
  border-color: currentcolor;
  -webkit-box-shadow: 0 0 1px currentcolor;
          box-shadow: 0 0 1px currentcolor;
}
.km-android input[type=month],
.km-android input[type=color],
.km-android input[type=week],
.km-android input[type=date],
.km-android input[type=time],
.km-android input[type=datetime],
.km-android input[type=datetime-local],
.km-android select:not([multiple]),
.km-android .k-dropdown .k-dropdown-wrap {
  border-bottom-color: rgba(127, 127, 127, 0.5);
  background: 100% 100% no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAACWCAQAAABj2vCcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAf0lEQVR4XuzLsQkAAAwCsP7/tD1CcAqZcykMsizLsizLsizLsizLsizLsizLsizL8ncuxzYAgEAAhJz893BazVfXE2quGc9z32asZahlqGWoZahlqGWoZahlqGWoZahlqGWoZahlqGWoZahlqGWoZahlqOUq5CrkKuStlKuS3QdJPtFaleZ1DgAAAABJRU5ErkJggg==") transparent;
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
}
/* ActionSheet */
.km-android li.km-actionsheet-title {
  color: inherit;
  border-color: inherit;
}
/* PopUp */
.km-android .km-shim,
.km-phone .km-android .km-actionsheet-wrapper {
  background: rgba(102, 102, 102, 0.5);
}
.km-android .km-actionsheet,
.km-android .km-popup .k-list-container {
  background: #000;
  -webkit-box-shadow: 2px 2px 5px rgba(102, 102, 102, 0.5);
          box-shadow: 2px 2px 5px rgba(102, 102, 102, 0.5);
}
.km-android-light .km-actionsheet,
.km-android-light .km-popup .k-list-container {
  background: #fcfcfc;
}
.km-android .km-popup .k-item,
.km-android .km-actionsheet > li > a {
  border-color: rgba(255, 255, 255, 0.2);
}
.km-android-light .km-popup .k-item,
.km-android-light .km-actionsheet > li > a {
  border-color: rgba(34, 34, 34, 0.2);
}
.km-android .km-popup-arrow:before {
  border-color: rgba(153, 153, 153, 0.7) transparent;
}
.km-android .km-left .km-popup-arrow:before,
.km-android .km-right .km-popup-arrow:before,
.km-android .k-toolbar .km-buttongroup {
  border-color: transparent rgba(153, 153, 153, 0.7);
}
.km-android .km-popup-arrow:after {
  border-color: #000 transparent;
}
.km-android .km-left .km-popup-arrow:after,
.km-android .km-right .km-popup-arrow:after {
  border-color: transparent #000;
}
.km-android-light .km-popup-arrow:after {
  border-color: #e9e9e9 transparent;
}
.km-android-light .km-left .km-popup-arrow:after,
.km-android-light .km-right .km-popup-arrow:after {
  border-color: transparent #e9e9e9;
}
/* Checkboxes and Radios */
.km-android .km-state-active input[type=radio],
.km-android .km-state-active input[type=checkbox] {
  border-color: #dddddd;
  -webkit-box-shadow: 0 0 1px #dddddd;
          box-shadow: 0 0 1px #dddddd;
}
.km-android-light .km-state-active input[type=radio],
.km-android-light .km-state-active input[type=checkbox] {
  border-color: #ffffff;
  -webkit-box-shadow: 0 0 1px #ffffff;
          box-shadow: 0 0 1px #ffffff;
}
/* SplitView */
.km-android .km-splitview .km-pane:last-child {
  -webkit-box-shadow: -1px 0 1px 1px rgba(102, 102, 102, 0.5);
          box-shadow: -1px 0 1px 1px rgba(102, 102, 102, 0.5);
}
.km-android .km-popup.km-pane .km-view,
.km-android .km-splitview .km-pane:last-child .km-content {
  border-color: rgba(128, 128, 128, 0.4);
  -webkit-box-shadow: inset 0 0 1px 1px #000;
          box-shadow: inset 0 0 1px 1px #000;
}
.km-android-light .km-popup.km-pane .km-view,
.km-android-light .km-splitview .km-pane:last-child .km-content {
  border-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 1px 1px #fcfcfc;
          box-shadow: inset 0 0 1px 1px #fcfcfc;
}
/* ModalView */
.km-android .km-modalview {
  -webkit-box-shadow: 0 0 10px rgba(102, 102, 102, 0.5) 0 0 2px rgba(255, 255, 255, 0.7);
          box-shadow: 0 0 10px rgba(102, 102, 102, 0.5) 0 0 2px rgba(255, 255, 255, 0.7);
  border-color: #000;
}
.km-android .km-modalview .km-view-title {
  -webkit-box-shadow: inset 0 -2px rgba(255, 255, 255, 0.1);
          box-shadow: inset 0 -2px rgba(255, 255, 255, 0.1);
}
.km-android-light .km-modalview {
  -webkit-box-shadow: 0 0 10px rgba(102, 102, 102, 0.5) 0 0 2px rgba(34, 34, 34, 0.7);
          box-shadow: 0 0 10px rgba(102, 102, 102, 0.5) 0 0 2px rgba(34, 34, 34, 0.7);
  border-color: #fcfcfc;
}
/* Collapsible */
.km-android .km-collapsible-header,
.km-android .km-collapsible-content {
  border-color: rgba(102, 102, 102, 0.5);
}
.km-android-light .km-collapsible-header,
.km-android-light .km-collapsible-content {
  border-color: rgba(204, 204, 204, 0.5);
}
.km-android .km-collapsible-header .km-icon {
  color: #999;
}
/* Tablet Styles */
.km-tablet .km-android *:not(.km-actionsheet-phone) > .km-actionsheet {
  -webkit-box-shadow: 0 0 7px rgba(153, 153, 153, 0.5);
          box-shadow: 0 0 7px rgba(153, 153, 153, 0.5);
}
/* Active States */
.km-android .k-list,
.km-android .k-draghandle,
.km-android .km-actionsheet,
.km-android .km-actionsheet-wrapper,
.km-android .k-dropdown .k-input,
.km-android .km-tabstrip .km-button,
.km-android .km-actionsheet > li > a:focus,
.km-android .km-actionsheet > li > a:active,
.km-android .km-widget .k-state-hover,
.km-android .km-widget .k-state-focused,
.km-android .km-widget .k-state-selected,
.km-android .km-popup > .k-list-container,
.km-android .km-state-active .km-detail,
.km-android .km-state-active .km-listview-link,
.km-android .km-state-active .km-listview-label {
  color: inherit;
}
.km-android .km-badge,
.km-android .k-slider-selection,
.km-android .km-touch-scrollbar,
.km-android .km-state-active,
.km-android .km-widget .km-state-active,
.km-android .km-popup .k-state-hover,
.km-android .km-popup .k-state-focused,
.km-android .km-popup .k-state-selected,
.km-android .km-actionsheet > li > a:focus,
.km-android .km-actionsheet > li > a:active,
.km-android .km-detail.km-state-active,
.km-android .km-state-active .km-detail,
.km-android .km-pages .km-current-page,
.km-android .k-slider .k-draghandle,
.km-android .k-slider .k-draghandle:hover,
.km-android .k-slider .k-draghandle:before,
.km-android .km-switch-on .km-switch-handle,
.km-android select:not([multiple]):focus option,
.km-android .km-listview-label input[type=radio]:checked:after {
  background: currentcolor;
}
.km-android .km-switch-label-on,
.km-android .km-state-active .km-switch-label-off,
.km-android .km-state-active .km-text,
.km-android .km-state-active .km-icon,
.km-android .km-state-active.km-detail:after,
.km-android .km-state-active .km-detail:after {
  color: #fff;
}
.km-android .km-actionsheet > li > a:focus,
.km-android .km-actionsheet > li > a:active,
.km-android .km-popup .k-state-hover,
.km-android .km-popup .k-state-focused,
.km-android .km-popup .k-state-selected {
  text-indent: -10000px;
  text-shadow: 10000px 0 0 #fff;
}
.km-android2 .km-actionsheet > li > a:focus,
.km-android2 .km-actionsheet > li > a:active,
.km-android2 .km-popup .k-state-hover,
.km-android2 .km-popup .k-state-focused,
.km-android2 .km-popup .k-state-selected {
  text-shadow: 10000px 0 1px #fff;
}
.km-android .km-state-active .km-listview-link,
.km-android .km-state-active .km-listview-label {
  color: #fff;
  background: #33b5e5;
}
.km-android li.km-actionsheet-cancel > a:focus,
.km-android li.km-actionsheet-cancel > a:active,
.km-android .km-badge {
  text-indent: -10000px;
  text-shadow: 5000px 0 0 #fff;
  opacity: .8;
}
.km-android2 li.km-actionsheet-cancel > a:focus,
.km-android2 li.km-actionsheet-cancel > a:active,
.km-android2 .km-badge {
  text-shadow: 5000px 0 1px #fff;
}
.km-android .k-split-container > li > a:focus,
.km-android .k-split-container > li > a:active,
.km-android .k-overflow-container > li > a:focus,
.km-android .k-overflow-container > li > a:active {
  text-indent: 0;
  text-shadow: none;
}
.km-android .km-actionsheet > li > a:focus > *,
.km-android .km-actionsheet > li > a:active > *,
.km-android .km-popup .k-state-hover > *,
.km-android .km-popup .k-state-focused > *,
.km-android .km-popup .k-state-selected > *,
.km-android li.km-actionsheet-cancel > a:focus > *,
.km-android li.km-actionsheet-cancel > a:active > *,
.km-android .km-badge > * {
  text-indent: 0;
  text-shadow: none;
  color: #fff;
}
.km-android select:focus,
.km-android textarea:focus,
.km-android.km-pane input:focus,
.km-android li.km-actionsheet-title,
.km-android .k-dropdown .k-state-focused {
  border-color: currentcolor;
}
.km-android .km-listview-label input[type=checkbox]:checked:after {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.km-android .km-state-active input[type=radio]:before,
.km-android .km-state-active input[type=checkbox]:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.km-android-light .km-listview-label input[type=checkbox]:checked:after {
  text-shadow: 0 1px 1px rgba(252, 252, 252, 0.2);
}
.km-android-light .km-state-active input[type=radio]:before,
.km-android-light .km-state-active input[type=checkbox]:before {
  background-color: rgba(252, 252, 252, 0.2);
}
.km-android .km-icon:before,
.km-android .km-contactadd:before,
.km-android .km-rowdelete:before,
.km-android .km-rowinsert:before,
.km-android .km-detaildisclose:before,
.km-android .k-overflow-container .km-state-disabled {
  display: block;
  color: #999;
}
.km-android .k-overflow-container a.km-state-disabled {
  color: #999;
  background-color: #000;
  border-color: rgba(153, 153, 153, 0.7);
}
.km-android-light .k-overflow-container a.km-state-disabled {
  color: #999;
  background-color: #e9e9e9;
  border-color: rgba(204, 204, 204, 0.5);
}
.km-android .km-state-active.km-state-disabled .km-text {
  color: #999;
}
.km-android .km-shim,
.km-android .km-popup,
.km-android .k-slider,
.km-android .km-badge,
.km-android .km-switch-on,
.km-android .km-current-page,
.km-android input:focus,
.km-android select:focus,
.km-android textarea:focus,
.km-android input:checked,
.km-android .km-touch-scrollbar,
.km-android .km-widget .k-state-focused,
.km-android .km-widget .km-state-active {
  color: #33b5e5;
}
/* Nova theme features */
.km-android .km-required:after {
  color: #f44336;
}
.km-android-light .km-list fieldset legend {
  -webkit-box-shadow: 0 2px 0 0 rgba(34, 34, 34, 0.135);
  box-shadow: 0 2px 0 0 rgba(34, 34, 34, 0.135);
}
.km-android-dark .km-list fieldset legend {
  -webkit-box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.4);
  box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.4);
}
.km-android-light .km-label-above,
.km-android-light .km-icon-label,
.km-android-light .km-radio-label:before,
.km-android-light .km-radio-label:after,
.km-android-light .km-checkbox-label:before,
.km-android-light .km-checkbox-label:after {
  color: rgba(34, 34, 34, 0.4);
}
.km-android-dark .km-label-above,
.km-android-dark .km-icon-label,
.km-android-dark .km-radio-label:before,
.km-android-dark .km-radio-label:after,
.km-android-dark .km-checkbox-label:before,
.km-android-dark .km-checkbox-label:after {
  color: rgba(255, 255, 255, 0.4);
}
.km-android .km-radio-label:after {
  background-color: #33b5e5;
}
.km-android-dark .km-radio-label:before,
.km-android-dark .km-checkbox-label:before,
.km-android-dark .km-checkbox-label:after {
  border-color: rgba(153, 153, 153, 0.5);
}
.km-android-light input.km-checkbox:checked + .km-checkbox-label:before {
  border-color: #222;
}
.km-android input.km-checkbox:focus + .km-checkbox-label:before,
.km-android input.km-checkbox:focus + .km-checkbox-label:before {
  -webkit-box-shadow: 0 0 1px 2px #33b5e5;
          box-shadow: 0 0 1px 2px #33b5e5;
}
.km-android input.km-checkbox:checked + .km-checkbox-label:after {
  color: #33b5e5;
}
.km-android input.km-radio:checked + .km-radio-label:after {
  -webkit-box-shadow: 0 0 16px 1px #33b5e5;
          box-shadow: 0 0 16px 1px #33b5e5;
  border-color: rgba(34, 34, 34, 0.4);
}
/* Validation */
.km-android .km-list > li label.km-invalid,
.km-android .km-list fieldset .km-legend-button.km-invalid,
.km-android .km-list > li label.km-invalid.km-required:after {
  color: #f52312;
}
.km-android .km-invalid-msg {
  color: #fff;
  background: #f52312;
}
/* Active States */
.km-android .km-button.km-state-active,
.km-android input[type=file]:active,
.km-android input[type=file]:focus {
  overflow: visible;
}
.km-android .km-button.km-state-active:after,
.km-android .km-buttongroup .km-state-active:after {
  content: "\a0";
  display: block;
  top: -4px;
  left: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 4px solid transparent;
  background: inherit;
  border-radius: 2px;
  opacity: .5;
}
.km-android .km-navbar .km-state-active:after,
.km-android .k-toolbar .km-state-active:after,
.km-android .k-split-container .km-state-active:after,
.km-android .k-overflow-container .km-state-active:after,
.km-android .km-tabstrip .km-state-active:after,
.km-android2 .km-buttongroup .km-state-active:after {
  display: none;
}
/* Tablet Styles */
.km-tablet .km-android .km-actionsheet-title,
.km-tablet .km-android li.km-actionsheet-cancel {
  display: none;
}
.km-tablet .km-android .km-actionsheet-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-tablet .km-android *:not(.km-actionsheet-phone) > .km-actionsheet {
  margin: 0;
  width: 100%;
}
.km-tablet .km-android *:not(.km-actionsheet-phone) > .km-actionsheet :nth-last-child(2) > a {
  border-bottom-width: 0;
}
/* Button */
.km-android .km-button {
  border-radius: 1px;
  padding: .6em 1em;
  border-width: 1px;
  border-style: solid;
}
.km-android .km-button .km-text {
  position: relative;
  z-index: 1;
}
.km-android .km-button .km-notext {
  width: 1em;
}
.km-android .km-back:before {
  display: none;
}
/* Badges & Details */
.km-android .km-badge,
.km-android .km-detail {
  border-width: .3em;
  border-style: solid;
}
.km-android .km-badge {
  opacity: .8;
  border: 0;
  top: -1.05em;
  min-width: 1.1em;
  height: 2em;
  line-height: 2.2em;
  border-radius: 3em;
}
.km-android .km-detail {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .km-detail:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.km-root .km-android .km-detail {
  height: 100%;
  width: 3rem;
  font-size: 1.8rem;
  margin: 0;
}
.km-android .km-detail .km-icon,
.km-android .km-detail .km-icon:after,
.km-android .km-detail .km-icon:before,
.km-root .km-android .km-detail:after {
  line-height: 3.4rem;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.4rem;
  vertical-align: middle;
}
.km-android .km-detail .km-icon {
  display: inline-block;
  position: relative;
}
.km-android .km-detail .km-text {
  width: 100%;
  display: block;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.8rem;
  border-left-width: 1px;
  border-left-style: dotted;
}
/* ButtonGroup */
.km-android .km-widget.km-buttongroup {
  background: none;
}
.km-android .km-navbar .km-buttongroup {
  margin-top: .2em;
  line-height: 1.2em;
  height: 1.8em;
}
.km-android .km-buttongroup .km-button,
.km-android .km-navbar .km-buttongroup .km-button {
  border-radius: 1px;
  padding: .6em 1em;
  border-style: solid;
  border-width: 1px 0 1px 1px;
}
.km-android .km-navbar .km-buttongroup .km-button {
  font-size: .9em;
}
.k-ff .km-android .km-buttongroup .km-button {
  display: inline-block;
  margin-left: -4px;
}
.km-android .km-buttongroup .km-state-active,
.km-android .km-navbar .km-buttongroup .km-state-active {
  overflow: visible;
  z-index: 1;
}
.km-android .km-buttongroup .km-button .km-text {
  position: relative;
  width: 100%;
  z-index: 1;
}
.km-android .km-buttongroup .km-button .km-icon {
  width: 1.1em;
  height: 1.1em;
}
.km-root .km-android .km-buttongroup .km-button:first-child {
  margin-left: 0;
  border-radius: 1px 0 0 1px;
}
.km-root .km-android .km-buttongroup .km-button:last-child {
  border-radius: 0 1px 1px 0;
}
/* NavBar */
.km-android .km-view-title {
  visibility: hidden;
}
.km-android .km-popover-root .km-view-title,
.km-android .km-modalview .km-view-title,
.km-android .km-splitview .km-view-title {
  visibility: visible;
  display: block;
}
.km-android .km-view-title,
.km-android .km-dialog-title {
  text-align: center;
  font-size: 1.4em;
  line-height: 2.4em;
  font-weight: bold;
}
.km-android.km-horizontal .km-view-title {
  line-height: 2.1em;
}
.km-root .km-android .km-navbar,
.km-root .km-android .k-toolbar {
  font-size: .8em;
}
.km-android .km-navbar {
  padding: 0 .5em;
}
.km-android .km-navbar .km-button,
.km-android .k-toolbar .km-button {
  margin: 0;
  border-style: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
}
.km-android .km-navbar .km-button {
  display: inline;
}
.km-android .km-navbar [class*=item] .km-button {
  padding: .95em 0;
  text-transform: uppercase;
}
.km-android .k-toolbar > .km-button {
  text-transform: uppercase;
}
.km-android .km-navbar [class*=item] .km-text {
  float: none;
  border: 0;
  border-right-width: 1px;
  border-right-style: solid;
  padding: 0 .95em;
}
.km-android.km-horizontal .km-leftitem .km-button,
.km-android.km-horizontal .km-rightitem .km-button {
  padding: .4em 0;
}
html .km-android .km-navbar .km-state-active,
html .km-android .k-toolbar .km-state-active {
  background: currentcolor;
  opacity: .6;
}
html .km-android .k-toolbar .km-state-active {
  opacity: 1;
}
/* ToolBar */
.km-android .k-toolbar {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2.6em;
  background-image: none;
}
.km-android .k-toolbar > * {
  vertical-align: top;
}
.km-android .k-toolbar .k-button-icon .km-icon,
.km-android .k-toolbar .k-button-icontext .km-icon,
.km-android .k-split-button .km-arrowdown {
  width: 1em;
  height: 1em;
  font-size: 2.4em;
  margin-top: -8px;
  margin-bottom: -5px;
  margin-right: .1em;
  vertical-align: middle;
}
.km-android .k-toolbar .km-button {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .k-toolbar .km-text {
  vertical-align: middle;
}
.km-android .k-toolbar .km-buttongroup .k-button-icon {
  padding-left: .6em;
  padding-right: .6em;
}
.km-android .k-toolbar .km-buttongroup {
  border-width: 0 1px;
  border-style: solid;
  padding: 0 .5em;
}
.km-android .k-toolbar .km-buttongroup .km-button {
  border-style: solid;
  border-color: transparent;
  border-width: 1px 0;
}
.km-android .k-toolbar .km-buttongroup + .km-buttongroup {
  padding-left: 0;
  border-left: 0;
  margin-left: 0;
}
.km-root .k-toolbar .km-buttongroup.k-toolbar-last-visible {
  padding-right: 0;
  border-right: 0;
  margin-right: 0;
}
.km-android .k-toolbar .k-split-button-arrow {
  border-radius: 0;
}
.km-android .k-split-button .km-arrowdown {
  margin: 0;
}
.km-android .km-popup .k-split-container,
.km-android .km-popup .k-overflow-container {
  margin-left: 0 !important;
  width: 100% !important;
}
.km-android .k-split-wrapper > .km-popup-wrapper,
.km-android .k-overflow-wrapper > .km-popup-wrapper {
  padding: 7px 7px 13px;
}
.km-tablet .km-android .k-split-wrapper > .km-popup-wrapper,
.km-tablet .km-android .k-overflow-wrapper > .km-popup-wrapper {
  padding: 9px 9px 15px;
}
.km-android .k-split-container .km-actionsheet-cancel,
.km-android .k-overflow-container .km-actionsheet-cancel {
  display: none;
}
.km-android .k-split-container .km-button,
.km-android .k-overflow-container .km-button {
  margin: 0;
  padding-right: 3em;
}
.km-android .k-overflow-wrapper {
  height: auto !important;
  bottom: 0;
}
/* TabStrip */
.km-android .km-tabstrip {
  display: table;
  padding: 0;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-android .km-tabstrip .km-text {
  font-size: 0;
}
.km-android .km-tabstrip .km-button {
  display: table-cell;
  font-size: .8em;
  padding: .5em 0 .4em 0;
  border-radius: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  min-width: 5em;
}
.km-android.km-horizontal .km-tabstrip .km-text {
  height: 2em;
}
.km-android.km-horizontal .km-tabstrip .km-text {
  margin-top: -2.2em;
}
.km-android.km-horizontal .km-tabstrip .km-button {
  padding: 0;
}
.km-android .km-tabstrip .km-badge {
  top: 0;
}
.km-android .km-tabstrip .km-state-active {
  border-bottom-width: .4em;
  padding-bottom: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: currentcolor;
}
.km-android .km-tabstrip .km-state-active .km-icon {
  color: inherit;
}
.km-android .km-tabstrip .km-icon:before {
  display: none;
}
.km-android .km-tabstrip .km-state-active .km-icon:before {
  display: block;
}
/* Switch */
.km-android .km-switch-background,
.km-android .km-slider-background {
  display: none;
}
.km-android .km-switch {
  background: transparent;
  width: 7em;
  height: 1.8em;
  line-height: 1.8em;
}
.km-android .km-switch-container {
  overflow: hidden;
  border-radius: 0;
  border: 2px solid transparent;
  border-left-width: 1px;
}
.km-android .km-switch-handle {
  width: 3em;
  margin: 0 3px 1px 1px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android2 .km-switch-handle {
  border-radius: 0;
}
.km-android .km-switch-label-on,
.km-android .km-switch-label-off {
  font-size: .8em;
  left: 0;
  width: 100%;
  line-height: 1.9em;
  height: 100%;
  text-align: center;
  text-shadow: none;
  position: absolute;
  z-index: 1;
}
.km-android .km-switch-off .km-switch-label-on,
.km-android .km-switch-on .km-switch-label-off {
  display: none;
}
/* ListView */
.km-android li.km-state-active {
  border: 0;
}
.km-android .km-listinset,
.km-android .km-listgroupinset {
  margin: 0;
}
.km-android .km-listview-wrapper {
  padding: 0 1em;
}
.km-android .km-list > li {
  font-size: 1.3em;
  font-weight: normal;
  line-height: 2em;
  overflow: hidden;
}
.km-android .km-list {
  margin-bottom: 1em;
  -webkit-margin-collapse: separate;
}
.km-android .km-group-title {
  margin-top: 0;
  background: transparent;
  padding: .8em 0 .5em;
  line-height: inherit;
  text-transform: uppercase;
}
.km-android .km-scroll-header .km-group-title {
  margin: 0 1em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.km-android .km-listview-link:after,
.km-android .km-listview-label:after {
  display: none;
}
.km-android .km-list .km-item {
  height: 56px;
}
.km-android .km-listview .km-switch {
  margin-top: -0.8em;
}
/* Filter box */
.km-android .km-listview-wrapper form .km-filter-wrap > input {
  font-size: 1.2em;
  padding-left: 1.8em;
  padding-right: 1.8em;
}
.km-android .km-filter-wrap:before {
  margin: 0 -1.3em 0 0.3em;
}
.km-android .km-filter-wrap:before,
.km-android .km-filter-reset .km-clear {
  font-size: 1.4em;
}
.km-android .km-filter-reset .km-clear:after {
  content: "\e038";
}
/* Pull To Refresh */
.km-root .km-android .km-load-more,
.km-android .km-scroller-pull {
  font-size: 1.2em;
}
/* Load more */
.km-android .km-load-more .km-icon,
.km-android .km-scroller-pull .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1.7em;
}
.km-android .km-scroller-pull .km-template {
  min-width: 0;
}
.km-android .km-item label:before {
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .km-listview-item-badge.km-badge,
.km-android .km-listview-link > .km-badge {
  min-width: 2.1em;
}
/* ScrollView */
.km-android .km-pages {
  display: table;
  width: 100%;
  height: 3px;
}
.km-android .km-pages li {
  display: table-cell;
  height: 2px;
  background: transparent;
}
.km-android .km-pages .km-current-page {
  opacity: .6;
}
/* Slider */
.km-android .k-slider-horizontal {
  margin-top: -0.5em;
  height: .1em;
}
.km-android .km-list .km-full-width-slider .k-slider-track {
  border-width: 0;
}
.km-android .k-slider .k-draghandle,
.km-android .k-slider .k-draghandle:hover {
  width: .4em;
  height: .4em;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 5em;
  border: 0;
}
.km-android .k-slider-horizontal .k-draghandle {
  top: -3px;
}
.km-android .k-slider-vertical .k-draghandle {
  left: -0.5em;
}
.km-android .k-slider-horizontal .k-slider-selection {
  margin-top: -1px;
  height: .2em;
}
.km-android .k-slider-vertical .k-slider-selection {
  margin-left: -0.1em;
  width: .2em;
}
.km-android .k-slider-horizontal .k-slider-track {
  height: .1em;
}
.km-android .k-slider-vertical .k-slider-track {
  width: .1em;
}
.km-android .km-list .km-full-width-slider .k-slider-track,
.km-android .km-list .km-full-width-slider .k-slider-selection {
  height: .15rem;
}
.km-android .k-slider-selection {
  margin-left: 0;
}
/* Forms */
.km-android input[type=password],
.km-android input[type=search],
.km-android input[type=number],
.km-android input[type=tel],
.km-android input[type=url],
.km-android input[type=email],
.km-android input[type=month],
.km-android input[type=color],
.km-android input[type=week],
.km-android input[type=date],
.km-android input[type=time],
.km-android input[type=datetime],
.km-android input[type=datetime-local],
.km-android input[type=text]:not(.k-input),
.km-android select:not([multiple]),
.km-android .k-dropdown,
.km-android textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: .4em;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-android .km-list input[type=password],
.km-android .km-list input[type=search],
.km-android .km-list input[type=number],
.km-android .km-list input[type=tel],
.km-android .km-list input[type=url],
.km-android .km-list input[type=email],
.km-android .km-list input[type=month],
.km-android .km-list input[type=color],
.km-android .km-list input[type=week],
.km-android .km-list input[type=date],
.km-android .km-list input[type=time],
.km-android .km-list input[type=datetime],
.km-android .km-list input[type=datetime-local],
.km-android .km-list input[type=text]:not(.k-input),
.km-android .km-list select:not([multiple]),
.km-android .km-list .k-dropdown {
  margin-top: -1.05em;
  font-size: 1em;
}
.km-native-scrolling .km-android .km-list input[type=password],
.km-native-scrolling .km-android .km-list input[type=search],
.km-native-scrolling .km-android .km-list input[type=number],
.km-native-scrolling .km-android .km-list input[type=tel],
.km-native-scrolling .km-android .km-list input[type=url],
.km-native-scrolling .km-android .km-list input[type=email],
.km-native-scrolling .km-android .km-list input[type=month],
.km-native-scrolling .km-android .km-list input[type=color],
.km-native-scrolling .km-android .km-list input[type=week],
.km-native-scrolling .km-android .km-list input[type=date],
.km-native-scrolling .km-android .km-list input[type=time],
.km-native-scrolling .km-android .km-list input[type=datetime],
.km-native-scrolling .km-android .km-list input[type=datetime-local],
.km-native-scrolling .km-android .km-list input[type=text]:not(.k-input),
.km-native-scrolling .km-android .km-list select:not([multiple]),
.km-native-scrolling .km-android .km-list textarea {
  position: static;
  float: right;
  margin-top: 0;
  margin-bottom: -0.2em;
}
.km-android input[type=file] {
  padding: .25em;
  outline: none;
}
.km-android .km-list textarea {
  font-size: 1em;
}
.km-native-scrolling .km-android .km-list textarea {
  width: 50%;
  margin: 0;
}
.km-android .k-dropdown {
  padding: 0;
  min-width: 8em;
}
.km-root .km-android .k-dropdown {
  margin-top: -1.2em;
}
.km-native-scrolling .km-android .km-list .k-dropdown {
  width: 48%;
  width: -webkit-calc(50% - .7em);
  width: calc(50% - .7em);
  margin-right: .7em;
}
.km-android .k-dropdown .k-dropdown-wrap {
  width: 100%;
  padding: .1em .3em;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-android .k-dropdown .k-input {
  border-radius: 0;
}
.km-android input[type=password],
.km-android input[type=search],
.km-android input[type=number],
.km-android input[type=tel],
.km-android input[type=url],
.km-android input[type=email],
.km-android input[type=text]:not(.k-input),
.km-root .km-android textarea {
  outline-width: 4px;
  outline-style: solid;
  outline-offset: -5px;
  border-width: 0 0 5px;
  border-style: solid;
}
.km-android input[type=password]:focus,
.km-android input[type=search]:focus,
.km-android input[type=number]:focus,
.km-android input[type=tel]:focus,
.km-android input[type=url]:focus,
.km-android input[type=email]:focus,
.km-android input[type=text]:not(.k-input):focus,
.km-android textarea:focus {
  outline-offset: -6px;
}
.km-android input[type=month],
.km-android input[type=color],
.km-android input[type=week],
.km-android input[type=date],
.km-android input[type=time],
.km-android input[type=datetime],
.km-android input[type=datetime-local],
.km-android select:not([multiple]),
.km-android .k-dropdown .k-dropdown-wrap {
  border-width: 0 0 1px;
  border-style: solid;
  margin-bottom: 1px;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
.km-android .k-slider .k-draghandle,
.km-android .k-slider .k-draghandle:hover {
  margin-top: -0.5em;
  padding: .5em;
  background-clip: content-box;
  -webkit-background-clip: content-box;
}
.km-android .k-slider .k-draghandle:before {
  content: "\a0";
  display: block;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 100%;
  height: 100%;
  opacity: .3;
  border-radius: 5em;
  padding: .5em;
  margin-bottom: .5em;
}
.km-android .k-slider .k-draghandle:active:before {
  opacity: .4;
}
.km-android .km-popup .k-state-hover {
  opacity: .6;
}
/* Checkboxes and Radios */
.km-android .km-listview-label input[type=radio],
.km-android .km-listview-label input[type=checkbox] {
  border-width: 1px;
  border-style: solid;
  width: 1em;
  height: 1em;
  font-size: 1.2em;
}
.km-android .km-listview-label input[type=radio],
.km-android .km-listview-label input[type=radio]:after,
.km-android .km-state-active input[type=radio]:before {
  border-radius: 1em;
}
.km-android .km-listview-label input[type=radio]:after,
.km-android .km-listview-label input[type=checkbox]:after,
.km-android .km-state-active input[type=radio]:before,
.km-android .km-state-active input[type=checkbox]:before {
  content: "\a0";
  display: block;
  width: 78%;
  height: 78%;
}
.km-android .km-listview-label input[type=radio]:after {
  margin: 11% 0 0 11%;
}
.km-android .km-listview-label input[type=checkbox]:after {
  margin: -20% 0 0 20%;
  width: 120%;
  height: 120%;
  -webkit-transform: scale(1, 1.2);
      -ms-transform: scale(1, 1.2);
          transform: scale(1, 1.2);
}
.km-android .km-state-active input[type=radio]:before,
.km-android .km-state-active input[type=checkbox]:before {
  position: absolute;
  margin: -45% 0 0 -45%;
  width: 190%;
  height: 190%;
}
.km-android .km-state-active input[type=radio],
.km-android .km-state-active input[type=checkbox] {
  border-width: 1px;
  border-style: solid;
}
.km-android .km-listview-label input[type=radio]:checked:after {
  content: "\a0";
  -webkit-box-shadow: 0 0 3px currentcolor;
          box-shadow: 0 0 3px currentcolor;
}
/* Nova theme features */
.km-android .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-android .km-label-above {
  font-size: 0.87em;
  margin-top: -2px;
  padding-top: .42em;
  padding-bottom: 2em;
  margin-bottom: .13em;
}
.km-android fieldset .km-label-above {
  margin-top: 0;
  padding-top: 1.455em;
  padding-bottom: 3.091em;
}
.km-android .km-label-above textarea {
  top: 50%;
}
.km-android .km-list .km-label-above input[type=password],
.km-android .km-list .km-label-above input[type=search],
.km-android .km-list .km-label-above input[type=number],
.km-android .km-list .km-label-above input[type=tel],
.km-android .km-list .km-label-above input[type=url],
.km-android .km-list .km-label-above input[type=email],
.km-android .km-list .km-label-above input[type=month],
.km-android .km-list .km-label-above input[type=color],
.km-android .km-list .km-label-above input[type=week],
.km-android .km-list .km-label-above input[type=date],
.km-android .km-list .km-label-above input[type=time],
.km-android .km-list .km-label-above input[type=datetime],
.km-android .km-list .km-label-above input[type=datetime-local],
.km-android .km-list .km-label-above input[type=text]:not(.k-input),
.km-android .km-list .km-label-above select:not([multiple]),
.km-android .km-list .km-label-above .k-dropdown-wrap,
.km-android .km-list .km-label-above textarea {
  padding-left: .7em;
  margin-top: -0.375em;
  border: none;
}
.km-android .km-list fieldset .km-label-above input[type=password],
.km-android .km-list fieldset .km-label-above input[type=search],
.km-android .km-list fieldset .km-label-above input[type=number],
.km-android .km-list fieldset .km-label-above input[type=tel],
.km-android .km-list fieldset .km-label-above input[type=url],
.km-android .km-list fieldset .km-label-above input[type=email],
.km-android .km-list fieldset .km-label-above input[type=month],
.km-android .km-list fieldset .km-label-above input[type=color],
.km-android .km-list fieldset .km-label-above input[type=week],
.km-android .km-list fieldset .km-label-above input[type=date],
.km-android .km-list fieldset .km-label-above input[type=time],
.km-android .km-list fieldset .km-label-above input[type=datetime],
.km-android .km-list fieldset .km-label-above input[type=datetime-local],
.km-android .km-list fieldset .km-label-above input[type=text]:not(.k-input),
.km-android .km-list fieldset .km-label-above select:not([multiple]),
.km-android .km-list fieldset .km-label-above .k-dropdown-wrap,
.km-android .km-list fieldset .km-label-above textarea {
  margin-top: -0.25em;
}
.km-android .km-list fieldset {
  border: none;
  padding: 0 1.61em;
  margin: -0.5em -1.61em;
}
.km-android .km-list fieldset > input[type=password],
.km-android .km-list fieldset > input[type=search],
.km-android .km-list fieldset > input[type=number],
.km-android .km-list fieldset > input[type=tel],
.km-android .km-list fieldset > input[type=url],
.km-android .km-list fieldset > input[type=email],
.km-android .km-list fieldset > input[type=month],
.km-android .km-list fieldset > input[type=color],
.km-android .km-list fieldset > input[type=week],
.km-android .km-list fieldset > input[type=date],
.km-android .km-list fieldset > input[type=time],
.km-android .km-list fieldset > input[type=datetime],
.km-android .km-list fieldset > input[type=datetime-local],
.km-android .km-list fieldset > input[type=text]:not(.k-input),
.km-android .km-list fieldset > select:not([multiple]),
.km-android .km-list fieldset > .k-dropdown-wrap,
.km-android .km-list fieldset > textarea {
  padding: 1.23em 0 1.23em 0;
  margin: 2px 0;
  border: none;
  outline: none;
}
.km-android .km-list fieldset legend {
  text-indent: 0;
  margin: 0 -1.61em;
  font-weight: normal;
  padding: 1.23em 1.61em .11em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  text-transform: uppercase;
}
.km-android .km-legend-button {
  text-transform: uppercase;
}
.km-android .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1.61em;
}
.km-android .km-list fieldset .km-inline-field:first-of-type {
  padding-left: 0;
}
.km-android .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-android .km-list .km-inline-field input[type=password],
.km-android .km-list .km-inline-field input[type=search],
.km-android .km-list .km-inline-field input[type=number],
.km-android .km-list .km-inline-field input[type=tel],
.km-android .km-list .km-inline-field input[type=url],
.km-android .km-list .km-inline-field input[type=email],
.km-android .km-list .km-inline-field input[type=month],
.km-android .km-list .km-inline-field input[type=color],
.km-android .km-list .km-inline-field input[type=week],
.km-android .km-list .km-inline-field input[type=date],
.km-android .km-list .km-inline-field input[type=time],
.km-android .km-list .km-inline-field input[type=datetime],
.km-android .km-list .km-inline-field input[type=datetime-local],
.km-android .km-list .km-inline-field input[type=text]:not(.k-input),
.km-android .km-list .km-inline-field select:not([multiple]),
.km-android .km-list .km-inline-field .k-dropdown-wrap,
.km-android .km-list .km-inline-field textarea {
  left: 0;
  padding-left: 0;
  padding-left: 1em;
}
.km-android .km-list .km-inline-field:first-of-type input[type=password],
.km-android .km-list .km-inline-field:first-of-type input[type=search],
.km-android .km-list .km-inline-field:first-of-type input[type=number],
.km-android .km-list .km-inline-field:first-of-type input[type=tel],
.km-android .km-list .km-inline-field:first-of-type input[type=url],
.km-android .km-list .km-inline-field:first-of-type input[type=email],
.km-android .km-list .km-inline-field:first-of-type input[type=month],
.km-android .km-list .km-inline-field:first-of-type input[type=color],
.km-android .km-list .km-inline-field:first-of-type input[type=week],
.km-android .km-list .km-inline-field:first-of-type input[type=date],
.km-android .km-list .km-inline-field:first-of-type input[type=time],
.km-android .km-list .km-inline-field:first-of-type input[type=datetime],
.km-android .km-list .km-inline-field:first-of-type input[type=datetime-local],
.km-android .km-list .km-inline-field:first-of-type input[type=text]:not(.k-input),
.km-android .km-list .km-inline-field:first-of-type select:not([multiple]),
.km-android .km-list .km-inline-field:first-of-type .k-dropdown-wrap,
.km-android .km-list .km-inline-field:first-of-type textarea {
  margin-left: 0;
  padding-left: 0;
}
.km-android .km-list .km-full-width-slider {
  margin-top: 0;
  padding: 1.55em 0;
}
/* Standalone Checkboxes and RadioButtons */
.km-android .km-checkbox-label,
.km-android .km-radio-label {
  font-size: 1em;
  line-height: 1.5em;
  margin-left: .89em;
}
.km-android .km-checkbox-label:before,
.km-android .km-checkbox-label:after {
  right: .7em;
  width: 1.07rem;
  height: 1.07rem;
  text-align: right;
  font-size: 1.5em;
}
.km-android .km-checkbox-label:before {
  border-width: 0.133rem;
  border-style: solid;
}
.km-android .km-radio-label {
  padding-left: 2.5em;
  line-height: 1.5em;
}
.km-android .km-radio-label:before {
  width: 1.07rem;
  height: 1.07rem;
  border-width: 0.133rem;
  border-style: solid;
}
.km-android input.km-radio:checked + .km-radio-label:after {
  border-width: 2px;
  border-style: solid;
  top: 45%;
}
.km-android input.km-radio:checked + .km-radio-label:after {
  width: .55rem;
  height: .55rem;
  left: 0.2rem;
}
/* ActionSheet */
.km-android .km-shim.km-actionsheet-root {
  left: 0 !important;
  width: 100%;
  height: 100% !important;
}
.km-android .km-actionsheet-wrapper {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.km-android .km-actionsheet > li:last-child > a {
  border: 0;
}
.km-android li.km-actionsheet-cancel > a {
  text-align: center;
  font-size: 1em;
}
.km-android li.km-actionsheet-title {
  font-weight: normal;
  line-height: normal;
  padding: .8em 1em;
  font-size: 1.2em;
  border-bottom-width: 2px;
  border-radius: 5px 5px 0 0;
}
/* PopOver */
.km-android .km-popup.km-pane .km-view {
  padding: 1px;
}
.km-android .km-popup.km-pane {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.km-android .km-popover-root .km-list:last-child {
  margin-bottom: 0;
}
.km-android .km-left .km-popup-arrow,
.km-android .km-right .km-popup-arrow {
  margin-top: 0;
}
.km-android .km-popup-arrow:after,
.km-android .km-popup-arrow:before {
  border-style: solid;
  border-width: 0 10px 10px;
}
.km-android .km-down .km-popup-arrow:after {
  margin-top: -1px;
}
.km-android .km-down .km-popup-arrow:before {
  margin-top: -2px;
}
.km-android .km-up .km-popup-arrow:before {
  margin-top: 1px;
}
.km-android .km-up .km-popup-arrow:after,
.km-android .km-up .km-popup-arrow:before {
  border-width: 10px 10px 0 10px;
}
.km-android .km-left .km-popup-arrow:after,
.km-android .km-left .km-popup-arrow:before {
  border-width: 10px 0 10px 10px;
}
.km-android .km-right .km-popup-arrow:after,
.km-android .km-right .km-popup-arrow:before {
  border-width: 10px 10px 10px 0;
}
/* ModalView */
.km-android .km-modalview-wrapper {
  padding: 10px;
}
.km-android .km-modalview {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
}
/* Scroller */
.km-android .km-touch-scrollbar {
  border-radius: 0;
}
.km-android .km-vertical-scrollbar {
  width: .3em;
}
.km-android .km-horizontal-scrollbar {
  height: .3em;
}
/* SplitView */
.km-android .km-popup.km-pane .km-view,
.km-android .km-scrollview .km-pane:last-child .km-content {
  border-width: 1px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-android .km-pane {
  z-index: 1;
}
.km-android .km-pane:first-child {
  z-index: 0;
}
.km-flat {
  font: normal 1em "HelveticaNeue Light", "Roboto Light", "Slate Light", "Segoe WP", NokiaPureTextLight, sans-serif;
}
/* override transform options for performance */
.km-root .km-flat.km-pane,
.km-root .km-flat .km-view,
.km-root .km-flat .km-slider,
.km-root .km-flat .km-switch,
.km-root .km-flat .km-group-title,
.km-root .km-flat .km-filter-reset,
.km-root .km-flat .km-shim .k-animation-container {
  -webkit-transform: none;
}
.km-flat,
.km-flat * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
/* Revert box/clip for Web widgets */
.km-flat [class^=k-] {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-flat .km-tabstrip .km-button {
  background: none;
}
/* PopUp */
.km-flat .km-popup .k-popup {
  font-size: 1em !important;
}
.km-flat .km-popup .k-item,
.km-flat .km-actionsheet > li > a {
  text-decoration: none;
  padding: .5em .6em;
  border-radius: 0;
  border-width: 0 0 1px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-flat .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-flat .km-popup .k-list-container {
  width: 100% !important;
  height: auto !important;
}
.km-flat .km-actionsheet,
.km-flat .km-popup .k-list-container {
  max-height: 80%;
}
.km-flat .km-actionsheet-wrapper,
.km-flat .km-popup .k-list-container {
  bottom: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-flat .km-shim .k-animation-container {
  left: 0;
  top: auto;
  bottom: 0;
}
.km-flat .km-popup-wrapper {
  padding: 15px;
}
.km-flat .km-popup.km-pane,
.km-flat .km-actionsheet-wrapper.km-popup {
  border-radius: 3px;
}
/* Loader & Pull-to-refresh */
.km-flat .km-load-more {
  height: 3.4em;
}
.km-flat .km-load-more .km-button {
  margin: 0 .8em;
  display: block;
}
.km-flat .km-loader:before,
.km-flat .km-scroller-refresh.km-load-more,
.km-flat .km-scroller-pull {
  border-radius: 20em;
  overflow: visible;
}
.km-flat .km-loader:before {
  content: "\a0";
  display: block;
  position: absolute;
  margin-top: -2em;
  margin-left: -2em;
  width: 4em;
  height: 4em;
  top: 50%;
  left: 50%;
  border-radius: 5em;
}
.km-flat .km-loader {
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.km-flat .km-scroller-refresh.km-load-more {
  padding: 0;
  position: relative;
  margin: auto;
}
.km-flat .km-scroller-refresh.km-load-more,
.km-flat .km-scroller-pull {
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  top: .25em;
  white-space: nowrap;
}
.km-flat .km-scroller-pull {
  left: 50%;
  margin: 0 0 0 -90px;
}
.km-flat .km-loader h1 {
  display: none;
  font-size: 1em;
  position: absolute;
  left: -50%;
  width: 200%;
  top: 55%;
}
.km-flat .km-scroller-pull .km-template {
  position: absolute;
  line-height: 2em;
  font-size: 1.2em;
  min-width: 0;
  top: 0;
  left: 3em;
}
.km-flat .km-loading,
.km-flat .km-loader .km-loading-left,
.km-flat .km-loader .km-loading-right,
.km-flat .km-load-more.km-scroller-refresh .km-icon,
.km-flat .km-scroller-pull.km-scroller-refresh .km-icon,
.km-flat .km-scroller-refresh .km-loading-left,
.km-flat .km-scroller-refresh .km-loading-right {
  font-size: 1em;
  display: block;
  width: .36em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.8em;
  margin-top: -0.5em;
  border-radius: 1em;
  -webkit-animation: km-flatload 0.6s infinite linear;
          animation: km-flatload 0.6s infinite linear;
  -webkit-background-clip: none;
}
.km-flat .km-scroller-pull .km-icon {
  margin-right: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}
.km-flat .km-load-more.km-scroller-refresh .km-icon,
.km-flat .km-scroller-pull.km-scroller-refresh .km-icon,
.km-flat .km-scroller-refresh .km-loading-left,
.km-flat .km-scroller-refresh .km-loading-right {
  height: .6em;
  margin-top: -0.3em;
  margin-left: -0.6em;
  width: .25em;
  -webkit-animation: km-flatrefresh 0.6s infinite linear;
          animation: km-flatrefresh 0.6s infinite linear;
}
.km-flat .km-scroller-pull .km-icon:after {
  content: "\e012";
  margin-left: -3px;
}
.km-flat .km-scroller-refresh .km-icon:after {
  display: none;
}
.km-root .km-flat .km-loading-left {
  margin-left: -0.2em;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.km-root .km-flat .km-loading-right {
  margin-left: .42em;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.km-flat .km-scroller-refresh .km-loading-left {
  margin-left: -0.18em;
}
.km-flat .km-scroller-refresh .km-loading-right {
  margin-left: .28em;
}
@keyframes km-flatload {
  0% {
    height: 1em;
    margin-top: -0.5em;
  }
  33% {
    height: 2em;
    margin-top: -1em;
  }
  66% {
    height: 1em;
    margin-top: -0.5em;
  }
}
@-webkit-keyframes km-flatload {
  0% {
    height: 1em;
    margin-top: -0.5em;
  }
  33% {
    height: 2em;
    margin-top: -1em;
  }
  66% {
    height: 1em;
    margin-top: -0.5em;
  }
}
@keyframes km-flatrefresh {
  0% {
    height: .6em;
    margin-top: -0.3em;
  }
  33% {
    height: 1.2em;
    margin-top: -0.6em;
  }
  66% {
    height: .6em;
    margin-top: -0.3em;
  }
}
@-webkit-keyframes km-flatrefresh {
  0% {
    height: .6em;
    margin-top: -0.3em;
  }
  33% {
    height: 1.2em;
    margin-top: -0.6em;
  }
  66% {
    height: .6em;
    margin-top: -0.3em;
  }
}
/* Color Template */
.km-flat,
.km-flat .km-navbar,
.km-flat .k-toolbar,
.km-flat .km-detail,
.km-flat .km-content {
  color: #4c5356;
  background-color: #f4f4f4;
}
/* Button */
.km-flat .km-button {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}
/* Badges and Details */
.km-flat .km-detail,
.km-flat .k-toolbar {
  border-color: rgba(0, 0, 0, 0.1);
}
/* Switch */
.km-flat .km-switch-wrapper {
  background-color: #bababa;
}
.km-flat .km-switch-background,
.km-flat .k-slider-selection {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#bababa), to(#bababa));
  background-image: -webkit-linear-gradient(top, #bababa, #bababa);
  background-image: linear-gradient(to bottom, #bababa, #bababa);
}
.km-flat .km-switch-handle {
  background: #f4f4f4;
  border-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
  box-shadow: 0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
}
.km-flat .km-switch-off .km-switch-handle {
  -webkit-box-shadow: 0 1px 0 1px #bababa, 0 -1px 0 1px #bababa;
  box-shadow: 0 1px 0 1px #bababa, 0 -1px 0 1px #bababa;
}
.km-flat .km-switch-container,
.km-flat .km-switch-wrapper {
  border-color: #f0f0f0;
}
/* Slider */
.km-flat .k-slider-track {
  background-color: #bababa;
}
/* ListView */
.km-flat .km-list > li {
  background: #fcfcfc;
  border-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}
.km-flat .km-listinset > li:first-child,
.km-flat .km-listgroupinset .km-list > li:first-child {
  border-color: rgba(0, 0, 0, 0.1);
}
.km-flat .km-listinset > li:last-child,
.km-flat .km-listgroupinset .km-list > li:last-child,
.km-flat .km-list fieldset .km-inline-field {
  border-color: rgba(0, 0, 0, 0.1);
}
.km-flat .km-listview-link:after {
  color: #97a0a4;
  border-color: currentcolor;
}
.km-flat .km-group-title,
.km-flat .km-list fieldset legend {
  background: #f4f4f4;
  border-color: rgba(0, 0, 0, 0.1);
}
.km-flat .km-filter-wrap:before,
.km-flat .km-filter-reset .km-clear {
  color: #4c5356;
}
.km-flat .km-filter-wrap > input {
  color: #4c5356;
  border-color: rgba(0, 0, 0, 0.1);
}
.km-flat .km-filter-wrap > input:focus {
  border-color: #000;
}
/* ScrollView */
.km-flat .km-pages li {
  background: rgba(0, 0, 0, 0.1);
}
/* Forms */
.km-flat .km-label-above {
  color: #bababa;
}
.km-flat .km-list input[type=password],
.km-flat .km-list input[type=search],
.km-flat .km-list input[type=number],
.km-flat .km-list input[type=tel],
.km-flat .km-list input[type=url],
.km-flat .km-list input[type=email],
.km-flat .km-list input[type=month],
.km-flat .km-list input[type=color],
.km-flat .km-list input[type=week],
.km-flat .km-list input[type=date],
.km-flat .km-list input[type=time],
.km-flat .km-list input[type=datetime],
.km-flat .km-list input[type=datetime-local],
.km-flat .km-list input[type=text]:not(.k-input),
.km-flat .km-list select:not([multiple]),
.km-flat .km-list .k-dropdown-wrap,
.km-flat .km-list textarea,
.km-flat .km-list .k-dropdown-wrap .k-input {
  color: #4c5356;
}
.km-flat .km-list select:not([multiple]) option {
  color: #333;
}
.km-flat .km-content .km-list .km-state-active input[type=password],
.km-flat .km-content .km-list .km-state-active input[type=search],
.km-flat .km-content .km-list .km-state-active input[type=number],
.km-flat .km-content .km-list .km-state-active input[type=tel],
.km-flat .km-content .km-list .km-state-active input[type=url],
.km-flat .km-content .km-list .km-state-active input[type=email],
.km-flat .km-content .km-list .km-state-active input[type=month],
.km-flat .km-content .km-list .km-state-active input[type=color],
.km-flat .km-content .km-list .km-state-active input[type=week],
.km-flat .km-content .km-list .km-state-active input[type=date],
.km-flat .km-content .km-list .km-state-active input[type=time],
.km-flat .km-content .km-list .km-state-active input[type=datetime],
.km-flat .km-content .km-list .km-state-active input[type=datetime-local],
.km-flat .km-content .km-list .km-state-active input[type=text]:not(.k-input),
.km-flat .km-content .km-list .km-state-active select:not([multiple]),
.km-flat .km-content .km-list .km-state-active textarea {
  color: #fff;
}
/* Checkboxes and Radios */
.km-flat .km-listview-label input[type=radio],
.km-flat .km-listview-label input[type=checkbox] {
  border-color: rgba(0, 0, 0, 0.1);
  background: #f4f4f4;
}
.km-flat .km-listview-label input[type=checkbox]:checked:after {
  color: #eee;
}
/* Shim */
.km-flat .km-shim,
.km-phone .km-flat .km-actionsheet-wrapper {
  background: rgba(0, 0, 0, 0.4);
}
/* PopUp */
.km-flat .km-popup .k-item,
.km-flat .km-actionsheet > li > a {
  background: #363940;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.km-flat .km-popup {
  background: rgba(0, 0, 0, 0.4);
}
.km-flat .km-actionsheet-wrapper,
.km-flat .km-popup .k-list-container {
  background: rgba(0, 0, 0, 0.4);
  border-top-color: rgba(255, 255, 255, 0.2);
}
.km-flat .km-popup.km-pane,
.km-tablet .km-flat .km-actionsheet-wrapper {
  background-color: #363940;
}
.km-flat .km-popup-arrow:after {
  border-color: #363940 transparent;
}
.km-flat .km-left .km-popup-arrow:after,
.km-flat .km-right .km-popup-arrow:after {
  border-color: transparent #363940;
}
/* Loader & Pull-to-refresh */
.km-flat .km-loader {
  background: rgba(0, 0, 0, 0.05);
}
.km-flat .km-loader h1 {
  color: #4c5356;
}
/* Collapsible */
.km-flat .km-collapsible-header,
.km-flat .km-collapsible-content {
  border-color: rgba(0, 0, 0, 0.1);
}
.km-flat .km-collapsible-header {
  background: #ededed;
}
/* Active States */
.km-flat .km-detail:active,
.km-flat .km-state-active .km-detail,
.km-flat .km-state-active[style*=background] {
  -webkit-box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
/* Active States */
.km-flat .km-badge,
.km-flat .km-rowinsert,
.km-flat .km-rowdelete,
.km-flat .km-state-active,
.km-flat .km-switch-label-on,
.km-flat .km-switch-label-off,
.km-flat .km-tabstrip .km-button,
.km-flat .km-popup .k-item,
.km-flat .km-actionsheet > li > a,
.km-flat .km-tabstrip .km-state-active,
.km-flat .k-slider .k-draghandle,
.km-flat .k-slider .k-draghandle:hover,
.km-flat .km-scroller-pull .km-icon,
.km-flat .km-popup.km-pane .km-navbar,
.km-flat .km-popup.km-pane .k-toolbar,
.km-flat .km-popup.km-pane .km-tabstrip,
.km-flat .km-popup .k-state-hover,
.km-flat .km-popup .k-state-focused,
.km-flat .km-popup .k-state-selected,
.km-flat .km-actionsheet > li > a:active,
.km-flat .km-actionsheet > li > a:hover,
.km-flat .km-actionsheet > li > .km-state-active,
.km-flat li.km-state-active .km-listview-link,
.km-flat li.km-state-active .km-listview-label,
.km-flat .km-state-active .km-listview-link:after {
  color: #fff;
}
.km-flat .km-loader > *:not(h1),
.km-flat .km-filter-wrap > input,
.km-flat .km-switch-handle.km-state-active,
.km-root .km-flat .km-scroller-refresh span:not(.km-template) {
  background: #fff;
}
.km-flat .km-switch-handle,
.km-flat .k-slider-selection,
.km-flat .km-switch-background,
.km-flat .km-list fieldset legend {
  color: #10c4b2;
}
.km-flat .km-rowinsert,
.km-flat .km-state-active,
.km-flat .km-scroller-pull,
.km-flat .km-loader:before,
.km-flat .k-slider-selection,
.km-flat .km-touch-scrollbar,
.km-flat .km-pages .km-current-page,
.km-flat .k-slider .k-draghandle,
.km-flat .k-slider .k-draghandle:hover,
.km-flat .km-tabstrip .km-state-active,
.km-flat .km-scroller-refresh.km-load-more,
.km-flat .km-popup .k-state-hover,
.km-flat .km-popup .k-state-focused,
.km-flat .km-popup .k-state-selected,
.km-flat .km-actionsheet > li > a:active,
.km-flat .km-actionsheet > li > a:hover,
.km-flat .km-actionsheet > li > .km-state-active,
.km-flat li.km-state-active .km-listview-link,
.km-flat li.km-state-active .km-listview-label,
.km-flat .km-listview-label input[type=radio]:checked,
.km-flat .km-listview-label input[type=checkbox]:checked {
  background: #10c4b2;
}
.km-flat .km-filter-wrap > input:focus {
  border-color: #10c4b2;
}
.km-flat .km-badge,
.km-flat .km-rowdelete {
  background: #da2228;
}
.km-flat label.km-required:after {
  color: #da2228;
}
.km-flat .km-checkbox-label:before,
.km-flat .km-checkbox-label:after {
  color: #10c4b2;
  border-color: #10c4b2;
}
.km-flat .km-radio-label:before {
  border-color: #10c4b2;
}
.km-flat .km-radio-label:after {
  background-color: #10c4b2;
}
.km-flat .km-list fieldset legend {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
/* Validation */
.km-flat .km-list > li label.km-invalid,
.km-flat .km-list fieldset .km-legend-button.km-invalid,
.km-flat .km-list > li label.km-invalid.km-required:after {
  color: #f52312;
}
.km-flat .km-invalid-msg {
  color: #fff;
  background: #f52312;
}
/* Tablet Styles */
.km-tablet .km-flat.km-horizontal .km-navbar .km-button,
.km-tablet .km-flat.km-horizontal .km-toolbar .km-button {
  margin-top: .2rem;
  margin-bottom: .2rem;
}
/* Button */
.km-flat .km-button {
  padding: .5em .8em;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}
/* Badges and Details */
.km-flat .km-badge,
.km-flat .km-detail {
  border: 0;
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 5em;
}
.km-flat .km-detail {
  min-width: 1.4em;
  height: 1.4em;
  line-height: 1.4em;
  border-width: 1px;
  border-style: solid;
}
.km-flat .km-detaildisclose {
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
}
.km-flat .km-detaildisclose:after,
.km-flat .km-detaildisclose:before {
  left: .1em;
  top: 0.35em;
}
.km-flat .km-detail .km-icon:before {
  display: none;
}
/* ButtonGroup */
.km-flat .km-buttongroup > *:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.km-flat .km-buttongroup > *:first-child {
  border-radius: 3px 0 0 3px;
}
.km-flat .km-buttongroup > *:last-child {
  border-radius: 0 3px 3px 0;
}
.km-flat .km-buttongroup > *.km-state-active {
  border-right-width: 1px;
}
.km-flat .km-buttongroup > *.km-state-active + * {
  border-left-width: 0;
}
/* NavBar */
.km-flat .km-navbar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.km-flat .km-header .km-navbar {
  border-width: 0 0 1px;
}
.km-flat .km-footer .km-navbar {
  border-width: 1px 0 0;
}
.km-flat .km-toolbar,
.km-flat .km-navbar,
.km-flat .km-tabstrip,
.km-flat .km-tabstrip .km-button {
  border-radius: 0;
}
/* ToolBar */
.km-flat .k-toolbar {
  line-height: 2.1em;
}
.km-flat .k-toolbar .km-button,
.km-flat .k-toolbar .k-split-button {
  padding-top: 0;
  padding-bottom: 0;
  line-height: inherit;
}
.km-flat .k-toolbar .k-button-icon .km-icon,
.km-flat .k-toolbar .k-button-icontext .km-icon,
.km-flat .k-split-button .km-arrowdown {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
  margin-top: -7px;
  margin-bottom: -2px;
  vertical-align: middle;
}
.km-flat .k-split-button .km-arrowdown {
  margin-left: 2px;
  margin-right: 1px;
}
.km-flat .k-split-button .k-button-icontext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.km-flat .k-split-button-arrow {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.km-flat .km-actionsheet.k-split-container > li > .km-button,
.km-flat .km-actionsheet.k-overflow-container > li > .km-button {
  min-width: 10em;
  text-align: center;
  font-size: 1.1em;
  border: 0;
  border-radius: 0;
}
.km-flat .km-actionsheet.k-split-container > li > .km-button {
  min-width: 5em;
}
.km-flat .k-split-wrapper .km-actionsheet-wrapper,
.km-flat .k-overflow-wrapper .km-actionsheet-wrapper {
  padding: 1px;
}
html .km-flat .k-split-container.km-actionsheet > li,
html .km-flat .k-overflow-container.km-actionsheet > li {
  margin-top: 0;
  margin-bottom: 0;
}
.km-flat .k-split-container.km-actionsheet,
.km-flat .k-overflow-container.km-actionsheet {
  border-top: 0;
}
/* TabStrip */
.km-flat .km-tabstrip {
  padding: 0;
  display: table;
  table-layout: fixed;
}
.km-flat .km-tabstrip .km-button {
  font-size: .7em;
  display: table-cell;
  border: 0;
}
.km-flat .km-tabstrip .km-icon:before {
  display: none;
}
/* Switch */
.km-flat .km-switch {
  width: 4.6rem;
  height: 1.9rem;
  line-height: 1.76rem;
  overflow: hidden;
}
.km-flat .km-switch-wrapper {
  overflow: hidden;
}
.km-flat .km-switch-background,
.km-flat .k-slider-selection {
  background-position: 4.25em 0;
  background-repeat: no-repeat;
  background-color: currentcolor;
  margin-left: -3.4rem;
}
.km-flat .km-switch-container {
  padding: 1px 0 1px 1px;
  border-width: 0;
}
.km-flat .km-switch-handle {
  width: 1.6em;
  margin: 0 4px 0 0;
  border-width: 1px;
  border-style: solid;
}
.km-flat .km-switch-label-off {
  left: 1.5em;
}
.km-flat .km-switch-label-on {
  left: -2.8em;
}
.km-flat .km-switch-label-on,
.km-flat .km-switch-label-off {
  text-shadow: none;
  width: 185%;
  font-size: 1em;
  line-height: 1.5em;
  vertical-align: middle;
}
.km-flat .km-switch-wrapper,
.km-flat .km-switch-container,
.km-flat .km-switch-background {
  border-radius: 4px;
}
.km-flat .km-switch-handle {
  border-radius: 3px;
}
.km-flat .km-switch-container,
.km-flat .km-switch-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
/* ListView */
.km-flat .km-list > li {
  border-style: solid;
  border-width: 0 0 1px 0;
}
.km-flat .km-listinset > li:first-child,
.km-flat .km-listgroupinset .km-list > li:first-child {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px 3px 0 0;
}
.km-flat .km-listinset > li,
.km-flat .km-listgroupinset .km-list > li {
  border-width: 0 1px 1px 1px;
}
.km-flat .km-listinset > li:last-child,
.km-flat .km-listgroupinset .km-list > li:last-child {
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 3px 3px;
}
.km-flat .km-listinset > li:first-child:last-child,
.km-flat .km-listgroupinset .km-list > li:first-child:last-child {
  border-width: 1px;
  border-radius: 3px;
}
.km-flat .km-listview-link:after {
  border-width: .2rem .2rem 0 0;
}
.km-flat:not(.km-on-android) .km-listview-link:after {
  width: .66rem;
  height: .64rem;
  border-width: 0;
  -webkit-box-shadow: inset -0.2rem 0.2rem 0;
          box-shadow: inset -0.2rem 0.2rem 0;
}
.km-flat .km-listinset li:first-child > .km-listview-link,
.km-flat .km-listgroupinset li:first-child > .km-listview-link,
.km-flat .km-listinset li:first-child > .km-listview-label,
.km-flat .km-listgroupinset li:first-child > .km-listview-label {
  border-radius: 2px 2px 0 0;
}
.km-flat .km-listinset li:last-child > .km-listview-link,
.km-flat .km-listgroupinset li:last-child > .km-listview-link,
.km-flat .km-listinset li:last-child > .km-listview-label,
.km-flat .km-listgroupinset li:last-child > .km-listview-label {
  border-radius: 0 0 2px 2px;
}
.km-flat .km-listinset li:first-child:last-child > .km-listview-link,
.km-flat .km-listgroupinset li:first-child:last-child > .km-listview-link,
.km-flat .km-listinset li:first-child:last-child > .km-listview-label,
.km-flat .km-listgroupinset li:first-child:last-child > .km-listview-label {
  border-radius: 2px;
}
.km-flat .km-group-title {
  border-style: solid;
  border-width: 1px 0;
}
.km-flat .km-scroll-header .km-group-title {
  border-width: 0 0 1px;
}
.km-flat .km-listgroupinset .km-group-title {
  border: 0;
  background: none;
}
.km-flat .km-listview .km-switch {
  margin-top: -0.9rem;
}
/* Filter box */
.km-flat .km-listview-wrapper form .km-filter-wrap > input {
  font-size: 1.2em;
  padding: .3em 1.8em;
}
.km-flat .km-filter-wrap:before {
  margin: 0.05em -1.3em 0 0.3em;
}
.km-flat .km-filter-reset {
  margin: 0.05em 0 0 -2em;
}
.km-flat .km-filter-reset .km-clear:after {
  content: "\e038";
}
.km-flat .km-filter-wrap > input {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
}
.km-flat .km-filter-wrap > input:focus {
  border-width: 1px;
  border-style: solid;
}
/* ScrollView */
.km-flat .km-pages {
  padding-top: .4em;
}
.km-flat .km-pages li {
  border-radius: 1em;
}
/* Slider */
.km-flat .k-slider .k-draghandle,
.km-flat .k-slider .k-draghandle:hover {
  border: 0;
  border-radius: 5em;
}
.km-flat .k-slider .k-draghandle {
  top: -7px;
}
.km-flat .k-slider-track {
  margin: -0.5em 0.5em 0 0;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  border-radius: .5em;
  background-color: #bababa;
}
.km-flat .k-slider-horizontal .k-slider-track {
  height: .29rem;
}
.km-flat .k-slider-vertical .k-slider-track {
  width: .29rem;
}
.km-flat .k-slider-selection {
  margin-left: 0;
}
/* Forms */
.km-flat .km-list input[type=password],
.km-flat .km-list input[type=search],
.km-flat .km-list input[type=number],
.km-flat .km-list input[type=tel],
.km-flat .km-list input[type=url],
.km-flat .km-list input[type=email],
.km-flat .km-list input[type=month],
.km-flat .km-list input[type=color],
.km-flat .km-list input[type=week],
.km-flat .km-list input[type=date],
.km-flat .km-list input[type=time],
.km-flat .km-list input[type=datetime],
.km-flat .km-list input[type=datetime-local],
.km-flat .km-list input[type=text]:not(.k-input),
.km-flat .km-list select:not([multiple]),
.km-flat .km-list .k-dropdown-wrap,
.km-flat .km-list textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.1rem;
  min-width: 6em;
  border: 0;
  padding: .4em 0;
  outline: none;
  background: transparent;
}
.km-flat .km-list .k-dropdown-wrap {
  padding: .2em;
}
.km-flat .km-list .k-dropdown {
  margin-top: -1.05em;
  font-weight: normal;
}
.km-flat .km-list input[type=color],
.km-flat .km-list input[type=week],
.km-flat .km-list input[type=date],
.km-flat .km-list input[type=time],
.km-flat .km-list input[type=month],
.km-flat .km-list input[type=datetime],
.km-flat .km-list input[type=datetime-local],
.km-flat .km-list .k-dropdown {
  text-align: left;
}
.km-flat .km-list .k-dropdown .k-dropdown-wrap {
  display: block;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* Checkboxes and Radios */
.km-flat .km-listview-label input[type=checkbox] {
  margin-top: -0.7em;
}
.km-flat .km-listview-label input[type=radio],
.km-flat .km-listview-label input[type=checkbox] {
  border-width: 1px;
  border-style: solid;
  width: 1.4em;
  height: 1.4em;
  border-radius: 3px;
}
.km-flat .km-listview-label input[type=radio] {
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
}
.km-flat .km-listview-label input[type=checkbox]:after {
  content: "\a0";
  display: block;
  width: 90%;
  height: 76%;
  -webkit-transform: scale(0.9, 1);
      -ms-transform: scale(0.9, 1);
          transform: scale(0.9, 1);
  -webkit-transform-origin: 10% 50%;
      -ms-transform-origin: 10% 50%;
          transform-origin: 10% 50%;
}
.km-flat .km-listview-label input[type=checkbox]:checked:after {
  font-size: 1.4em;
}
.km-flat .km-listview-label input[type=radio]:after {
  color: transparent;
}
/* Nova theme features */
.km-flat .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-flat .km-label-above {
  font-size: 0.87em;
  margin-top: -2px;
  padding-top: .92em;
  padding-bottom: 2.5em;
}
.km-flat fieldset .km-label-above {
  margin-top: 0;
  padding-top: 1.455em;
  padding-bottom: 3.091em;
}
.km-flat .km-label-above textarea {
  top: 50%;
}
.km-flat .km-list .km-label-above input[type=password],
.km-flat .km-list .km-label-above input[type=search],
.km-flat .km-list .km-label-above input[type=number],
.km-flat .km-list .km-label-above input[type=tel],
.km-flat .km-list .km-label-above input[type=url],
.km-flat .km-list .km-label-above input[type=email],
.km-flat .km-list .km-label-above input[type=month],
.km-flat .km-list .km-label-above input[type=color],
.km-flat .km-list .km-label-above input[type=week],
.km-flat .km-list .km-label-above input[type=date],
.km-flat .km-list .km-label-above input[type=time],
.km-flat .km-list .km-label-above input[type=datetime],
.km-flat .km-list .km-label-above input[type=datetime-local],
.km-flat .km-list .km-label-above input[type=text]:not(.k-input),
.km-flat .km-list .km-label-above select:not([multiple]),
.km-flat .km-list .km-label-above .k-dropdown-wrap,
.km-flat .km-list .km-label-above textarea {
  left: .7em;
  margin-top: -0.375em;
}
.km-flat .km-list fieldset .km-label-above input[type=password],
.km-flat .km-list fieldset .km-label-above input[type=search],
.km-flat .km-list fieldset .km-label-above input[type=number],
.km-flat .km-list fieldset .km-label-above input[type=tel],
.km-flat .km-list fieldset .km-label-above input[type=url],
.km-flat .km-list fieldset .km-label-above input[type=email],
.km-flat .km-list fieldset .km-label-above input[type=month],
.km-flat .km-list fieldset .km-label-above input[type=color],
.km-flat .km-list fieldset .km-label-above input[type=week],
.km-flat .km-list fieldset .km-label-above input[type=date],
.km-flat .km-list fieldset .km-label-above input[type=time],
.km-flat .km-list fieldset .km-label-above input[type=datetime],
.km-flat .km-list fieldset .km-label-above input[type=datetime-local],
.km-flat .km-list fieldset .km-label-above input[type=text]:not(.k-input),
.km-flat .km-list fieldset .km-label-above select:not([multiple]),
.km-flat .km-list fieldset .km-label-above .k-dropdown-wrap,
.km-flat .km-list fieldset .km-label-above textarea {
  margin-top: -0.25em;
}
.km-flat .km-list fieldset {
  border: none;
  padding: 0 1.61em;
  margin: -0.5em -1.61em;
}
.km-flat .km-list fieldset > input[type=password],
.km-flat .km-list fieldset > input[type=search],
.km-flat .km-list fieldset > input[type=number],
.km-flat .km-list fieldset > input[type=tel],
.km-flat .km-list fieldset > input[type=url],
.km-flat .km-list fieldset > input[type=email],
.km-flat .km-list fieldset > input[type=month],
.km-flat .km-list fieldset > input[type=color],
.km-flat .km-list fieldset > input[type=week],
.km-flat .km-list fieldset > input[type=date],
.km-flat .km-list fieldset > input[type=time],
.km-flat .km-list fieldset > input[type=datetime],
.km-flat .km-list fieldset > input[type=datetime-local],
.km-flat .km-list fieldset > input[type=text]:not(.k-input),
.km-flat .km-list fieldset > select:not([multiple]),
.km-flat .km-list fieldset > .k-dropdown-wrap {
  padding: 1.23em 0;
  margin-top: 0;
}
.km-flat .km-list fieldset legend {
  text-indent: 0;
  margin: 0 -1.61em;
  font-weight: normal;
  padding: 1.8em 1.61em .72em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  text-transform: uppercase;
}
.km-flat .km-legend-button {
  padding: 2.5em 1.61em .39em;
  margin: -2.5em -1.61em -0.39em;
  text-transform: uppercase;
}
.km-flat .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1.61em;
  border-right-width: 1px;
  border-right-style: solid;
}
.km-flat .km-list fieldset .km-inline-field:first-of-type {
  padding-left: 0;
}
.km-flat .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-flat .km-list .km-inline-field input[type=password],
.km-flat .km-list .km-inline-field input[type=search],
.km-flat .km-list .km-inline-field input[type=number],
.km-flat .km-list .km-inline-field input[type=tel],
.km-flat .km-list .km-inline-field input[type=url],
.km-flat .km-list .km-inline-field input[type=email],
.km-flat .km-list .km-inline-field input[type=month],
.km-flat .km-list .km-inline-field input[type=color],
.km-flat .km-list .km-inline-field input[type=week],
.km-flat .km-list .km-inline-field input[type=date],
.km-flat .km-list .km-inline-field input[type=time],
.km-flat .km-list .km-inline-field input[type=datetime],
.km-flat .km-list .km-inline-field input[type=datetime-local],
.km-flat .km-list .km-inline-field input[type=text]:not(.k-input),
.km-flat .km-list .km-inline-field select:not([multiple]),
.km-flat .km-list .km-inline-field .k-dropdown-wrap,
.km-flat .km-list .km-inline-field textarea {
  left: 0;
  padding-left: 0;
  padding-left: 1em;
}
.km-flat .km-list .km-inline-field:first-of-type input[type=password],
.km-flat .km-list .km-inline-field:first-of-type input[type=search],
.km-flat .km-list .km-inline-field:first-of-type input[type=number],
.km-flat .km-list .km-inline-field:first-of-type input[type=tel],
.km-flat .km-list .km-inline-field:first-of-type input[type=url],
.km-flat .km-list .km-inline-field:first-of-type input[type=email],
.km-flat .km-list .km-inline-field:first-of-type input[type=month],
.km-flat .km-list .km-inline-field:first-of-type input[type=color],
.km-flat .km-list .km-inline-field:first-of-type input[type=week],
.km-flat .km-list .km-inline-field:first-of-type input[type=date],
.km-flat .km-list .km-inline-field:first-of-type input[type=time],
.km-flat .km-list .km-inline-field:first-of-type input[type=datetime],
.km-flat .km-list .km-inline-field:first-of-type input[type=datetime-local],
.km-flat .km-list .km-inline-field:first-of-type input[type=text]:not(.k-input),
.km-flat .km-list .km-inline-field:first-of-type select:not([multiple]),
.km-flat .km-list .km-inline-field:first-of-type .k-dropdown-wrap,
.km-flat .km-list .km-inline-field:first-of-type textarea {
  margin-left: 0;
  padding-left: 0;
}
.km-flat .km-list .km-full-width-slider {
  margin-top: 0;
  padding: 1.55em 0;
}
/* Standalone Checkboxes and RadioButtons */
.km-root .km-checkbox-label,
.km-root .km-radio-label {
  font-size: 1em;
  line-height: 1.5em;
  margin-left: .89em;
}
.km-root .km-checkbox-label:before,
.km-root .km-checkbox-label:after {
  right: .7em;
  width: 1.33rem;
  height: 1.33rem;
  border-width: 0.133rem;
  border-style: solid;
  border-radius: 3px;
  text-align: center;
}
.km-root input.km-checkbox:checked + .km-checkbox-label:after {
  line-height: 1.2em;
}
.km-root .km-radio-label {
  padding-left: 2.5em;
  line-height: 1.5em;
}
.km-root .km-radio-label:before {
  width: 1.35rem;
  height: 1.35rem;
  border-width: 0.133rem;
  border-style: solid;
}
.km-root input.km-radio:checked + .km-radio-label:after {
  width: .95rem;
  height: .95rem;
  left: 0.25rem;
}
/* ActionSheet */
.km-flat .km-actionsheet > li > a {
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
}
.km-flat li.km-actionsheet-cancel > a {
  color: #bababa;
}
.km-flat .km-actionsheet > li > a {
  display: block;
}
.km-flat .km-actionsheet > li:last-child > a {
  border: 0;
}
.km-flat .km-shim li.km-actionsheet-title,
.km-flat .km-popup li.km-actionsheet-title {
  display: none;
}
.km-flat .km-actionsheet-wrapper.km-popup {
  padding: 2px 0;
}
/* PopOver */
.km-flat .km-popup.km-pane {
  border: 5px solid transparent;
}
.km-flat .km-popup.km-pane .km-navbar,
.km-flat .km-popup.km-pane .km-toolbar,
.km-flat .km-popup.km-pane .km-tabstrip {
  background: none;
}
.km-flat .km-popup.km-pane .km-header {
  padding: 0 5px;
  margin: -5px -5px 2px;
  border-radius: 3px 3px 0 0;
  -webkit-margin-collapse: separate;
}
.km-flat .km-popup-arrow:after {
  border-color: rgba(0, 0, 0, 0.5) transparent;
  border-style: solid;
  border-width: 0 15px 15px;
}
.km-flat .km-down .km-popup-arrow:before {
  margin-top: -1px;
}
.km-flat .km-up .km-popup-arrow:after {
  border-width: 15px 15px 0 15px;
}
.km-flat .km-left .km-popup-arrow:after {
  border-width: 15px 0 15px 15px;
}
.km-flat .km-right .km-popup-arrow:after {
  border-width: 15px 15px 15px 0;
}
/* Scroller */
.km-flat .km-touch-scrollbar {
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 1em;
}
.km-flat .km-scroller-pull .km-icon:after {
  margin-left: -5px;
  margin-top: -2px;
}
/* SplitView */
.km-nova {
  font: normal 1em "Helvetica Neue", "Helvetica", "Roboto Light", "Arial", "Verdana", "sans-serif";
  line-height: 1.2;
}
/* General content */
.km-nova h1 {
  font-size: 1.47em;
}
.km-nova h2 {
  font-size: 1.2em;
}
.km-nova h3 {
  font-size: 1.07em;
}
.km-nova h4 {
  font-size: .9em;
}
.km-nova h5 {
  font-size: .87em;
}
.km-nova h6 {
  font-size: .67em;
}
.km-nova p {
  font-size: .87em;
}
.km-nova .km-icon:before,
.km-nova .km-icon:after {
  text-align: center;
}
.km-nova .km-detail .km-icon,
.km-nova .km-button .km-icon {
  margin-left: 0;
  margin-right: 0.8em;
}
.km-nova .km-list .km-icon {
  margin-left: 0;
  margin-right: 1rem;
}
/* override transform options for performance */
.km-root .km-nova.km-pane,
.km-root .km-nova .km-view,
.km-root .km-nova .km-slider,
.km-root .km-nova .km-switch,
.km-root .km-nova .km-group-title,
.km-root .km-nova .km-filter-reset,
.km-root .km-nova .km-shim .k-animation-container {
  -webkit-transform: none;
}
.km-nova,
.km-nova * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-background-clip: border-box;
          background-clip: border-box;
}
/* Revert box/clip for Web widgets */
.km-nova [class^=k-] {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-nova .km-tabstrip .km-button {
  background: none;
}
/* PopUp */
.km-nova .km-popup .k-popup {
  font-size: 1em !important;
}
.km-nova .km-popup .k-item,
.km-nova .km-actionsheet > li > a {
  display: block;
  text-decoration: none;
  border-radius: 0;
  border-width: 0 0 1px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 1.14em .7em;
}
.km-nova .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-nova .km-popup .k-list-container {
  width: 100% !important;
  height: auto !important;
  padding: 0;
}
.km-nova .km-actionsheet,
.km-nova .km-popup .k-list-container {
  max-height: 80%;
}
.km-nova .km-actionsheet-wrapper,
.km-nova .km-popup .k-list-container {
  bottom: 0;
  border-style: solid;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
}
.km-nova .km-shim .k-animation-container {
  left: 0;
  top: auto;
  bottom: 0;
}
.km-nova .km-popup-wrapper {
  padding: 15px;
}
/* Color Template */
.km-nova h1,
.km-nova h2,
.km-nova h3,
.km-nova h4,
.km-nova h5,
.km-nova h6,
.km-nova p {
  color: #32364c;
}
.km-nova .km-content {
  color: #32364c;
  background-color: #fff;
}
/* Shim */
.km-nova .km-popup,
.km-nova .km-shim,
.km-phone .km-nova .km-actionsheet-wrapper,
.km-nova .km-actionsheet-root {
  background: rgba(0, 0, 0, 0.4);
}
/* Button */
.km-nova .km-button {
  color: #888e90;
  background: #fff;
  border-color: #888e90;
}
.km-nova .km-button.km-state-active {
  background: #f5f6f6;
}
/* Primary Button */
.km-nova .km-primary {
  color: #fff;
  background: #ff4350;
  border-color: #ff4350;
}
.km-nova .km-icon-button,
.km-nova .km-list .km-icon-button .km-icon {
  color: #fff;
}
.km-nova .km-primary.km-state-active {
  background: #e63c48;
  border-color: #e63c48;
}
/* Link button */
.km-nova .km-link-button {
  color: #00acc1;
  border-color: transparent;
}
.km-nova a:not([class]),
.km-nova .km-external-link {
  color: #00acc1;
}
/* TabStrip */
.km-nova .km-footer {
  background: #32364c;
}
.km-nova .km-navbar,
.km-nova .k-toolbar,
.km-nova .km-tabstrip {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2e3246), to(#242736));
  background-image: -webkit-linear-gradient(top, #2e3246 0%, #242736 100%);
  background-image: linear-gradient(to bottom, #2e3246 0%, #242736 100%);
}
.km-nova .km-vertical-tabstrip {
  background-color: #242736;
}
.km-nova .km-navbar,
.km-nova .k-toolbar,
.km-nova .k-toolbar .km-button,
.km-nova .k-toolbar .km-icon,
.km-nova .km-tabstrip,
.km-nova .km-navbar .km-back:before,
.km-nova .k-toolbar .km-back:before,
.km-nova .km-navbar .km-button,
.km-nova .km-view-title {
  color: #fff;
}
.km-nova .km-tabstrip .km-button {
  color: #fff;
  background: transparent;
  border-color: transparent;
}
.km-nova .km-tabstrip .km-button.km-state-active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#202333), to(#081421));
  background-image: -webkit-linear-gradient(top, #202333 0%, #081421 100%);
  background-image: linear-gradient(to bottom, #202333 0%, #081421 100%);
}
.km-nova .km-drawer .km-navbar,
.km-nova .km-drawer .km-vertical-tabstrip .km-button.km-state-active,
.km-nova .km-drawer .km-listview li.km-state-active {
  background: #1e202d;
}
.km-nova .km-light .km-view-title {
  color: #ff4350;
}
.km-nova .km-light.km-navbar,
.km-nova .km-light.k-toolbar,
.km-nova .km-light.km-tabstrip {
  background: #fff;
  -webkit-box-shadow: 0 1px 40px 4px #f8fafb;
          box-shadow: 0 1px 40px 4px #f8fafb;
}
.km-nova .km-accent.km-navbar,
.km-nova .km-accent.k-toolbar,
.km-nova .km-accent.km-tabstrip,
.km-nova .km-vertical-tabstrip .km-button.km-state-active:before,
.km-nova .km-drawer .km-listview li.km-state-active:before {
  background: #ff4350;
}
.km-nova .km-listview-link:after,
.km-nova .km-listview-label:after {
  border-color: #cacdce;
}
.km-nova .km-drawer .km-list .km-listview-link:after,
.km-nova .km-drawer .km-list .km-listview-label:after {
  border-color: #fff;
  opacity: .3;
}
.km-nova .km-touch-scrollbar {
  background: #32364c;
}
/* Light NavBar button */
.km-nova .km-light.km-navbar .km-back:before,
.km-nova .km-light.k-toolbar .km-back:before,
.km-nova .km-light.km-navbar .km-button {
  color: #ff4350;
}
.km-nova .km-navbar .km-button,
.km-nova .k-toolbar .km-button {
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* ButtonGroup */
.km-nova .km-buttongroup .km-button {
  border-color: #9ca6a6;
}
.km-nova .km-buttongroup .km-state-active {
  color: #fff;
  background-color: #9ca6a6;
}
.km-nova .km-buttongroup.km-primary .km-button {
  color: #ff4350;
  border-color: #ff4350;
}
.km-nova .km-buttongroup.km-primary .km-state-active {
  color: #fff;
  background-color: #ff4350;
}
/* Inverse ButtonGroup */
.km-nova .km-inverse .km-button {
  color: #fff;
  background-color: #9ca6a6;
  border-color: #fff;
}
.km-nova .km-inverse .km-state-active {
  color: #9ca6a6;
  background-color: #fff;
}
.km-nova .km-inverse.km-primary .km-button {
  color: #fff;
  border-color: #fff;
  background-color: #ff4350;
}
.km-nova .km-inverse.km-primary .km-state-active {
  color: #ff4350;
  background-color: #fff;
}
/* Accent NavBar */
.km-nova .km-accent.km-navbar,
.km-nova .km-accent.k-toolbar,
.km-nova .km-accent.km-tabstrip,
.km-nova .km-accent.km-navbar .km-back:before,
.km-nova .km-accent.k-toolbar .km-back:before,
.km-nova .km-accent.km-navbar .km-button {
  color: #fff;
}
/* ButtonGroup in NavBar*/
.km-nova .km-navbar .km-buttongroup .km-button {
  border-color: #fff;
}
.km-nova .km-navbar .km-buttongroup .km-state-active {
  color: #32364c;
  background-color: #fff;
}
/* ListView */
.km-nova .km-list > li {
  border-color: #dfe1e2;
  color: #32364c;
}
.km-nova .km-list > li.km-load-more {
  border-color: transparent;
}
.km-nova .km-list > li > .km-listview-link,
.km-nova .km-legend-button,
.km-nova .km-collapsible-header,
.km-nova .km-collapsible-header h1,
.km-nova .km-collapsible-header h2,
.km-nova .km-collapsible-header h3,
.km-nova .km-collapsible-header h4,
.km-nova .km-collapsible-header h5,
.km-nova .km-collapsible-header h6 {
  color: #ff4350;
}
.km-nova .km-legend-button,
.km-nova .km-drawer a {
  text-decoration: none;
}
.km-nova .km-list > li label,
.km-nova .km-list fieldset textarea,
.km-nova .km-list fieldset input,
.km-nova .km-checkbox-label,
.km-nova .km-radio-label {
  color: #9da2a4;
}
.km-nova .km-list fieldset .km-inline-field {
  border-color: #dfe1e2;
}
.km-nova label.km-required:after {
  color: #ff4350;
}
.km-nova .km-group-title,
.km-nova .km-list fieldset legend,
.km-nova .km-shim li.km-actionsheet-title {
  background: #f8fafb;
  border-color: #dfe1e2;
  color: #32364c;
}
.km-nova .km-popup .k-state-focused,
.km-nova .km-popup .k-state-selected {
  color: #f8fafb;
  background: #ff4350;
}
/* Drawer */
.km-nova .km-drawer {
  color: #fff;
  background-color: #2a2d40;
}
.km-nova .km-drawer .km-content {
  background-color: #2a2d40;
}
/* ListView in Drawer */
.km-nova .km-drawer .km-group-title {
  color: #fff;
  background-color: #14151e;
  border-color: #3c415b;
}
.km-nova .km-drawer .km-list > li {
  border-color: #3c415b;
}
.km-nova .km-drawer .km-list > li,
.km-nova .km-drawer .km-list > li > .km-listview-link,
.km-nova .km-drawer .km-list > li .km-icon {
  color: #fff;
}
/* Generic content */
.km-nova .km-thumbnail {
  border-color: #dfe1e2;
}
.km-nova .km-listview-link:after {
  color: #dfe1e2;
}
/* Filter box */
.km-nova .km-filter-wrap {
  background: #f8fafb;
  color: #32364c;
}
.km-nova .km-filter-wrap:before,
.km-nova .km-filter-wrap .km-clear {
  color: #cacdce;
}
/* Slider */
.km-nova .k-slider .k-slider-track {
  background-color: #dfe1e2;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-nova .k-slider-selection {
  background-color: #ff4350;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-nova .k-slider .k-draghandle,
.km-nova .k-slider .k-draghandle:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 0.202rem 0.403rem 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0.202rem 0.403rem 0 rgba(0, 0, 0, 0.3);
}
/* Switch */
.km-nova .km-switch-wrapper {
  border-color: #ff4350;
  background-color: #ff4350;
}
.km-nova .km-switch-off .km-switch-container {
  -webkit-box-shadow: inset 0 0.202rem 0.403rem 0 #dee7ec;
          box-shadow: inset 0 0.202rem 0.403rem 0 #dee7ec;
  border-color: #f8fafb;
  background-color: #f8fafb;
}
.km-nova span.km-switch {
  color: #ff4350;
}
.km-nova span.km-switch-off {
  color: #f8fafb;
}
.km-nova .km-switch-off .km-switch-wrapper {
  background-color: #f8fafb;
  border-color: #f8fafb;
}
.km-nova .km-switch-handle {
  background: #fff;
  -webkit-box-shadow: 0 0.202rem 0.403rem 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0.202rem 0.403rem 0 rgba(0, 0, 0, 0.3);
  border-color: #dee7ec;
}
/* ScrollView */
.km-nova .km-scrollview-dark {
  background-color: #32364c;
}
.km-nova .km-pages li {
  background: rgba(255, 255, 255, 0.2);
  border-color: #dfe1e2;
}
.km-nova .km-pages .km-current-page {
  background: #dfe1e2;
}
/* Checkboxes and RadioButtons inside ListView */
.km-nova input[type=radio],
.km-nova input[type=checkbox] {
  color: #ff4350;
  border-color: #dee7ec;
}
.km-nova input[type=checkbox]:checked:after {
  color: #ff4350;
}
.km-nova input[type=radio]:checked:after,
.km-nova.km-on-android .km-view input[type=radio].km-check:checked:after {
  background: #ff4350;
}
/* Standalone Checkboxes and RadioButtons */
.km-nova .km-checkbox:checked + .km-checkbox-label:after {
  color: #ff4350;
  border-color: #dee7ec;
}
.km-nova .km-checkbox-label:before {
  border-color: transparent;
}
.km-nova .km-checkbox-label:after,
.km-nova .km-radio-label:after {
  color: #dee7ec;
}
.km-nova .km-radio:checked + .km-radio-label:after {
  background: #ff4350;
}
/* ModalView */
.km-nova .km-modalview,
.km-nova .km-modalview .km-footer .km-rightitem {
  background: #fff;
}
.km-nova .km-modalview .km-footer .km-button {
  color: #59859b;
  border-color: #dfe1e2;
}
.km-nova .km-modalview .km-footer .km-rightitem .km-button:last-child,
.km-nova .km-modalview .km-footer .km-button.km-state-active {
  color: #00acc1;
}
/* ActionSheet, Footer */
.km-nova .km-actionsheet > li > a,
.km-nova .km-popup .k-item {
  color: #32364c;
  background: #fff;
}
.km-nova .km-actionsheet > li:nth-last-child(2) > a {
  -webkit-border-image: none;
          border-image: none;
}
.km-nova .km-footer .km-tabstrip .km-button,
.km-nova .km-actionsheet-wrapper,
.km-nova .km-actionsheet > li > a,
.km-nova .km-shim li.km-actionsheet-title,
.km-nova .km-popup .k-list-container,
.km-nova .km-popup .k-item {
  border-color: #dfe1e2;
}
.km-nova .km-actionsheet > .km-actionsheet-cancel > a {
  color: #32364c;
}
.km-nova .km-popup.km-pane,
.km-tablet .km-nova .km-actionsheet-wrapper {
  background-color: #fff;
}
/* Collapsible */
.km-nova .km-collapsible-header,
.km-nova .km-collapsible-content {
  background: #fff;
  border-color: #dee7ec;
}
.km-nova .km-collapsible-content {
  color: #32364c;
}
.km-nova .km-collapsible-header .km-icon,
.km-nova .km-expanded .km-collapsible-header .km-icon {
  color: #cacdce;
}
/* Loader & Pull-to-refresh */
.km-nova .km-loader {
  background: rgba(0, 0, 0, 0.05);
}
.km-nova .km-detail,
.km-nova .km-detail .km-icon:after {
  color: #ff4350;
  background: #fff;
}
.km-nova .km-list > li .km-style-default .km-icon:after {
  color: #32364c;
}
.km-nova .km-list > li .km-style-info .km-icon:after {
  color: #2196f3;
}
.km-nova .km-list > li .km-style-success .km-icon:after {
  color: #00c853;
}
.km-nova .km-list > li .km-style-warning .km-icon:after {
  color: #f52312;
}
.km-nova .km-list > li .km-style-error .km-icon:after {
  color: #f44336;
}
.km-nova .km-badge {
  color: #fff;
  background: #ff4350;
  border-color: #ff4350;
}
.km-nova .km-loading-left:before {
  background: #32364c;
}
.km-nova .km-loading-left:after {
  background: #dfe1e2;
}
.km-nova span.km-loading-right {
  background: #fff;
}
/* Validation */
.km-nova .km-list > li label.km-invalid,
.km-nova .km-list fieldset .km-legend-button.km-invalid,
.km-nova .km-list > li label.km-invalid.km-required:after {
  color: #f52312;
}
.km-nova .km-invalid-msg {
  background: #f52312;
}
.km-nova .km-invalid-msg {
  color: #fff;
}
/* Tablet Styles */
.km-tablet .km-nova.km-horizontal .km-navbar .km-button,
.km-tablet .km-nova.km-horizontal .km-toolbar .km-button {
  margin-top: 0;
  margin-bottom: 0;
}
.km-nova .km-icon:before,
.km-nova .km-contactadd:before,
.km-nova .km-rowdelete:before,
.km-nova .km-rowinsert:before,
.km-nova .km-detaildisclose:before {
  margin-top: 0;
}
/* Loader & Pull-to-refresh */
.km-nova .km-load-more {
  height: 3.4em;
}
.km-nova .km-load-more .km-button {
  margin: -1.09em 0.8em 0;
  display: block;
}
.km-nova .km-scroller-refresh.km-load-more,
.km-nova .km-scroller-pull {
  border-radius: 50%;
  overflow: visible;
}
.km-nova .km-loader {
  left: 45%;
  margin: 0;
  width: 100%;
  height: 100%;
  background: none;
}
.km-nova .km-loader .km-loading {
  -webkit-animation: none;
          animation: none;
  display: none;
}
.km-nova .km-loading-left,
.km-nova .km-loading-right {
  background: none;
}
.km-nova .km-loading-left,
.km-nova .km-loading-right,
.km-nova .km-scroller-refresh .km-icon {
  position: absolute;
  top: 50%;
  left: 45%;
}
.km-nova .km-scroller-pull .km-loading-left,
.km-nova .km-scroller-pull .km-loading-right,
.km-nova .km-scroller-refresh > .km-icon {
  display: none;
}
.km-nova .km-scroller-refresh .km-loading-left,
.km-nova .km-scroller-refresh .km-loading-right {
  display: block;
}
.km-nova .km-scroller-refresh .km-loading-left,
.km-nova .km-scroller-refresh .km-loading-right,
.km-nova .km-scroller-refresh .km-icon {
  top: 0;
  left: 0;
}
.km-nova .km-scroller-refresh .km-icon:before {
  content: "\a0";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
}
.km-root .km-nova .km-scroller-pull .km-icon {
  -webkit-background-clip: inherit;
  -webkit-background-size: inherit inherit;
          background-size: inherit;
  border-radius: 50%;
}
.km-nova .km-loading:after {
  display: none;
}
.km-nova .km-scroller-refresh.km-load-more {
  padding: 0;
  position: relative;
  margin: auto;
}
.km-nova .km-scroller-refresh.km-load-more,
.km-nova .km-scroller-pull {
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  top: .25em;
  white-space: nowrap;
}
.km-nova .km-scroller-pull {
  left: 50%;
  margin: 0 0 0 -90px;
}
.km-nova .km-loader h1 {
  display: none;
  font-size: 1em;
  position: absolute;
  left: -50%;
  width: 200%;
  top: 55%;
}
.km-nova .km-scroller-pull .km-template {
  position: absolute;
  line-height: 2em;
  font-size: 1.2em;
  min-width: 0;
  top: 0;
  left: 2em;
}
.km-nova .km-scroller-pull .km-icon {
  margin-right: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}
.km-nova .km-scroller-pull .km-icon:after {
  content: "\e012";
  margin-left: -3px;
}
.km-nova .km-scroller-refresh .km-icon {
  display: none;
}
.km-nova .km-load-more .km-icon,
.km-nova .km-scroller-pull .km-icon {
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
          transition: none;
}
.km-nova .km-load-more .km-icon:after,
.km-nova .km-scroller-pull .km-icon:after {
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 62% 50%;
      -ms-transform-origin: 62% 50%;
          transform-origin: 62% 50%;
  -webkit-transition: -webkit-transform 300ms linear;
          transition: transform 300ms linear;
}
.km-nova .km-scroller-release .km-icon:after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.km-nova .km-scroller-refresh .km-icon:after {
  -webkit-transition: none;
          transition: none;
}
.km-nova .km-icon-left .km-arrow-n:after {
  content: "\e012";
}
.km-nova .km-icon-left .km-arrow-s:after {
  content: "\e010";
}
.km-nova .km-icon-right .km-arrow-n:after {
  content: "\e011";
}
.km-nova .km-icon-right .km-arrow-s:after {
  content: "\e012";
}
/* transitions */
.km-root .km-nova.k-fx-slide {
  /* left reverse */
  /* right */
}
.km-root .km-nova.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-filter: brightness(70%);
}
.km-root .km-nova.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-root .km-nova.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
  -webkit-filter: none;
}
.km-root .km-nova.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-filter: brightness(70%);
}
.km-root .km-nova.k-fx-slide.k-fx-right {
  /* right reverse */
}
.km-root .km-nova.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-root .km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
  -webkit-filter: none;
}
.km-root .km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
/* Loader */
.km-nova .km-loader {
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: none;
}
.km-nova .km-loader .km-loading {
  -webkit-animation: none;
          animation: none;
  display: none;
}
.km-nova .km-loading-left,
.km-nova .km-loading-right {
  width: 1.33em;
  height: 1.33em;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes animate_left {
  0% {
    -webkit-transform: translate3d(0, -0.67em, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0.67em, 0);
  }
}
@keyframes animate_left {
  0% {
    -webkit-transform: translate3d(0, -0.67em, 0);
            transform: translate3d(0, -0.67em, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0.67em, 0);
            transform: translate3d(0, 0.67em, 0);
  }
}
.km-nova .km-loading-left:before,
.km-nova .km-loading-left:after {
  position: absolute;
  content: "";
  width: 1.33em;
  border-radius: 50%;
  height: 1.33rem;
  z-index: 1;
  display: block;
  animation: animate_left 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -webkit-animation: animate_left 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
}
.km-nova .km-loading-left:before {
  right: 0;
  bottom: 0;
  animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
}
.km-nova .km-loading-left:after {
  left: 0;
  top: 0;
}
@-webkit-keyframes animate_right {
  0% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -webkit-transform: scale(1, 1) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
  }
}
@keyframes animate_right {
  0% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
            transform: scale(0.01, 0.01) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -webkit-transform: scale(1, 1) rotateY(0);
            transform: scale(1, 1) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
            transform: scale(0.01, 0.01) rotateY(0);
  }
}
.km-nova .km-loading-right {
  z-index: 2;
  border-radius: 50%;
  animation: animate_right 0.8s ease-in infinite;
  -webkit-animation: animate_right 0.8s ease-in infinite;
}
/* Icons */
.km-nova .km-icon.km-phone:after,
.km-nova .km-state-active .km-phone:after {
  content: "\e326";
}
.km-nova .km-detaildisclose:after {
  content: "i";
}
.km-nova .km-action:after {
  content: "\e1ff";
}
.km-nova .km-add:after {
  content: "\e200";
}
.km-nova .km-mostrecent:after {
  content: "\e201";
}
.km-nova .km-battery:after {
  content: "\e203";
}
.km-nova .km-bookmarks:after {
  content: "\e204";
}
.km-nova .km-camera:after {
  content: "\e205";
}
.km-nova .km-cart:after {
  content: "\e206";
}
.km-nova .km-recents:after {
  content: "\e207";
}
.km-nova .km-download:after,
.km-nova .km-downloads:after {
  content: "\e209";
}
.km-nova .km-edit:after {
  content: "\e20a";
}
.km-nova .km-mostviewed:after {
  content: "\e20b";
}
.km-nova .km-toprated:after,
.km-nova .km-favorites:after {
  content: "\e20c";
}
.km-nova .km-featured:after {
  content: "\e20d";
}
.km-nova .km-fastforward:after {
  content: "\e20e";
}
.km-nova .km-settings:after {
  content: "\e20f";
}
.km-nova .km-globe:after {
  content: "\e211";
}
.km-nova .km-home:after {
  content: "\e212";
}
.km-nova .km-info:after,
.km-nova .km-about:after {
  content: "\e213";
}
.km-nova .km-share:after {
  content: "\e214";
}
.km-nova .km-more:after {
  content: "\e215";
}
.km-nova .km-details:after {
  content: "\e217";
}
.km-nova .km-pause:after {
  content: "\e218";
}
.km-nova .km-contacts:after {
  content: "\e219";
}
.km-nova .km-play:after {
  content: "\e21a";
}
.km-nova .km-refresh:after {
  content: "\e21b";
}
.km-nova .km-history:after {
  content: "\e21c";
}
.km-nova .km-rewind:after {
  content: "\e21d";
}
.km-nova .km-search:after {
  content: "\e21e";
}
.km-nova .km-stop:after {
  content: "\e21f";
}
.km-nova .km-organize:after {
  content: "\e220";
}
.km-nova .km-trash:after,
.km-nova .km-delete:after {
  content: "\e221";
}
.km-nova .km-reply:after {
  content: "\e222";
}
.km-nova .km-forward:after {
  content: "\e223";
}
.km-nova .km-sounds:after,
.km-nova .km-volume:after {
  content: "\e224";
}
.km-nova .km-wifi:after {
  content: "\e225";
}
.km-nova .km-phone:after {
  content: "\e226";
}
.km-nova .km-hamburger:after {
  content: "\e105";
}
.km-nova .km-state-active .km-action:after {
  content: "\e2ff";
}
.km-nova .km-state-active .km-add:after {
  content: "\e300";
}
.km-nova .km-state-active .km-mostrecent:after {
  content: "\e301";
}
.km-nova .km-state-active .km-battery:after {
  content: "\e303";
}
.km-nova .km-state-active .km-bookmarks:after {
  content: "\e304";
}
.km-nova .km-state-active .km-camera:after {
  content: "\e305";
}
.km-nova .km-state-active .km-cart:after {
  content: "\e306";
}
.km-nova .km-state-active .km-recents:after {
  content: "\e307";
}
.km-nova .km-state-active .km-download:after,
.km-nova .km-state-active .km-downloads:after {
  content: "\e309";
}
.km-nova .km-state-active .km-edit:after {
  content: "\e20a";
}
.km-nova .km-state-active .km-mostviewed:after {
  content: "\e30b";
}
.km-nova .km-state-active .km-toprated:after,
.km-nova .km-state-active .km-favorites:after {
  content: "\e30c";
}
.km-nova .km-state-active .km-featured:after {
  content: "\e30d";
}
.km-nova .km-state-active .km-fastforward:after {
  content: "\e30e";
}
.km-nova .km-state-active .km-settings:after {
  content: "\e30f";
}
.km-nova .km-state-active .km-globe:after {
  content: "\e311";
}
.km-nova .km-state-active .km-home:after {
  content: "\e312";
}
.km-nova .km-state-active .km-info:after,
.km-nova .km-state-active .km-about:after {
  content: "\e313";
}
.km-nova .km-state-active .km-share:after {
  content: "\e314";
}
.km-nova .km-state-active .km-more:after {
  content: "\e315";
}
.km-nova .km-state-active .km-details:after {
  content: "\e317";
}
.km-nova .km-state-active .km-pause:after {
  content: "\e318";
}
.km-nova .km-state-active .km-contacts:after {
  content: "\e319";
}
.km-nova .km-state-active .km-play:after {
  content: "\e31a";
}
.km-nova .km-state-active .km-refresh:after {
  content: "\e31b";
}
.km-nova .km-state-active .km-history:after {
  content: "\e31c";
}
.km-nova .km-state-active .km-rewind:after {
  content: "\e31d";
}
.km-nova .km-state-active .km-search:after {
  content: "\e31e";
}
.km-nova .km-state-active .km-stop:after {
  content: "\e31f";
}
.km-nova .km-state-active .km-organize:after {
  content: "\e320";
}
.km-nova .km-state-active .km-trash:after,
.km-nova .km-state-active .km-delete:after {
  content: "\e321";
}
.km-nova .km-state-active .km-reply:after {
  content: "\e322";
}
.km-nova .km-state-active .km-forward:after {
  content: "\e323";
}
.km-nova .km-state-active .km-sounds:after,
.km-nova .km-state-active .km-volume:after {
  content: "\e324";
}
.km-nova .km-state-active .km-wifi:after {
  content: "\e325";
}
/* Button */
/* Medium button - default state */
.km-nova .km-button,
.km-nova .km-buttongroup .km-button {
  padding: .6em .688em;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  line-height: 1.2em;
  vertical-align: middle;
}
.km-nova .km-buttongroup .km-button {
  border-width: 1px 0 1px 1px;
}
/* Small button */
.km-nova .km-button.km-small,
.km-nova .km-buttongroup.km-small .km-button {
  font-size: .8em;
  padding: .35em .7em;
  line-height: 1.5em;
}
/* Large button */
.km-nova .km-button.km-large,
.km-nova .km-buttongroup.km-large .km-button {
  padding: .85em;
  font-size: 1.07em 1.25em;
}
/* Icon button */
.km-nova .km-icon-button .km-icon {
  margin: 0;
}
/* NavBar button */
.km-nova .km-navbar .km-back:before,
.km-nova .k-toolbar .km-back:before {
  content: "\a0";
  display: inline-block;
  width: 1em;
  height: 1em;
  border-width: 0;
  -webkit-box-shadow: inset 0.2rem -0.2rem 0 currentcolor;
          box-shadow: inset 0.2rem -0.2rem 0 currentcolor;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  vertical-align: middle;
}
.km-nova .km-navbar .km-back .km-text,
.km-nova .km-navbar .km-back .km-arrow-w.km-notext,
.km-nova .k-toolbar .km-back .km-text {
  display: none;
}
.km-nova .km-justified {
  display: block;
  width: 100%;
}
/* Badges and Details */
.km-nova .km-detail {
  font-size: 1.5rem;
}
.km-nova .km-badge,
.km-nova .km-detail {
  border: 0;
  min-width: 1.812em;
  height: 1.812em;
  line-height: 2em;
  border-radius: 5em;
}
.km-nova .km-button-right {
  float: right;
}
.km-nova .km-button-left {
  float: left;
}
/* ButtonGroup */
.km-nova .km-buttongroup {
  border-radius: 4px;
  -webkit-transform: translatez(0);
}
.km-nova .km-buttongroup > *:first-child {
  border-radius: 4px 0 0 4px;
}
.km-nova .km-buttongroup > *:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.km-nova .km-buttongroup > *:last-child {
  border-radius: 0 4px 4px 0;
}
.km-nova .km-collapsible-header {
  height: 4.125em;
  line-height: 4.125em;
  padding: 0 1.16em 0 2.4em;
  font-size: 1.087em;
}
.km-nova .km-collapsible-content {
  padding: 1em;
}
.km-nova .km-collapsible-header h1,
.km-nova .km-collapsible-header h2,
.km-nova .km-collapsible-header h3,
.km-nova .km-collapsible-header h4,
.km-nova .km-collapsible-header h5,
.km-nova .km-collapsible-header h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.km-nova .km-collapsible-header h1 {
  line-height: 2.85em;
}
.km-nova .km-collapsible-header .km-icon {
  font-size: 1.29rem;
  left: .4em;
  top: 45%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.km-nova .km-collapsible-content .km-collapsible {
  margin: -0.4em;
}
/* NavBar */
.km-nova .km-navbar .km-no-title {
  padding-top: .45rem;
  padding-bottom: .45rem;
}
.km-nova .km-view-title {
  font-size: 1.2em;
  line-height: 2.78em;
}
.km-nova .km-navbar .km-no-title > [data-role='view-title'] {
  line-height: 2.2em;
}
.km-nova .km-view-subtitle {
  font-size: .47em;
  display: block;
  line-height: 1.2;
  position: absolute;
  bottom: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  margin-bottom: .875em;
  white-space: nowrap;
}
.km-nova .km-navbar .km-button {
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 0 1.135em;
  height: 100%;
  line-height: 3.27em;
}
.km-nova .km-navbar .km-buttongroup {
  display: table;
  width: 100%;
}
.km-nova .km-navbar .km-buttongroup .km-button {
  display: inline-block;
}
/* NavBar with title and ButtonGroup */
.km-nova .km-navbar .km-buttongroup {
  margin: 0;
}
.km-nova .km-widget.km-navbar-buttongroup .km-buttongroup {
  padding: .45rem .77em;
  display: table;
}
.km-nova .km-widget.km-navbar-buttongroup .km-leftitem .km-button,
.km-nova .km-widget.km-navbar-buttongroup .km-rightitem .km-button {
  line-height: 5.6em;
}
.km-nova .km-no-title .km-buttongroup {
  margin-top: -0.45rem;
  margin-bottom: -0.45rem;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  border-radius: 0;
  top: auto;
}
.km-nova .km-navbar-buttongroup .km-buttongroup > .km-button,
.km-nova .km-no-title .km-buttongroup > .km-button {
  font-size: 1.2em;
  padding: 1.02rem;
  -webkit-transform: translatez(0);
}
.km-nova .km-navbar .km-no-title .km-buttongroup .km-button {
  font-size: 1.2em;
  line-height: 2.05em;
  padding: 0 .9em 0 .8em;
  border-width: 1px 0 1px 1px;
  border-style: solid;
}
.km-nova .km-navbar .km-no-title .km-buttongroup > *:first-child {
  border-radius: 4px 0 0 4px;
}
.km-nova .km-navbar .km-no-title .km-buttongroup .km-button:last-child {
  border-right-width: 1px;
}
.km-nova .km-navbar .km-no-title .km-buttongroup > *:last-child {
  border-radius: 0 4px 4px 0;
}
.km-nova .km-leftitem {
  left: 0;
}
.km-nova .km-rightitem {
  right: 0;
}
/* iOS 7 transitions styles */
.km-nova.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.km-nova.k-fx-slide.k-fx-end .k-fx-next .km-view-title,
.km-nova.k-fx-slide.k-fx-end .k-fx-next .km-leftitem,
.km-nova.k-fx-slide.k-fx-end .k-fx-current .km-view-title,
.km-nova.k-fx-slide.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.km-nova.k-fx-slide.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-nova.k-fx-slide.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
  opacity: 0;
}
.km-nova.k-fx-slide.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-nova.k-fx-slide.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
  opacity: 0;
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-nova.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-right {
  /* left */
  /* right reverse */
}
.km-nova.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-view-title {
  -webkit-transform: translatex(50%);
      -ms-transform: translatex(50%);
          transform: translatex(50%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-leftitem {
  -webkit-transform: translatex(-50%);
      -ms-transform: translatex(-50%);
          transform: translatex(-50%);
  opacity: 0;
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-view-title {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.km-nova.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-leftitem {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
/* ToolBar */
.km-nova .k-toolbar {
  line-height: 1.2em;
  padding: .4em 3.4em .4em .5em;
}
.km-nova .k-toolbar .km-button,
.km-nova .k-toolbar .k-split-button {
  line-height: inherit;
}
/* TabStrip */
.km-nova .km-tabstrip {
  padding: 0;
  display: table;
  table-layout: fixed;
  min-height: 4.1rem;
}
.km-nova .km-tabstrip .km-button {
  font-size: .6em;
  display: table-cell;
  border: 0;
  padding: 0;
}
.km-nova .km-tabstrip .km-icon {
  font-size: 2rem;
  margin-bottom: .29rem;
}
.km-nova .km-tabstrip .km-button .km-icon {
  margin: 0 auto .1em;
}
/* Badges */
.km-nova .km-tabstrip .km-badge {
  margin-left: .5em;
  border-radius: 0;
  height: 1.834rem;
  line-height: 1.45rem;
  clip-path: polygon(0 68%, 100% 100%, 100% 0, 0 0);
  -webkit-clip-path: polygon(0 68%, 100% 100%, 100% 0, 0 0);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f54c4d), to(#e51841));
  background-image: -webkit-linear-gradient(top, #f54c4d 0%, #e51841 100%);
  background-image: linear-gradient(to bottom, #f54c4d 0%, #e51841 100%);
}
/* Switch */
.km-nova .km-switch {
  width: 4.3rem;
  height: 2.4rem;
  overflow: visible;
}
.km-nova .km-switch-wrapper {
  overflow: hidden;
}
.km-nova .km-switch-background {
  background-position: 4.25em 0;
  background-repeat: no-repeat;
  background-color: currentcolor;
  margin-left: -4.1rem;
}
.km-nova .km-switch-container {
  overflow: visible;
  border-width: 0;
  padding: 0 0 0 1px;
}
.km-nova .km-switch-handle {
  width: 2.2em;
  -webkit-transition: width 0.2s linear;
          transition: width 0.2s linear;
  margin: 0 3px 0 0;
  border-width: 1px;
  border-style: solid;
}
.km-nova .km-switch-label-on,
.km-nova .km-switch-label-off {
  display: none;
}
.km-nova .km-switch-handle,
.km-nova .km-switch-wrapper,
.km-nova .km-switch-container,
.km-nova .km-switch-background {
  border-radius: 3em;
}
.km-nova .km-switch-container,
.km-nova .km-switch-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  -webkit-background-clip: content-box;
          background-clip: content-box;
}
@font-face {
  font-family: "Kendo UI";
  src: url("images/kendoui.woff?v=1.1") format("woff"), url("images/kendoui.ttf?v=1.1") format("truetype"), url("images/kendoui.svg#kendoui") format("svg");
}
/* ListView */
.km-nova .km-listinset,
.km-nova .km-listgroupinset {
  margin: 0;
}
.km-nova .km-list > li {
  font-size: 1.2em;
  padding-left: .89em;
  background: transparent;
  border-radius: 0;
  border-width: 1px 0 0;
  border-style: solid;
  line-height: 1.5em;
  padding: 1.09em .84em 1.23em .84em;
}
.km-nova .km-list:not(.km-virtual-list) > li:last-child {
  border-bottom-width: 1px;
}
.km-nova .km-listview-link:after {
  border-width: .2rem .2rem 0 0;
}
.km-nova .km-listinset li:first-child > .km-listview-link,
.km-nova .km-listgroupinset li:first-child > .km-listview-link {
  border-radius: 1px 1px 0 0;
}
.km-nova .km-listinset li:last-child > .km-listview-link,
.km-nova .km-listgroupinset li:last-child > .km-listview-link {
  border-radius: 0 0 1px 1px;
}
.km-nova .km-listinset li:first-child:last-child > .km-listview-link,
.km-nova .km-listgroupinset li:first-child:last-child > .km-listview-link {
  border-radius: 1px;
}
.km-nova .km-list li > .km-listview-link {
  margin-left: -1em;
  padding-left: 1em;
  border-radius: 0;
}
.km-nova .km-group-title {
  font-size: 1.2em;
  position: -webkit-sticky;
  text-indent: 0;
  margin-top: 0;
  font-weight: normal;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 1.17em .84em .39em;
}
.km-nova .km-listview-icons .km-group-title {
  padding-left: 3em;
}
.km-nova .km-listview-icons .km-icon::before,
.km-nova .km-listview-icons .km-icon::after {
  margin-left: 0;
  font-size: 1.2em;
  line-height: 0.8em;
}
.km-nova .km-listview-images .km-group-title {
  padding-left: 4em;
}
.km-nova .km-listgroup .km-group-title,
.km-nova .km-listgroupinset .km-group-title {
  line-height: 1.5em;
  border-top-width: 0px;
  border-top-style: solid;
}
.km-nova .km-listview .km-switch {
  margin-top: -1.3rem;
}
.km-nova .km-listview-item-badge.km-badge,
.km-nova .km-listview-link > .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4em;
}
.km-nova li.km-state-active {
  background: #f5f6f6;
}
/* Filter box */
.km-nova .km-filter-wrap {
  padding: .25em 1.2em;
  border-radius: 4px;
}
.km-nova .km-filter-wrap > input {
  padding: .5em 1.8em;
}
.km-nova .km-filter-wrap > input::-webkit-input-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap > input::-moz-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap > input::-ms-input-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap > input:focus::-webkit-input-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap > input:focus::-moz-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap > input:focus::-ms-input-placeholder {
  text-align: left;
}
.km-nova .km-filter-wrap:before,
.km-nova .km-filter-reset .km-clear {
  position: absolute;
  top: 50%;
  margin-top: -0.55em;
}
.km-nova .km-filter-wrap:before {
  font-size: 1em;
  content: "\e21e";
}
.km-nova .km-filter-wrap .km-clear:after {
  content: "\e031";
}
/* ScrollView */
.km-nova .km-scrollview-overlay .km-pages {
  padding-top: .4em;
  position: absolute;
  bottom: 3.5em;
  left: 0;
  right: 0;
}
.km-nova .km-pages li {
  width: .55rem;
  height: .55rem;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}
/* Forms */
.km-nova .k-slider .k-draghandle {
  width: 1.333em;
  height: 1.333em;
  border-radius: 5em;
  border: 0;
}
.km-nova .km-list .km-full-width-slider {
  position: relative;
  margin-top: 0;
  padding: 1.55em 0;
  width: auto;
  display: block;
}
.km-nova .k-slider-horizontal .k-draghandle {
  top: 2px;
}
.km-nova .k-slider-vertical .k-draghandle {
  left: -0.665em;
}
.km-nova .k-slider-horizontal .k-slider-selection {
  margin-top: 0;
}
.km-nova .k-slider-vertical .k-slider-selection {
  margin-left: -0.1em;
  width: .29rem;
}
.km-nova .k-slider-horizontal .k-slider-track {
  height: .29rem;
}
.km-nova .k-slider-vertical .k-slider-track {
  width: .29rem;
}
.km-nova .k-slider-selection {
  margin-left: 0;
}
.km-nova .k-slider .k-draghandle,
.km-nova .k-slider .k-draghandle:hover {
  margin-top: -0.665em;
  padding: 0;
  background-clip: content-box;
  -webkit-background-clip: content-box;
}
.km-nova .k-slider .k-draghandle:active:before {
  opacity: .4;
}
.km-nova .k-slider .k-draghandle,
.km-nova .k-slider .k-draghandle:hover {
  border: 0;
  border-radius: 5em;
}
.km-nova .k-slider-track {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  border-radius: .5em;
  border-width: 0;
}
.km-nova .k-slider-selection {
  margin-left: 0;
}
.km-nova .km-list input[type=password],
.km-nova .km-list input[type=search],
.km-nova .km-list input[type=number],
.km-nova .km-list input[type=tel],
.km-nova .km-list input[type=url],
.km-nova .km-list input[type=email],
.km-nova .km-list input[type=month],
.km-nova .km-list input[type=color],
.km-nova .km-list input[type=week],
.km-nova .km-list input[type=date],
.km-nova .km-list input[type=time],
.km-nova .km-list input[type=datetime],
.km-nova .km-list input[type=datetime-local],
.km-nova .km-list input[type=text]:not(.k-input),
.km-nova .km-list select:not([multiple]),
.km-nova .km-list .k-dropdown-wrap,
.km-nova .km-list textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.2rem;
  min-width: 6em;
  border: 0;
  outline: none;
  background: transparent;
  margin-top: 0;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.km-nova .km-list .k-dropdown-wrap {
  padding: .2em;
}
.km-nova .km-list .k-dropdown {
  font-weight: normal;
  -ms-transform: translateY(50%);
      transform: translateY(50%);
  -webkit-transform: translateY(50%);
}
.km-nova .km-list input[type=color],
.km-nova .km-list input[type=week],
.km-nova .km-list input[type=date],
.km-nova .km-list input[type=time],
.km-nova .km-list input[type=month],
.km-nova .km-list input[type=datetime],
.km-nova .km-list input[type=datetime-local],
.km-nova .km-list .k-dropdown {
  text-align: left;
}
.km-nova .km-list .km-label-above .k-dropdown-wrap {
  display: block;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
}
.km-nova .km-list .km-label-above .k-dropdown-wrap .k-input {
  text-indent: 0;
  padding: .2em 0;
}
.km-nova .km-label-above {
  width: 100%;
  font-size: 0.87em;
  display: block;
  margin-top: -1.3em;
  padding-top: .92em;
  padding-bottom: 3.3em;
  margin-bottom: -2em;
}
.km-nova .km-label-above textarea {
  top: 50%;
}
.km-nova .km-label-above,
.km-nova label.km-inline-field {
  font-size: .72em;
  line-height: 1.2em;
}
.km-nova .km-list .km-label-above input[type=text]:not(.k-input),
.km-nova .km-list .km-label-above input[type=password],
.km-nova .km-list .km-label-above input[type=search],
.km-nova .km-list .km-label-above input[type=number],
.km-nova .km-list .km-label-above input[type=tel],
.km-nova .km-list .km-label-above input[type=url],
.km-nova .km-list .km-label-above input[type=email],
.km-nova .km-list .km-label-above input[type=file],
.km-nova .km-list .km-label-above input[type=month],
.km-nova .km-list .km-label-above input[type=color],
.km-nova .km-list .km-label-above input[type=week],
.km-nova .km-list .km-label-above input[type=date],
.km-nova .km-list .km-label-above input[type=time],
.km-nova .km-list .km-label-above input[type=datetime],
.km-nova .km-list .km-label-above input[type=datetime-local],
.km-nova .km-list .km-label-above select:not([multiple]),
.km-nova .km-list .km-label-above .k-dropdown,
.km-nova .km-list .km-label-above textarea {
  width: 100%;
  right: 0;
  padding-left: 0;
  left: .89em;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
}
.km-nova label.km-required:after {
  content: "*";
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.km-nova .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-nova .km-icon-label:before {
  display: inline-block;
  width: 1em;
  height: 1em;
  font: 1em/1em "Kendo UI";
  margin-right: .89em;
}
.km-nova .km-icon-label input[type=password],
.km-nova .km-icon-label input[type=search],
.km-nova .km-icon-label input[type=number],
.km-nova .km-icon-label input[type=tel],
.km-nova .km-icon-label input[type=url],
.km-nova .km-icon-label input[type=email],
.km-nova .km-icon-label input[type=month],
.km-nova .km-icon-label input[type=color],
.km-nova .km-icon-label input[type=week],
.km-nova .km-icon-label input[type=date],
.km-nova .km-icon-label input[type=time],
.km-nova .km-icon-label input[type=datetime],
.km-nova .km-icon-label input[type=datetime-local],
.km-nova .km-icon-label input[type=text]:not(.k-input),
.km-nova .km-icon-label select:not([multiple]),
.km-nova .km-icon-label .k-dropdown-wrap,
.km-nova .km-icon-label textarea {
  right: auto;
}
.km-nova .km-list fieldset {
  border: none;
  padding: 0 1.61em;
  margin: -1.18em -1.61em;
}
.km-nova .km-list textarea,
.km-nova .km-list fieldset input[type=password],
.km-nova .km-list fieldset input[type=search],
.km-nova .km-list fieldset input[type=number],
.km-nova .km-list fieldset input[type=tel],
.km-nova .km-list fieldset input[type=url],
.km-nova .km-list fieldset input[type=email],
.km-nova .km-list fieldset input[type=month],
.km-nova .km-list fieldset input[type=color],
.km-nova .km-list fieldset input[type=week],
.km-nova .km-list fieldset input[type=date],
.km-nova .km-list fieldset input[type=time],
.km-nova .km-list fieldset input[type=datetime],
.km-nova .km-list fieldset input[type=datetime-local],
.km-nova .km-list fieldset input[type=text]:not(.k-input),
.km-nova .km-list fieldset select:not([multiple]),
.km-nova .km-list fieldset .k-dropdown-wrap {
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
}
.km-nova .km-list fieldset > input[type=password],
.km-nova .km-list fieldset > input[type=search],
.km-nova .km-list fieldset > input[type=number],
.km-nova .km-list fieldset > input[type=tel],
.km-nova .km-list fieldset > input[type=url],
.km-nova .km-list fieldset > input[type=email],
.km-nova .km-list fieldset > input[type=month],
.km-nova .km-list fieldset > input[type=color],
.km-nova .km-list fieldset > input[type=week],
.km-nova .km-list fieldset > input[type=date],
.km-nova .km-list fieldset > input[type=time],
.km-nova .km-list fieldset > input[type=datetime],
.km-nova .km-list fieldset > input[type=datetime-local],
.km-nova .km-list fieldset > input[type=text]:not(.k-input),
.km-nova .km-list fieldset > select:not([multiple]),
.km-nova .km-list fieldset > .k-dropdown-wrap {
  padding: 1.23em 0;
}
.km-nova .km-list fieldset textarea {
  width: 100%;
  -ms-transform: translateY(0);
      transform: translateY(0);
  -webkit-transform: translateY(0);
  margin-right: 0;
  padding-left: 0;
}
.km-nova .km-list fieldset input[type=password],
.km-nova .km-list fieldset input[type=search],
.km-nova .km-list fieldset input[type=number],
.km-nova .km-list fieldset input[type=tel],
.km-nova .km-list fieldset input[type=url],
.km-nova .km-list fieldset input[type=email],
.km-nova .km-list fieldset input[type=month],
.km-nova .km-list fieldset input[type=color],
.km-nova .km-list fieldset input[type=week],
.km-nova .km-list fieldset input[type=date],
.km-nova .km-list fieldset input[type=time],
.km-nova .km-list fieldset input[type=datetime],
.km-nova .km-list fieldset input[type=datetime-local],
.km-nova .km-list fieldset input[type=text]:not(.k-input),
.km-nova .km-list fieldset select:not([multiple]),
.km-nova .km-list fieldset .k-dropdown-wrap,
.km-nova .km-list fieldset textarea {
  width: 100%;
  position: relative;
}
.km-nova .km-list fieldset legend {
  text-indent: 0;
  margin: 0 -1.61em;
  font-weight: normal;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 1.28em 1.61em .55em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.km-nova .km-legend-button {
  display: block;
  padding: 1.17em 1.61em .39em;
  margin: -1.17em -1.61em -0.39em;
}
.km-nova .km-inline-field {
  display: inline-block;
  position: relative;
  width: -webkit-calc(48.39%);
  width: calc(48.39%);
  overflow: hidden;
  white-space: nowrap;
}
.km-nova .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1.61em;
  border-right-width: 1px;
  border-right-style: solid;
}
.km-nova .km-list fieldset .km-inline-field:first-of-type {
  padding-left: 0;
}
.km-nova .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-nova .km-list .km-inline-field input[type=text]:not(.k-input),
.km-nova .km-list .km-inline-field input[type=password],
.km-nova .km-list .km-inline-field input[type=search],
.km-nova .km-list .km-inline-field input[type=number],
.km-nova .km-list .km-inline-field input[type=tel],
.km-nova .km-list .km-inline-field input[type=url],
.km-nova .km-list .km-inline-field input[type=email],
.km-nova .km-list .km-inline-field input[type=file],
.km-nova .km-list .km-inline-field input[type=month],
.km-nova .km-list .km-inline-field input[type=color],
.km-nova .km-list .km-inline-field input[type=week],
.km-nova .km-list .km-inline-field input[type=date],
.km-nova .km-list .km-inline-field input[type=time],
.km-nova .km-list .km-inline-field input[type=datetime],
.km-nova .km-list .km-inline-field input[type=datetime-local],
.km-nova .km-list .km-inline-field select:not([multiple]),
.km-nova .km-list .km-inline-field .k-dropdown,
.km-nova .km-list .km-inline-field textarea {
  position: absolute;
  width: 100%;
  left: 0;
  padding-left: 0;
  padding-left: 1em;
}
.km-nova .km-list .km-inline-field:first-of-type input[type=text]:not(.k-input),
.km-nova .km-list .km-inline-field:first-of-type input[type=password],
.km-nova .km-list .km-inline-field:first-of-type input[type=search],
.km-nova .km-list .km-inline-field:first-of-type input[type=number],
.km-nova .km-list .km-inline-field:first-of-type input[type=tel],
.km-nova .km-list .km-inline-field:first-of-type input[type=url],
.km-nova .km-list .km-inline-field:first-of-type input[type=email],
.km-nova .km-list .km-inline-field:first-of-type input[type=file],
.km-nova .km-list .km-inline-field:first-of-type input[type=month],
.km-nova .km-list .km-inline-field:first-of-type input[type=color],
.km-nova .km-list .km-inline-field:first-of-type input[type=week],
.km-nova .km-list .km-inline-field:first-of-type input[type=date],
.km-nova .km-list .km-inline-field:first-of-type input[type=time],
.km-nova .km-list .km-inline-field:first-of-type input[type=datetime],
.km-nova .km-list .km-inline-field:first-of-type input[type=datetime-local],
.km-nova .km-list .km-inline-field:first-of-type select:not([multiple]),
.km-nova .km-list .km-inline-field:first-of-type .k-dropdown,
.km-nova .km-list .km-inline-field:first-of-type textarea {
  margin-left: 0;
  padding-left: 0;
}
/* Checkboxes and RadioButtons inside ListView */
.km-nova input[type=radio],
.km-nova input[type=checkbox] {
  border-width: 2px;
  border-style: solid;
  height: 1.882rem;
  width: 1.882rem;
  border-radius: 3px;
  opacity: 1;
  margin: 0;
  top: auto;
}
.km-nova .km-list input[type=radio],
.km-nova .km-list input[type=checkbox] {
  right: 0;
}
.km-nova input[type=radio] {
  height: 1.882rem;
  width: 1.882rem;
  border-radius: 50%;
}
.km-nova input[type=radio].km-check:checked:after,
.km-nova input[type=checkbox].km-check:after {
  content: "\a0";
  display: block;
  font-size: 1.2em;
  text-align: center;
}
.km-nova input[type=checkbox].km-check:checked:after {
  margin-left: -1px;
}
.km-nova input[type=radio].km-check:checked:after {
  margin-top: 0.269rem;
  margin-left: 0.269rem;
  width: 1.075rem;
  height: 1.075rem;
  border-radius: 50%;
}
/* Standalone Checkboxes and RadioButtons */
.km-nova input.km-checkbox,
.km-nova input.km-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
}
.km-nova .km-checkbox-label,
.km-nova .km-radio-label {
  display: block;
  position: relative;
  vertical-align: middle;
  font-size: 1.2em;
  line-height: 1.5em;
  padding-left: 2.5em;
  margin-left: .89em;
}
.km-nova .km-checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.613rem;
  height: 1.613rem;
  border-width: 0.133rem;
  border-style: solid;
  border-radius: 3px;
}
.km-nova .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.613rem;
  height: 1.613rem;
  border-width: 0.133rem;
  border-style: solid;
  border-radius: 3px;
}
.km-nova input.km-checkbox:checked + .km-checkbox-label:after {
  content: "\e227";
  font-size: 1.2em;
  line-height: 1em;
  font-family: "Kendo UI";
}
.km-nova .km-radio-label {
  position: relative;
  padding-left: 2.5em;
  vertical-align: middle;
  line-height: 1.5em;
}
.km-nova .km-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-width: 0.133rem;
  border-style: solid;
  border-radius: 50%;
}
.km-nova input.km-radio:checked + .km-radio-label:after {
  content: "";
  width: 1.067rem;
  height: 1.067rem;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0.365rem;
  border-radius: 50%;
}
/* ActionSheet */
.km-nova .km-actionsheet > li > a {
  font-weight: normal;
  text-align: left;
}
.km-nova li.km-actionsheet-cancel > a {
  font-weight: bold;
}
.km-nova .km-shim li.km-actionsheet-title {
  border-width: 0 0 1px;
  border-style: solid;
  font-size: 1.2em;
  text-indent: 0;
  margin-top: 0;
  font-weight: normal;
  padding: 1.17em .7em .39em;
}
.km-nova .km-actionsheet > li:last-child > a {
  border: 0;
}
.km-nova .km-popup li.km-actionsheet-title {
  display: none;
}
.km-nova .k-overflow-wrapper .km-actionsheet-wrapper .km-actionsheet {
  border: 0;
}
/* PopOver */
.km-nova .km-drawer,
.km-nova [data-role=drawer] {
  width: 16.801rem;
}
.km-nova .km-drawer > .km-scroll-wrapper,
.km-nova .km-drawer .km-scroll-container {
  height: 100%;
}
.km-nova .km-drawer-vertical-tabstrip {
  width: 4.86rem;
}
.km-nova .km-drawer .km-scroll-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-justify-content: center;
}
.km-nova .km-drawer .km-vertical-tabstrip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-justify-content: center;
}
.km-nova .km-vertical-tabstrip .km-button {
  display: block;
  width: 100%;
  font-size: .6em;
  padding: 1.25em 0;
}
.km-nova .km-vertical-tabstrip .km-button.km-state-active:before,
.km-nova .km-drawer .km-listview li.km-state-active:before {
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.km-nova .km-drawer .km-list:not(.km-virtual-list) > li:first-child {
  border-top-width: 1px;
}
.km-nova .km-drawer .km-list:not(.km-virtual-list) > li:last-child {
  border-bottom-width: 1px;
}
.km-nova .km-drawer .km-listview li .km-badge {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4em;
}
.km-nova .km-right-drawer .km-listview-link:after,
.km-nova .km-right-drawer .km-listview-label:after {
  display: none;
}
.km-nova .km-drawer.km-left-drawer {
  -webkit-transform: translate3d(0, 0px, 0px);
          transform: translate3d(0, 0px, 0px);
}
.km-nova .km-popup .km-navbar .km-view-title,
.km-nova .km-modalview .km-header .km-navbar .km-view-title {
  padding-left: .591em;
}
.km-nova .km-modalview .km-footer .km-rightitem {
  width: 100%;
  right: 0;
}
.km-nova .km-modalview .km-footer .km-rightitem .km-button {
  width: 50%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  padding: .88em 0;
  line-height: 1.5em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: 1px 0 0 0;
  border-style: solid;
}
.km-nova .km-modalview .km-footer .km-rightitem .km-button:last-child {
  border-left-width: 1px;
}
/* Scroller */
.km-nova .km-vertical-scrollbar {
  width: .3em;
}
.km-nova .km-horizontal-scrollbar {
  height: .3em;
}
/* SplitView */
/* Generic content */
.km-nova h1,
.km-nova h2,
.km-nova h3,
.km-nova h4,
.km-nova h5,
.km-nova h6,
.km-nova p {
  margin: 0;
}
.km-nova .km-thumbnail {
  border-radius: 50%;
  width: 3.35rem;
  height: 3.35rem;
  border-width: 1px;
  border-style: solid;
  float: left;
  margin-right: 1.07rem;
}
.km-nova .km-light-font {
  font-weight: 300;
}
.km-nova .km-medium-font {
  font-weight: 500;
}
.km-nova .km-bold-font {
  font-weight: bold;
}
.km-nova h1 {
  font-weight: 500;
  margin-top: .4em;
  margin-bottom: .4em;
}
.km-nova h2 {
  font-weight: 300;
  margin-top: .4em;
  margin-bottom: .4em;
}
.km-nova h3 {
  font-weight: 400;
  margin-top: .4em;
  margin-bottom: .4em;
}
.km-nova h4 {
  font-weight: 500;
  margin-top: .4em;
  margin-bottom: .4em;
}
.km-nova h5 {
  font-weight: 300;
  margin-top: .5em;
  margin-bottom: .5em;
}
.km-nova h6 {
  font-weight: 400;
  text-transform: uppercase;
  margin-top: .6em;
  margin-bottom: .6em;
}
.km-nova .km-content .km-scroll-container > h1,
.km-nova .km-content .km-scroll-container > h2,
.km-nova .km-content .km-scroll-container > h3,
.km-nova .km-content .km-scroll-container > h4,
.km-nova .km-content .km-scroll-container > h5,
.km-nova .km-content .km-scroll-container > h6,
.km-nova .km-content .km-scroll-container > p {
  margin-left: 1rem;
  margin-right: 1rem;
}
.km-nova p {
  margin-top: .4em;
  margin-bottom: .4em;
}
.km-nova a:not([class]),
.km-nova .km-external-link {
  text-decoration: none;
}
.km-wp {
  font-size: 1em;
  font-family: 'Segoe WP', 'Segoe UI Symbol', 'Segoe UI', sans-serif;
}
.km-wp a {
  text-decoration: none;
}
.km-wp .km-button,
.km-root .km-wp .km-view .km-buttongroup .km-button {
  border-style: solid;
  border-width: .1em;
  border-radius: 0;
  line-height: 1em;
  padding: .04em .5em .16em;
}
.km-root .km-wp .km-tabstrip .km-icon,
.km-wp .km-scroller-pull .km-icon {
  width: 1em;
  height: 1em;
  font-size: 3.2em;
  border-width: .08em;
  border-style: solid;
  border-radius: 5em;
  margin-bottom: .2em;
  padding: .1em;
}
.km-wp .km-scroller-pull .km-icon {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
}
.km-wp .km-icon {
  line-height: 1em;
}
.km-wp .km-icon:before {
  margin-top: .08em;
}
.km-wp .km-icon:after {
  margin-top: .04em;
  vertical-align: middle;
  height: 1em;
}
.km-wp .km-tabstrip .km-icon:after,
.km-wp .km-tabstrip .km-icon:before {
  margin-top: .02em;
  margin-left: -0.05em;
}
.km-wp .km-tabstrip .km-icon:before {
  margin-top: .06em;
}
/* Scroll hints */
.km-wp .km-touch-scrollbar {
  border-width: 1px;
  border-style: solid;
}
.km-wp .km-vertical-scrollbar {
  width: 2px;
}
.km-wp .km-horizontal-scrollbar {
  height: 2px;
}
.km-wp .km-scrollview {
  overflow: hidden;
}
/* Orientation fallback */
@media all and (orientation: landscape) {
  .km-root > .km-pane {
    -webkit-animation-name: -kendo-landscape;
            animation-name: -kendo-landscape;
  }
}
/* Pull To Refresh */
.km-wp .km-load-more,
.km-wp .km-scroller-pull {
  font-size: 1.2em;
}
/* DropDownList */
.km-wp .km-popup {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-wp .km-popup .k-list-container {
  width: 100% !important;
  height: auto !important;
  bottom: 0;
  border: 0;
  padding: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-wp .km-actionsheet,
.km-phone .km-wp .km-popup .k-list-container {
  max-height: 80%;
}
.km-wp .km-popup li.k-state-hover {
  color: inherit;
}
/* Loader */
.km-wp .km-loader,
.km-wp .km-loading-right,
.km-wp .km-scroller-refresh {
  display: block;
  width: 100%;
  height: 4em;
  padding: 0;
  top: 50%;
  left: 0;
  border-radius: 0;
  border: 0;
  margin: -2em 0 0;
  font-size: 1.2em;
  text-align: center;
  vertical-align: middle;
  line-height: .65rem;
}
.km-wp .km-loading-right {
  position: absolute;
  z-index: -1;
}
.km-wp .km-loader {
  margin: 0;
  top: 0;
  height: 100%;
  background: transparent;
}
.km-wp .km-scroller-refresh {
  background: transparent;
  height: 3em;
  margin: 0;
  width: 100%;
  font-size: 1.2rem;
}
.km-load-more.km-scroller-refresh {
  position: relative;
}
.km-wp .km-loader h1,
.km-wp .km-scroller-refresh .km-template {
  position: absolute;
  display: none;
  font-size: 1em;
  width: 100%;
  left: 0;
  margin-left: 0;
  top: 50%;
}
.km-wp .km-scroller-refresh .km-template {
  left: 0;
  margin-left: 0;
  text-align: center;
  width: 100%;
}
.km-wp .km-loader .km-loading,
.km-wp .km-scroller-refresh .km-icon {
  -webkit-animation: none;
          animation: none;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
}
.km-wp .km-loader:before,
.km-wp .km-loader:after,
.km-wp .km-loader .km-loading,
.km-wp .km-loader .km-loading:after,
.km-wp .km-loader .km-loading:before,
.km-wp .km-scroller-refresh:after,
.km-wp .km-scroller-refresh:before,
.km-wp .km-scroller-refresh .km-icon,
.km-wp .km-scroller-refresh .km-icon:after,
.km-wp .km-scroller-refresh .km-icon:before {
  position: absolute;
  display: inline-block;
  content: "\a0";
  top: 0;
  left: 0;
  border: 0;
  width: 12em;
  margin: 0;
  padding: 0;
  height: 0;
  text-align: left;
  font-family: "Kendo UI";
  vertical-align: top;
}
.km-wp .km-scroller-refresh:after,
.km-wp .km-scroller-refresh:before,
.km-wp .km-scroller-refresh .km-icon,
.km-wp .km-scroller-refresh .km-icon:after,
.km-wp .km-scroller-refresh .km-icon:before {
  width: 100%;
}
.km-wp .km-scroller-refresh:after,
.km-wp .km-scroller-refresh:before,
.km-wp .km-scroller-refresh .km-icon {
  top: 46%;
  font-size: .3em;
}
.km-wp .km-loader:before,
.km-wp .km-loader:after,
.km-wp .km-loader .km-loading {
  left: 50%;
  margin-left: -7em;
  top: 50%;
  font-size: .3em;
  line-height: 0;
}
.km-wp .km-loader:before,
.km-wp .km-loader:after,
.km-wp .km-loader .km-loading:after,
.km-wp .km-loader .km-loading:before,
.km-wp .km-scroller-refresh:after,
.km-wp .km-scroller-refresh:before,
.km-wp .km-scroller-refresh .km-icon:after,
.km-wp .km-scroller-refresh .km-icon:before {
  opacity: 0;
  height: .6em;
  border-left: .7em solid;
  -webkit-animation: km-wpload 2s infinite linear;
          animation: km-wpload 2s infinite linear;
}
.km-wp .km-loader .km-loading:after,
.km-wp .km-scroller-refresh .km-icon:after {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.km-wp .km-loader .km-loading:before,
.km-wp .km-scroller-refresh .km-icon:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.km-wp .km-loader:before,
.km-wp .km-scroller-refresh:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.km-wp .km-loader:after,
.km-wp .km-scroller-refresh:after {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@keyframes km-wpload {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: translateX(30%);
            transform: translateX(30%);
  }
  16% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  42% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes km-wpload {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: translateX(30%);
  }
  16% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  42% {
    -webkit-transform: translateX(70%);
  }
  50% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.km-wp .km-view:not(.km-splitview) .km-content:not(.km-stretched-view) {
  display: block;
}
/* Color Template */
/* Variables */
.km-wp,
.km-wp .km-content,
.km-wp .km-button {
  background-color: Background;
}
.km-wp-dark-force,
.km-wp-dark-force .km-content,
.km-wp-dark-force .km-button {
  background-color: #000;
}
.km-wp-light-force,
.km-wp-light-force .km-content,
.km-wp-light-force .km-button {
  background-color: #fff;
}
.km-wp-dark,
.km-wp-dark a,
.km-wp-dark .k-widget,
.km-wp-dark .k-group,
.km-wp-dark .k-content,
.km-wp-dark .km-button,
.km-wp-dark .km-load-more,
.km-wp-dark .km-scroller-pull,
.km-wp-dark .km-detail,
.km-wp-dark .km-popup .km-item,
.km-wp-dark .k-dropdown .k-dropdown-wrap {
  color: #fff;
}
.km-wp-light,
.km-wp-light a,
.km-wp-light .k-widget,
.km-wp-light .k-group,
.km-wp-light .k-content,
.km-wp-light .km-button,
.km-wp-light .km-load-more,
.km-wp-light .km-scroller-pull,
.km-wp-light .km-detail,
.km-wp-light .km-popup .km-item,
.km-wp-light .k-dropdown .k-dropdown-wrap {
  color: #000;
}
.km-wp-dark .km-actionsheet-wrapper,
.km-wp-dark .km-popup .k-list-container,
.km-wp-dark .km-modalview,
.km-wp-dark .km-popup-wrapper,
.km-wp-dark .km-footer {
  background-color: #1f1f1f;
}
.km-wp-light .km-actionsheet-wrapper,
.km-wp-light .km-popup .k-list-container,
.km-wp-light .km-modalview,
.km-wp-light .km-popup-wrapper,
.km-wp-light .km-footer {
  background-color: #ddd;
}
.km-wp-dark .km-button,
.km-wp-dark .km-listview-link:after {
  border-color: #fff;
}
.km-wp-light .km-button,
.km-wp-light .km-listview-link:after {
  border-color: #000;
}
.km-wp .km-detail:active,
.km-wp .km-button:active,
.km-wp .km-detail.km-state-active,
.km-wp .km-button.km-state-active,
.km-wp .km-actionsheet > li > a:hover,
.km-wp .km-actionsheet > li > a:active,
.km-wp .km-actionsheet > li > .k-state-active,
.km-wp .km-popup li.k-item:active,
.km-wp .km-popup li.k-state-selected {
  background: Highlight;
}
.km-wp .km-detail:active,
.km-wp .km-detail.km-state-active,
.km-wp *:not(.km-tabstrip) > .km-button:active,
.km-wp *:not(.km-tabstrip) > .km-button.km-state-active,
.km-wp .km-actionsheet > li > a:hover,
.km-wp .km-actionsheet > li > a:active,
.km-wp .km-actionsheet > li > .k-state-active,
.km-wp .km-popup li.k-item:active,
.km-wp .km-popup li.k-state-selected {
  color: HighlightText;
}
.km-wp-dark .km-list > li > .km-icon,
.km-wp-dark .km-listview-link > .km-icon {
  background: #333;
}
.km-wp-light .km-list > li > .km-icon,
.km-wp-light .km-listview-link > .km-icon {
  background: #bebebe;
}
.km-wp-dark .km-state-active .km-listview-link,
.km-wp-dark .km-state-active .km-listview-label {
  color: #ddd;
}
.km-wp-light .km-state-active .km-listview-link,
.km-wp-light .km-state-active .km-listview-label {
  color: #1f1f1f;
}
.km-wp .km-badge,
.km-wp .km-state-active .km-listview-link:after {
  border-color: HighlightText;
}
.km-wp .km-listview-label input[type=radio],
.km-wp .km-listview-label input[type=checkbox] {
  border-color: #fff;
  -webkit-box-shadow: 0 0 1px #fff;
          box-shadow: 0 0 1px #fff;
}
.km-wp-light .km-listview-label input[type=radio],
.km-wp-light .km-listview-label input[type=checkbox] {
  border-color: #000;
  -webkit-box-shadow: 0 0 1px #000;
          box-shadow: 0 0 1px #000;
}
.km-wp .km-listview-label input[type=checkbox]:checked:after {
  color: #fff;
}
.km-wp-light .km-listview-label input[type=checkbox]:checked:after {
  color: #000;
}
.km-wp .km-listview-label input[type=radio]:checked:after {
  color: transparent;
  background: #fff;
}
.km-wp-light .km-listview-label input[type=radio]:checked:after {
  background: #000;
}
.km-wp .km-badge,
.km-wp .km-group-title {
  background-color: Highlight;
  color: HighlightText;
}
.km-wp-dark .km-loader h1,
.km-wp-dark .km-filter-wrap:before,
.km-wp-dark .km-filter-reset .km-clear {
  color: #fff;
}
.km-wp-light .km-loader h1,
.km-wp-light .km-filter-wrap:before,
.km-wp-light .km-filter-reset .km-clear {
  color: #000;
}
.km-wp .km-navbar,
.km-wp .k-toolbar {
  background: Background;
}
.km-wp-dark-force .km-navbar,
.km-wp-dark-force .k-toolbar {
  background: #000;
}
.km-wp-light-force .km-navbar,
.km-wp-light-force .k-toolbar {
  background: #fff;
}
.km-wp-dark .km-pages > li {
  background: #fff;
}
.km-wp-light .km-pages > li {
  background: #000;
}
.km-wp .km-pages .km-current-page {
  background: Highlight;
}
.km-wp-dark .km-switch-wrapper {
  border-color: #fff;
}
.km-wp-light .km-switch-wrapper {
  border-color: #000;
}
.km-wp-light .km-switch-container {
  -webkit-box-shadow: inset 0 0 0 0.2em #fff;
          box-shadow: inset 0 0 0 0.2em #fff;
}
.km-wp-dark .km-switch-container {
  -webkit-box-shadow: inset 0 0 0 0.2em #000;
          box-shadow: inset 0 0 0 0.2em #000;
}
.km-wp-dark .km-switch-handle {
  -webkit-box-shadow: 0 0 0 0.3em #000;
          box-shadow: 0 0 0 0.3em #000;
  background: #fff;
}
.km-wp-light .km-switch-handle {
  -webkit-box-shadow: 0 0 0 0.3em #fff;
          box-shadow: 0 0 0 0.3em #fff;
  background: #000;
}
.km-wp .km-switch-background {
  background: Highlight;
}
.km-wp .km-tabstrip .km-state-active .km-icon {
  background-color: Highlight;
  color: HighlightText;
}
.km-wp-dark .km-scroller-pull .km-icon,
.km-wp-dark .km-tabstrip .km-state-active .km-icon {
  border-color: #fff;
}
.km-wp-light .km-scroller-pull .km-icon,
.km-wp-light .km-tabstrip .km-state-active .km-icon {
  border-color: #000;
}
.km-wp-light .km-loader:before,
.km-wp-light .km-loader:after,
.km-wp-light .km-loader .km-loading,
.km-wp-light .km-loader .km-loading:after,
.km-wp-light .km-loader .km-loading:before,
.km-wp-light .km-scroller-refresh:after,
.km-wp-light .km-scroller-refresh:before,
.km-wp-light .km-scroller-refresh .km-icon,
.km-wp-light .km-scroller-refresh .km-icon:after,
.km-wp-light .km-scroller-refresh .km-icon:before {
  color: #000;
}
.km-wp-dark .km-loading-right {
  background: #1f1f1f;
}
.km-wp-light .km-loading-right {
  background: #ddd;
}
.km-wp .km-touch-scrollbar {
  border-color: rgba(255, 255, 255, 0.4);
}
/* Forms */
.km-wp input[type=password],
.km-wp input[type=search],
.km-wp input[type=number],
.km-wp input[type=tel],
.km-wp input[type=url],
.km-wp input[type=email],
.km-wp input[type=text]:not(.k-input),
.km-wp input[type=month],
.km-wp input[type=color],
.km-wp input[type=week],
.km-wp input[type=date],
.km-wp input[type=time],
.km-wp input[type=datetime],
.km-wp input[type=datetime-local],
.km-wp select:not([multiple]),
.km-wp .k-dropdown,
.km-wp textarea {
  border-color: #fff;
  background: Background;
  color: #fff;
}
.km-wp input::-moz-selection,
.km-wp textarea::-moz-selection {
  color: HighlightText;
  background: Highlight;
}
.km-wp input::selection,
.km-wp textarea::selection {
  color: HighlightText;
  background: Highlight;
}
.km-wp-light input[type=password],
.km-wp-light input[type=search],
.km-wp-light input[type=number],
.km-wp-light input[type=tel],
.km-wp-light input[type=url],
.km-wp-light input[type=email],
.km-wp-light input[type=text]:not(.k-input),
.km-wp-light input[type=month],
.km-wp-light input[type=color],
.km-wp-light input[type=week],
.km-wp-light input[type=date],
.km-wp-light input[type=time],
.km-wp-light input[type=datetime],
.km-wp-light input[type=datetime-local],
.km-wp-light select:not([multiple]),
.km-wp-light .k-dropdown,
.km-wp-light textarea {
  border-color: #000;
  color: #000;
}
.km-wp-dark-force input[type=password],
.km-wp-dark-force input[type=search],
.km-wp-dark-force input[type=number],
.km-wp-dark-force input[type=tel],
.km-wp-dark-force input[type=url],
.km-wp-dark-force input[type=email],
.km-wp-dark-force input[type=text]:not(.k-input),
.km-wp-dark-force input[type=month],
.km-wp-dark-force input[type=color],
.km-wp-dark-force input[type=week],
.km-wp-dark-force input[type=date],
.km-wp-dark-force input[type=time],
.km-wp-dark-force input[type=datetime],
.km-wp-dark-force input[type=datetime-local],
.km-wp-dark-force select:not([multiple]),
.km-wp-dark-force .k-dropdown,
.km-wp-dark-force textarea {
  background: #000;
}
.km-wp-light-force input[type=password],
.km-wp-light-force input[type=search],
.km-wp-light-force input[type=number],
.km-wp-light-force input[type=tel],
.km-wp-light-force input[type=url],
.km-wp-light-force input[type=email],
.km-wp-light-force input[type=text]:not(.k-input),
.km-wp-light-force input[type=month],
.km-wp-light-force input[type=color],
.km-wp-light-force input[type=week],
.km-wp-light-force input[type=date],
.km-wp-light-force input[type=time],
.km-wp-light-force input[type=datetime],
.km-wp-light-force input[type=datetime-local],
.km-wp-light-force select:not([multiple]),
.km-wp-light-force .k-dropdown,
.km-wp-light-force textarea {
  background: #fff;
}
.km-wp input[type=password]:focus,
.km-wp input[type=search]:focus,
.km-wp input[type=number]:focus,
.km-wp input[type=tel]:focus,
.km-wp input[type=url]:focus,
.km-wp input[type=email]:focus,
.km-wp input[type=text]:not(.k-input):focus,
.km-wp input[type=month]:focus,
.km-wp input[type=color]:focus,
.km-wp input[type=week]:focus,
.km-wp input[type=date]:focus,
.km-wp input[type=time]:focus,
.km-wp input[type=datetime]:focus,
.km-wp input[type=datetime-local]:focus,
.km-wp textarea:focus {
  border-color: Highlight;
}
.km-wp select:not([multiple]):focus,
.km-wp .k-dropdown .k-state-active,
.km-wp .k-dropdown .k-state-focused {
  color: HighlightText;
  border-color: HighlightText;
  background-color: Highlight;
}
/* Slider */
.km-wp .k-slider .k-draghandle,
.km-wp .k-slider .k-draghandle:hover {
  background: #fff;
}
.km-wp-light .k-slider .k-draghandle,
.km-wp-light .k-slider .k-draghandle:hover {
  background: #000;
}
.km-wp .k-slider .k-tick-large:before {
  background: #000;
}
.km-wp-light .k-slider .k-tick-large:before {
  background: #fff;
}
.km-wp .k-slider-selection {
  background: Highlight;
}
.km-wp .k-slider-track {
  background: #333;
}
.km-wp-light .k-slider-track {
  background: #bebebe;
}
/* Collapsible */
.km-wp .km-collapsible-header,
.km-wp .km-collapsible-content {
  border-color: transparent;
}
.km-wp .km-collapsible-content {
  color: #999;
}
/* Nova theme features */
.km-wp label.km-required:after {
  color: #f44336;
}
.km-wp .km-list .km-legend-button,
.km-wp .km-list fieldset legend {
  background-color: Highlight;
  color: HighlightText;
}
.km-wp .km-list fieldset input[type=password],
.km-wp .km-list .km-label-above input[type=password],
.km-wp .km-list fieldset input[type=search],
.km-wp .km-list .km-label-above input[type=search],
.km-wp .km-list fieldset input[type=number],
.km-wp .km-list .km-label-above input[type=number],
.km-wp .km-list fieldset input[type=tel],
.km-wp .km-list .km-label-above input[type=tel],
.km-wp .km-list fieldset input[type=url],
.km-wp .km-list .km-label-above input[type=url],
.km-wp .km-list fieldset input[type=email],
.km-wp .km-list .km-label-above input[type=email],
.km-wp .km-list fieldset input[type=month],
.km-wp .km-list .km-label-above input[type=month],
.km-wp .km-list fieldset input[type=color],
.km-wp .km-list .km-label-above input[type=color],
.km-wp .km-list fieldset input[type=week],
.km-wp .km-list .km-label-above input[type=week],
.km-wp .km-list fieldset input[type=date],
.km-wp .km-list .km-label-above input[type=date],
.km-wp .km-list fieldset input[type=time],
.km-wp .km-list .km-label-above input[type=time],
.km-wp .km-list fieldset input[type=datetime],
.km-wp .km-list .km-label-above input[type=datetime],
.km-wp .km-list fieldset input[type=datetime-local],
.km-wp .km-list .km-label-above input[type=datetime-local],
.km-wp .km-list fieldset input[type=text]:not(.k-input),
.km-wp .km-list .km-label-above input[type=text]:not(.k-input),
.km-wp .km-list fieldset select:not([multiple]),
.km-wp .km-list .km-label-above select:not([multiple]),
.km-wp .km-list fieldset .k-dropdown-wrap,
.km-wp .km-list .km-label-above .k-dropdown-wrap,
.km-wp .km-list fieldset textarea,
.km-wp .km-list .km-label-above textarea {
  color: #000;
  background: #ddd;
}
.km-wp input.km-radio:checked + .km-radio-label:after {
  background: #fff;
}
.km-wp-light input.km-radio:checked + .km-radio-label:after {
  background: #000;
}
/* Validation */
.km-wp .km-list > li label.km-invalid,
.km-wp .km-list fieldset .km-legend-button.km-invalid,
.km-wp .km-list > li label.km-invalid.km-required:after {
  color: #f52312;
}
.km-wp .km-invalid-msg {
  color: #fff;
  background: #f52312;
}
/* Button */
.km-wp .km-button {
  text-transform: lowercase;
}
.km-wp .km-button {
  font-size: .9em;
  line-height: 1.3em;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.km-wp .km-button .km-icon {
  margin-top: 2px;
  margin-bottom: 2px;
  vertical-align: text-top;
}
.km-wp .km-button .km-text {
  margin-top: -3px;
  display: inline-block;
  padding-bottom: 3px;
}
/* Badges */
.km-wp .km-badge {
  border-width: .1em;
  border-style: solid;
  -webkit-background-clip: border-box;
          background-clip: border-box;
  line-height: 1.9em;
  border-radius: 2em;
}
.km-wp .km-tabstrip .km-badge {
  margin-top: -0.7em;
  line-height: 2.1em;
}
/* ButtonGroup */
html .km-wp .km-buttongroup {
  top: 0;
  display: block;
  margin: auto;
  text-align: center;
}
.km-wp .k-toolbar .km-buttongroup {
  display: inline-block;
}
.km-root .km-wp .km-view .km-buttongroup .km-button {
  border-radius: 0;
  display: inline-block;
  margin-left: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.km-root .km-wp .km-view .km-buttongroup .km-button:first-child {
  margin-left: 0;
}
.km-wp .km-buttongroup .km-icon {
  vertical-align: middle;
}
/* NavBar */
.km-wp .km-leftitem,
.km-wp .km-rightitem {
  line-height: 2.6rem;
  vertical-align: middle;
}
.km-wp .km-navbar .km-view-title {
  visibility: visible;
  font-size: 1.6em;
  line-height: 2.6rem;
  text-align: left;
  margin-left: .5em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-wp .km-navbar .km-back {
  display: none;
}
.km-wp .km-leftitem {
  position: relative;
  float: left;
  margin-right: 1em;
}
/* ToolBar */
.km-wp .k-toolbar {
  line-height: 1.6em;
}
.km-wp .k-toolbar .km-button,
.km-wp .k-toolbar .k-split-button,
.km-root .km-wp .k-toolbar .km-buttongroup .km-button {
  padding-top: 0;
  padding-bottom: 0;
}
.km-wp .k-toolbar > * {
  color: inherit;
}
.km-wp .k-toolbar > .km-button,
.km-wp .k-toolbar .km-buttongroup,
.km-wp .k-toolbar .k-split-button,
.km-wp .k-toolbar .k-widget,
.km-wp .k-toolbar .km-widget,
.km-wp .k-toolbar .k-textbox,
.km-wp .k-toolbar label,
.km-wp .k-toolbar .k-separator {
  margin: 0 .3em;
}
.km-wp .k-toolbar .km-button,
.km-wp .k-toolbar .k-split-button,
.km-wp .k-toolbar .km-buttongroup,
.km-root .km-wp .k-toolbar .km-buttongroup .km-button {
  line-height: inherit;
}
.km-wp .k-toolbar .km-button .km-text {
  padding-bottom: 0;
}
.km-wp .k-toolbar .k-split-button-arrow {
  border-radius: 0;
}
.km-root .km-wp .k-toolbar .km-buttongroup .km-button {
  margin-left: -2px;
  height: auto;
  padding-bottom: .3em;
}
.km-wp .k-overflow-anchor .km-icon:after,
.km-wp .k-overflow-anchor .km-icon:before {
  margin-top: 0;
}
.km-wp .k-toolbar .k-button-icon .km-icon,
.km-wp .k-toolbar .k-button-icontext .km-icon,
.km-wp .k-split-button .km-arrowdown {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
  margin-top: -5px;
  margin-bottom: -1px;
  vertical-align: middle;
}
.km-wp .k-split-button .km-arrowdown {
  margin-left: 2px;
  margin-right: 1px;
}
.km-wp .k-split-button .k-button-icontext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.km-wp .k-split-button-arrow {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.km-wp .km-actionsheet.k-split-container > li > .km-button,
.km-wp .km-actionsheet.k-overflow-container > li > .km-button {
  min-width: 10em;
  text-align: center;
  font-size: 1.1em;
  border: 0;
  border-radius: 0;
}
.km-wp .km-actionsheet.k-split-container > li > .km-button {
  min-width: 5em;
}
.km-wp .k-split-wrapper .km-actionsheet-wrapper,
.km-wp .k-overflow-wrapper .km-actionsheet-wrapper {
  padding: 1px;
}
html .km-wp .k-split-container.km-actionsheet > li,
html .km-wp .k-overflow-container.km-actionsheet > li {
  margin-top: 0;
  margin-bottom: 0;
}
.km-wp .k-split-container.km-actionsheet,
.km-wp .k-overflow-container.km-actionsheet {
  border-top: 0;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}
.km-wp .k-split-container.km-actionsheet,
.km-wp .k-overflow-container.km-actionsheet {
  border-top: 0;
}
.km-wp .k-split-container.km-actionsheet *,
.km-wp .k-overflow-container.km-actionsheet * {
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}
/* TabStrip */
.km-wp .km-tabstrip .km-text {
  display: block;
}
.km-wp .km-tabstrip {
  padding: .7em 0 .2em;
  background: transparent;
}
.km-wp.km-horizontal .km-tabstrip {
  padding: .4em 0 .2em;
}
.km-wp .km-tabstrip .km-button {
  border-width: 0;
  margin: 0 1.2em;
  background-color: transparent;
  font-size: .5em;
}
.km-wp .km-tabstrip .km-icon:after,
.km-wp .km-tabstrip .km-icon:before {
  vertical-align: middle;
  line-height: .8em;
}
/* Switch */
.km-wp .km-switch {
  font-size: .7em;
  width: 6.4em;
  height: 2.4em;
  line-height: 2.4em;
}
.km-wp .km-list .km-switch {
  margin-top: -1.2em;
}
.km-wp .km-switch-wrapper,
.km-wp .km-switch-handle,
.km-wp .km-switch-container {
  border-radius: 0;
}
.km-wp .km-switch-wrapper {
  border-style: solid;
  border-width: .2em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-wp .km-switch-container {
  left: .2em;
  top: .2em;
  right: .2em;
  bottom: .2em;
  width: auto;
  height: auto;
  overflow: visible;
}
.km-wp .km-switch-handle {
  width: 1.4em;
  margin-top: -0.2em;
  padding-bottom: .4em;
  z-index: 1;
}
.km-wp .km-switch-handle > span {
  display: none;
}
.km-wp .km-switch-background {
  width: 100%;
  margin-left: -6em;
}
/* ListView */
.km-wp .km-listinset,
.km-wp .km-listgroupinset {
  margin: 0 1em;
}
.km-wp .km-list > li > .km-icon,
.km-wp .km-listview-link > .km-icon {
  margin: -0.1em 0.2em 0 -0.4em;
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  font-size: 1.6em;
  vertical-align: middle;
}
.km-wp .km-list > li > .km-icon:before,
.km-wp .km-listview-link > .km-icon:before {
  display: none;
}
.km-wp .km-list > li > .km-icon:after,
.km-wp .km-listview-link > .km-icon:after {
  display: inline-block;
  width: auto;
}
.km-wp .km-listview-link,
.km-wp .km-listview-label {
  padding-bottom: .3em;
  -webkit-transition: -webkit-transform .1s, color .1s;
          transition: transform .1s, color .1s;
}
.km-wp .km-state-active .km-listview-link,
.km-wp .km-state-active .km-listview-label {
  background: none;
  -webkit-transform: scale(0.98, 0.94);
      -ms-transform: scale(0.98, 0.94);
          transform: scale(0.98, 0.94);
}
.km-wp .km-listview-link:after {
  display: none;
}
.km-wp .km-group-title {
  text-transform: uppercase;
  font-size: 1em;
  padding: 0;
  margin-bottom: .2em;
}
.km-wp .km-group-title .km-text {
  text-indent: 0;
  padding-left: .7em;
}
.km-wp .km-scroller-pull .km-template {
  text-transform: lowercase;
}
/* Filter box */
.km-wp .km-listview-wrapper form .km-filter-wrap > input {
  margin-top: -1px;
  font-size: 1.2em;
  padding-left: 1.6em;
  padding-right: 1.8em;
}
.km-wp .km-filter-wrap > input::-ms-clear {
  display: none;
}
.km-wp .km-filter-wrap:before,
.km-wp .km-filter-reset .km-clear {
  font-size: 1em;
}
.km-wp .km-filter-wrap:before {
  font-size: .8em;
  margin: 0.05em -1.3em 0 0.3em;
}
.km-wp .km-filter-reset .km-clear:after {
  content: "\e038";
}
/* ListView */
.km-wp .km-list > li {
  overflow: hidden;
}
/* ListView Detail Icons */
.km-wp .km-detail {
  position: absolute;
  height: 1.2em;
  width: 1.3em;
  right: .4em;
  font-size: 1.5em;
  margin-top: -0.6em;
  border: none;
  background: Background;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.km-wp .km-detaildisclose:after,
.km-wp .km-detaildisclose:before {
  font-size: .86em;
  margin-left: -2px;
}
/* ScrollView */
.km-wp .km-pages > li {
  width: .2em;
  height: .2em;
}
/* Checkboxes and Radios */
.km-wp .km-listview-label input[type=radio],
.km-wp .km-listview-label input[type=checkbox] {
  border-width: 1px;
  border-style: solid;
  width: 1em;
  height: 1em;
}
.km-wp .km-listview-label input[type=radio],
.km-wp .km-listview-label input[type=radio]:after,
.km-wp .km-state-active input[type=radio]:before {
  border-radius: 1em;
}
.km-wp .km-listview-label input[type=radio]:after,
.km-wp .km-listview-label input[type=checkbox]:after,
.km-wp .km-state-active input[type=radio]:before,
.km-wp .km-state-active input[type=checkbox]:before {
  content: "\a0";
  display: block;
  margin: 16% 0 0 16%;
  width: 78%;
  height: 78%;
}
.km-wp .km-listview-label input[type=radio]:after {
  margin: 11% 0 0 11%;
}
.km-wp .km-listview-label input[type=checkbox]:after {
  margin: -60% 0 0 10%;
  width: 120%;
  height: 120%;
}
.km-wp .km-listview-label input[type=checkbox]:checked:after {
  font-size: 1.2em;
  -webkit-transform: scale(1, 1.2);
      -ms-transform: scale(1, 1.2);
          transform: scale(1, 1.2);
}
.km-wp .km-state-active input[type=radio]:before,
.km-wp .km-state-active input[type=checkbox]:before {
  position: absolute;
  margin: -45% 0 0 -45%;
  width: 190%;
  height: 190%;
}
/* Forms */
.km-wp input[type=password],
.km-wp input[type=search],
.km-wp input[type=number],
.km-wp input[type=tel],
.km-wp input[type=url],
.km-wp input[type=email],
.km-wp input[type=text]:not(.k-input),
.km-wp input[type=month],
.km-wp input[type=color],
.km-wp input[type=week],
.km-wp input[type=date],
.km-wp input[type=time],
.km-wp input[type=datetime],
.km-wp input[type=datetime-local],
.km-wp select:not([multiple]),
.km-wp .k-dropdown,
.km-wp textarea {
  border-width: 2px;
  border-style: solid;
  font-size: 100%;
  font-family: inherit;
  padding: 0 .3em;
  margin-top: -0.65em;
}
.km-wp .k-dropdown {
  padding: 0;
  width: auto;
  min-width: 8em;
  margin-top: -0.75em;
  border-radius: 0;
}
.km-wp .k-dropdown .k-dropdown-wrap {
  width: 100%;
  padding: 0;
  display: block;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
  border: 0;
}
.km-wp .k-dropdown .k-input {
  color: inherit;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 .2em;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.km-wp select::-ms-expand {
  display: none;
}
/* Slider */
.km-wp .k-slider-horizontal {
  height: .5em;
  margin: -0.65em 0.35em 0 0;
  width: -webkit-calc(50% - .7em);
  width: calc(50% - .7em);
}
.km-wp .k-slider-vertical {
  width: .5em;
}
.km-wp .k-slider .k-draghandle,
.km-wp .k-slider .k-draghandle:hover {
  border: 0;
  border-radius: 0;
  z-index: 1;
}
.km-wp .k-slider-horizontal .k-draghandle {
  top: -0.25em;
  height: 200%;
  width: .6em;
}
.km-wp .k-slider-vertical .k-draghandle {
  left: -0.25em;
  width: 200%;
  height: .6em;
}
.km-wp .k-slider-track,
.km-wp .k-slider-selection {
  top: 0;
  left: 0 !important;
  right: 0;
  border-radius: 0;
}
.km-wp .k-slider-vertical .k-slider-track,
.km-wp .k-slider-vertical .k-slider-selection {
  top: auto;
  bottom: 0;
}
.km-wp .k-slider .k-tick,
.km-wp .k-slider .k-tick-large {
  visibility: hidden;
  display: inline-block;
  float: none;
}
.km-wp .k-slider .k-tick-large {
  background: none;
  visibility: visible;
}
.km-wp .k-slider .k-tick-large:before {
  content: "\a0";
  display: inline-block;
  text-align: center;
  position: relative;
  width: 2px;
  height: 100%;
}
.km-wp .k-slider-items {
  top: 100%;
  white-space: nowrap;
}
.km-wp .k-slider-items,
.km-wp .k-slider .k-tick-large {
  position: relative;
}
.km-wp .k-slider .k-tick-large {
  z-index: 1;
}
.km-wp .k-slider-selection {
  margin-left: 0;
}
.km-wp .k-slider-track {
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: .5em solid transparent;
  border-width: .5em 0;
}
.km-wp-light .k-slider-track {
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
.km-wp .km-list .km-full-width-slider {
  position: relative;
  margin-top: 0;
  padding: 1.55em 0;
  width: auto;
  display: block;
}
.km-wp .km-full-width-slider .k-slider-track {
  height: .5rem;
  top: 50%;
}
/* Nova theme features */
.km-wp .km-icon-label.km-required:after {
  margin-left: -0.89em;
}
.km-wp .km-label-above {
  font-size: 1em;
  padding-top: .27em;
  padding-bottom: 2.5em;
  margin-left: -0.7em;
}
.km-wp .km-label-above textarea {
  top: 50%;
}
.km-wp .km-list fieldset {
  border: none;
  margin: 0.55em -1.4em 0;
  padding: 0 .7em;
}
.km-wp .km-list fieldset legend {
  line-height: 2em;
  text-indent: 0;
  font-weight: normal;
  padding: 1.23em 1.61em .11em;
  width: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  text-transform: uppercase;
  padding: 0;
  padding-left: .7em;
}
.km-wp .km-list fieldset legend + .km-inline-field {
  margin-top: .5em;
}
.km-wp .km-label-above {
  font-size: 1em;
  margin-left: -0.7em;
}
.km-wp .km-legend-button {
  text-transform: uppercase;
}
.km-wp .km-list fieldset .km-inline-field {
  margin-top: 0;
  margin-left: 0;
}
.km-wp .km-inline-field.km-label-above {
  margin-bottom: 0;
}
.km-wp .km-list .km-inline-field input[type=password],
.km-wp .km-list .km-inline-field input[type=search],
.km-wp .km-list .km-inline-field input[type=number],
.km-wp .km-list .km-inline-field input[type=tel],
.km-wp .km-list .km-inline-field input[type=url],
.km-wp .km-list .km-inline-field input[type=email],
.km-wp .km-list .km-inline-field input[type=month],
.km-wp .km-list .km-inline-field input[type=color],
.km-wp .km-list .km-inline-field input[type=week],
.km-wp .km-list .km-inline-field input[type=date],
.km-wp .km-list .km-inline-field input[type=time],
.km-wp .km-list .km-inline-field input[type=datetime],
.km-wp .km-list .km-inline-field input[type=datetime-local],
.km-wp .km-list .km-inline-field input[type=text]:not(.k-input),
.km-wp .km-list .km-inline-field select:not([multiple]),
.km-wp .km-list .km-inline-field .k-dropdown-wrap,
.km-wp .km-list .km-inline-field textarea {
  left: 0;
  padding-left: 1em;
}
.km-wp .km-list fieldset input[type=password],
.km-wp .km-list .km-label-above input[type=password],
.km-wp .km-list fieldset input[type=search],
.km-wp .km-list .km-label-above input[type=search],
.km-wp .km-list fieldset input[type=number],
.km-wp .km-list .km-label-above input[type=number],
.km-wp .km-list fieldset input[type=tel],
.km-wp .km-list .km-label-above input[type=tel],
.km-wp .km-list fieldset input[type=url],
.km-wp .km-list .km-label-above input[type=url],
.km-wp .km-list fieldset input[type=email],
.km-wp .km-list .km-label-above input[type=email],
.km-wp .km-list fieldset input[type=month],
.km-wp .km-list .km-label-above input[type=month],
.km-wp .km-list fieldset input[type=color],
.km-wp .km-list .km-label-above input[type=color],
.km-wp .km-list fieldset input[type=week],
.km-wp .km-list .km-label-above input[type=week],
.km-wp .km-list fieldset input[type=date],
.km-wp .km-list .km-label-above input[type=date],
.km-wp .km-list fieldset input[type=time],
.km-wp .km-list .km-label-above input[type=time],
.km-wp .km-list fieldset input[type=datetime],
.km-wp .km-list .km-label-above input[type=datetime],
.km-wp .km-list fieldset input[type=datetime-local],
.km-wp .km-list .km-label-above input[type=datetime-local],
.km-wp .km-list fieldset input[type=text]:not(.k-input),
.km-wp .km-list .km-label-above input[type=text]:not(.k-input),
.km-wp .km-list fieldset select:not([multiple]),
.km-wp .km-list .km-label-above select:not([multiple]),
.km-wp .km-list fieldset .k-dropdown-wrap,
.km-wp .km-list .km-label-above .k-dropdown-wrap,
.km-wp .km-list fieldset textarea,
.km-wp .km-list .km-label-above textarea {
  border: none;
  padding: .4em 0 .4em .7em;
  margin-top: 0;
}
.km-wp .km-list fieldset input[type=password],
.km-wp .km-list fieldset input[type=search],
.km-wp .km-list fieldset input[type=number],
.km-wp .km-list fieldset input[type=tel],
.km-wp .km-list fieldset input[type=url],
.km-wp .km-list fieldset input[type=email],
.km-wp .km-list fieldset input[type=month],
.km-wp .km-list fieldset input[type=color],
.km-wp .km-list fieldset input[type=week],
.km-wp .km-list fieldset input[type=date],
.km-wp .km-list fieldset input[type=time],
.km-wp .km-list fieldset input[type=datetime],
.km-wp .km-list fieldset input[type=datetime-local],
.km-wp .km-list fieldset input[type=text]:not(.k-input),
.km-wp .km-list fieldset select:not([multiple]),
.km-wp .km-list fieldset .k-dropdown-wrap,
.km-wp .km-list fieldset textarea {
  padding: 5px 0;
  margin-top: 0;
  padding-left: .7em;
}
/* Standalone Checkboxes and RadioButtons */
.km-wp .km-checkbox-label,
.km-wp .km-radio-label {
  font-size: 1em;
  line-height: 1.5em;
  margin-left: .89em;
}
.km-wp .km-checkbox-label:before,
.km-wp .km-checkbox-label:after {
  right: .7em;
  width: 1.07rem;
  height: 1.07rem;
  text-align: right;
  font-size: 1.5em;
}
.km-wp .km-checkbox-label:before {
  border-width: 0.133rem;
  border-style: solid;
}
.km-wp input.km-checkbox:checked + .km-checkbox-label:after {
  font-size: 1.2em;
  line-height: 1em;
  right: 1em;
}
.km-wp .km-radio-label {
  padding-left: 2.5em;
  line-height: 1.5em;
}
.km-wp .km-radio-label:before {
  width: 1rem;
  height: 1rem;
  border-width: 0.133rem;
  border-style: solid;
}
.km-wp input.km-radio:checked + .km-radio-label:after {
  border-width: 2px;
  border-style: solid;
  top: 36%;
  -webkit-transform: translateY(-36%);
      -ms-transform: translateY(-36%);
          transform: translateY(-36%);
}
.km-wp input.km-radio:checked + .km-radio-label:after {
  width: .27rem;
  height: .27rem;
  left: .35rem;
}
/* ActionSheet */
.km-wp .km-actionsheet-root > .k-animation-container {
  left: 0 !important;
}
.km-wp .km-actionsheet-wrapper {
  bottom: 0;
}
.km-wp .km-popup .k-item,
.km-wp .km-actionsheet > li {
  padding: 0;
}
.km-wp .km-popup .k-item,
.km-wp li.km-actionsheet-title,
.km-wp .km-actionsheet > li > a {
  display: block;
  padding: .3em .8em;
  border-radius: 0;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
}
.km-wp li.km-actionsheet-title {
  font-weight: bold;
}
/* PopOver */
/* ModalView */
/* Scroller */
/* SplitView */
.km-on-blackberry.km-blackberry6 .km-view .km-icon,
.km-on-blackberry.km-blackberry7 .km-view .km-icon,
.km-on-blackberry.km-ios .km-view .km-icon,
.km-pane.km-on-android .km-view .km-icon,
.km-pane.km-on-meego .km-view .km-icon {
  background: none;
}
.k-chart,
.k-gauge,
.k-sparkline,
.k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-chart,
.k-stockchart {
  height: 400px;
}
div.k-chart,
div.k-gauge,
span.k-sparkline,
.k-stockchart {
  background-color: transparent;
}
.k-gauge {
  text-align: left;
  position: relative;
}
.k-baseline-marker {
  zoom: 1;
  *display: inline;
}
.k-chart-tooltip {
  border-radius: 4px;
  padding: 6px;
  white-space: nowrap;
  z-index: 12000;
  line-height: normal;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==);
  color: #fff;
}
.k-chart-tooltip-inverse {
  color: #000;
}
.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}
.k-chart-tooltip th {
  width: auto;
  text-align: center;
  padding: 1px;
}
.k-chart-tooltip td {
  width: auto;
  text-align: left;
  padding: .1em .2em;
}
/*Stock Charts*/
/* Selection */
.k-selector {
  position: absolute;
  -webkit-transform: translateZ(0);
}
.k-selection {
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  border-bottom: 0;
  height: 100%;
}
.k-selection-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.01);
  filter: alpha(opacity=1);
}
.k-handle {
  background: #d2d2d2;
  width: 7px;
  height: 26px;
  cursor: e-resize;
  z-index: 1;
  border-radius: 6px;
  position: absolute;
}
.k-handle div {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.k-leftHandle {
  left: -4px;
}
.k-rightHandle {
  right: -4px;
}
.k-leftHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 30px 0 0;
}
.k-rightHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 0 0 30px;
}
.k-mask {
  position: absolute;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=80);
  opacity: 0.80;
}
.k-border {
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
}
/* Navigator hint */
.k-navigator-hint div {
  position: absolute;
}
.k-navigator-hint .k-scroll {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background: #d2d2d2;
}
.k-navigator-hint .k-tooltip {
  margin-top: 20px;
  min-width: 160px;
  opacity: 1;
  text-align: center;
  border: 0;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background: #fff;
}
/* Sparklines */
.k-sparkline,
.k-sparkline span {
  display: inline-block;
  vertical-align: top;
}
.k-sparkline span {
  height: 100%;
  width: 100%;
}
/* Map */
.k-map,
.k-diagram {
  height: 600px;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper {
  padding-bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper,
.k-map .km-scroll-container,
.k-diagram .km-scroll-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.k-map .k-layer,
.k-diagram .k-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.k-map .km-touch-scrollbar,
.k-diagram .km-touch-scrollbar {
  display: none;
}
.k-map .k-marker {
  position: absolute;
  width: 28px;
  height: 40px;
  margin: -40px 0 0 -14px;
  cursor: pointer;
}
.k-map .k-marker-pin {
  background-position: 0px 40px;
}
.k-map .k-marker-pin-target {
  background-position: 0px 0px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    width: 56px;
    height: 80px;
    margin: -80px 0 0 -28px;
  }
  .k-map .k-marker-pin {
    background-position: 0px 80px;
  }
}
/* Control positions */
.k-map .k-pos-top {
  top: 0;
}
.k-map .k-pos-bottom {
  bottom: 0;
}
.k-map .k-pos-left {
  left: 0;
}
.k-map .k-pos-right {
  right: 0;
}
.k-map-controls {
  position: absolute;
}
.k-map-controls.k-pos-left .k-widget:first-child {
  margin-right: 0;
}
.k-map-controls.k-pos-right .k-widget:first-child {
  margin-left: 0;
}
/* Map navigator */
.k-navigator {
  width: 50px;
  height: 50px;
  margin: 20px;
  border-radius: 80px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.k-pdf-export .k-navigator {
  display: none;
}
.k-navigator > button {
  border-color: transparent;
  background: none;
}
div.k-navigator > .k-button {
  margin: 0;
  padding: 0;
  line-height: 10px;
  border-radius: 16px;
  position: absolute;
  font-size: 1px;
  /*IE7*/
  line-height: 1px;
}
div.k-navigator .k-navigator-n {
  top: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-e {
  right: 2px;
  top: 50%;
  margin-top: -9px;
}
div.k-navigator .k-navigator-s {
  bottom: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-w {
  left: 2px;
  top: 50%;
  margin-top: -9px;
}
/* Attribution */
.k-map .k-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
  padding: 2px 4px;
  z-index: 1000;
}
/* Zoom */
.k-zoom-control {
  margin: 14px;
  vertical-align: middle;
}
.k-pdf-export .k-zoom-control {
  display: none;
}
.k-button-wrap {
  border-radius: 4px;
  display: inline-block;
}
.k-button-wrap .k-button {
  position: relative;
  font: bold 17px/1.18 monospace;
}
.k-buttons-horizontal :first-child {
  border-radius: 4px 0 0 4px;
}
.k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: -1px;
}
.k-buttons-horizontal .k-zoom-out {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.k-button-wrap .k-button:hover {
  z-index: 1;
}
.k-buttons-vertical .k-button {
  display: block;
}
.k-buttons-vertical :first-child {
  border-radius: 4px 4px 0 0;
}
.k-buttons-vertical .k-zoom-out {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}
.k-zoom-text {
  margin: 0;
  width: 4.3em;
  vertical-align: top;
}
/* RTL */
.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: 0;
  margin-right: -1px;
}
.k-rtl .k-buttons-horizontal .k-zoom-out {
  border-radius: 4px 0 0 4px;
  margin-left: 0;
  margin-right: -1px;
}
/* Diagram */
.k-diagram {
  height: 600px;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-canvas-container {
  width: 100%;
  height: 100%;
}
/* IE8- */
.k-diagram img {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
/* TreeMap start */
.k-treemap {
  overflow: hidden;
  height: 400px;
}
.k-treemap-tile {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin: -1px 0 0 -1px;
  overflow: hidden;
}
.k-treemap-tile.k-leaf {
  padding: .6em;
}
.k-treemap-wrap.k-last > .k-treemap-tile {
  padding: .3em;
}
.k-treemap-tile.k-state-hover,
.k-treemap-tile.k-state-hover:hover {
  z-index: 2;
  background-image: none;
}
.k-treemap > .k-treemap-tile {
  position: relative;
  height: 100%;
}
.k-treemap-title {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.42em;
  height: 2.42em;
  padding: 0 .6em;
  white-space: nowrap;
}
.k-treemap-wrap .k-treemap-title {
  border-width: 0 0 1px;
  border-style: solid;
}
.k-treemap-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-treemap-title + .k-treemap-wrap {
  top: 2.42em;
}
.k-treemap-title-vertical {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.42em;
  line-height: 2.42em;
  overflow: hidden;
  padding: .6em 0;
  white-space: nowrap;
}
.k-treemap-title-vertical > div {
  position: absolute;
  top: 0;
  right: 1.23em;
  -webkit-transform-origin: right;
      -ms-transform-origin: right;
          transform-origin: right;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.k-treemap-title-vertical + .k-treemap-wrap {
  left: 2.42em;
}
/* TreeMap end */
